import { Component, OnInit, Compiler } from '@angular/core';
import { Router, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ProfileService } from '../profiles/profile.service';
import { LoginService, Principal, Account } from 'app/core';
@Component({
  selector: 'jhi-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class JhiMainComponent implements OnInit {
  isLoggedIn: any = false;
  inProduction: boolean;
  swaggerEnabled: boolean;
  imageUrl: any;
  isLeavePage: boolean;

  constructor(private titleService: Title, public router: Router, private principal: Principal, private profileService: ProfileService, private _compiler: Compiler) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/') {
          this.isLoggedIn = true;
        }
        if (event.url === '/invalidLogin') {
          this.isLoggedIn = false;
        }
       if (event.url.includes('/leave-management')) {
          this.isLeavePage = true;
        }
      }
    });
  }

  private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
    let title: string = routeSnapshot.data && routeSnapshot.data['pageTitle'] ? routeSnapshot.data['pageTitle'] : 'iTrack2App';
    if (routeSnapshot.firstChild) {
      title = this.getPageTitle(routeSnapshot.firstChild) || title;
    }
    return title;
  }

  ngOnInit() {
    this._compiler.clearCache();
  }

  changeOfRoutes() {
    this.isLoggedIn = localStorage.getItem('isAuth');
  }
}
