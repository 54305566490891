import { Component, OnInit } from '@angular/core';
import { ViewAccountService } from 'app/accounts/view-account/view-account.service';

@Component({
  selector: 'jhi-invalid-user',
  templateUrl: './invalid-user.component.html',
  styleUrls: ['./invalid-user.component.scss']
})
export class InvalidUserComponent implements OnInit {
  constructor(private accountService: ViewAccountService) {}

  ngOnInit() {
    this.accountService.invalidUser().subscribe(data => {});
  }

  closeWindow() {
    let win = window.open('about:blank', '_self');
    win.close();
  }
}
