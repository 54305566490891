import { Component, OnDestroy } from '@angular/core';
import { JhiEventManager, JhiAlertService } from 'ng-jhipster';
import { Subscription } from 'rxjs';

@Component({
  selector: 'jhi-alert-error',
  template: `
        <div class="alerts" role="alert">
            <div *ngFor="let alert of alerts"  [ngClass]="{\'alert.position\': true, \'toast\': alert.toast}">
                <ngb-alert *ngIf="alert && alert.type && alert.msg" [type]="alert.type" (close)="alert.close(alerts)">
                    <pre [innerHTML]="alert.msg"></pre>
                </ngb-alert>
            </div>
        </div>`
})
export class JhiAlertErrorComponent implements OnDestroy {
  alerts: any[];
  cleanHttpErrorListener: Subscription;
  /* tslint:disable */
  constructor(private alertService: JhiAlertService, private eventManager: JhiEventManager) {
    /* tslint:enable */
    this.alerts = [];

    this.cleanHttpErrorListener = eventManager.subscribe('itrack2App.httpError', response => {
      let i;
      let httpErrorResponse = response.content;
      switch (httpErrorResponse.status) {
        // connection refused, server not reachable
        case 0:
          this.addErrorAlert('Server not reachable', 'error.server.not.reachable');
          break;

        case 400:
          let arr = httpErrorResponse.headers.keys();
          let errorHeader = null;
          let entityKey = null;
          arr.forEach(entry => {
            if (entry.endsWith('app-error')) {
              errorHeader = httpErrorResponse.headers.get(entry);
            } else if (entry.endsWith('app-params')) {
              entityKey = httpErrorResponse.headers.get(entry);
            }
          });
          if (errorHeader) {
            let entityName = entityKey;
            this.addErrorAlert(errorHeader, errorHeader, { entityName });
          } else if (httpErrorResponse.error !== '' && httpErrorResponse.error.fieldErrors) {
            let fieldErrors = httpErrorResponse.error.fieldErrors;
            for (i = 0; i < fieldErrors.length; i++) {
              let fieldError = fieldErrors[i];
              // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
              let convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
              let fieldName = convertedField.charAt(0).toUpperCase() + convertedField.slice(1);
              this.addErrorAlert('Error on field "' + fieldName + '"', 'error.' + fieldError.message, { fieldName });
            }
          } else if (httpErrorResponse.error !== '' && httpErrorResponse.error.message) {
            this.addErrorAlert(httpErrorResponse.error.message, httpErrorResponse.error.message, httpErrorResponse.error.params);
          } else {
            this.addErrorAlert(httpErrorResponse.error);
          }
          break;

        case 404:
          this.addErrorAlert('Not found', 'error.url.not.found');
          break;

        default:
          if (httpErrorResponse.error !== '' && httpErrorResponse.error.message) {
            this.addErrorAlert(httpErrorResponse.error.message);
          } else {
            this.addErrorAlert(httpErrorResponse.error);
          }
      }
    });
  }

  ngOnDestroy() {
    if (this.cleanHttpErrorListener !== undefined && this.cleanHttpErrorListener !== null) {
      this.eventManager.destroy(this.cleanHttpErrorListener);
      this.alerts = [];
    }
  }

  addErrorAlert(message, key?, data?) {
    this.alerts.push(
      this.alertService.addAlert(
        {
          type: 'danger',
          msg: message,
          timeout: 5000,
          toast: this.alertService.isToast(),
          scoped: true
        },
        this.alerts
      )
    );
  }
}
