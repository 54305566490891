import { Component, OnInit, Input } from '@angular/core';
import { DEFAULT_DATE_FORMAT } from '../app.constants';

@Component({
    selector: 'jhi-audit',
    templateUrl: './audit.component.html',
    styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {
    @Input() historyData: any;
    @Input() task: any;
    DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
    constructor() { }

    ngOnInit() { }
}
