import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'app/material.module';
import { HttpClientModule } from '@angular/common/http';
import { Itrack2SharedModule } from 'app/shared';
import { HOME_ROUTE, HomeComponent } from './';
import { ResourceService } from 'app/resource/resource.service';
import { makeReferralPopup } from 'app/Referrals/submit-referral/submit-referral.component';

@NgModule({
  imports: [Itrack2SharedModule, HttpClientModule, MaterialModule, RouterModule.forChild([HOME_ROUTE])],
  declarations: [HomeComponent],
  entryComponents: [makeReferralPopup],
  providers: [ResourceService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class Itrack2HomeModule {}
