<div *ngIf="data.selectedOption == 1">
    <h1 mat-dialog-title>Update Office</h1>
    <div mat-dialog-content>
        <form [formGroup]="EditForm">
            <!-- <mat-grid-list cols="3" rowHeight="60px"> -->
            <div class="col-sm-12 col-md-6 col-lg-4 float_left_class">
                <mat-form-field>
                    <mat-label>Company Name</mat-label>
                    <input matInput formControlName="companyName" value="{{data.companyName}}">
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-4 float_left_class">
                <mat-form-field>
                    <mat-select placeholder="Country*" formControlName="countryCode" [(ngModel)]="selectedCountry">
                        <mat-divider></mat-divider>
                        <mat-option *ngFor="let country of allCountries" [value]="country.countryCode"
                            (click)="countrySelected(country)">
                            {{country.countryName}} ( {{country.countryCode}} )
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-4 float_left_class">
                <mat-form-field>
                    <mat-select placeholder="State*" formControlName="stateName" [(ngModel)]="selectedState">
                        <mat-divider></mat-divider>
                        <div *ngFor="let country of allCountries">
                            <div *ngIf="country.countryCode == selectedCountry">
                                <mat-option *ngFor="let state of country.states" [value]="state.stateName"
                                    (click)="stateSelected(state)">
                                    {{state.stateName}}
                                </mat-option>
                            </div>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 float_left_class">
                <mat-form-field>
                    <input matInput placeholder="City*" formControlName="cityName" [(ngModel)]="selectedCity"
                        [maxlength]="50">
                    <mat-error *ngIf="EditForm.get('cityName').hasError('required')">This is
                        required field</mat-error>
                </mat-form-field>
            </div>
            <!-- </mat-grid-list> -->
        </form>
    </div>
    <div mat-dialog-actions class="buttons-position">
        <button mat-raised-button (click)="Close()">Cancel</button>
        <button mat-raised-button (click)="Edit()" [disabled]="!EditForm.valid" class="add-button-css">Save</button>
    </div>
</div>