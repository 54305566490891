import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';
import { Principal } from 'app/core/auth/principal.service';

@Directive({
    selector: '[jhiHasAnyAuthorityConditional]'
})
export class HasAnyAuthorityConditionalDirective implements OnInit {
    private authorities: string[];

    constructor(private principal: Principal, private renderer: Renderer2, private el: ElementRef) { }

    ngOnInit() {
        this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
    }

    @Input()
    set jhiHasAnyAuthorityConditional(value: string | string[]) {
        this.authorities = typeof value === 'string' ? [value] : value;
        this.hasAnyAuthority();
    }

    private hasAnyAuthority(): void {
        this.principal.hasAnyAuthority(this.authorities).then(result => {
            if (result) {
                this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
            }
        });
    }
}
