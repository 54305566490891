import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'jhi-submit-timesheet-legend',
  templateUrl: './submit-timesheet-legend-view.component.html',
  styleUrls: ['./submit-timesheet-legend-view.component.css']
})
export class SubmitTimesheetLegendViewComponent implements OnInit {
  @Input() public standardHours;
  public Hours;

  constructor(public dialog: MatDialog) {}
  ngOnInit() {
    this.Hours = this.standardHours;
  }

  openDialog() {
    let dialogRef = this.dialog.open(DialogContentExampleDialogComponent);
    dialogRef.componentInstance.day = this.standardHours.day;
    dialogRef.componentInstance.week = this.standardHours.week;
    dialogRef.afterClosed().subscribe(result => {});
  }
}

@Component({
  selector: 'jhi-dialog-content-example-dialog',
  templateUrl: 'dialog-content-example-dialog.html',
  styleUrls: ['./submit-timesheet-legend-view.component.css']
})
export class DialogContentExampleDialogComponent {
  day: string;
  week: string;
  @Input() public standardHours;
}
