import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { enviornment } from '../../environments/environment'
import { map } from 'rxjs/operators';
let httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class ReferralsService {
    deleteAlloc(allocationId: any) {
        throw new Error('Method not implemented.');
    }
    constructor(private http: HttpClient) { }

    /* Applicant Service */
    saveApplicantDetails (action, formData): Observable<any> {
        let formData1 = new FormData();
        let httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'multipart/form-data; charset=UTF-8'})
        };
        let payLoadData={
            alternateContactNumber: formData.altContactNumber ? formData.altContactNumber : '',
            applicantId: formData.appId? formData.appId:'',
            applicantStatus: formData.applicantStatus,
            contactNumber: formData.primaryTelNumber,
            creation_date: formData.creation_date? formData.creation_date:'',
            emailId: formData.email,
            file: formData.fileDetails,
            jobId: formData.jobId,
            jobTitle: formData.jobTitle,
            message: formData.messageArea ? formData.messageArea : '',
            name: formData.fullName,
            resourceCode: formData.resourceCode,
            resumePath: formData.resumePath? formData.resumePath:'',
            rewards: formData.rewards,
        };
        formData1.append('alternateContactNumber', payLoadData.alternateContactNumber);
        formData1.append('applicantID', payLoadData.applicantId);
        formData1.append('applicantStatus', payLoadData.applicantStatus);
        formData1.append('contactNumber', payLoadData.contactNumber);
        formData1.append('creation_date', payLoadData.creation_date);
        formData1.append('emailId', payLoadData.emailId);
        formData1.append('file', payLoadData.file);
        formData1.append('jobId', payLoadData.jobId);
        formData1.append('jobTitle', payLoadData.jobTitle);
        formData1.append('message', payLoadData.message);
        formData1.append('name', payLoadData.name);
        formData1.append('resourceCode', payLoadData.resourceCode);
        formData1.append('resumePath', payLoadData.resumePath);
        formData1.append('rewards', payLoadData.rewards);
        if (action==='save') {
            return this.http.post(`${enviornment.apiUrl}/applicants/`, formData1, {}).pipe(map((response: HttpResponse<any>) => {return response;}));
        }
    }
    getAllApplicants (): Observable<any> {
        return this.http.get(`${enviornment.apiUrl}/applicants/getAllApplicants`,{}).pipe(map((response: HttpResponse<any>) => { return response; }));
    }
    updateApplicant (formData): Observable<any> {
        return this.http.put(`${enviornment.apiUrl}/applicants/`,formData,{}).pipe(map((response: HttpResponse<any>) => {return response;}));
    }
    getApplicant (id): Observable<any> {
            return this.http.get(`${enviornment.apiUrl}/applicants/`+id, {}).pipe(map((response: HttpResponse<any>) => {return response;}));
    }
    deleteApplicant (id): Observable<any> {
            return this.http.delete(`${enviornment.apiUrl}/applicants/`+id);
    }

    getMyApplicant (resourceCode): Observable<any> {
            return this.http.get(`${enviornment.apiUrl}/applicants/myapplicants/`+resourceCode, {}).pipe(map((response: HttpResponse<any>) => { return response; }));
    }
    getReferredby (applicantId,resourceCode): Observable<any> {
            return this.http.get(`${enviornment.apiUrl}/applicants/referredBy`+'?applicantId='+applicantId + '&resourceCode='+resourceCode, {}).pipe(map((response: HttpResponse<any>) => { return response; }));
    }
    getAllStatus (): Observable<any> {
        return this.http.get(`${enviornment.apiUrl}/applicants/getAllStatus`,{}).pipe(map((response: HttpResponse<any>) => { return response; }));
    }

    /* Job Service  */
    getAlljobs (experience, jobTitle, location, practices, dashboard,   ): Observable<any> {
            return this.http.get(`${enviornment.apiUrl}/Jobs/getAllJobs`+'?expMin='+experience+'&jobTitle='+jobTitle+'&location='+location+'&dashboard='+dashboard+'&practice='+practices, {}).pipe(map((response: HttpResponse<any>) => { return response; }));
    }
    getAllJobsWithResume (jobTitle, status): Observable<any> {
            return this.http.get(`${enviornment.apiUrl}/Jobs/getAllJobsWithResume`+'?jobTitle='+jobTitle+'&status='+status, {}).pipe(map((response: HttpResponse<any>) => { return response; }));
    }

    uploadJobs (fileToUpload: File): Observable<any> {
        let formData = new FormData();
        let httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'multipart/form-data; charset=UTF-8'})
        };
        formData.append('file', fileToUpload);
            return this.http.post(`${enviornment.apiUrl}/Jobs/uploadJobs`, formData, {})
                .pipe(map((response: HttpResponse<any>) => {
                    return response;
                })
                );
    }

    /* Document Service */
    downloadResume (id): Observable<HttpResponse<Blob>> {
        return this.http.get<Blob>(`${enviornment.apiUrl}/documents/download/`+id, {
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }

    downloadResumeHeader (id): Observable<any> {
        return this.http.get(`${enviornment.apiUrl}/documents/downloadFileHeader/`+id, {});
    }

    /*Comment Service*/
    addComment (payload): Observable<any> {
        return this.http.post(`${enviornment.apiUrl}/comment/`+ '?applicantId='+payload.applicantId + '&comment='+payload.comment + '&comment_by=' + payload.comment_by,payload);
    }

    updateComment (payload): Observable<any> {
        return this.http.put(`${enviornment.apiUrl}/comment/`+ '?applicantId='+payload.applicantId + '&comment='+payload.comment + '&comment_by=' + payload.comment_by,payload);
    }

    getComment(applicantId): Observable<any> {
        return this.http.get(`${enviornment.apiUrl}/comment/`+applicantId, {}).pipe(map((response: HttpResponse<any>) => { return response; }));
    }
    deleteComment (commentId): Observable<any> {
        return this.http.delete(`${enviornment.apiUrl}/comment/`+commentId, {}).pipe(map((response: HttpResponse<any>) => { return response; }));
    }
    updatejob(jobsedit) : Observable<any>{
        return this.http.put(`${enviornment.apiUrl}/Jobs/`,jobsedit ,{}).pipe(map((response: HttpResponse<any>) => { return response; }));
    }
    deleteCommentjobs(disabledThe): Observable<any>{
        return this.http.patch(`${enviornment.apiUrl}/Jobs/disabledTheJobById?jobId=`+disabledThe, {}).pipe(map((response: HttpResponse<any>) => { return response; }));
    }
    getAllParentPractices (): Observable<any> {
        return this.http.get(`${enviornment.apiUrl}/Jobs/getAllParentPractices`, {});
    }
    getAllLocations(): Observable<any> {
        return this.http.get(`${enviornment.apiUrl}/applicants/getAllLocations`);
    }
    newjobNotification(payload): Observable<any> {
        return this.http.post(`${enviornment.apiUrl}/Jobs/jobNotification`,payload);
    }

    unscheduleJobNotification(jobId): Observable<any>{
        return this.http.patch(`${enviornment.apiUrl}/Jobs/unscheduledNotification?jobId=`+jobId,{});
    }
    sendOnDemandNotification(payload) : Observable<any> {
        return this.http.post(`${enviornment.apiUrl}/Jobs/sendOnDemandNotification`,payload,{});
    }
}
