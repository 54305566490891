<div class="row">
    <div class="col-10">
        <h1 mat-dialog-title class="dialog-title"><strong> Make a Referral - {{jobName}}
            </strong></h1>
        <sub>Provide us with some information on your referral</sub>
    </div>
    <div class="col-2">
        <span class="close-icon material-icons" (click)="onNoClick()">close</span>
    </div>
</div>
<mat-divider class="spacing-divider"></mat-divider>
<br>

<div class="col-12">
    <p> <mat-hint>Please attach the resume</mat-hint></p>
    <input type="file" required
        accept=".doc,.docx,.pdf"
        id="file-upload" (change)="handleFileInput($event.target.files)" style="outline: none;" />
    <img id="delete-icon" *ngIf="formFileSelected" (click)="removeFile($event)" src="../../assets/delete.png"
        class="cancel-icon" alt="Cancel" />
        <mat-error *ngIf="!formFileSelected">Please upload a .docx or .pdf file only</mat-error>
</div>

<!-- </div> -->
<br>
<div class="row container">
    <p class="referral-details"><strong> Referral Details</strong></p>
    <form [formGroup]="makeReferralForm">
        <div class="col-sm-12 col-md-6 col-lg-6" style="float: left;">
            <p>
                <mat-form-field class="example-full-width">
                    <input matInput formControlName="fullName" placeholder="Full Name" required>
                    <mat-error *ngIf="makeReferralForm.get('fullName').hasError('required')">This field is a required
                        field.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field class="example-full-width">
                    <input type="text" (keypress)="validateNumber
                    ($event)" required pattern="([0-9]+)" minlength="10" maxlength="10" matInput
                        formControlName="primaryTelNumber" placeholder="Mobile Number">
                    <mat-error *ngIf="makeReferralForm.get('primaryTelNumber').hasError('required')">
                        This field is a required field.
                    </mat-error>
                    <mat-error *ngIf="makeReferralForm.get('primaryTelNumber').hasError('minlength')">A contact number
                        should be of 10 digits.</mat-error>
                </mat-form-field>
            </p>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6" style="float: left;">
            <p>
                <mat-form-field class="example-full-width">
                    <input matInput formControlName="email" required placeholder="Email" maxlength="200"
                        style="text-transform:lowercase">
                    <mat-error *ngIf="makeReferralForm.get('email').hasError('required')">
                        This field is a required field.
                    </mat-error>
                    <mat-error *ngIf="makeReferralForm.get('email').hasError('email')">Enter a proper email
                        address.</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field class="example-full-width">
                    <input type="text" (keypress)="validateNumber
                    ($event)" pattern="([0-9]+)" minlength="10" maxlength="10" matInput
                        formControlName="altContactNumber" placeholder="Alternate Mobile Number">
                    <mat-error *ngIf="makeReferralForm.get('altContactNumber').hasError('minlength')">A contact number
                        should be of 10 digits.</mat-error>
                </mat-form-field>
            </p>
        </div>
        <div class="col-12" style="float: left;">
            <p>
                <mat-form-field class="example-full-width">
                    <textarea matInput formControlName="messageArea" placeholder="Message (optional)"></textarea>
                </mat-form-field>
            </p>
        </div>
    </form>
</div>
<div class="col-12" style="float: left;">
    <div style="float: right;">
        <button mat-raised-button (click)="resetData()">Reset</button>
        <button mat-raised-button [disabled]="!makeReferralForm.valid || !formFileSelected" class="submit-button-dialog"
            (click)="submitReferral()">Submit</button>
    </div>
</div>
<!-- <ng4-loading-spinner *ngIf="isLoading"></ng4-loading-spinner> -->