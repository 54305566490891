import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { NotificationsService } from 'app/interceptors/notifications/notifications.service';

@Component({
  selector: 'jhi-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
    } else if (event.target.innerHTML === '×') {
      event.preventDefault();
      // this.interceptor.clearAll();
    } else {
      this.interceptor.clearAll();
    }
  }
  constructor(public interceptor: NotificationsService, private eRef: ElementRef) {}

  ngOnInit() {}

  // close notification when click on 'x' button individual
  closeAlert(alert) {
    this.interceptor.closeAlert(alert);
  }
}
