<div ngbDropdown class="d-inline-block" *ngIf="monthPendingSince && dateRange">
    <button *ngIf="dateRange.check" [disabled]="monthPendingSince.pendingCount==0" [ngClass]="{'btn btn-outline-primary': true}"
        id="weeklyPending" ngbDropdownToggle>Pending
        <span class="badge badge-secondary">{{ monthPendingSince.pendingCount }}</span>
    </button>
    <button *ngIf="!dateRange.check" [disabled]="monthPendingSince.pendingCount==0" [ngClass]="{'monthly-view': true, 'btn btn-outline-primary': true}"
        id="weeklyPending" ngbDropdownToggle>Pending
        <span class="badge badge-secondary">{{ monthPendingSince.pendingCount }}</span>
    </button>
    <div ngbDropdownMenu aria-labelledby="weeklyPending" class="pending-weeks-popup">
        <table class="pendingDropdown-layout" *ngIf="monthPendingSince.weekStatuses">
            <tr>
                <th>
                    Week
                </th>
                <th>
                    Status
                </th>
            </tr>
            <tr *ngFor="let week of monthPendingSince.weekStatuses">
                <td>
                    <p (click)="week.week.weekStartDate && pickDate(week.week.weekStartDate)">{{ week.week.weekName }}</p>
                </td>
                <td>
                    <span *ngIf="week.status.description">{{ week.status.description }}</span>
                    <span *ngIf="!week.status.description">&nbsp;-&nbsp;</span>
                </td>
            </tr>
        </table>
        <div *ngIf="!monthPendingSince.weekStatuses" class="alert alert-info">
            <strong>Info!</strong> No pending actions
        </div>
    </div>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <p class="dateRanges">
        <span *ngIf="!dateRange.check"><strong>for {{month}}</strong></span>
        <span *ngIf="dateRange.check">
            <strong>{{dateRange.fromDate}}<br />{{dateRange.toData}}</strong>
        </span>
    </p>

</div>