<footer class="container-fluid">
    <div class="other-links">
        <div class="row">
            <div class="col other-links-title"><strong>Other Links: </strong>
                <a href="https://gspann.greythr.com/login.do" target="_blank" > GreytHr |</a>
                <a href="https://gspann.freshservice.com/" target="_blank" > Tech Ops</a>
            </div>
        </div>
    </div>
    <div>
        <p>
            <strong>A GSPANN Product</strong>. Copyright &copy; {{currentDate | date: 'yyyy'}} GSPANN Technologies, Inc. All Rights Reserved. <a skipLocationChange routerLink="/privacypolicy"target="_blank" >Privacy Policy</a>
        </p>
    </div>
</footer>