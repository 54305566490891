import { Component, OnInit, ComponentFactoryResolver, Type, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { TimesheetService } from './../timesheet.service';
import { WeekTimesheetViewComponent } from '../week-timesheet-view/week-timesheet-view.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
// import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeComponent, OwlDateTimeFormats } from 'ng-pick-datetime';

import * as _moment from 'moment';
import { Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
const moment = (_moment as any).default ? (_moment as any).default : _moment;
import { CommonDataService } from 'app/common-data.service';

// export const MY_MOMENT_DATE_TIME_FORMATS: OwlDateTimeFormats = {
//     parseInput: 'DD/MM/YYYY',
//     fullPickerInput: 'l LT',
//     datePickerInput: 'DD/MM/YYYY',
//     timePickerInput: 'LT',
//     monthYearLabel: 'MMM YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'MMMM YYYY'
// };
@Component({
    selector: 'jhi-previous-timesheet-view',
    templateUrl: './previous-timesheet-view.component.html',
    styleUrls: ['./previous-timesheet-view.component.css'],
    providers: [
        // `MomentDateTimeAdapter` and `OWL_MOMENT_DATE_TIME_FORMATS` can be automatically provided by importing
        // `OwlMomentDateTimeModule` in your applications root module. We provide it at the component level
        // here, due to limitations of our example generation script.
        // { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
        // { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_DATE_TIME_FORMATS }
    ]
})
export class PreviousTimesheetViewComponent implements OnInit {
    displayedColumns = ['weekOf', 'submittedOn', 'status', 'comments', 'quickLinks'];
    timesheets: any;
    dateRange: any = {
        check: false
    };
    month: any;
    weekData: any;
    weekObj: any = {};
    minDate: any;
    maxDate: any;
    resourceCode:any;
    monthDpModel: any;
    monthObj: any = {};
    monthData: any;
    selectedMonthDate: Date;
    loading: any = true;
    public monthPendingSince: any = {};
    WeekTimeSheetObj = [];
    timesheetData: any;
    leaveTypes = [];
    private standard_hours: any;
    private months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    name: any;
    expandedElement: any;
    dynamicComponentRef: any;
    timesheetsData: any = {};
    timesheetActorImage: Array<any>;
    @ViewChild(WeekTimesheetViewComponent) WeekTScomponent: WeekTimesheetViewComponent;
    @ViewChild('monthPicker') monthPicker: MatDatepicker<Date>;
    public date = new FormControl(moment());
    isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private router: Router,
        private timesheetService: TimesheetService,
        private route: ActivatedRoute,
        private commonData: CommonDataService
    ) {
        this.selectedMonthDate = new Date();
        this.selectedMonthDate.setDate(1)
     }
    chosenYearHandler(normalizedYear: Moment) {
        let ctrlValue = this.date.value;
        ctrlValue.year(normalizedYear.year());
        this.date.setValue(ctrlValue);
    }

    // chosenMonthHandler(normalizedMonth: Moment, datepicker: OwlDateTimeComponent<Moment>) {
    //     // let ctrlValue = this.date.value;
    //     // ctrlValue.month(normalizedMonth.month());
    //     // this.date.setValue(ctrlValue);
    //     datepicker.close();
    // }

    public routeWeekTS(date: any): void {
        // console.log('Picked date: ', date);
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            if (params.resourceCode) {
                this.resourceCode = params.resourceCode;
            }
        })
        setTimeout(() => {
            if(this.route.component.length == 8){
                this.resourceCode = sessionStorage.getItem('resourceCode');
            } else if (this.route.component.length == 14) {
                this.resourceCode = this.commonData.resourceCode; 
            }   
            if (!this.resourceCode) {
                this.resourceCode = sessionStorage.getItem('resourceCode');
            }
            this.getMonthTSData(this.resourceCode);
        }, 2000);
    }

    public pickDate(date: any): void {
        let d = new Date();
        let n = d.valueOf();
        let url = 'timesheet-view/' + date + '_' + n;
        this.router.navigateByUrl(url,{skipLocationChange: true});
    }

    getMonthPendingSince(month?) {
        // this.selWeekTS = value;
        this.timesheetService.getMonthPendingSince(month).subscribe(monthPendingSince => {
            this.monthPendingSince = monthPendingSince;
        });
    }

    selectedMonth(calenderDate) {
        let selDate = new Date(calenderDate);
        let selYear = selDate.getFullYear();
        let selMonth = selDate.getMonth() + 1;
        let month;
        if (selMonth < 10) {
            month = selYear + '-' + '0' + selMonth;
        } else {
            month = selYear + '-' + selMonth;
        }
        this.getMonthTSData(this.resourceCode,month);
        this.monthPicker.close()
        this.selectedMonthDate = selDate
        this.selectedMonthDate.setDate(1)
    }
    getMonthTSData(resourcecode: any, month?: any): void {
        let that = this;
        this.timesheetActorImage = [];
        that.timesheetService.getMonthlyTSData(resourcecode, month).subscribe(monthtimesheetData => {
            this.standard_hours = {
                week: monthtimesheetData.weeklyStandardHours,
                day: monthtimesheetData.dailyStandardHours
            };
            monthtimesheetData.timesheets.forEach(weekTSData => {
                if (weekTSData.status && weekTSData.status.actor) {
                    if (this.timesheetActorImage && this.timesheetActorImage.length > 0) {
                        let check = false;
                        this.timesheetActorImage.forEach(element => {
                            if (weekTSData.status.actor.code === element.code) {
                                check = true;
                                this.timesheetActorImage.push(element);
                            }
                        });
                        // if (!check) {
                        //     this.timesheetService.getImage(weekTSData.status.actor._links['itrack:image'].href).subscribe((imageData: any) => {
                        //         this.timesheetActorImage.push({ name: weekTSData.status.actor.name, code: weekTSData.status.actor.code, img: imageData });
                        //     });
                        // }
                    } else {
                        // this.timesheetService.getImage(weekTSData.status.actor._links['itrack:image'].href).subscribe((imageData: any) => {
                        //     this.timesheetActorImage.push({ name: weekTSData.status.actor.name, code: weekTSData.status.actor.code, img: imageData });
                        // });
                    }
                } else {
                    this.timesheetActorImage.push({ name: '', code: '', img: '' });
                }
            });
            this.monthData = monthtimesheetData;
            this.getMonthPickData();
            this.getWeeksData(this.monthData.timesheets);
        });
        this.getMonthPendingSince(month);
    }

    updateTimesheetData(date?) {
        date = new Date(date);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();

        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        date = year + '-' + month;
        this.getMonthTSData(this.resourceCode,date);
    }

    getMonthPickData() {
        this.monthObj.previousMonthEnabled = this.monthData.previousMonth && this.monthData.previousMonth != null ? true : false;
        this.monthObj.previousMonthStartDate = this.monthData.previousMonth && this.monthData.previousMonth.yearMonth;
        this.monthObj.previousMonth = this.monthData.previousMonth && this.monthData.previousMonth.monthName;
        this.monthObj.nextMonthEnabled = this.monthData.nextMonth && this.monthData.nextMonth != null ? true : false;
        this.monthObj.nextMonthStartDate = this.monthData.nextMonth && this.monthData.nextMonth.yearMonth;
        this.monthObj.nextMonth = this.monthData.nextMonth && this.monthData.nextMonth.monthName;
        this.monthObj.currentMonthStartDate = this.monthData.monthDetails.yearMonth;
        this.monthObj.currentMonth = this.monthData.monthDetails.monthName;
        this.minDate = new Date(this.monthData.timesheetSettings.timesheetsWindow.windowStartDate);
        this.maxDate = new Date(this.monthData.timesheetSettings.timesheetsWindow.windowEndDate);
        this.monthDpModel = new Date(this.monthObj.currentMonthStartDate);
    }
    getWeeksData(monthTSData) {
        this.WeekTimeSheetObj = [];
        for (let weekTSData of monthTSData) {
            let weekParseData: any = this.bindweekData(weekTSData);
            let s = _.cloneDeep(weekParseData);
            s.projects.forEach(project => {
                project.hours.forEach((element, index) => {
                    if (weekTSData.weekMetaData && weekTSData.weekMetaData.dayDetails && weekTSData.weekMetaData.dayDetails[index]) {
                        element.remarks = weekTSData.weekMetaData.dayDetails[index].remarks; 
                     }else{
                        element.remarks ='';
                     }
                });
            });
            this.WeekTimeSheetObj.push(s);
        }
        this.loading = false;
    }

    bindweekData(weeklyTSdata) {
        this.timesheetData = this.parseModel(weeklyTSdata);
        this.weekData = this.createInitialTimesheetData();
        return this.weekData;
    }

    parseModel(timesheetData) {
        this.weekObj.code = timesheetData.status.timesheetStatus.code;
        this.weekObj.status = timesheetData.status.timesheetStatus.value;
        this.weekObj.statusObj = timesheetData.status;
        this.weekObj.description = timesheetData.status.timesheetStatus.description;
        let timeEntries = {
            hours: '',
            comments: ''
        };
        timesheetData.timesheet.allocationWiseTimeSheets[0].dailyEntries.forEach(day => {
            day.timeEntries = timeEntries;
        });
        return timesheetData;
    }

    createInitialTimesheetData() {
        if (this.timesheetData.timesheet) {
            this.weekObj.timesheet = this.timesheetData.timesheet;
        } else {
            this.weekObj.timesheet = false;
        }
        this.weekObj.projects = this.createProjectsModel(this.timesheetData.timesheet.allocationWiseTimeSheets);
        this.weekObj.daysData = this.createDaysData(this.timesheetData.timesheet.totalEntries);
        this.weekObj.weekName = this.timesheetData.weekMetaData.weekDetails.weekName;
        this.weekObj.actions = this.timesheetData.status.actions;
        this.weekObj.startDate = this.timesheetData.weekMetaData.weekDetails.weekStartDate;
        this.weekObj.endDate = this.timesheetData.weekMetaData.weekDetails.weekEndDate;
        this.weekObj.weeklyStandardHours = this.timesheetData.weekMetaData.weekDetails.weeklyStandardHours;
        this.weekObj.commentsAdded = true;
        this.weekObj.leaves = [];
        this.weekObj.previousWeekEnabled = this.timesheetData.previousWeek && this.timesheetData.previousWeek != null ? true : false;
        this.weekObj.previousWeekStartDate = this.weekObj.previousWeekEnabled && this.timesheetData.previousWeek.week.weekStartDate;
        this.weekObj.nextWeekEnabled = this.timesheetData.nextWeek && this.timesheetData.nextWeek != null ? true : false;
        this.weekObj.nextWeekStartDate = this.weekObj.nextWeekEnabled && this.timesheetData.nextWeek.week.weekStartDate;
        this.weekObj.next_week_date_param = false;
        this.weekObj.previous_week_date_param = false;
        this.weekObj.lastUpdatedOn = this.timesheetData.status.lastUpdatedOn.substring(
            0,
            this.timesheetData.status.lastUpdatedOn.lastIndexOf(' ')
        );
        return this.weekObj;
    }

    createProjectsModel(projects) {
        let projectsModel = [];
        let condition;
        if (projects.length > 0) {
            projects.forEach(project => {
                let dataObj = {
                    code: '',
                    tooltip: '',
                    name: '',
                    attachment: '',
                    type: '',
                    hours: [],
                    totalHours: '',
                    allocationSummary: {}
                };
                if (this.weekObj.code === 'PENDING_SUBMISSION') {
                    condition = project.wbs.project.type.key !== 'PDL' && project.wbs.project.type.key !== 'UPL';
                    dataObj.code = project.wbs.project.identifier.key;
                    dataObj.name = project.wbs.project.identifier.value + ' (' + project.occupancy + '%)';
                    dataObj.tooltip = dataObj.code + ' _ ' + dataObj.name;
                    dataObj.attachment = project.clientTimeTracking;
                    dataObj.type = project.wbs.project.type.key;
                    dataObj.hours = [
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' }
                    ];
                    dataObj.allocationSummary = project;
                } else if (
                    this.weekObj.code === 'SUBMITTED' ||
                    this.weekObj.code === 'PARTIALLY_SUBMITTED' ||
                    this.weekObj.code === 'SAVED' ||
                    this.weekObj.code === 'REJECTED' ||
                    this.weekObj.code === 'APPROVED' ||
                    this.weekObj.code === 'PARTIALLY_APPROVED' ||
                    this.weekObj.code === 'PARTIALLY_APPROVED_REJECTED' ||
                    this.weekObj.code === 'PARTIALLY_APPROVED_PENDING' ||
                    this.weekObj.code === 'PARTIALLY_REJECTED_PENDING'
                ) {
                    condition = true;

                    dataObj.code = project.allocationSummary.wbs.project.identifier.key;
                    dataObj.name = project.allocationSummary.wbs.project.identifier.value + ' (' + project.allocationSummary.occupancy + '%)';
                    dataObj.tooltip = dataObj.code + ' _ ' + dataObj.name;
                    dataObj.attachment = project.allocationSummary.clientTimeTracking;
                    dataObj.type = project.allocationSummary.wbs.project.type.key;
                    project.dailyEntries.forEach(day => {
                        let hour = this.getTime(day.hours);
                        dataObj.hours.push({ hour: (hour), comments: day.comments });
                    });
                    dataObj.totalHours = project.projectTotalHours;
                    if (project.allocationSummary) {
                        dataObj.allocationSummary = project.allocationSummary;
                    }
                }
                if (condition) {
                    let projObj = {
                        code: dataObj.code,
                        name: dataObj.name,
                        tooltip: dataObj.tooltip,
                        hours: dataObj.hours,
                        type: dataObj.type,
                        totalHours: dataObj.totalHours,
                        allocationSummary: dataObj.allocationSummary
                    };
                    projectsModel.push(projObj);
                } else {
                    let projObj = {
                        code: dataObj.code,
                        tooltip: dataObj.tooltip,
                        name: dataObj.name,
                        hours: dataObj.hours,
                        type: dataObj.type,
                        totalHours: dataObj.totalHours
                    };
                    this.leaveTypes.push(projObj);
                }
            });
        }
        return projectsModel;
    }

    public getTime(timeString) {
        let hours = '';
        let timeArray = timeString.match(/[0-9]+/g);
            timeArray.forEach((ele, index) => {
                if (ele === '30') {
                    if (ele === '30' && index === 0 && (timeString.charAt(timeString.indexOf('30') + 2) === 'H')) {
                        hours = ele;
                    } else if (ele === '30' && index === 0 && (timeString.charAt(timeString.indexOf('30') + 2) === 'M')){
                        hours = '0.5';
                    } else {
                        hours = hours + '.5';
                    }
                } else {
                    hours = ele;
                }
            });
        return hours;
    }

    createDaysData(daysData) {
        let daysViewModel = [];
        if (daysData.length > 0) {
            daysData.forEach((dayObj, index) => {
                let dModel: any = {};
                let splittedDateObj = dayObj.date.split('-');
                dModel.date = splittedDateObj[2];
                dModel.month = this.months[parseInt(splittedDateObj[1], 10) - 1];
                dModel.day = dayObj.day.substr(0, 3);
                dModel.remarks = dayObj.remarks;
                dModel.code = dayObj.type.code;
                dModel.weekOffchecked = dayObj.weekOff;
                if (this.weekObj.timesheet) {
                    dModel.dailyComment = this.weekObj.timesheet.totalEntries[index].dailyComment;
                } else {
                    dModel.dailyComment = null;
                }
                daysViewModel.push(dModel);
            });
        }
        return daysViewModel;
    }
}

export class DetailedRowData {
    constructor(public component: Type<any>, public data: any) { }
}
