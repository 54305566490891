<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Login & Timesheet">
      <div class="training_m">
          <h2>Login</h2>
          <p>User can check Resource On-boarded mail.</p>
          <img src="../assets/Trainings/timesheet/login1.png" />
          <p>Then copy One-Time Password.</p>
          <img src="../assets/Trainings/timesheet/login2.png" />
          <p>Then click on Login link</p>
          <img src="../assets/Trainings/timesheet/login2.png" />
          <p>Then enter password to corresponding window.</p>
          <img src="../assets/Trainings/timesheet/login4.png" />
          <img src="../assets/Trainings/timesheet/login5.png" />
          <p>Then user able to look Aperture homepage.</p>
          <img src="../assets/Trainings/timesheet/login6.png" />
          <p>If user able to look own name means login successfully in Aperture.</p>
          <p>Note: For future login user can use Gspann mail Id & password.</p>
          <br />
          <h2>Timesheet</h2>
          <img src="../assets/Trainings/timesheet/timesheet1.png" />
          <p class="bld">Login to aperture:</p>
          <p>Example:</p>
          <p>Login ID/ Pwd: abc@gspann.com / XXXXX@12</p>
          <h3>My Timesheet</h3>
          <h4>User can see My Timesheet in 2 ways-</h4>
          <p>i) From Aperture Homepage</p>
          <p>User can see pending Timesheet by clicking on “Submit” button.</p>
          <img src="../assets/Trainings/timesheet/timesheet1.png" />
          <p>ii) By clicking on "Timesheet" tab</p>
          <p>Select "My Timesheets"</p>
          <img src="../assets/Trainings/timesheet/timesheet2.png" />
          <p>Then user can see “My Timesheet” page.</p>
          <img src="../assets/Trainings/timesheet/timesheet3.png" />
          <h3>2. Navigate Timesheet ( Weekly,Monthly,Date Range)</h3>
          <h4>Weekly</h4>
          <p>i) User navigate to see current & previous week Timesheet by clicking on forward backward arrow.</p>

          <img src="../assets/Trainings/timesheet/timesheet4.png" />
          <p>ii) User can also navigate to see weekly Timesheet by clicking on calendar.</p>
          <img src="../assets/Trainings/timesheet/timesheet5.png" />
          <h3>Monthly</h3>
          <p>i) User can navigate to see Timesheet in month wise by clicking on “Month” and navigate by arrow.</p>

          <img src="../assets/Trainings/timesheet/timesheet6.png" />
          <p>ii) User can also navigate to see month wise Timesheet on Calendar click.</p>
          <img src="../assets/Trainings/timesheet/timesheet7.png" />
          <h3>Date Range</h3>
          <p>i) User navigate to see Timesheet on specific date by click on “Date Range”.</p>
          <p>Note:- Current month Timesheet must be submitted to see using Date Range.</p>
          <img src="../assets/Trainings/timesheet/timesheet8.png" />
          <p>ii) Then user select date range From & To Date by click on Calendar to see submitted Timesheet.</p>

          <img src="../assets/Trainings/timesheet/timesheet9.png" />
          <h3>3.Pending Timesheet (Monthly and Date Range):</h3>
          <h2>Monthly:</h2>
          <p>User can navigate to see pending Timesheet month-wise by clicking on “Month” and navigate by arrow.</p>
          <p>User can see pending Timesheets of Current month by click on Pending drop-down.</p>
          <img src="../assets/Trainings/timesheet/timesheet10.png" />
          <h3>Date-Range:</h3>
          <p>User can navigate to see pending Timesheet on specific Date range-wise by clicking on “Date-range” and navigate by an arrow.</p>
          <p>i)User can see pending Timesheet/s of a specifically.</p>
          <p>selected Date range by click on Pending drop-down.</p>
          <img src="../assets/Trainings/timesheet/timesheet11.png" />
          <h2>Enter working hours:</h2>
          <p>User can enter regular 8 hours on Monday to Friday and click on “submit”.</p>
          <img src="../assets/Trainings/timesheet/timesheet12.png" />
          <p>ii) If the user is working for more or less than 8 hours on a particular date then s/he must provide a comment by click on “Total Hours” on a particular day.</p>
          <img src="../assets/Trainings/timesheet/timesheet13.png" />
          <h2>Note-</h2>
          <p>a) User can be entered hours infraction as well, in multiple of 0.5 hours. e.g.: 2.5,4.5, etc.</p>
          <img src="../assets/Trainings/timesheet/timesheet14.png" />
          <p>B) If worked on the weekend then the user must give a comment on Saturday or Sunday in the “Total Hours per Day” row for the weekend.</p>
          <img src="../assets/Trainings/timesheet/timesheet15.png" />
          <h2>Attachment:</h2>
          <p>If the user is in a client Project and filling Timesheet for a client, then he/she must upload a screenshot in Aperture by click on the attachment (clip) button.</p>
          <img src="../assets/Trainings/timesheet/timesheet16.png" />
          <p>Then click on ‘Save’.</p>
          <h2>Add Leaves:</h2>
          <h4>To add Non-Billable Working Hours:</h4>
          <p>If Resource is working on a client project and for any day, they will not work under billing hours. Then user add Non-Billable Working Hours.</p>
          <img src="../assets/Trainings/timesheet/timesheet21.png" />
          <h2>Submit with comment:</h2>
          <p>A comment is optional when the user finally submits the timesheet.</p>
          <img src="../assets/Trainings/timesheet/timesheet22.png" />
          <h2>Holiday colour:</h2>
          <p>The pink colour in the Timesheet indicates a holiday in GSPANN as per the user’s location.</p>
          <img src="../assets/Trainings/timesheet/timesheet23.png" />
          <p>Note: If working on holiday then enter a comment for the corresponding day.</p>
          <img src="../assets/Trainings/timesheet/timesheet24.png" />
          <h2>Recall Timesheet</h2>
          <p>User can recall timesheets even after submitting by clicking on the recall button to rectify the corresponding timesheet.</p>
          <img src="../assets/Trainings/timesheet/timesheet25.png" />
          <p>Then the user needs to fill the timesheet again and click on “Submit”.</p>
          <img src="../assets/Trainings/timesheet/timesheet26.png" />
      </div>
  </mat-tab>
  <ng-template [jhiHasAnyAuthority]="'TIMESHEETS_APPROVE'">
      <mat-tab label="Timesheet Approvals">
          <div class="training_m">
              <h2>Timesheet Approvals</h2>
              <p class="bld">Login to aperture:</p>
              <p>Example: Login using mail Id</p>
              <p>Login ID/ Pwd: abc@gspann.com / XXXXX@12</p>
              <h2>Timesheet Approvals</h2>
              <img src="../assets/Trainings/TA/ta1.png" />
              <p>By clicking on "Timesheet" tab</p>
              <p>Select "Timesheet Approvals"</p>
              <img src="../assets/Trainings/TA/ta2.png" />
              <p>Then Approver is able to see current week Timesheet screen which will be pending for Approved.</p>
              <img src="../assets/Trainings/TA/ta3.png" />
              <h3>2. Navigate Timesheet ( Weekly,Monthly,Date Range)</h3>
              <h4>Weekly: Pending:</h4>
              <p>Approver navigate to see current & previous week Timesheet by clicking on forward backward arrow which has Approved,Rejected and will Pending.</p>
              <img src="../assets/Trainings/TA/ta4.png" />
              <h5>Approved</h5>
              <img src="../assets/Trainings/TA/ta5.png" />
              <h5>Rejected</h5>
              <img src="../assets/Trainings/TA/ta6.png" />
              <p>ii) Approver can also navigate to see weekly Timesheet by clicking on calendar which has Approved,Rejected and will Pending.</p>
              <img src="../assets/Trainings/TA/ta7.png" />
              <h4>Monthly</h4>
              <p>Approver can navigate to see Timesheet in month wise by clicking on “Month” and navigate by arrow</p>
              <img src="../assets/Trainings/TA/ta8.png" />
              <p>ii) Approver can also navigate to see month wise Timesheet on Calendar click.</p>
              <img src="../assets/Trainings/TA/ta9.png" />
              <h4>Date Range</h4>
              <p>Approver navigate to see Timesheet on specific date by click on “Date Range” which has Approved,Rejected and will Pending.</p>
              <img src="../assets/Trainings/TA/ta10.png" />
              <h2>View By Resource Type:</h2>
              <p>User can see the Timesheets by Diff resource types i.e., Onshore, Offshore, All.</p>
              <img src="../assets/Trainings/TA/ta11.png" />
              <h2>By Details:</h2>
              <img src="../assets/Trainings/TA/ta12.png" />
              <img src="../assets/Trainings/TA/ta13.png" />
              <img src="../assets/Trainings/TA/ta14.png" />
              <h2>Check client Timesheet Screenshot:</h2>
              <p>If the resource is in Client Project and filling the timesheet. Then Approver can see a screenshot of the client timesheet by clicking on attach button.</p>
              <img src="../assets/Trainings/TA/ta15.png" />
              <h2>All Projects:</h2>
              <p>If Approver having numbers of project and wants to sort timesheet according to project wise. Then Approver check radio button of project name or search in All Projects search box.</p>
              <p>Approver can see the Timesheets Project wise/for All projects.</p>
              <img src="../assets/Trainings/TA/ta16.png" />
              <h2>All Projects search box:</h2>
              <img src="../assets/Trainings/TA/ta17.png" />
              <h2>All Resource:</h2>
              <p>If Approver want to see timesheet of any specific resource. Then Approver check radio button of resource name or search in All Resources search box.</p>
              <img src="../assets/Trainings/TA/ta18.png" />
              <p>Check radio button of resource name.</p>
              <img src="../assets/Trainings/TA/ta19.png" />
              <h2>All Resources search box:</h2>
              <img src="../assets/Trainings/TA/ta20.png" />
              <h2>Approve:</h2>
              <p>
                  Approver who has assigned as a manager offshore & onshore can approved resource Timesheet by clicking on the check box & by clicking on details and should be able to see Timesheet details and here approver, approve and
                  reject the resource TS.
              </p>
              <img src="../assets/Trainings/TA/ta21.png" />
              <img src="../assets/Trainings/TA/ta22.png" />
              <img src="../assets/Trainings/TA/ta23.png" />
              <p>After Approved Green Right is showing</p>
              <img src="../assets/Trainings/TA/ta24.png" />
              <img src="../assets/Trainings/TA/ta25.png" />
              <h2>Revert:</h2>
              <p>If Approver has approved the timesheet of any resource and having any request. Then Approver can do the Revert approved timesheet by clicking on the Revert (sky blue) button.</p>
              <img src="../assets/Trainings/TA/ta26.png" />
              <img src="../assets/Trainings/TA/ta27.png" />
              <img src="../assets/Trainings/TA/ta28.png" />
              <img src="../assets/Trainings/TA/ta29.png" />
              <img src="../assets/Trainings/TA/ta30.png" />
              <img src="../assets/Trainings/TA/ta31.png" />
              <h2>Reject:</h2>
              <p>
                  If Approver seeing any mistake doing by resource in the timesheet. Then Approver can Reject the timesheet by clicking the check box and bulk Reject button. (For if you want to reject multiple TS you can use the bulk
                  reject button) Or by clicking on Details and click on the Reject button.
              </p>
              <img src="../assets/Trainings/TA/ta32.png" />
              <img src="../assets/Trainings/TA/ta33.png" />
              <img src="../assets/Trainings/TA/ta34.png" />
              <img src="../assets/Trainings/TA/ta35.png" />
              <img src="../assets/Trainings/TA/ta36.png" />
              <p>After Reject Red cross is showing.</p>
              <img src="../assets/Trainings/TA/ta37.png" />
              <h2>Bulk Approval:</h2>
              <p>User can approve the selected all Timesheets and can approve at the same time by clicking on “Select All” check box.</p>
              <img src="../assets/Trainings/TA/ta38.png" />
              <img src="../assets/Trainings/TA/ta39.png" />
              <p>If user wants to clear all checkboxes by clicking on Clear all</p>
              <img src="../assets/Trainings/TA/ta40.png" />
              <p>By submitting the comment user can approve/Reject the bulk Timesheets.</p>
              <img src="../assets/Trainings/TA/ta41.png" />
          </div>
      </mat-tab>
  </ng-template>
  <ng-template [jhiHasAnyAuthority]="'ACCOUNTS_NAVBAR'">
      <mat-tab label="Account">
          <div class="training_m">
              <p class="bld">Login to aperture:</p>
              <p>Example:</p>
              <p>Login ID/ Pwd: abc@gspann.com / XXXXX@12</p>
              <p class="bld">Click on Account Tab:</p>
              <p>User can Search and view the existing Account with type customer name, account code, Entity, Manager, etc.</p>
              <img src="../assets/Trainings/account/account1.png" />
              <h3>Add New Account</h3>
              <p>User can create new Account by click on "AddAccount"</p>
              <img src="../assets/Trainings/account/account2.png" />
              <h3>Select GSpann Entity:</h3>
              <p>User can search by clicking on “Search Gspann Entity” and selects from the dropdown.</p>
              <img src="../assets/Trainings/account/account3.png" />
              <h3>Select or Enter Customer Group</h3>
              <p>User can select or create Customer Group using four-character by click on "Select or Enter Customer Group".</p>
              <img src="../assets/Trainings/account/account4.png" />
              <h3>Enter Customer Name</h3>
              <p>User can enter unique customer name by click on "Customer Name".</p>
              <img src="../assets/Trainings/account/account5.png" />
              <h3>Enter Customer Entity</h3>
              <p>User enter unique customer entity by click on "Customer Entity"</p>
              <img src="../assets/Trainings/account/account6.png" />
              <h3>Enter Customer Contact Person</h3>
              <p>User enter customer contact person by click on "Customer Contact Person"</p>
              <img src="../assets/Trainings/account/account7.png" />
              <h3>Search Account Manager</h3>
              <p>User search Account Manager by click on "Search Account Manager” should selects from the suggested dropdown values.</p>
              <img src="../assets/Trainings/account/account8.png" />
              <h3>Select Country</h3>
              <p>User select country by click on "country drop-down"</p>
              <img src="../assets/Trainings/account/account9.png" />
              <h3>Enter Contact Person's Email</h3>
              <p>User enter appropriate email format in "contact person's email".</p>
              <img src="../assets/Trainings/account/account10.png" />
              <h3>Contact Person's Mobile Number</h3>
              <p>Users enter contact person’s 10 digit mobile number. User can select the Country code form the dropdown.</p>
              <img src="../assets/Trainings/account/account11.png" />
              <h3>Mutual Termination</h3>
              <p>The user checks the Mutual Termination check box or selects MSA End Date. (One is required, and we can all fill both fields)</p>
              <img src="../assets/Trainings/account/account12.png" />
              <h2>MSA Start Date/End Date</h2>
              <p>User selects MSA Start Date /End date from the calendar.</p>
              <img src="../assets/Trainings/account/account13.png" />
              <h2>Search AR Manager</h2>
              <p>User search AR Manager by click on "Search AR Owner”.</p>
              <img src="../assets/Trainings/account/account14.png" />
              <p>Time Tracking system</p>
              <p>If the user wants “Time Tracking System Available" yes or no can check it.</p>
              <img src="../assets/Trainings/account/account15.png" />
              <h3>Attach NDA/MSA Document</h3>
              <p>User can Attached NDA/MSA Document in 'Attached NDA/MSA Document'.</p>
              <img src="../assets/Trainings/account/account16.png" />
              <p>Then Click on Create Account >New Account will be created.</p>
              <img src="../assets/Trainings/account/account17.png" />
              <p>Reset</p>
              <p>If user clicks on Reset All > All fields will be clear, and user can fill all the fields again.</p>
              <h3>Export Accounts</h3>
              <p>User can download Export Accounts by click 'Export Account' on the Account landing page and the user can see all the accounts in an excel sheet and view the existing Account in.</p>
              <img src="../assets/Trainings/account/account18.png" />
              <h4>User can edit Accounts field</h4>
              <p>If user can do any mistake while creating an account so they can be changed Account field by clicking on <b>Edit</b>.</p>
              <p>User can edit the following fields::</p>
              <ol>
                  <li>GSpann Entity</li>
                  <li>Customer Entity</li>
                  <li>Search Manager.</li>
                  <li>Customer Name</li>
                  <li>Customer Contact Person.</li>
                  <li>Contact personal Email.</li>
                  <li>Contact Person's Mobile number.</li>
                  <li>Attached NDA/MSA documents.</li>
                  <li>Change client time Tracking.</li>
                  <li>MSA Start Date.</li>
                  <li>MSA End date.</li>
                  <li>Country</li>
                  <li>AR Manager</li>
              </ol>
              <img src="../assets/Trainings/account/account19.png" />
              <img src="../assets/Trainings/account/account20.png" />
              <h4>Quick Links</h4>
              <p>User can create new Project using “Quick links” button.</p>
              <img src="../assets/Trainings/account/account21.png" />
              <p>User can View the Account related Projects and Account related SOWs in the sub tabs.</p>
              <img src="../assets/Trainings/account/account22.png" />
              <img src="../assets/Trainings/account/account23.png" />
              <img src="../assets/Trainings/account/account24.png" />
          </div>
      </mat-tab>
  </ng-template>
  <ng-template [jhiHasAnyAuthority]="'PROJECTS_NAVBAR'">
      <mat-tab label="Add/Edit Project">
          <div class="training_m">
              <p class="bld">Project</p>
              <p class="bld">Login to aperture:</p>
              <p>Example:</p>
              <p>Login ID/ Pwd: abc@gspann.com / XXXXX@12</p>
              <p class="bld">Click on Project Tab:</p>
              <p>User can Search and view the existing Project with type project name, project id, account name, etc...</p>
              <img src="../assets/Trainings/project/project1.png" />
              <h3>Add New Project</h3>
              <p>User can create new Project by click on "AddProject"</p>
              <img src="../assets/Trainings/project/project2.png" />
              <h3>Select Account</h3>
              <p>User can Select any existing Account by click on Account drop-down.</p>
              <p>For Bench Project select account "Internal"</p>
              <img src="../assets/Trainings/project/project3.png" />
              <h3>Enter Customer Project Code</h3>
              <p>User can enter Customer Project Code.</p>
              <img src="../assets/Trainings/project/project4.png" />
              <h3>Enter Customer Contact Person</h3>
              <p>User can enter Customer Contact Person.</p>
              <img src="../assets/Trainings/project/project5.png" />
              <h3>Enter Customer Project Name</h3>
              <p>User can enter Customer Project Name which is unique.</p>
              <h3>Enter Project Name</h3>
              <p>User can enter Customer Project Name which is unique or same as Customer Project Name.</p>
              <img src="../assets/Trainings/project/project6.png" />
              <h3>Select & Search for Onshore Manager</h3>
              <p>User can Select & Search Onshore Manager.</p>
              <img src="../assets/Trainings/project/project7.png" />
              <h3>Select & Search for Offshore Manager</h3>
              <p>User can Select & Search Offshore Manager.</p>
              <img src="../assets/Trainings/project/project8.png" />
              <h3>Delegated Manager :</h3>
              <p>If in Project having large numbers of resource and want to assign role of DM to any resource for Timesheet Approved</p>
              <p>Then check the radio button 'Yes' or 'No'</p>
              <img src="../assets/Trainings/project/project9.png" />
              <h3>Select Type</h3>
              <p>User can select appropriate type like Fix Bid, Bench etc.</p>
              <img src="../assets/Trainings/project/projecttype.png" />
              <h3>Select Country</h3>
              <p>User can select Country.</p>
              <img src="../assets/Trainings/project/projectcountry.png" />
              <h3>Select Practices</h3>
              <p>User can select any Practices like Quality Engineer, App Dev etc.</p>
              <img src="../assets/Trainings/project/project11.png" />

              <h3>Select Status</h3>
              <p>User can select Status form the dropdown.</p>
              <img src="../assets/Trainings/project/project12.png" />

              <h3>Select Technologies</h3>
              <p>User can enter or select any Technologies.</p>
              <img src="../assets/Trainings/project/project13.png" />

              <h3>Select Start Date & End Date</h3>
              <p>User can select start Date & end Date using calendar.</p>
              <img src="../assets/Trainings/project/project14.png" />
              <img src="../assets/Trainings/project/project15.png" />
              <h3>Click on Reset</h3>
              <p>If user can do any mistake then click on "Rest All"</p>
              <img src="../assets/Trainings/project/project16.png" />
              <h3>Click on Create Project</h3>
              <p>If user can enter all fields correct then click on "Create Project"</p>
              <img src="../assets/Trainings/project/project17.png" />
              <h3>Edit After Create</h3>
              <p>If user want to edit any created project like Name, Type , Date etc.</p>
              <p>Select that Project in project landing page.</p>
              <img src="../assets/Trainings/project/project18.png" />
              <p>Then click on 'Edit'.</p>
              <img src="../assets/Trainings/project/project19.png" />
              <p>Then edit which field required.</p>
              <img src="../assets/Trainings/project/project20.png" />
              <p>Then click on 'Save'</p>
              <h3>If Delegated Manager is Yes.</h3>
              <p>Then below screen is showing.</p>
              <img src="../assets/Trainings/project/project21.png" />
              <p>In below screen under Offshore Engagement.</p>
              <p>Resource Allocated: 8</p>
              <p>If user want to assign some resource to DM</p>
              <img src="../assets/Trainings/project/project22.png" />
              <p>Then search resource to assign DM role.</p>
              <br />
              <p>Note: Offshore & Onshore Engagement name will not search in DM list.</p>
              <img src="../assets/Trainings/project/projectdmrole.png" />
              <p>Under Team Members all allocated resource list is showing which in the project.</p>
              <img src="../assets/Trainings/project/projectdmteam.png" />
              <p>Then click on Add.</p>
              <img src="../assets/Trainings/project/projectdmteamadd.png" />
              <img src="../assets/Trainings/project/project26.png" />
              <p>If user want to update, then select actions and click on Edit/Delete.</p>
              <img src="../assets/Trainings/project/projectdelegatemanager.png" />
              <h3>View Allocated Resources</h3>
              <p>User can view the Allocated Resources</p>
              <img src="../assets/Trainings/project/projectallocatedresources.png" />
              <p>Project related SOWs can view/edit in the SOW tab.</p>
              <img src="../assets/Trainings/project/project29.png" />
              <img src="../assets/Trainings/project/project30.png" />
          </div>
      </mat-tab>
  </ng-template>

  <ng-template [jhiHasAnyAuthority]="'RESOURCES_NAVBAR'">
      <mat-tab label="Resource">
          <div class="training_m">
              <p class="bld">Login to aperture:</p>
              <p>Example:</p>
              <p>Login ID/ Pwd: abc@gspann.com / XXXXX@12</p>
              <p>User can click on Resource Tab to Create New Resource for On boarding</p>
              <img src="../assets/Trainings/resource/resource1.png" />
              <p>Users can Search existing Resources with Resource Code, Resource Email ID, Payroll ID, Resource ID.</p>
              <img src="../assets/Trainings/resource/resource2.png" />
              <p>Users can search the Resources with the Company Name</p>
              <img src="../assets/Trainings/resource/resource3.png" />
              <p>Users can search the Resource with Location wise and Click on Search.</p>
              <img src="../assets/Trainings/resource/resource4.png" />
              <p>Users can search the Resources with Account filter and Click on Search.</p>
              <img src="../assets/Trainings/resource/resource5.png" />
              <p>User can search existing resources using different filters.</p>
              <h2>Resource Type filters:</h2>
              <p>user can search with the field “Type”.</p>
              <p>1. Full Time Employee</p>
              <p>2. Direct Contractor</p>
              <p>3. Vendor Contractor</p>
              <img src="../assets/Trainings/resource/resource6.png" />
              <h2>Status Field:</h2>
              <p>Displays the employment status like Joining Awaited, Did Not Join, Active, On Long Leave, Resigned, Absconded, Terminated, Blocked.</p>
              <img src="../assets/Trainings/resource/resource7.png" />
              <h2>Billability Field:</h2>
              <p>Employee status in project Billable, Non-Billable or Buffer</p>
              <img src="../assets/Trainings/resource/resource8.png" />
              <h2>Utilization Field:</h2>
              <p>It Displays the following Under Utilized, Over Utilized or Optimally Utilized</p>
              <img src="../assets/Trainings/resource/resource9.png" />
              <h2>Gender Field:</h2>
              <p>Displays employee gender Male, Female, Other in the drop down.</p>
              <img src="../assets/Trainings/resource/resource10.png" />
              <p>User can search with “Resource Joining from date & To date” by pick the date form the calendar.</p>
              <img src="../assets/Trainings/resource/resource11.png" />
              <p>User can search with the End date from & End date to by pick the calendar form the dropdown.</p>
              <img src="../assets/Trainings/resource/resource12.png" />
              <p>Search by Last used year field: Enter year and click on search.</p>
              <img src="../assets/Trainings/resource/resource13.png" />
              <h2>Practices Field:</h2>
              <p>Display practices details as shown below.</p>
              <img src="../assets/Trainings/resource/resource14.png" />
              <h2>Skill:</h2>
              <p>User can search the resources using different skills.</p>
              <p>While searching with the skilled user can search Skill group, Experience skill set.</p>
              <p>Click on Search.</p>
              <p>If there are no resources with the filters> user can see “There is no resources for the given filters” message.</p>
              <img src="../assets/Trainings/resource/resource15.png" />
              <img src="../assets/Trainings/resource/resource16.png" />
              <h2>Add New Resource:</h2>
              <p>To Add a new resource, click on "Add Resource" button as shown below.</p>
              <img src="../assets/Trainings/resource/resource17.png" />
              <h2>Enter Resource Name:</h2>
              <p>User can be redirected to the "Add New Resource" page to add a new resource.</p>
              <p>User enters Resource Name if any.</p>
              <img src="../assets/Trainings/resource/resource18.png" />
              <h2>Select the Gender:</h2>
              <p>Select the Resource Gender form the Radio buttons.</p>
              <img src="../assets/Trainings/resource/resource19.png" />
              <h2>Search or Select Company Name:</h2>
              <p>User can search/select the Company name form the dropdown.</p>
              <img src="../assets/Trainings/resource/resource20.png" />
              <h2>Select the Department:</h2>
              <p>User selects the Department form the dropdown.</p>
              <img src="../assets/Trainings/resource/resource21.png" />
              <h2>Enter Designation:</h2>
              <p>User enters designation.</p>
              <img src="../assets/Trainings/resource/resource22.png" />
              <h2>Select Base Location:</h2>
              <p>User search/ select the Resource’s base location form the dropdown.</p>
              <img src="../assets/Trainings/resource/resource23.png" />
              <h2>Expected joining Date:</h2>
              <p>User can select the Expected joining date from the calendar.</p>
              <img src="../assets/Trainings/resource/resource24.png" />
              <h2>Select Practices:</h2>
              <p>Users select/ search the Practices form the dropdown.</p>
              <img src="../assets/Trainings/resource/resource25.png" />
              <h2>Select Primary skills:</h2>
              <p>User can search and selects the Resource’s Primary skills form the dropdown.</p>
              <img src="../assets/Trainings/resource/resource26.png" />
              <h2>Secondary Skills:</h2>
              <p>User can search and selects the Resource’s secondary skills form the dropdown.</p>
              <img src="../assets/Trainings/resource/resource27.png" />
              <h2>Select profile Picture:</h2>
              <p>Select the Resource picture and upload.</p>
              <img src="../assets/Trainings/resource/resource28.png" />
              <h2>Select Resume:</h2>
              <p>Select the Resource Resume if required and upload.</p>
              <img src="../assets/Trainings/resource/resource29.png" />
              <p>If user want re-enter the values simply Click on “Reset All” button clear the all entered values.</p>
              <img src="../assets/Trainings/resource/resource30.png" />
              <img src="../assets/Trainings/resource/resource31.png" />
              <p>Enter all the values and click on Save & Next</p>
              <img src="../assets/Trainings/resource/resource32.png" />
              <h2>Costing Details:</h2>
              <p>Resource Type:</p>
              <p>Users navigate to Costing details Screen and select the Resource Type from the Radio buttons which are Full-Time Employee/ Sub contractor/contractor.</p>
              <p>Select FTE form the radio buttons.</p>
              <img src="../assets/Trainings/resource/resource33.png" />
              <h2>Select Currency:</h2>
              <p>User can select the Currency from the dropdown.</p>
              <img src="../assets/Trainings/resource/resource34.png" />
              <h2>Enter the Salary:</h2>
              <p>User can enter the Salary amount in the salary field.</p>
              <img src="../assets/Trainings/resource/resource35.png" />
              <p>If user can enter Commission, Annual Bonus, other, and comments if any which are optional.</p>
              <img src="../assets/Trainings/resource/resource36.png" />
              <p>If user want to clear all entered values, click on “Reset All”.</p>
              <img src="../assets/Trainings/resource/resource37.png" />
              <img src="../assets/Trainings/resource/resource38.png" />
              <p>Enter all mandatory fields again and Click on Create Resource.</p>
              <img src="../assets/Trainings/resource/resource39.png" />
              <p>If user don’t want to proceed can click on “Cancel”</p>
              <img src="../assets/Trainings/resource/resource40.png" />
              <p>If user want to proceed with the details user can Click on “Confirm”. (Which are non-editable).</p>
              <img src="../assets/Trainings/resource/resource41.png" />
              <p>User can redirected to the Resource Details screen where the resource status is showing as “Joining awaited”.</p>
              <img src="../assets/Trainings/resource/resource42.png" />
              <p>If user want to edit/update the previous values, click on “Edit”.</p>
              <img src="../assets/Trainings/resource/resource43.png" />
              <p>User can update the values if required.</p>
              <img src="../assets/Trainings/resource/resource44.png" />
              <p>After changing values click on “Save”</p>
              <img src="../assets/Trainings/resource/resource45.png" />
              <p>If user don’t want to change previous values can click on “Reset”.</p>
              <img src="../assets/Trainings/resource/resource46.png" />
              <p>Don’t change any Previous values click on “Cancel”.</p>
              <img src="../assets/Trainings/resource/resource47.png" />
              <h2>Contractor/Sub-Contractor:</h2>
              <p>While adding/onboarding Contract Employee select “Contractor” in Costing details screen.</p>
              <img src="../assets/Trainings/resource/resource48.png" />
              <p>While adding/onboarding Vendor Contract Employee select “Sub-Contractor” in Costing details screen.</p>
              <p>Other details:</p>
              <p>Effective date should be updated.</p>
              <p>Enter Rate per Hour field (Which is mandatory)</p>
              <p>Vendor Name (Mandatory)</p>
              <p>Click on “Create Resource”.</p>
              <img src="../assets/Trainings/resource/resource49.png" />
              <h2>On-boarding of a Resource:</h2>
              <p>For On Boarding click on “On Board”</p>
              <img src="../assets/Trainings/resource/resource50.png" />
              <p>Enter all the mandatory fields which are Payroll Id, Email address, Date of joining, Deputed location if any.</p>
              <h2>Payroll ID:</h2>
              <p>Enter Payroll ID</p>
              <img src="../assets/Trainings/resource/resource51.png" />
              <h2>Enter Email Address:</h2>
              <p>Enter email address of the resource.</p>
              <img src="../assets/Trainings/resource/resource52.png" />
              <p>Joining Date:</p>
              <p>Enter joining date pick from the calendar.</p>
              <img src="../assets/Trainings/resource/resource53.png" />
              <h2>Select Deputed Location:</h2>
              <p>Select the Deputed location from the dropdown.</p>
              <img src="../assets/Trainings/resource/resource54.png" />
              <p>Email Notification:</p>
              <img src="../assets/Trainings/resource/resource55.png" />
              <p>Click on “Onboard”.</p>
              <img src="../assets/Trainings/resource/resource56.png" />
              <p>When On-board successfully it is showing Active.</p>
              <img src="../assets/Trainings/resource/resource57.png" />
              <h2>Edit Resource details:</h2>
              <p>If user want to update/edit the resource details if any.</p>
              <p>User can click on “Edit”.</p>
              <p>Update the details which required to update and click on “Update”.</p>
              <img src="../assets/Trainings/resource/resource58.png" />
              <p>If user don’t want to update the values user can click on “Cancel”</p>
              <img src="../assets/Trainings/resource/resource59.png" />
              <p>If user want to Clear the updated values user can click on “Reset”.</p>
              <p>Then user can see Resource View Details screen.</p>
              <img src="../assets/Trainings/resource/resource60.png" />
              <p>Resource Timesheet:</p>
              <p>If user want to view/Edit the Resource timesheet, then click on “Timesheet” tab.</p>
              <p>User navigated to the Resource Timesheet screen.</p>
              <img src="../assets/Trainings/resource/resource61.png" />
              <h2>Resume Builder:</h2>
              <p>Users want to update the Resume user can update the details and build their resume.</p>
              <img src="../assets/Trainings/resource/resource62.png" />
              <h2>Position info:</h2>
              <p>User can see the logged in user’s Active allocation information in the Position Info tab.</p>
              <p>If the logged in user is an Engagement Manager for those Projects, then they can see team member details in the “My Team” Table.</p>
              <img src="../assets/Trainings/resource/resource63.png" />
              <h2>Allocation Info:</h2>
              <p>User can see the Complete Allocation History of any Resource (which can include Active, Inactive, Future)</p>
              <p>In this Table user can see Account, Billable type, Project, Occupancy & Allocation duration.</p>
              <img src="../assets/Trainings/resource/resource64.png" />
              <h2>Block the Resource:</h2>
              <p>Select any Active Resource & Click on “Block” tab.</p>
              <img src="../assets/Trainings/resource/resource65.png" />
              <p>User navigated to the Block screen.</p>
              <img src="../assets/Trainings/resource/resource66.png" />
              <p>Select “Account” form the dropdown.</p>
              <img src="../assets/Trainings/resource/resource67.png" />
              <p>Select “Project” related to the account (Optional).</p>
              <img src="../assets/Trainings/resource/resource68.png" />
              <p>Select “Block Date” from the calendar.</p>
              <img src="../assets/Trainings/resource/resource69.png" />
              <p>Select “Billing date” from the calendar. User can select current date & future as Billing start date.</p>
              <img src="../assets/Trainings/resource/resource70.png" />
              <p>Enter the “Remarks” if any, which is Optional.</p>
              <img src="../assets/Trainings/resource/resource71.png" />
              <p>If user want to clear/change the values can click on “Clear”.</p>
              <img src="../assets/Trainings/resource/resource72.png" />
              <p>Enter all the Mandatory fields & click on “Block”</p>
              <img src="../assets/Trainings/resource/resource73.png" />
              <p>User can see “Blocked” Status along with the “Active” after block the resource.</p>
              <p>Blocked details can see in the below table.</p>
              <img src="../assets/Trainings/resource/resource74.png" />
              <h2>Edit Block:</h2>
              <p>If user want to update the Blocked details, then Click on Actions & Select “Edit”.</p>
              <p>Update the details which user want.</p>
              <p>Click on update.</p>
              <p>Updated details can see the list in the table.</p>
              <img src="../assets/Trainings/resource/resource75.png" />
              <h2>Delete Block Record:</h2>
              <p>If user want to delete the Blocked details, then Click on Actions & Select “Delete”.</p>
              <p>Blocked record got deleted & Record will clear from the table.</p>
              <img src="../assets/Trainings/resource/resource76.png" />
              <img src="../assets/Trainings/resource/resource78.png" />
              <h2>Relocate:</h2>
              <p>While selecting the “Relocate” dropdown user can the options like</p>
              <p>1. Put on Long Leave</p>
              <p>2. Put on Resignation</p>
              <p>3. Mark Abscond</p>
              <p>4. Terminate</p>
              <p>5. HR Allocation</p>
              <h2>Put on Long Leave:</h2>
              <p>If user want to put on long leave of any resource.</p>
              <p>Select any Active Resoure.</p>
              <p>Click on Relocate Dropdown.</p>
              <p>Select & Click “Put on Long Leave”.</p>
              <img src="../assets/Trainings/resource/resource79.png" />
              <p>Select Leave Type, Leave Duration, Comment if any.</p>
              <p>Click on “Apply”</p>
              <img src="../assets/Trainings/resource/resource80.png" />
              <p>User can see “On Long Leave” Status instead of “Active”.</p>
              <img src="../assets/Trainings/resource/resource81.png" />
              <p>If user want to Update the Leave, then click on “Update Leave” from the Relocate Dropdown.</p>
              <img src="../assets/Trainings/resource/resource82.png" />
              <p>If user want to Re-active the resource from the Leave, then click on “Re-Active” from the Relocate Dropdown.</p>
              <img src="../assets/Trainings/resource/resource83.png" />
              <h2>Put On Resignation:</h2>
              <p>If user want to put on Resignation of any resource, then click on “Put On Resignation” form the Relocate dropdown.s</p>
              <img src="../assets/Trainings/resource/resource84.png" />
              <p>Enter the details like Resignation date, Notice period days(if any)</p>
              <p>Click on “Apply”.</p>
              <img src="../assets/Trainings/resource/resource85.png" />
              <p>Then the resource Status will be updated as “On Notice Period”.</p>
              <img src="../assets/Trainings/resource/resource86.png" />
              <p>If resource has left the Organization, then User needs to click on “Gracefully Exit” form the Relocate dropdown.</p>
              <p>Then the status will be updated as “Resigned”.</p>
              <img src="../assets/Trainings/resource/resource87.png" />
              <img src="../assets/Trainings/resource/resource88.png" />
              <h2>Mark Abscond:</h2>
              <p>If user want to mark the Resource as Absconded, then click on “Mark Abscond” form the Relocate dropdown.</p>
              <img src="../assets/Trainings/resource/resource89.png" />
              <p>Select the Date and Comments if any.</p>
              <p>Click on “Apply”.</p>
              <img src="../assets/Trainings/resource/resource90.png" />
              <p>Then the status will be updated as “Absconded” Instead of “Active”.</p>
              <img src="../assets/Trainings/resource/resource91.png" />
              <h2>Terminate:</h2>
              <p>If user want to mark the Resource as Terminate, then click on “Terminate” form the Relocate dropdown.</p>
              <img src="../assets/Trainings/resource/resource92.png" />
              <p>Select the Date and Comments if any.</p>
              <p>Click on “Apply”.</p>
              <img src="../assets/Trainings/resource/resource93.png" />
              <p>Then the status will be updated as “Terminated” Instead of “Active”.</p>
              <img src="../assets/Trainings/resource/resource94.png" />
              <h2>HR Allocation:</h2>
              <p>Select any Active resource and click on “HR allocation” form the Relocate dropdown.</p>
              <img src="../assets/Trainings/resource/resource95.png" />
              <p>User redirected to the HR allocation screen.</p>
              <img src="../assets/Trainings/resource/resource96.png" />
              <h2>Select Account:</h2>
              <p>User can select the Account from the dropdown.</p>
              <img src="../assets/Trainings/resource/resource97.png" />
              <h2>Select Project:</h2>
              <p>User can select Project related to the Account (Optional).</p>
              <img src="../assets/Trainings/resource/resource98.png" />
              <p>Select Start Date & End Date:</p>
              <p>User can select allocation Start date form the calendar.</p>
              <img src="../assets/Trainings/resource/resource99.png" />
              <p>User can select the End date form the calendar if any (Optional).</p>
              <img src="../assets/Trainings/resource/resource100.png" />
              <h2>Select Allocation Type:</h2>
              <p>User can select the Allocation type like “Billable, Non-billable, Buffer” from the dropdown.</p>
              <img src="../assets/Trainings/resource/resource101.png" />
              <h2>Occupancy:</h2>
              <p>User can enter the “Occupancy” which is Mandatory.</p>
              <img src="../assets/Trainings/resource/resource102.png" />
              <h2>Enter Remarks:</h2>
              <p>User can enter the Remarks if any.</p>
              <p>And click on “Allocate” button.</p>
              <img src="../assets/Trainings/resource/resource103.png" />
              <p>HR allocation details can see in the HR allocation table.</p>
              <p>Those details are,</p>
              <p>Sno, Account, project, start date, End date, Allocation type, Modified by, Occupancy, Remarks.</p>
              <img src="../assets/Trainings/resource/resource104.png" />
              <p>By using Actions, user can Edit/Delete the HR allocated records.</p>
              <p>User can click on “Edit” modify the changes and click on “Update”.</p>
              <p>Updated details can see in the HR Allocation table</p>
              <img src="../assets/Trainings/resource/resource105.png" />
              <p>Users want to delete the HR allocated records can click on “Delete” from the Actions.Click on “Delete” And the records got deleted form the table.</p>
              <img src="../assets/Trainings/resource/resource106.png" />
              <h2>History:</h2>
              <p>User can see the Modified changes/ details will see in the History tab.</p>
              <img src="../assets/Trainings/resource/resource107.png" />
              <h2>Reports:</h2>
              <p>User can export different reports which are,</p>
              <p>a) Resource Attendance Report</p>
              <p>b) Export Resource List</p>
              <p>c) Resource Block Report</p>
              <p>d) Resource Buffer Report</p>
              <p>e) Resource Leave Report</p>
              <p>From the Resource list screen/Home page user can see “Reports” dropdown.</p>
              <img src="../assets/Trainings/resource/resource108.png" />
              <h2>Export Resource List:</h2>
              <p>User can download .xlsx document with the Resources details.</p>
              <img src="../assets/Trainings/resource/resource109.png" />
              <h2>Leave Report:</h2>
              <p>User can export the data with the Leave History of the resources for the selected duration.</p>
              <p>Click on “Export”</p>
              <img src="../assets/Trainings/resource/resource110.png" />
              <p>User can download .xlsx document with the Resource leave information.</p>
              <h2>Resource Attendance Report:</h2>
              <p>User can download the Resource’s complete attendance information.</p>
              <p>Click on Attendance report & select the duration.</p>
              <p>Click on Export</p>
              <img src="../assets/Trainings/resource/resource111.png" />
              <p>User can download .xlsx document with the Resources attendance information.</p>
              <p>Resource Block Report:</p>
              <p>User can download the Blocked resources details.</p>
              <p>Click on Block report form the Reports dropdown.</p>
              <p>select the duration.</p>
              <p>Click on Export</p>
              <img src="../assets/Trainings/resource/resource113.png" />
              <img src="../assets/Trainings/resource/resource114.png" />
              <p>User can download .xlsx document with the Blocked Resources details.</p>
              <h2>Resource Buffer Report:</h2>
              <p>User can download the Buffer resources details.</p>
              <p>Click on Buffer report form the Reports dropdown.</p>
              <img src="../assets/Trainings/resource/resource115.png" />
              <p>User can download the Buffer resources information in the. Xlsx report.</p>
          </div>
      </mat-tab>
  </ng-template>
  <ng-template [jhiHasAnyAuthority]="'ALLOCATION_NAVBAR'">
      <mat-tab label="Allocation">
          <div class="training_m">
              <h2>Allocation:</h2>
              <p class="bld">Login to aperture:</p>
              <p>Example:: Login using mail Id</p>
              <p>Login ID/ Pwd: abc@gspann.com / XXXXX@12</p>
              <img src="../assets/Trainings/allocation/allocation1.png" />
              <h2>Select the Allocation Tab:</h2>
              <img src="../assets/Trainings/allocation/allocation2.png" />
              <h2>Select or Search Account from Drop-down List:</h2>
              <p>Search by default</p>
              <img src="../assets/Trainings/allocation/allocation3.png" />
              <p>User Select Account from the dropdown & by clicking on Search user can see the selected account’s allocation records.</p>
              <img src="../assets/Trainings/allocation/allocation4.png" />
              <img src="../assets/Trainings/allocation/allocation4a.png" />
              <h2>Select or Search Project Drop-down List:</h2>
              <img src="../assets/Trainings/allocation/allocation5.png" />
              <p>Select Group by Project or Resource type option to search Allocations.</p>
              <img src="../assets/Trainings/allocation/allocation6.png" />
              <img src="../assets/Trainings/allocation/allocation7.png" />
              <h2>Select or Search SOW:</h2>
              <p>Statement of work (SOW) is picked from drop-down list provided under Field SOW</p>
              <img src="../assets/Trainings/allocation/allocation8.png" />
              <h2>Search the Resource:</h2>
              <p>Resource can be searched by providing resource id or resource name or email id.</p>
              <p>E.g.: Below screen highlights the name "Krishna Garg” using this resource name details are shown</p>
              <img src="../assets/Trainings/allocation/allocation9.png" />
              <h2>Select Resource Category: (Allocation)</h2>
              <p>Choose Billable/Non-Billable/Buffer from drop-down</p>
              <img src="../assets/Trainings/allocation/allocation10.png" />
              <h2>Select Status of resource:</h2>
              <p>Choose Active/Inactive/Future Resource type from Drop-down.</p>
              <img src="../assets/Trainings/allocation/allocation11.png" />
              <p>Below screen will fetch the results of the Resource who are in Active state.</p>
              <img src="../assets/Trainings/allocation/allocation12.png" />
              <img src="../assets/Trainings/allocation/allocation13.png" />
              <h2>Flexible Weekend:</h2>
              <p>If Resource is on Flexible Weekend. Then check Flexible Weekend</p>
              <img src="../assets/Trainings/allocation/allocation14.png" />
              <p>If Resource is on Client Time Tracking. Then check Client Time Tracking</p>
              <img src="../assets/Trainings/allocation/allocation15.png" />
              <p>If user want to look Allocation from specific date duration. Then select dates from calendar.</p>
              <img src="../assets/Trainings/allocation/allocation16.png" />
              <h2>Export Allocation:</h2>
              <p>If user want download allocation data in excel sheet. Then click on Export.</p>
              <img src="../assets/Trainings/allocation/allocation17.png" />
              <h2>Reset:</h2>
              <p>If user want to clear all fields “Click on Reset”</p>
              <img src="../assets/Trainings/allocation/allocation18.png" />
              <h2>Delete Active Allocation:</h2>
              <p>If user want to delete any Active allocation. Then click on Delete.</p>
              <p>Note: If resource has submitted Timesheet. Then ask to Manager to Revert that Timesheet and change to Zero hours in weekday no comments then click on SAVE.</p>
              <img src="../assets/Trainings/allocation/allocation19.png" />
              <img src="../assets/Trainings/allocation/allocation20.png" />
              <img src="../assets/Trainings/allocation/allocation21.png" />
              <h2>Add Allocation:</h2>
              <p>For Resource Allocation</p>
              <p>Click on Allocate button as displayed below.</p>
              <img src="../assets/Trainings/allocation/allocation22.png" />
              <p>Select or Search "Account" & "SOW"</p>
              <img src="../assets/Trainings/allocation/allocation23.png" />
              <img src="../assets/Trainings/allocation/allocation24.png" />
              <img src="../assets/Trainings/allocation/allocation25.png" />
              <p>Check "Start date" & "End date”, SOW Details etc.,</p>
              <img src="../assets/Trainings/allocation/allocation26.png" />
              <p>Check - Available, Allocated, Partially Allocated</p>
              <p>Note:</p>
              <p>Available (0) - WBS is not Available for Allocation</p>
              <p>Available (1) - One WBS is utilized.</p>
              <p>Allocated (0)- Resource may not be allocated or Partially Allocated.</p>
              <p>Allocated (1)- Resource allocation start date & end date same as WBS.</p>
              <p>Partial Allocated (0)- Resource allocation start date & end date same as WBS.</p>
              <p>Partial Allocated (1)- Resource allocation start date & end date not same as WBS.</p>
              <img src="../assets/Trainings/allocation/allocation27.png" />
              <h2>Select Project, Allocation Type & Resource Type:</h2>
              <img src="../assets/Trainings/allocation/allocation28.png" />
              <img src="../assets/Trainings/allocation/allocation29.png" />
              <img src="../assets/Trainings/allocation/allocation30.png" />
              <h2>Search resource using Name, Email Id & Resource Id</h2>
              <img src="../assets/Trainings/allocation/allocation31.png" />
              <p>Then check allocation details by click on 'See details'.</p>
              <img src="../assets/Trainings/allocation/allocation32.png" />
              <img src="../assets/Trainings/allocation/allocation33.png" />
              <p>Then check utilization percentage Active & Future Allocation.</p>
              <h2>Select Allocation Start Date & End Date:</h2>
              <img src="../assets/Trainings/allocation/allocation34.png" />
              <p>If Client Time Tracking applicable, then checked.</p>
              <img src="../assets/Trainings/allocation/allocation35.png" />
              <p>If Flexible Week Ends applicable, then checked.</p>
              <img src="../assets/Trainings/allocation/allocation36.png" />
              <p>If Pseudo Resource applicable, then checked & enter other details.</p>
              <img src="../assets/Trainings/allocation/allocation37.png" />
              <p>If Delegate Manager is required for Timesheet Approval, then search & select.</p>
              <img src="../assets/Trainings/allocation/allocation38.png" />
              <p>Enter occupancy then click on 'Allocate’.</p>
              <img src="../assets/Trainings/allocation/allocation39.png" />
              <p>If user want to clear the fields and want to update again user can click on “Reset”</p>
              <img src="../assets/Trainings/allocation/allocation40.png" />
              <p>User can see allocated successfully message.</p>
              <p>User can see Allocated resource records in the allocation list screen.</p>
              <img src="../assets/Trainings/allocation/allocation41.png" />
              <h2>Edit Allocation:</h2>
              <p>Search the Resource: Which resource allocation user want to update.</p>
              <p>Resource can be searched by providing resource id or resource name or email id.</p>
              <p>And select that resource from the Drop-down.</p>
              <img src="../assets/Trainings/allocation/allocation42.png" />
              <img src="../assets/Trainings/allocation/allocation42.png" />
              <p>Then click on search.</p>
              <img src="../assets/Trainings/allocation/allocation43.png" />
              <p>Then we can see all the allocation for that resource e.g.: Active, inactive & future.</p>
              <img src="../assets/Trainings/allocation/allocation44.png" />
              <p>Then click on Active allocation Edit button.</p>
              <img src="../assets/Trainings/allocation/allocation45.png" />
              <img src="../assets/Trainings/allocation/allocation46.png" />
              <p>User can update the values which user want to update.</p>
              <img src="../assets/Trainings/allocation/allocation47.png" />
              <p>Click on Update</p>
              <img src="../assets/Trainings/allocation/allocation48.png" />
              <p>User can see Allocation updated successfully message.</p>
              <img src="../assets/Trainings/allocation/allocation49.png" />
              <h2>Bulk Allocation:</h2>
              <p>User can allocate multiple allocations/Allocate multiple resources at the same time with the help of Bulk allocation.</p>
              <p>User can click on Allocate button.</p>
              <img src="../assets/Trainings/allocation/allocation50.png" />
              <p>User navigated to the add new allocation screen.</p>
              <h2>Select Account & SOW:</h2>
              <img src="../assets/Trainings/allocation/allocation51.png" />
              <p>If user want to allocate bulk resources, then the user selected SOW should have more than 1 WBS</p>
              <img src="../assets/Trainings/allocation/allocation52.png" />
              <p>User can see Bulk Allocation screen below the sow details.</p>
              <h2>Search Resource:</h2>
              <p>User can search for Resource & select form the dropdown.</p>
              <img src="../assets/Trainings/allocation/allocation53.png" />
              <h2>Search Project:</h2>
              <p>User can search and Select the Project form the dropdown.</p>
              <img src="../assets/Trainings/allocation/allocation54.png" />
              <h2>Select Allocation type:</h2>
              <p>User can select the Allocation type from the dropdown.</p>
              <img src="../assets/Trainings/allocation/allocation55.png" />
              <h2>Select Resource Type:</h2>
              <p>User can select resource type from the dropdown.</p>
              <img src="../assets/Trainings/allocation/allocation56.png" />
              <h2>Enter Occupancy:</h2>
              <img src="../assets/Trainings/allocation/allocation57.png" />
              <h2>Delegate Manager:</h2>
              <p>If user want to provide Delegate Manager to approve their TS,</p>
              <p>User can search DM with resource code, email ID, Resource ID, Payroll ID.</p>
              <img src="../assets/Trainings/allocation/allocation58.png" />
              <h2>Flexible Weekends:</h2>
              <p>If user want to give Flexible weekends check Flexible weekends checkbox</p>
              <img src="../assets/Trainings/allocation/allocation59.png" />
              <p>If user want to add new allocation user click on “+” button.</p>
              <img src="../assets/Trainings/allocation/allocation60.png" />
              <p>Then user can see one more allocation and need to enter the details again for the required fields.</p>
              <img src="../assets/Trainings/allocation/allocation61.png" />
              <img src="../assets/Trainings/allocation/allocation62.png" />
              <h2>Enter Start date & End date:</h2>
              <p>User can select the allocation start date & End date form the calendar.</p>
              <img src="../assets/Trainings/allocation/allocation63.png" />
              <p>If user want to do some mistake and want to re-enter the details can click on “Reset”.</p>
              <img src="../assets/Trainings/allocation/allocation64.png" />
              <h2>Allocate:</h2>
              <p>After entering all the details user can click on “Allocate” button.</p>
              <img src="../assets/Trainings/allocation/allocation65.png" />
              <p>User can see “Bulk allocation was successful.”</p>
              <img src="../assets/Trainings/allocation/allocation66.png" />
              <p>Allocated resources records user can see in the allocation list screen.</p>
              <img src="../assets/Trainings/allocation/allocation67.png" />
              <h2>Pending HR Allocation screen:</h2>
              <p>Click on the add Allocation button.</p>
              <img src="../assets/Trainings/allocation/allocation68.png" />
              <img src="../assets/Trainings/allocation/allocation69.png" />
              <p>Click on the Allocation Pending.</p>
              <p>It should have the record count.</p>
              <img src="../assets/Trainings/allocation/allocation70.png" />
              <p>HR Allocation (Pending Allocation) page should be displayed.</p>
              <img src="../assets/Trainings/allocation/allocation71.png" />
              <h2>Search and select the resource.</h2>
              <p>User can search by Resource ID, Email ID, Resource code, Payroll ID</p>
              <img src="../assets/Trainings/allocation/allocation72.png" />
              <p>Able to see the search resource.</p>
              <img src="../assets/Trainings/allocation/allocation73.png" />
              <p>Click on the Resource name. Add allocation page should be displayed with the associated account.</p>
              <img src="../assets/Trainings/allocation/allocation74.png" />
              <p>Search and select the SOW from the drop-down.</p>
              <img src="../assets/Trainings/allocation/allocation75.png" />
              <p>Search and select the WBS from the drop-down.</p>
              <img src="../assets/Trainings/allocation/allocation76.png" />
              <p>Click on the Allocation Type & select form the dropdown.</p>
              <img src="../assets/Trainings/allocation/allocation77.png" />
              <p>Select Resource type:</p>
              <img src="../assets/Trainings/allocation/allocation78.png" />
              <p>Search and select the Resource Type.</p>
              <img src="../assets/Trainings/allocation/allocation79.png" />
              <p>Allocate Resource information should be displayed with the resource name and start date and end date.</p>
              <img src="../assets/Trainings/allocation/allocation80.png" />
              <p>Click on the Allocate button.</p>
              <img src="../assets/Trainings/allocation/allocation81.png" />
              <p>Allocation successfully message will display.</p>
              <img src="../assets/Trainings/allocation/allocation82.png" />
          </div>
      </mat-tab>
  </ng-template>

  <ng-template [jhiHasAnyAuthority]="'ORDER_TO_CASH_NAVBAR'||'SOW_NAVBAR'">
      <mat-tab label="Order to cash & Sow">
          <div class="training_m">
              <h2>Order to Cash</h2>
              <p class="bld">Login to aperture:https://aperture.gspann.com/#/</p>
              <p>Example:Login using mail Id/</p>
              <p>Login ID/ Pwd: abc@gspann.com / XXXXX@12</p>
              <p>Select Order to Cash Tab</p>
              <p>Select SOW from Order to Cash drop-down.</p>
              <img src="../assets/Trainings/sow/sow1.png" />
              <h2>Then user able to look SOW landing page.</h2>
              <p>User can view the Sow list screen.</p>
              <img src="../assets/Trainings/sow/sow2.png" />
              <p>Select & Search Account from Account Drop-down List</p>
              <p>E.g.: Select account from Drop-down list ex: Amazon</p>
              <img src="../assets/Trainings/sow/sow3.png" />
              <p>User can Select & Search Type from Type Drop-down List:</p>
              <p>E.g.: Select Type from Drop-down list ex: Parent only or Child Only (means Extend SOW).</p>
              <img src="../assets/Trainings/sow/sow4.png" />
              <p>User can Select & Search SOW</p>
              <p>Statement of Work (SOW) is picked from drop-down list provided under field SOW Account->Type>SOW.</p>
              <img src="../assets/Trainings/sow/sow5.png" />
              <img src="../assets/Trainings/sow/sow5a.png" />
              <p>If user wants to look SOW which Available for allocation. Then checked Available for allocation box.</p>
              <img src="../assets/Trainings/sow/sow6.png" />
              <p>Then click on “Search” and see, which is available for Allocation.</p>
              <img src="../assets/Trainings/sow/sow7.png" />
              <p>If user wants to do Reset all selected fields, then click on Reset button.</p>
              <img src="../assets/Trainings/sow/sow8.png" />
              <p>If user wants to Export SOW data. Then click on Export SOW button excel file is downloaded.</p>
              <img src="../assets/Trainings/sow/sow9.png" />
              <h2>Add SOW:</h2>
              <p>If user wants to create new SOW.</p>
              <p>Click on “Add SOW” button as displayed below.</p>
              <img src="../assets/Trainings/sow/sow10.png" />
              <h2>Select Account</h2>
              <p>Select & Search Account from Account Drop-down List:</p>
              <p>Eg: Select account from Drop-down list e.g.: Allen Solly</p>
              <img src="../assets/Trainings/sow/sow11.png" />
              <img src="../assets/Trainings/sow/sow11a.png" />
              <h2>Enter SOW Name</h2>
              <p>Enter SOW name in SOW name field</p>
              <p>E.g.: Gspann_sow_01 etc.</p>
              <img src="../assets/Trainings/sow/sow12.png" />
              <h2>Enter Purchase Order Number</h2>
              <p>User can enter unique id/number.</p>
              <img src="../assets/Trainings/sow/sow13.png" />
              <h2>Select Currency</h2>
              <p>User can Search & Select Currency from Currency drop-down.</p>
              <img src="../assets/Trainings/sow/sow14.png" />
              <h2>Select Status</h2>
              <p>User selects the Status type form the dropdown.</p>
              <img src="../assets/Trainings/sow/sow15.png" />
              <h2>Select Start Date & End Date</h2>
              <p>User Selects 'Start Date' & 'End Date' from the calendar.</p>
              <img src="../assets/Trainings/sow/sow16.png" />
              <p>Then user able to look WBS (Work Breakdown structures) details page.</p>
              <h2>Enter MISC Bill</h2>
              <p>User can enter Misc Bill number.</p>
              <img src="../assets/Trainings/sow/sow17.png" />
              <h2>Select Document Status</h2>
              <p>User can search/Select the Document status from the dropdown.</p>
              <img src="../assets/Trainings/sow/sow18.png" />
              <h2>Enter SOW Value:</h2>
              <p>User can Enter Unique SOW Value Amount</p>
              <p>Note; It should allow up to 2 decimal values.</p>
              >
              <img src="../assets/Trainings/sow/sow19.png" />
              <h2>Enter Remarks:</h2>
              <p>User can enter Remarks if any.</p>
              <img src="../assets/Trainings/sow/sow20.png" />
              <h2>Under WBS:</h2>
              <p>User will Select Project, Allocation Type, Enter Role ID, Enter Hourly Rate & Enter Contracted Hours.</p>
              <p>a). Select Project:</p>
              <img src="../assets/Trainings/sow/sow21.png" />
              <p>b). Allocation Type: Billable, Non-Billable & Buffer.</p>
              <img src="../assets/Trainings/sow/sow22.png" />
              <p>c). Enter Role ID:</p>
              <img src="../assets/Trainings/sow/sow23.png" />
              <p>d). Enter Hourly Rate:</p>
              <img src="../assets/Trainings/sow/sow24.png" />
              <p>e). Enter Contracted Hours:</p>
              <img src="../assets/Trainings/sow/sow25.png" />
              <p>18. If required more rows of SOW. Then click on add icon under Actions:</p>
              <img src="../assets/Trainings/sow/sow26.png" />
              <p>a). New row is added:</p>
              <img src="../assets/Trainings/sow/sow27.png" />
              <p>b). If want to remove added row. Then click on cross icon:</p>
              <img src="../assets/Trainings/sow/sow28.png" />
              <p>c). If want to copy same SOW. Then click on copy icon:</p>
              <img src="../assets/Trainings/sow/sow29.png" />
              <h2>Attach Supporting documents:</h2>
              <p>By clicking button size up to 7 mb.</p>
              <p>User can attach different type of documents like .doc, .jpeg,.xlsx,.png,. etc.,</p>
              <img src="../assets/Trainings/sow/sow30.png" />
              <h2>Click on Create:</h2>
              <img src="../assets/Trainings/sow/sow31.png" />
              <p>20. If doing any mistake then click on Reset all field should be clear.</p>
              <img src="../assets/Trainings/sow/sow32.png" />
              <p>After SOW create it looks display below</p>
              <img src="../assets/Trainings/sow/sow33.png" />
              <p>If user wants to add new WBS then user can click on “Add WBS”</p>
              <img src="../assets/Trainings/sow/sow34.png" />
              <p>Enter all required details: Then click on save.</p>
              <img src="../assets/Trainings/sow/sow35.png" />
              <p>If want to Edit, Copy & Delete then click it.</p>
              <img src="../assets/Trainings/sow/sow36.png" />
              <p>If user wants to edit SOW name or any field</p>
              <p>a). Select by click on SOW name.</p>
              <img src="../assets/Trainings/sow/sow37.png" />
              <p>b). Then click on Edit button:</p>
              <img src="../assets/Trainings/sow/sow38.png" />
              <p>c). Then Edit required field:</p>
              <img src="../assets/Trainings/sow/sow39.png" />
              <p>d). Then click on Save button:</p>
              <img src="../assets/Trainings/sow/sow40.png" />
              <h2>Extend SOW Module</h2>
              <p>Extend SOW: If want to Extend any existing SOW and create Child of Parent SOW screen display below</p>
              <p>Click on Extend SOW button right drop-down of Add SOW as displayed below.</p>
              <img src="../assets/Trainings/sow/sow41.png" />
              <p>2. Select & Search Account from Account Drop-down List:</p>
              <p>E.g.: Select account from Drop-down list ex: Allen solly</p>
              <img src="../assets/Trainings/sow/sow42.png" />
              <p>3. Select & Search the Parent SOW from Parent SOW Drop-down List. (That means existing SOW names):</p>
              <p>E.g.: Select Extend SOW from Drop-down list ex: Gspann_sow_01.</p>
              <img src="../assets/Trainings/sow/sow43.png" />
              <p>Enter SOW name in SOW name field (Child SOW name):</p>
              <p>E.g.: Gspann_management_01 etc.</p>
              <img src="../assets/Trainings/sow/sow44.png" />
              <p>Then followed steps same as for creating SOW.</p>
              <h2>Clone SOW:</h2>
              <p>User can Clone the sow.</p>
              <p>User navigated to the SOW list screen.</p>
              <p>Select any Existing sow, Click on Clone on Actions in the Same row</p>
              <img src="../assets/Trainings/sow/sow45.png" />
              <p>Enter Unique SOW name.</p>
              <img src="../assets/Trainings/sow/sow46.png" />
              <p>Click on “Clone” button.</p>
              <p>User can see the Cloned View SOW</p>
              <img src="../assets/Trainings/sow/sow47.png" />
          </div>
      </mat-tab>
  </ng-template>
  <ng-template [jhiHasAnyAuthority]="'PROJECTS_VIEW'">
      <mat-tab label="Project View">
          <div class="training_m">
              <p class="bld">Login to aperture:</p>
              <p>Example:</p>
              <p>Login ID/ Pwd: abc@gspann.com / XXXXX@12</p>
              <p class="bld">User can click on Project Tab.</p>
              <img src="../assets/Trainings/projectView/pv1.png" />
              <p>User click on search box which project is looking for Allocation.</p>
              <img src="../assets/Trainings/projectView/pv2.png" />
              <p>Click on Project</p>
              <img src="../assets/Trainings/projectView/pv3.png" />
              <img src="../assets/Trainings/projectView/pv4.png" />
              <p>Then user can click on Allocated Resources.</p>
              <img src="../assets/Trainings/projectView/pv5.png" />
              <p>Then click on ‘Allocate’</p>
              <img src="../assets/Trainings/projectView/pv6.png" />
              <p>Then select Project which will be for Allocation.</p>
              <img src="../assets/Trainings/projectView/pv7.png" />
              <img src="../assets/Trainings/allocation/allocation21.png" />
              <img src="../assets/Trainings/allocation/allocation22.png" />
              <h3>Check "Start date" & "End date" , SOW Details,</h3>
              <img src="../assets/Trainings/allocation/allocation23.png" />
              <h3>Check - Available, Allocated, Partially Allocated</h3>
              <p>Note- Available(0) - WBS is not Available for Allocation</p>
              <br />
              <p>Available(1) - One Wbs is utilized.</p>
              <br />
              <p>Allocated(0)- Resource may not be allocated or Partially Allocated.</p>
              <br />
              <p>Allocated(1)- Resource allocation start date & end date same as WBS.</p>
              <br />
              <p>Partial Allocated(0)- Resource allocation start date & end date same as WBS.</p>
              <br />
              <p>Partial Allocated(1)- Resource allocation start date & end date not same as WBS.</p>
              <img src="../assets/Trainings/allocation/allocation24.png" />
              <h3>Select Project, Allocation Type & Resource Type</h3>
              <img src="../assets/Trainings/allocation/allocation25.png" />
              <h3>Search resource using Name,Email Id & Resource Id</h3>
              <img src="../assets/Trainings/allocation/allocation26.png" />
              <h3>Then check allocation details by click on 'See details'.</h3>
              <img src="../assets/Trainings/allocation/allocation27.png" />
              <h3>Then check utilization percentage Active & Future Allocation.</h3>
              <img src="../assets/Trainings/allocation/allocation28.png" />
              <h3>If Client Time Tracking applicable then checked.</h3>
              <img src="../assets/Trainings/allocation/allocation29.png" />
              <h3>If Flexible Week Ends applicable then checked.</h3>
              <img src="../assets/Trainings/allocation/allocation30.png" />
              <h3>If Pseudo Resource applicable then checked & enter other details.</h3>
              <img src="../assets/Trainings/allocation/allocation31.png" />
              <h3>If Delegate Manager is required for Timsheet Approval then search & select.</h3>
              <img src="../assets/Trainings/allocation/allocation32.png" />
              <h3>Then enter other details like start & end date & occupancy then click on 'Allocate'</h3>
              <img src="../assets/Trainings/allocation/allocation33.png" />
              <h3>If Delegated Manager is Yes.</h3>
              <p>Then below screen is showing.</p>
              <img src="../assets/Trainings/project/editdelegetmanageryes.png" />
              <img src="../assets/Trainings/project/editdelegatemanageryes-1.png" />
              <p>In Above screen under Offshore Engagement.</p>
              <br />
              <p>Resource Allocated : 4</p>
              <br />
              <p>If user want to assign some resource to DM</p>
              <img src="../assets/Trainings/project/delegatemangerteam-11.png" />
              <p>Then search resource to assign DM role.</p>
              <br />
              <p>Note: Offshore & Onshore Engagement name will not search in DM list.</p>
              <img src="../assets/Trainings/project/dmlist.png" />
              <p>Under Team Members all allocated resource list is showing which the in project.</p>
              <img src="../assets/Trainings/project/teammembers.png" />
              <img src="../assets/Trainings/project/addteammanagerdelegate.png" />
              <img src="../assets/Trainings/project/delegatemanagersandteam.png" />
              <img src="../assets/Trainings/project/delegate123.png" />
              <p>If user want to update then Edit/Delete.</p>
              <img src="../assets/Trainings/project/updatedeletedm.png" />
          </div>
      </mat-tab>
  </ng-template>

  <ng-template [jhiHasAnyAuthority]="'RESOURCES_TIMESHEET'">
      <mat-tab label="Resource Timesheet">
          <div class="training_m">
              <h2>Resource Timesheet:</h2>
              <p>Example:</p>
              <p>Login ID/ Pwd: abc@gspann.com / XXXXX@12</p>
              <p>Select Resource form the Menu & Select Aperture form the dropdown.</p>
              <p class="bld">If user having specific role to look Resource Timesheet.</p>
              <p>Search Resource Name in search box.</p>
              <img src="../assets/Trainings/resource/rt1.png" />
              <p>Click on the resource name form the suggested dropdown.</p>
              <p>User navigated to the resource details screen.</p>
              <img src="../assets/Trainings/resource/rt2.png" />
              <p>Then click on Timesheet.</p>
              <img src="../assets/Trainings/resource/rt3.png" />
              <p>User can see the Resource’s Current week timesheet.</p>
              <img src="../assets/Trainings/resource/rt4.png" />
              <h2>Weekly:</h2>
              <p>User can navigate previous week/ Next weeks by using weekly Navigation arrows.</p>
              <p>Or user select the Particular week TS using the calendar.</p>
              <img src="../assets/Trainings/resource/rt5.png" />
              <h2>Monthly Navigation:</h2>
              <p>Click on Monthly button to View the resource’s current month Timesheets.</p>
              <p>User can navigate to the Previous/ Future months with the Navigation arrows.</p>
              <p>User can select the specific Month form the calendar.</p>
              <img src="../assets/Trainings/resource/rt6.png" />
              <img src="../assets/Trainings/resource/rt7.png" />
              <img src="../assets/Trainings/resource/rt8.png" />
              <h2>Date Range:</h2>
              <p>Click on Date Range</p>
              <p>User can view the Timesheets within the selected date range.</p>
              <p>User can select the from date & To date using the calendar.</p>
              <img src="../assets/Trainings/resource/rt9.png" />
              <img src="../assets/Trainings/resource/rt10.png" />
              <p>If user having specific role to Edit Resource Timesheet, then user can submit/ save the Resource’s Timesheet.</p>
              <p>Resource Timesheet with the Pending Status</p>
              <img src="../assets/Trainings/resource/rt11.png" />
              <p>User can enter 8 hours per a day and click on Submit.</p>
              <img src="../assets/Trainings/resource/rt12.png" />
              <h2>Add Leaves:</h2>
              <p>If user want to add Leaves, then Click on “Add Leaves”.</p>
              <p>Select Leave type from the dropdown.</p>
              <!-- <p>Which are Paid Leave /Unpaid Leave/ Non-billable.</p> -->
              <p>Select the Leave type and enter 8 hours per the Leave days.</p>
              <!-- <img src="../assets/Trainings/resource/rt13.png" /> -->
              <!-- <img src="../assets/Trainings/resource/rt14.png" /> -->
              <img src="../assets/Trainings/resource/rt15.png" />
              <p>Click on submit.</p>
              <img src="../assets/Trainings/resource/rt16.png" />
              <p>While submitting the Timesheet user can enter Comments if any (250 characters only) which is not Optional.</p>
              <img src="../assets/Trainings/resource/rt17.png" />
              <p>Click on “submit”</p>
              <p>User can see the Timesheet with the “Submitted” status.</p>
              <img src="../assets/Trainings/resource/rt18.png" />
              <h2>Recall Timesheet:</h2>
              <p>If user want to re-submit the Timesheet the click on “Recall Timesheet” button.</p>
              <p>Then the TS will be in submitted status and user can re-submit the Timesheet with correct details.</p>
              <img src="../assets/Trainings/resource/rt19.png" />
              <img src="../assets/Trainings/resource/rt20.png" />
              <img src="../assets/Trainings/resource/rt21.png" />
              <img src="../assets/Trainings/resource/rt22.png" />
          </div>
      </mat-tab>
  </ng-template>
  <ng-template [jhiHasAnyAuthority]="'LMS_ADMIN'">
      <mat-tab label="LMS Admin">
          <div class="training_m">
              <h3 class="font-weight-bold">Admin</h3>
              <ol>
                  <li>
                      <h4 class="font-weight-bold">Admin User Login</h4>
                      <p>Admin can be an On-shore or Off-shore Admin. As per the role he take care of On-shore or Off-shore activities related to LMS. Users do login with valid credentials provided by an organisation.</p>
                      <!-- To add image admin login -->
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture1.PNG" alt="Keycloak landing screen" />
                      <h6>Aperture Landing Page</h6>
                      <p>After logging into the Aperture application successfully, the user is navigated to the Aperture Landing page. As per the assigned roles, user can see the different menus.</p>
                      <!-- To add image -->
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture2.PNG" alt="Admin Aperture Landing Screen" />
                  </li>
                  <li>
                      <h4 class="font-weight-bold">Leaves Dashboard</h4>
                      <p>After clicking on the Leaves menu from the Aperture Landing page, it is redirected to the Leave-Dashboard page.</p>
                      <!-- To add image -->
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture3.PNG" alt="Admin Leaves Landing screen" />
                  </li>
                  <li>
                      <h4 class="font-weight-bold">Admin</h4>
                      <p>Admin has below access :</p>
                      <ol>
                          <li>Apply leave for others</li>
                          <li>All Leave applications</li>
                          <li>Revert Leaves</li>
                          <li>Export</li>
                          <li>Leave Balance for others</li>
                      </ol>

                      <!-- To add image -->
                      <img src="../assets/Trainings/LMS_Admin/Picture25.png" alt="Admin options panel" />
                  </li>
                  <li>
                      <h4 class="font-weight-bold">Apply Leave for others</h4>
                      <p>Admin can apply leave on the behalf of others.</p>
                      <p>Click on the Apply Leave for Others link at the left panel of the Admin section.</p>
                      <img src="../assets/Trainings/LMS_Admin/Admin Leave for Others link.jpg" alt="Apply leave for others landing screen" />
                      <p>Admin search the employee for which he/she want to apply the leave.</p>
                      <img src="../assets/Trainings/LMS_Admin/Admin Leave for Others Search Employee.jpg" alt="Search Resource" />
                      <p>Then Admin can fill the required details of the resource and Submit Leave Application.</p>
                      <img src="../assets/Trainings/LMS_Admin/Filled Leave Applicationn form.jpg" alt="Fill in all the required details" />
                  </li>
                  <li>
                      <h4 class="font-weight-bold">All Leave Applications</h4>
                      <p>In All Leave Approvals, all types of leave applications are getting displayed. Admin can approve or reject the application individually or in a bulk.</p>
                      <p>
                          He/ She can search a record by using Employee name or Employee ID or Email or Resource ID. He/ She can see details of an employee(s) who applied for leave(s). Filter by functionality is also provided to filter
                          the leave records as per the Leave Types.
                      </p>
                      <p>He/ She can filter the leave records based on Leave Type and Location from front end UI. However, He/ She can filter the leave records as per the date range selection and those records comes from the backend.</p>
                      <p>Admin can export the leave records of employees in excel or csv file formats by applying the filters such as Leave Type, Location, Resource Code and Resource Type.</p>
                      <!-- May not br required -->
                      <p>Pagination functionality is provided to Leave Approvals page.</p>
                      <h6>Search by Name:</h6>
                      <p>Admin can search a resource by his/her name.</p>
                      <img src="../assets/Trainings/LMS_Admin/All_Leave_Applications_Search_By_Resource_Name.jpg" alt="Search resource by Name" />
                      <h6>Reset :</h6>
                      <p>By using reset functionality, previous entered values remove.</p>
                      <img src="../assets/Trainings/LMS_Admin/All_Leave_Applications_Reset_Functionality.jpg" alt="Reset after search" />
                      <p>There are 3 different ways by which Admin can approve or reject the Leave(s).</p>
                      <ol>
                          <li>
                              Using View Details popup
                              <p>After clicking on the 3 dots of extreme right of any record, View Details popup opens</p>
                              <img src="../assets/Trainings/LMS_Admin/All_Leave_Applications_Leave_Approve_Or_Reject_From_View_Details_Screen.jpg" alt="Display view details options" />
                              <img src="../assets/Trainings/LMS_Admin/All_Leave_Applications_Leave_Approve_Or_Reject_From_View_Details_Screen_2.jpg" alt="Display pop up with approve and reject options" />
                          </li>
                          <li>
                              By clicking on the approve or reject icons for a specific record.
                              <img src="../assets/Trainings/LMS_Admin/All_Leave_Applications_Leave_Approve_Or_Reject_By_Selecting_Right_Or_Cross_Icons.jpg" alt="Click approve or reject for a record" />
                          </li>
                          <li>
                              By selecting the checkbox of the specific record and then click on the Approve or Reject button at the top.
                              <img src="../assets/Trainings/LMS_Admin/All_Leave_Applications_Leave_Approve_Or_Reject_By_Selecting_Checkbox_And_Approve_Or_Reject.jpg" alt="Bulk selection options" />
                          </li>
                      </ol>
                      <p>To use the filters, click on the filter icon at the right top of the page.</p>
                      <img src="../assets/Trainings/LMS_Admin/All_Leave_Applications_Filters_Icon.jpg" alt="Click filter icon" />
                      <p>We can search the leave records on the basis of Applied Leave Dates range and we can sort the record by From Dates in Descending order.</p>
                      <img src="../assets/Trainings/LMS_Admin/All_Leave_Applications_Filters_Sort_By_From_Date_And_Search_Records_By_Duration_Of_Applied_Date.jpg" alt="Search based on dates" />
                      <p>Admin can apply the search the record by applying the combination filters such as Leave Type and Location.</p>
                      <img src="../assets/Trainings/LMS_Admin/All_Leave_Applications_Filters_Leave_Type_And_Location_Filters.jpg" alt="Search by combination filters" />
                      <p>Admin can export the All Application Leave Records in excel and csv file formats as mentioned be below.</p>
                      <img src="../assets/Trainings/LMS_Admin/All_Leave_Applications_Export_Btn_1.jpg" alt="export options" />
                      <img src="../assets/Trainings/LMS_Admin/All_Leave_Applications_Export_Btn_Export_Excel_And-CSV.jpg" alt="export options expand" />
                      <img src="../assets/Trainings/LMS_Admin/All_Leave_Applications_Export report to excel.jpg" alt="show the accordion for export" />
                      <img src="../assets/Trainings/LMS_Admin/All_Leave_Applications_Export report to csv.jpg" alt="accordion for csv" />
                      <p>Admin can do Approve or Reject Leave(s) using bulk selection. He/ She can select the checkbox.</p>
                      <p>Then he can proceed for bulk Approval or bulk Rejection.</p>
                      <img src="../assets/Trainings/LMS_Admin/All_Leave_Applications_Select_All_To_Approve_Or_Reject_From.jpg" alt="bulk selection" />
                  </li>
                  <li>
                      <h4 class="font-weight-bold">Revert Leaves</h4>
                      <p>Go to the left panel in the Admin section and click on the Revert Leaves link.</p>
                      <img src="../assets/Trainings/LMS_Admin/Revert_Leave_Page.jpg" alt="Revert leaves landing screen" />
                      <p>In Revert Leave, Admin can search a record by using Employee name or Employee ID or Email or Resource ID.</p>

                      <!-- Image is missing -->
                      <!-- <img src="../assets/Trainings/LMS_Admin/" alt="search resource"> -->
                      <img src="../assets/Trainings/LMS_Admin/Revert_Leave_Records.jpg" alt="display results after search" />
                      <p>Go to the View details. In View details pop-up, enter the comments. Then click on the Revert button.</p>
                      <img src="../assets/Trainings/LMS_Admin/Revert_Leave_View_Details_Button.jpg" alt="Revert leave from pop-up" />
                      <img src="../assets/Trainings/LMS_Admin/Revert_Leave_View_Details_Revert_Button.jpg" alt="Enter comment in pop-up" />
                      <p>Also, admin do revert directly from the revert icon as mentioned in the leave record row as mentioned below.</p>
                      <img src="../assets/Trainings/LMS_Admin/Revert_Leave_Revert_Leave_Icon.jpg" alt="click revert icon" />
                      <img src="../assets/Trainings/LMS_Admin/Revert_Leave_Pop_up_To_Revert.jpg" alt="enter revert comment" />
                      <img src="../assets/Trainings/LMS_Admin/Revert_Leave_Reverted_Leave_Successfully_Message.jpg" alt="leave reverted successfully" />
                      <p>Admin can sort the records as per the duration.</p>
                      <img src="../assets/Trainings/LMS_Admin/Revert_Leave_Sort_By_Duration_In_Descending_Order.jpg" alt="sort by duration" />
                      <p>Pagination is also available at the bottom of the page.</p>
                      <img src="../assets/Trainings/LMS_Admin/Revert_Leave_Pagination.jpg" alt="revert Pagination" />
                      <p>There are 2 filters are available. Filter by year and filter by leave type.</p>
                      <img src="../assets/Trainings/LMS_Admin/Revert_Leave_Filter_Options.jpg" alt="show filters" />
                      <p>Filter by year:</p>
                      <img src="../assets/Trainings/LMS_Admin/Revert_Leave_Filter_By_Year.jpg" alt="filter by year" />
                      <p>Filter by Leave type.</p>
                      <img src="../assets/Trainings/LMS_Admin/Revert_Leave_Filter_By_Leave_Type.jpg" alt="filter by leave type" />
                  </li>
                  <li>
                      <h4 class="font-weight-bold">Export Leaves</h4>
                      <p>Click on the Export link at the left panel for the Admin section.</p>
                      <img src="../assets/Trainings/LMS_Admin/Export_Export_Data.jpg" alt="export landing screen" />
                      <p>In Export Leave, Admin can search a record by using Employee name or Employee ID or Email or Resource ID.</p>
                      <img src="../assets/Trainings/LMS_Admin/Export_Search_Leave_Records_By_Resource_Name.jpg" alt="search by name" />
                      <p class="font-weight-bold">Reset Functionality</p>
                      <p>Reset clears the data which entered the data in text box or values are selected from the drop down List.</p>
                      <img src="../assets/Trainings/LMS_Admin/Export_Reset_1.jpg" alt="reset-image one" />
                      <img src="../assets/Trainings/LMS_Admin/Export_Reset_2.jpg" alt="reset-image-two" />
                      <p>He/ She can search the leave details for employees based on Date Range, Leave Type, Status, Practice, Account and Resource Type.</p>
                      <p>Search By Leave Type:</p>
                      <img src="../assets/Trainings/LMS_Admin/Export_Search_By_Leave_Type.jpg" alt="search by leave type" />
                      <p>Search by Leave Status:</p>
                      <img src="../assets/Trainings/LMS_Admin/Export_Search_By_Leave_Status.jpg" alt="search by leave status" />
                      <p>Search by Account type:</p>
                      <img src="../assets/Trainings/LMS_Admin/Export_Search_By_Account_Type.jpg" alt="search by account" />
                      <p>Search by Practice:</p>
                      <img src="../assets/Trainings/LMS_Admin/Export_Search_By_Practice_Type.jpg" alt="search by practice" />
                      <p>Search by resource type:</p>
                      <img src="../assets/Trainings/LMS_Admin/Export_Search_By_Resource_Type.jpg" alt="search by resource type" />
                      <p>Export Search By Date range With Sort By From Date:</p>
                      <img src="../assets/Trainings/LMS_Admin/Export_Search_By_Date_range_With_Sort_By_From_Date.jpg" alt="export search date range" />
                      <p>Click on the Export button:</p>
                      <img src="../assets/Trainings/LMS_Admin/Export_Export_Button.jpg" alt="click export" />
                      <p>Admin can export the employee leave details in Excel or CSV file formats.</p>
                      <p>Total UI Records: 82</p>
                      <img src="../assets/Trainings/LMS_Admin/Export_Total_UI_Records.jpg" alt="total 82 records" />
                      <p>Exported records in excel formats:</p>
                      <img src="../assets/Trainings/LMS_Admin/Export_Export_Excel_And_Export_CSV.jpg" alt="export records options" />
                      <p>Exported total export records:</p>
                      <img src="../assets/Trainings/LMS_Admin/Export_Total_Excel_Records.jpg" alt="excel sheet exported" />
                      <p>Exported total csv records:</p>
                      <img src="../assets/Trainings/LMS_Admin/Export_Total_CSV_Records.jpg" alt="exported csv records" />
                      <p>Pagination functionality is provided to Export Leave page.</p>
                      <img src="../assets/Trainings/LMS_Admin/Export_Pagination.jpg" alt="pagination functionality" />
                  </li>
                  <li>
                      <h4 class="font-weight-bold">Leave Balance for Others</h4>
                      <p>Click on the Leave balance for Others at the left panel for the Admin section.</p>
                      <img src="../assets/Trainings/LMS_Admin/Leave balance for Others Page.jpg" alt="leave balance landing screen" />
                      <p>Admin can search an employee and check his/ her leave balance.</p>
                      <img src="../assets/Trainings/LMS_Admin/Leave balance for Others_Search_With_Resource_Name.jpg" alt="search resource" />
                      <img src="../assets/Trainings/LMS_Admin/Leave balance for Others_Search_With_Resource_Name_2.jpg" alt="select from drop-down" />
                      <img src="../assets/Trainings/LMS_Admin/Leave balance for Others_Search_With_Resource_Name_3.jpg" alt="display results" />
                      <p>Update Leave Balance for Annual Leave type for the searched resource</p>
                      <p class="font-weight-bold">Opening Balance</p>
                      <p>Balance which is carry forwarded from the last year is the Opening balance. For Off-shore resource Opening balance is '10'.</p>
                      <p class="font-weight-bold">Accrued</p>
                      <p>
                          Balance which credited on monthly basis, and it will be added to the previous Accrued balance. It's started from Annual Performance cycle. For offshore resource, it starts from April to March cycle. By default,
                          initial Accrued balance is 1.75 days. Its cumulative balance adding in each month.
                      </p>
                      <p>Admin can update it.</p>
                      <img src="../assets/Trainings/LMS_Admin/Leave balance for Others_Annual_Leave.jpg" alt="update annual leave balance" />
                      <p>Update Leave Balance for Comp-Off Leave type for the searched resource</p>
                      <img src="../assets/Trainings/LMS_Admin/Leave balance for Others_Comp-Off_Leave.jpg" alt="comp-off leave balance" />
                      <p>Similarly, admin can update the leaves for other leave types for the required resource.</p>
                  </li>
              </ol>
          </div>
      </mat-tab>
  </ng-template>

  <ng-template [jhiHasAnyAuthority]="'LMS_LEAVE_REVIEWER'">
      <mat-tab label="Leave Review">
          <div class="training_m">
              <h3 class="font-weight-bold">Manager</h3>
              <ol>
                  <li>
                      <p>Users do login with valid credentials provided by an organisation.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture1.PNG" alt="Keycloak landing screen" />
                      <p>After logged into the Aperture application successfully, its navigating to the Aperture Landing page. As per the assigned roles, user can see the different menus.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture2.PNG" alt="Aperture landing screen" />
                      <p>After clicking on the Leaves menu from the Aperture Landing page, it is redirected to the Leave-Dashboard page.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture3.PNG" alt="Leave Dashboard" />
                      <h4 class="font-weight-bold">Review</h4>
                      <p>Resource Manager has access to below 3 links.</p>
                      <ol>
                          <li>Grant Comp-Offs</li>
                          <li>Leave Approvals</li>
                          <li>Completed Requests</li>
                      </ol>
                      <p>Click on the Grant Comp-Offs link. Then resource redirects to the Grant Comp-Offs page</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture4.PNG" alt="Grant comp-off landing screen" />
                  </li>
                  <li>
                      <h4>Grant Comp-offs</h4>
                      <p>In Leave Grant Comp-Offs, approver can search a record by using Employee name or Employee ID or Email or Resource ID.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture5.PNG" alt="search resource" />
                      <p>RM can Select single record and then Approve or Reject the Grant Comp-Off by using the Approve/Reject buttons.</p>
                      <p>For Reject functionality, comment is mandatory. However, for Approve functionality comments is not mandatory.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture6.PNG" alt="select single record from list" />
                      <p>RM can Approve or Reject the Grant Comp-Off by clicking on the approve or reject icons.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture7.PNG" alt="Select either approve/reject icon" />
                      <p>RM can Approve or Reject the Grant Comp-Off by using View Details pop-up for the specific record.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture8.PNG" alt="approve/reject from view details" />
                      <p>RM can Select All records at a time and Approve or Reject all the records.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture9.PNG" alt="Select All to approve/reject" />
                      <p>Pagination functionality is provided to Grant Comp-Offs page.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture10.PNG" alt="Pagination" />
                  </li>
                  <li>
                      <h4>Leave Approvals</h4>
                      <p>Click on the Leave Approvals link from the left panel. Then RM redirects to the Leave Approvals page.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture11.PNG" alt="Team Applications Landing screen" />
                      <p>In Leave Approvals, approver can search a record by using Employee name or Employee ID or Email or Resource ID.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture12.PNG" alt="search resource in leave approvals" />
                      <p>RM can Select single record and then Approve or Reject the leave by using the Approve/Reject buttons.</p>
                      <p>For Reject functionality, comment is mandatory. However, for Approve functionality comments is not mandatory.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture13.PNG" alt="select record from list" />
                      <p>RM can Approve or Reject the leave by clicking on the approve or reject icons.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture14.PNG" alt="Select either approve/reject icon for leave" />
                      <p>RM can Select All records at a time and Approve or Reject all the records.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture15.PNG" alt="Select All to approve/reject for leave" />
                      <p>RM can Approve or Reject the Leave using View Details pop-up for the specific record.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture16.PNG" alt="leave approvals view details pop-up" />
                      <h6>Delegate Leave</h6>
                      <p>Approver can delegate employee leaves to another approver/manager if necessary.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture17.PNG" alt="delegate dropdown" />
                      <p>From Delegate pop-up window, one RM can search the resource and delegate that leave record to Approve or Reject.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture18.PNG" alt="open delegate modal" />
                  </li>
                  <li>
                      <h4>Completed Requests</h4>
                      <p>Click on the Completed Requests link from the left panel. It redirects to the Completed Requests page.</p>
                      <p>In Completed Requests, approver can search a record by using Employee name or Employee ID or Email or Resource ID and can see the Leave Status of the employee.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture19.PNG" alt="Completed Requests landing screen" />
                      <p>Search Resource in Completed Requests page.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture20.PNG" alt="Search Resource in Completed Requests page" />
                      <p>Approver can see the leave records of all the employees who applied for leaves and also those which have been either approved, rejected or withdrawn.</p>
                      <img src="../assets/Trainings/LMS_REVIEWER/Capture21.PNG" alt="List of submitted leaves" />
                      <h6>Completed Requests Filters</h6>
                      <p>After clicking on the filter icon, 2 options will be displayed.</p>
                      <ol>
                          <li>Show records for: Last 7 days.</li>
                          <p>By default this options has been selected and records are getting displayed as per that.</p>
                          <img src="../assets/Trainings/LMS_REVIEWER/Capture23.PNG" alt="Default records" />
                          <li>Show records for Date Range. RM can apply the filter as per the Date Range.</li>
                          <p>Approver can filter the leave records based on selecting Last 7 days filter or Date Range filter.</p>
                          <img src="../assets/Trainings/LMS_REVIEWER/Capture24.PNG" alt="Show filters on date range" />
                          <p>
                              Records filters on the basis of Applied On dates. Initially Records are displayed in descending order on the basis of Applied On dates. After applying the filters by selecting the sort by from date checkbox,
                              we get the records in Descending order as per the from date.
                          </p>
                      </ol>
                  </li>
              </ol>
          </div>
      </mat-tab>
  </ng-template>
  <mat-tab label="LMS">
      <div *jhiHasAnyAuthority="['LMS_ONSHORE_VC_HOURLY', 'LMS_ONSHORE_DC']">
          <div class="training_m">
              <p class="bld">Login to Aperture :</p>
              <p>Example :</p>
              <p>Login ID: abc@gspann.com</p>
              <p>Password: XXXXX@12</p>
              <p class="bld">User logged in to the Aperture Home page.</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/1.png" alt="Aperture dashboard" />
              <p class="bld">Click on Leaves:</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/2.png" alt="click leaves" />
              <p>User is navigated to the leave dashboard.</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/3.png" alt="leave-dashboard" />
              <p>User able to see the LOP Leave Balance card on Dashboard screen.</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/4.png" alt="leave-dashboard" />
              <h5 class="bld">Team Leaves:</h5>
              <p>User is able to view the team members who are on Leave in the current week.</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/5.png" alt="leave-dashboard" />
              <h5 class="bld">Holidays in Dashboard:</h5>
              <p>User can see the Holidays based on their Deputed Location on Dashboard.</p>
              <p>View for all Locations > Will redirect to the Holiday Calendar screen.</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/6.png" alt="leave-dashboard" />
              <h5 class="bld">My Leaves</h5>
              <p>User is able to view the “My Leaves” on the left panel.</p>
              <p>From My Leaves dropdown, user Click & Navigate to the below mentioned screens.</p>
              <ul>
                  <li>Apply Leave</li>
                  <li>Leave History</li>
                  <li>Holiday Calendar</li>
                  <li>Leave Policy</li>
              </ul>
              <img src="../assets/Trainings/LMS-Onshore-VC/7.png" alt="leave-dashboard" />
              <h4 class="bld">Apply Leave</h4>
              <p>Users navigate to the submit leave application screen in two ways.</p>
              <li>My Leaves dropdown> Apply Leave.</li>
              <li><b>Apply Leave</b> button from the top right corner of the dashboard.</li>
              <img src="../assets/Trainings/LMS-Onshore-VC/8.png" alt="leave-8" />
              <h4 class="bld">Submit Leave Application:</h4>
              <p>When users click on <b>Apply Leave</b> button, it will navigate to the <b>Submit leave application</b> screen.</p>
              <p>User able to view the pre-populated logged in user's name.</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/9.png" alt="leave-9" />
              <h4 class="bld">Time Off Policy:</h4>
              <p>Users Click on Time Off Policy dropdown and select the Time Off Policy (LOP) to apply the Leave.</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/10.png" alt="leave-10" />
              <h4 class="bld">From Date & To date:</h4>
              <p>Users select the Leave duration using “from date & To date” calendars.</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/11.png" alt="leave-11" />
              <img src="../assets/Trainings/LMS-Onshore-VC/12.png" alt="leave-12" />
              <h4 class="bld">Hours:</h4>
              <p>Hours will auto calculate Based On selected dates. (Holidays & Weekends will not calculate as Leave)</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/13.png" alt="leave-13" />
              <h4 class="bld">Approving Person</h4>
              <p>If User is working for multiple projects, then select the Approver from the “Approving Person” dropdown.</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/14.png" alt="leave-14" />
              <h4 class="bld">Notify Other Persons:</h4>
              <p>If user is working for multiple projects, then user can select the other persons from the “Notify Other Persons” which is not Mandatory. (Only 2 persons are allowed)</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/15.png" alt="leave-15" />
              <h4 class="bld">Contact Details:</h4>
              <p>User can enter 10-digit valid mobile number which is not Mandatory.</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/16.png" alt="leave-16" />
              <h4 class="bld">Reason:</h4>
              <p>Users provide the Reason for applying Leave which is mandatory. It allows 250 characters.</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/17.png" alt="leave-17" />
              <h4 class="bld">Attachment:</h4>
              <p>While applying for the Leave> if user want to attach any Documents, then user can upload documents.</p>
              <p>It allows max size-10mb. (User can upload ZIP file for multiple documents).</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/18.png" alt="leave-18" />
              <img src="../assets/Trainings/LMS-Onshore-VC/19.png" alt="leave-19" />
              <h4 class="bld">Reset:</h4>
              <p>If user wants to change all given information, then user click on “Reset” Button and Continue.</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/20.png" alt="leave-20" />
              <img src="../assets/Trainings/LMS-Onshore-VC/21.png" alt="leave-21" />
              <h4 class="bld">Submit:</h4>
              <p>If user fill all the Mandatory fields, then “Submit” button will enable.</p>
              <p>Users click on “Submit” button, to proceed.</p>
              <p>User is able to view the Successful message as follows “(Leave Type) Leave applied successfully”.</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/22.png" alt="leave-22" />
              <img src="../assets/Trainings/LMS-Onshore-VC/23.png" alt="leave-23" />
              <p>After Applying the leave user will see the applied leave details on dashboard.</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/24.png" alt="leave-24" />
              <h4 class="bld">View Details:</h4>
              <p>User can check the applied leave details.</p>
              <p>Click on Ellipsis for viewing the “Details”, “Summary”, “Attachment” which uploaded for the leave request.</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/25.png" alt="leave-25" />
              <img src="../assets/Trainings/LMS-Onshore-VC/26.png" alt="leave-26" />
              <img src="../assets/Trainings/LMS-Onshore-VC/27.png" alt="leave-27" />
              <img src="../assets/Trainings/LMS-Onshore-VC/28.png" alt="leave-28" />
              <h4 class="bld">Timesheet with Leave:</h4>
              <p>After applying the leave user can check the Timesheet with the Leave row in submitted status. (Unpaid).</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/29.png" alt="leave-29" />
              <h4 class="bld">Withdraw:</h4>
              <p>If user want to cancel the applied leave, then they can withdraw the leave in 2 ways.</p>
              <ul>
                  <li>From the Leave record > click on Ellipsis and Click on withdraw.</li>
                  <li>From View details Pop-up> Click on Withdraw button.</li>
              </ul>
              <img src="../assets/Trainings/LMS-Onshore-VC/30.png" alt="leave-30" />
              <img src="../assets/Trainings/LMS-Onshore-VC/31.png" alt="leave-31" />
              <h4 class="bld">Leave History:</h4>
              <p>User can see the Complete leave history in this screen. Which are “submitted, Approved, Rejected, Withdrawn” leaves.</p>
              <p>Availed:</p>
              <p>All the submitted, Approved, Rejected, Withdrawn Leave records user can see in the Availed tab.</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/32.png" alt="leave-32" />
              <img src="../assets/Trainings/LMS-Onshore-VC/33.png" alt="leave-33" />
              <h4 class="bld">Filters:</h4>
              <p>User Search the records by using the Respective Filters.</p>
              <p class="bld">From date & To date</p>
              <p>Select From date and to date and click on “Search” Button, User will get the Records Fall in Between the Selected Dates.</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/34.png" alt="leave-34" />
              <p class="bld">Leave Status :</p>
              <p>Select Leave Status (Approved, Submit, Withdraw, Reject etc.,) and click on “Search” Button, User will get the Records Selected Leave Status.</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/35.png" alt="leave-35" />
              <h5 class="bld">Holiday Calendar</h5>
              <p>User see the Holidays Based on Logged in user's Deputed location.</p>
              <img src="../assets/Trainings/LMS-Onshore-VC/36.png" alt="leave-36" />
              <h5 class="bld">Leave Policy:</h5>
              <p>Users navigate Leave Policy screen in 2 ways.</p>
              <ul>
                  <li>My leaves dropdown> Leave Policy link</li>
                  <li>Leave Policy button from right side Top of the dashboard.</li>
              </ul>
              <img src="../assets/Trainings/LMS-Onshore-VC/37.png" alt="leave-37" />
          </div>
      </div>
      <div *jhiHasAnyAuthority="['LMS_ONSHORE_FTE_HOURLY','LMS_ONSHORE_FTE_MONTHLY']">
          <div class="training_m">
              <p class="bld">Login to Aperture :</p>
              <p>Example :</p>
              <p>Login ID: abc@gspann.com</p>
              <p>Password: XXXXX@12</p>
              <p class="bld">User logged in to the Aperture Home page.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/1.png" alt="Aperture dashboard" />
              <p class="bld">Click on Leaves:</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/2.png" alt="click leaves" />
              <p>User is navigated to the leave dashboard.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/3.png" alt="leave-dashboard" />
              <p>User will be able to see the Leave Balance cards on Dashboard.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/4.png" alt="leave-balances" />
              <h4 class="bld">Team Leaves</h4>
              <p>User will be able to view the team members who are on leave for the current week.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/5.png" alt="leave-team" />
              <img src="../assets/Trainings/LMS_Onshore_FTE/6.png" alt="leave-team-1" />
              <h4 class="bld">Holidays in dashboard</h4>
              <p>User can see the Holidays based on their Deputed Location On Dashboard.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/6_A.png" alt="leave-holidays" />
              <h4 class="bld">My Leaves</h4>
              <p>User is able to view the “My Leaves” on the left panel.</p>
              <p>From My Leaves dropdown, user Click & Navigate to the below mentioned screens.</p>
              <ul>
                  <li>Apply Leave</li>
                  <li>Request Comp-Off</li>
                  <li>Leave History</li>
                  <li>Holiday Calendar</li>
                  <li>Leave Policy</li>
              </ul>
              <img src="../assets/Trainings/LMS_Onshore_FTE/7.png" alt="leave-7" />
              <h4 class="bld">Apply Leave</h4>
              <p>Users navigate to the submit leave application screen in two ways.</p>
              <li>My Leaves dropdown> Apply Leave.</li>
              <li><b>Apply Leave</b> button from the top right corner of the dashboard.</li>
              <img src="../assets/Trainings/LMS_Onshore_FTE/8.png" alt="leave-8" />
              <h4 class="bld">Submit Leave Application:</h4>
              <p>When users click on <b>Apply Leave</b> button, it will navigate to the <b>Submit leave application</b> screen.</p>
              <p>User able to view the pre-populated logged in user's name.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/9.png" alt="leave-9" />
              <h5 class="bld">Time off Policy</h5>
              <p>Users Click on Time Off Policy dropdown and select the Time Off Policy to apply the Leave.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/10.png" alt="leave-10" />
              <h5 class="bld">From Date & To date:</h5>
              <p>Users select the Leave duration using “from date & To date” calendars.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/11.png" alt="leave-11" />
              <h5 class="bld">Hours</h5>
              <p>Hours will auto calculate Based On selected dates. (Holidays will not calculate as Leave)</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/12.png" alt="leave-12" />
              <h5 class="bld">Approving Person:</h5>
              <p>If User is working for multiple projects, then select the Approver from the “Approving Person” dropdown.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/13.png" alt="leave-13" />
              <h5 class="bld">Notify Other Persons:</h5>
              <p>If user working for multiple projects, then user can select the other persons from the “Notify Other Persons” which is not Mandatory. (Only 2 persons are allowed)</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/14.png" alt="leave-14" />
              <h5 class="bld">Contact Details:</h5>
              <p>User can also enter 10-digit valid mobile number which is not Mandatory.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/15.png" alt="contact_person" />
              <h5 class="bld">Reason:</h5>
              <p>Users provide the Reason for applying Leave which is mandatory. It allows 250 characters.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/16.png" alt="reason" />
              <h5 class="bld">Attachment:</h5>
              <p>While applying for the Covid-19, Maternity/Paternity Leaves Document is Mandatory.</p>
              <p>It allows max size-10mb. (User can upload ZIP file in case of multiple documents).</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/17.png" alt="attachment" />
              <img src="../assets/Trainings/LMS_Onshore_FTE/18.png" alt="attachment" />
              <h5 class="bld">Reset</h5>
              <p>If user wants to change all given information, then user click on “Reset” Button and Continue.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/19.png" alt="reset" />
              <h5 class="bld">Submit:</h5>
              <p>If user fills in all the mandatory fields, then “Submit” button will enable.</p>
              <p>Users click on “Submit” button to proceed.</p>
              <p>User is able to view the Successful message like “(Leave Type) Leave applied successfully”.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/21.png" alt="submit" />
              <img src="../assets/Trainings/LMS_Onshore_FTE/21A.png" alt="submit-message" />
              <p>After Applying the leave user will see the applied leave details on dashboard.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/22.png" alt="leaves-on-dashboard" />
              <h5 class="bld">View Details:</h5>
              <p>User can check the applied leave details.</p>
              <p>Click on Ellipsis to view the “Details”, “Summary”, “Attachment” which were uploaded at the time of requesting leave.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/23.png" alt="leave-details" />
              <img src="../assets/Trainings/LMS_Onshore_FTE/24.png" alt="leave-summary" />
              <img src="../assets/Trainings/LMS_Onshore_FTE/25.png" alt="leave-details" />
              <img src="../assets/Trainings/LMS_Onshore_FTE/26.png" alt="leave-attachment" />
              <h5 class="bld">Timesheet with Leave:</h5>
              <p>After applying the leave user can check the Timesheet with the Leave row in submitted status. (Paid/Unpaid).</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/27.png" alt="leave-ts" />
              <h5 class="bld">Withdraw:</h5>
              <p>If user want to cancel the applied leave then user withdraw the leave in 2 ways.</p>
              <li>From the Leave record > click on Ellipsis and Click on withdraw.</li>
              <li>From View details Pop-up> Click on Withdraw button.</li>
              <img src="../assets/Trainings/LMS_Onshore_FTE/28.png" alt="leave-withdraw" />
              <img src="../assets/Trainings/LMS_Onshore_FTE/29.png" alt="leave-withdraw" />
              <img src="../assets/Trainings/LMS_Onshore_FTE/30.png" alt="leave-withdraw-ts" />
              <h4 class="bld">Leave History:</h4>
              <p>“View All” link from the Upcoming/Pending Leave request will navigate in to the Leave History screen.</p>
              <p>User can see the Complete leave history in this screen which are in Submitted, Approved, Rejected, Withdrawn state.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/31.png" alt="leave-availed-tab" />
              <img src="../assets/Trainings/LMS_Onshore_FTE/32.png" alt="leave-view-all" />
              <h5 class="bld">Availed:</h5>
              <p>All the submitted, Approved, Rejected, Withdrawn Leave records user can see in the Availed tab.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/33.png" alt="leave-availed-tab" />
              <h6 class="bld">Filters:</h6>
              <p>User Search the records by using the Respective Filters.</p>
              <p>From date & To date</p>
              <p>Select From date and To date and click on “Search” Button, User will get the Records Fall in Between the Selected Dates.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/34.png" alt="leave-availed-tab" />
              <h6 class="bld">Leave Type</h6>
              <p>Select Leave Type (Annual PTO, LOP, Bereavement, FMLA, Comp-off etc.,) and click on “Search” Button, User will get the Records Selected Leave Type.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/35.png" alt="leave-availed-tab" />
              <h6 class="bld">Leave Status</h6>
              <p>Select Leave Status (Approved, Submit, Withdraw and Reject etc.,) and click on “Search” Button, User will get the Records Selected Leave Status.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/36.png" alt="leave-availed-tab" />
              <img src="../assets/Trainings/LMS_Onshore_FTE/37.png" alt="leave-availed-tab" />
              <h4 class="bld">Holiday Calendar</h4>
              <p>User can see the Holidays Based on Logged in user's Deputed location.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/38.png" alt="holiday-calendar" />
              <p><b>View for All Locations</b> link on the leave dashboard will also redirect to the Holiday Calendar screen.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/39.png" alt="holiday-calendar" />
              <h4 class="bld">Leave Policy</h4>
              <p>Users navigate Leave Policy screen in 2 ways.</p>
              <li>My leaves dropdown> Leave Policy link</li>
              <li>Leave Policy button from right side Top of the dashboard.</li>
              <img src="../assets/Trainings/LMS_Onshore_FTE/40.png" alt="holiday-calendar" />
              <h4 class="bld">Request Comp-Off</h4>
              <p>Comp-off is an additional leave granted as a compensation for working overtime or an off. It must be availed within 3 months. HR/Admin can provide the access for requesting the comp-off, Based on Eligibility</p>
              <p>User can Request comp-off in 2 ways.</p>
              <li>My Leaves drop-down> Click on Request Comp-off.</li>
              <li>Request Comp-off button form the right-side Top of the dashboard.</li>
              <img src="../assets/Trainings/LMS_Onshore_FTE/41.png" alt="request-comp-off" />
              <h5 class="bld">Requesting Comp-off:</h5>
              <p>When user click on Request Comp-off button then user navigate to the Request Comp-off apply screen.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/42.png" alt="request-comp-off" />
              <h5 class="bld">Project:</h5>
              <p>Select Project form the dropdown.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/43.png" alt="request-comp-off" />
              <h5 class="bld">Select start date and to date</h5>
              <img src="../assets/Trainings/LMS_Onshore_FTE/44.png" alt="request-comp-off" />
              <p>Enter all the mandatory fields & Attach documents if any.</p>
              <p>Click on “Submit”.</p>
              <p>User will see successful message like “Comp-off requested successfully”.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/45.png" alt="request-comp-off" />
              <img src="../assets/Trainings/LMS_Onshore_FTE/46.png" alt="request-comp-off" />
              <p>Requested Comp-off records will check in Requested Comp-off tab at the Leave History screen.</p>
              <p>Users will check Submitted, Withdrawn, Rejected records.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/47.png" alt="request-comp-off" />
              <h5 class="bld">Granted Tab</h5>
              <p>If approver accepted the requested comp-off, then the Status will be updated as “Approved”. That balance will get updated in the Leave Balance card.</p>
              <p>That record can see in the “Granted” tab.</p>
              <img src="../assets/Trainings/LMS_Onshore_FTE/48.png" alt="request-comp-off" />
          </div>
      </div>
      <div *jhiHasAnyAuthority="'LMS_OFFSHORE_DC'">
          <div class="training_m">
              <p class="bld">Login to Aperture :</p>
              <p>Example :</p>
              <p>Login ID: abc@gspann.com</p>
              <p>Password: XXXXX@12</p>
              <p class="bld">User logged in to the Aperture Home page.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/1.png" />
              <p class="bld">Click on Leaves:</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/2.png" />
              <p>User is navigated to the leave dashboard.</p>
              <!-- <img src="../assets/Trainings/Offshore_Direct_Contractor/3.png"> -->
              <p>User will be able to see the Leave Balance cards on Dashboard.</p>
              <p>Annual, Loss of pay, Weekend Leave balances will display in the card.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/3.png" />
              <h5 class="bld">Team Leaves:</h5>
              <p>User able to view the team members Who ever on Leave in the current week.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/4.png" />
              <h5 class="bld">Holidays in Dashboard:</h5>
              <p>User can see the Holidays based on their Deputed Location on Dashboard.</p>
              <p>View for all Locations >Will redirected to the Holiday Calendar screen.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/5.png" alt="leave-6" />
              <h5 class="bld">My Leaves:</h5>
              <p>User able to view the “My Leaves” on the left panel.</p>
              <p>From My Leaves dropdown, user Click & Navigate to the below mentioned screens.</p>
              <ul>
                  <li>Apply Leave</li>
                  <li>Leave History</li>
                  <li>Leave Calendar</li>
                  <li>Leave Policy</li>
              </ul>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/6.png" alt="leave-7" />
              <h5 class="bld">Apply Leave:</h5>
              <p>Users navigate to the submit leave application screen in two ways.</p>
              <p>1. My Leaves dropdown> Apply Leave.</p>
              <p>2. “Apply Leave” button form the right-side Top of the dashboard.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/7.png" alt="leave-8" />
              <h5 class="bld">Submit Leave Application:</h5>
              <p>When users click on Apply Leave button, it will navigate to the “Submit leave application” screen.</p>
              <p>User able to view the pre-populated logged in user's name.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/8.png" alt="leave-9" />
              <h5 class="bld">Leave Type:</h5>
              <p>Users Click on Leave Type dropdown and select the Leave type to apply the Leave. Which are Annual/ LOP/Weekend.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/9.png" alt="leave-10" />
              <h5 class="bld">Flexible Weekends checkbox:</h5>
              <p>If the user has the availability for flexible weekends, then user need to select the Flexible Weekend. And user can select any 2 weekdays as Weekend.</p>
              <!-- No image given for flexible weekend -->
              <h5 class="bld">Applying for:</h5>
              <p>Users select Half day/Full day to apply the leave based on duration.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/11.png" alt="leave-12" />
              <h5 class="bld">From Date & To date:</h5>
              <p>Users select the Leave duration using “from date & To date” calendars.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/12.png" alt="leave-13" />
              <img src="../assets/Trainings/Offshore_Direct_Contractor/13.png" alt="leave-14" />
              <h5 class="bld">Approving Person:</h5>
              <p>If User is working for multiple projects, then select the Approver from the “Approving Person” dropdown.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/14.png" alt="leave-14" />
              <h5 class="bld">Notify Other Persons:</h5>
              <p>If user working for multiple projects, then user can select the other persons from the “Notify Other Persons” which is not Mandatory. (Only 2 persons are allowed)</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/15.png" alt="leave-15" />
              <h5 class="bld">Contact Details :</h5>
              <p>User can enter 10-digit valid mobile number which is not Mandatory.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/16.png" alt="leave-15" />
              <h5 class="bld">Reason</h5>
              <p>Users provide the Reason for applying Leave which is mandatory. It allows 250 characters.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/17.png" alt="leave-15" />
              <h5 class="bld">Attachment:</h5>
              <p>While applying for the Leave> if user want to attach any Documents, then user can upload documents.</p>
              <p>It allows max size-10mb. (User can upload ZIP file for multiple documents).</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/18.png" alt="leave-15" />
              <img src="../assets/Trainings/Offshore_Direct_Contractor/19.png" alt="leave-15" />
              <h5 class="bld">Reset:</h5>
              <p>If user wants to change all given information, then user click on “Reset” Button and Continue.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/20.png" alt="leave-20" />
              <img src="../assets/Trainings/Offshore_Direct_Contractor/21.png" alt="leave-21" />
              <h5 class="bld">Submit:</h5>
              <p>If user fill all the Mandatory fields, then “Submit” button will enable.</p>
              <p>Users click on “Submit” button, to proceed.</p>
              <p>User is able to view the Successful message like "(Leave Type) Leave applied successfully".</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/22.png" alt="leave-22" />
              <p>After Applying the leave user will see the applied leave details on dashboard.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/23.png" alt="leave-23" />
              <h5 class="bld">View Details:</h5>
              <p>User can check the applied leave details.</p>
              <p>Click on Ellipsis for viewing the “Details”, “Summary”, “Attachment” which uploaded for the leave request.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/24.png" alt="leave-24" />
              <img src="../assets/Trainings/Offshore_Direct_Contractor/25.png" alt="leave-25" />
              <img src="../assets/Trainings/Offshore_Direct_Contractor/26.png" alt="leave-26" />
              <img src="../assets/Trainings/Offshore_Direct_Contractor/27.png" alt="leave-27" />
              <h5 class="bld">Timesheet with Leave:</h5>
              <p>After applying the leave user can check the Timesheet with the Leave row in submitted status. (Unpaid/Paid).</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/28.png" alt="leave-28" />
              <h5 class="bld">Withdraw:</h5>
              <p>If user want to cancel the applied leave, then user withdraw the leave in 2 ways.</p>
              <p>1. From the Leave record > click on Ellipsis and Click on withdraw.</p>
              <p>2. From View details Pop-up> Click on Withdraw button.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/29.png" alt="leave-29" />
              <img src="../assets/Trainings/Offshore_Direct_Contractor/30.png" alt="leave-30" />
              <h5 class="bld">Leave History:</h5>
              <p>User can see the Complete leave history in this screen. Which are “submitted, Approved, Rejected, Withdrawn” leaves.</p>
              <p>Availed:</p>
              <p>All the submitted, Approved, Rejected, Withdrawn Leave records user can see in the Availed tab.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/31.png" alt="leave-31" />
              <img src="../assets/Trainings/Offshore_Direct_Contractor/32.png" alt="leave-32" />
              <h5 class="bld">Filters</h5>
              <p>User can search the records by using the Respective Filters.</p>
              <p>1. From date & To date</p>
              <p>Select From date and to date and click on “Search” Button, User will get the Records Fall in Between the Selected Dates.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/33.png" alt="leave-33" />
              <p>User can filter the applied leave records based on Leave type filter.</p>
              <p>Like Annual, LOP, Weekend.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/34.png" alt="leave-34" />
              <p>User can filter the applied leave records based on “Leave Status” which are “Approved, Submitted, Withdrawn, Rejected.</p>
              <p>Select any status and click on “Search”. Then user can see matching Results in the table.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/35.png" alt="leave-35" />
              <h5 class="bld">Holiday Calendar:</h5>
              <p>User can see the Holidays Based on Logged in user’s Deputed location.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/36.png" alt="leave-36" />
              <h5 class="bld">Leave Policy:</h5>
              <p>User can navigate Leave Policy screen in 2 ways.</p>
              <p>1. My leaves dropdown> Leave Policy link</p>
              <p>2. Leave Policy button from right side Top of the dashboard.</p>
              <img src="../assets/Trainings/Offshore_Direct_Contractor/37.png" alt="leave-37" />
          </div>
      </div>
      <div *jhiHasAnyAuthority="'LMS_OFFSHORE_FTE'">
          <div class="training_m">
              <p class="bld">Login to Aperture :</p>
              <p>Example :</p>
              <p>Login ID: abc@gspann.com</p>
              <p>Password: XXXXX@12</p>
              <p class="bld">User logged in to the Aperture Home page.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/1.png" alt="Aperture dashboard" />
              <p class="bld">Click on Leaves:</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/2.png" alt="click leaves" />
              <p>User is navigated to the leave dashboard.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/3.png" alt="leave-dashboard" />
              <p>User will be able to see the Leave Balance cards on Dashboard.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/4.png" alt="leave-balances" />
              <p>User will be able to view the all leave balance cards using carousel.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/5.png" alt="leave-carousel" />
              <h4 class="bld">Team Leaves</h4>
              <p>User will be able to view the team members who are on leave for the current week.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/6.png" alt="leave-carousel" />
              <img src="../assets/Trainings/LMS_Offshore_FTE/7.png" alt="leave-carousel-1" />
              <h4 class="bld">My Leaves</h4>
              <p>User is able to view the “My Leaves” on the left panel.</p>
              <p>From My Leaves dropdown, user Click & Navigate to the below mentioned screens.</p>
              <ul>
                  <li>Apply Leave</li>
                  <li>Request Comp-Off</li>
                  <li>Leave History</li>
                  <li>Holiday Calendar</li>
                  <li>Leave Policy</li>
              </ul>
              <!-- <img src="../assets/Trainings/LMS_Offshore_FTE/8.png" alt="leave-panel"> -->
              <h4 class="bld">Apply Leave</h4>
              <p>Users navigate to the submit leave application screen in two ways.</p>
              <li>My Leaves dropdown> Apply Leave.</li>
              <li><b>Apply Leave</b> button from the top right corner of the dashboard.</li>
              <img src="../assets/Trainings/LMS_Offshore_FTE/9.png" alt="leave-options" />
              <h4 class="bld">Submit Leave Application:</h4>
              <p>When users click on <b>Apply Leave</b> button, it will navigate to the <b>Submit leave application</b> screen.</p>
              <p>User able to view the pre-populated logged in user's name.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/10.png" alt="leave-application" />
              <h5 class="bld">Leave Type:</h5>
              <p>Users can click on Leave Type dropdown and select the Leave type to apply the Leave.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/12.png" alt="leave-types" />
              <h5 class="bld">Applying For:</h5>
              <p>For Selecting the Full day/Half Day User need to Click on the “Applying for” dropdown.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/13.png" alt="leave-applying-for" />
              <h5 class="bld">From Date & To date:</h5>
              <p>Users select the Leave duration using “from date & To date” calendars.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/14.png" alt="leave-applying-dates" />
              <p>After selecting the from date & to date, User can view the “Applying Leave days” & “Balance after booked days”</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/15.png" />
              <h5 class="bld">Approving Person:</h5>
              <p>If User is working for multiple projects, then select the Approver from the “Approving Person” dropdown.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/16.png" />
              <h5 class="bld">Notify Other Persons:</h5>
              <p>If user working for multiple projects, then user can select the other persons from the “Notify Other Persons” which is not Mandatory. (Only 2 persons are allowed)</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/18.png" alt="notify_person" />
              <h5 class="bld">Contact Details:</h5>
              <p>User can also enter 10-digit valid mobile number which is not Mandatory.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/19.png" alt="contact_person" />
              <h5 class="bld">Reason:</h5>
              <p>Users provide the Reason for applying Leave which is mandatory. It allows 250 characters.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/20.png" alt="reason" />
              <h5 class="bld">Attachment:</h5>
              <p>While applying for the Covid-19, Maternity/Paternity Leaves Document is Mandatory.</p>
              <p>It allows max size-10mb. (User can upload ZIP file in case of multiple documents).</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/21.png" alt="attachment" />
              <h5 class="bld">Reset</h5>
              <p>If user wants to change all given information, then user click on “Reset” Button and Continue.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/22.png" alt="reset" />
              <h5 class="bld">Submit:</h5>
              <p>If user fills in all the mandatory fields, then “Submit” button will enable.</p>
              <p>Users click on “Submit” button to proceed.</p>
              <p>User is able to view the Successful message like “(Leave Type) Leave applied successfully”.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/23.png" alt="submit" />
              <img src="../assets/Trainings/LMS_Offshore_FTE/24.png" alt="submit-message" />
              <p>After Applying the leave user will see the applied leave details on dashboard.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/25.png" alt="leaves-on-dashboard" />
              <h5 class="bld">View Details:</h5>
              <p>User can check the applied leave details.</p>
              <p>Click on Ellipsis to view the “Details”, “Summary”, “Attachment” which were uploaded at the time of requesting leave.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/26.png" alt="leave-details" />
              <img src="../assets/Trainings/LMS_Offshore_FTE/27.png" alt="leave-summary" />
              <img src="../assets/Trainings/LMS_Offshore_FTE/28.png" alt="leave-details" />
              <img src="../assets/Trainings/LMS_Offshore_FTE/29.png" alt="leave-attachment" />
              <h5 class="bld">Timesheet with Leave:</h5>
              <p>After applying the leave user can check the Timesheet with the Leave row in submitted status. (Paid/Unpaid).</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/29_A.png" alt="leave-ts" />
              <h5 class="bld">Withdraw:</h5>
              <p>If user want to cancel the applied leave then user withdraw the leave in 2 ways.</p>
              <li>From the Leave record > click on Ellipsis and Click on withdraw.</li>
              <li>From View details Pop-up> Click on Withdraw button.</li>
              <img src="../assets/Trainings/LMS_Offshore_FTE/30.png" alt="leave-withdraw" />
              <img src="../assets/Trainings/LMS_Offshore_FTE/31.png" alt="leave-withdraw" />
              <img src="../assets/Trainings/LMS_Offshore_FTE/31_A.png" alt="leave-withdraw-ts" />
              <h4 class="bld">Leave History:</h4>
              <p>“View All” link from the Upcoming/Pending Leave request will navigate in to the Leave History screen.</p>
              <p>User can see the Complete leave history in this screen which are in Submitted, Approved, Rejected, Withdrawn state.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/32.png" alt="leave-view-all" />
              <h5 class="bld">Availed:</h5>
              <p>All the submitted, Approved, Rejected, Withdrawn Leave records user can see in the Availed tab.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/34.png" alt="leave-availed-tab" />
              <h6 class="bld">Filters:</h6>
              <p>User can Filter the records by using the Filters.</p>
              <p>From date & To date</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/35.png" alt="leave-availed-tab-filters" />
              <p>User can filter the applied leave records based on Leave type filter i.e. Annual, LOP, Covid-19, Bereavement, Sabbatical, Comp-off etc.,</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/36A.png" alt="leave-availed-tab-leaveType" />
              <p>User can filter the applied leave records based on “Leave Status” which are “Approved, Submitted, Withdrawn, Rejected.</p>
              <p>Select any status and click on “Search”. Then user can see matching Results in the table.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/37.png" alt="leave-availed-tab-leaveStatus" />
              <img src="../assets/Trainings/LMS_Offshore_FTE/38.png" alt="leave-availed-tab-search" />
              <h4 class="bld">Holiday Calendar</h4>
              <p>User can see the Holidays Based on Logged in user's Deputed location.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/40.png" alt="holiday-calendar" />
              <h4 class="bld">Leave Policy</h4>
              <p>User can navigate Leave Policy screen in 2 ways.</p>
              <li>My leaves dropdown> Leave Policy link</li>
              <li>Leave Policy button from right side Top of the dashboard.</li>
              <img src="../assets/Trainings/LMS_Offshore_FTE/41.png" alt="leave-policy" />
              <h4 class="bld">Request Comp-Off</h4>
              <p>Comp-off is an additional leave granted as a compensation for working overtime or an off. It must be availed within 3 months.</p>
              <p>HR/Admin can provide the access for requesting the comp-off.</p>
              <p>User can Request comp-off in 2 ways.</p>
              <li>My Leaves drop-down> Click on Request Comp-off.</li>
              <li>Request Comp-off button form the right-side Top of the dashboard.</li>
              <img src="../assets/Trainings/LMS_Offshore_FTE/42.png" alt="request-comp-off" />
              <h5 class="bld">Requesting Comp-off:</h5>
              <p>When user click on Request Comp-off button then user navigate to the Request Comp-off apply screen.</p>
              <h5 class="bld">Leave Type:</h5>
              <p>Users can click on Leave Type dropdown and select the Leave type to request the comp-off.</p>
              <li>Comp-off</li>
              <li>Out for Office work</li>
              <li>Onsite</li>
              <img src="../assets/Trainings/LMS_Offshore_FTE/43.png" alt="request-comp-off" />
              <img src="../assets/Trainings/LMS_Offshore_FTE/44.png" alt="request-comp-off-leavetype" />
              <img src="../assets/Trainings/LMS_Offshore_FTE/45.png" alt="request-comp-off-leavetype" />
              <img src="../assets/Trainings/LMS_Offshore_FTE/46.png" alt="request-comp-off-leavetype" />
              <h5 class="bld">Select From date & To date.</h5>
              <img src="../assets/Trainings/LMS_Offshore_FTE/47.png" alt="request-comp-off-dates" />
              <p>Enter all the mandatory fields & Attach documents if any (not mandatory).</p>
              <p>Click on “Submit”.</p>
              <p>User will see successful message like “Comp-off requested successfully”.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/48.png" alt="request-comp-off-details" />
              <img src="../assets/Trainings/LMS_Offshore_FTE/49.png" alt="request-comp-off-message" />
              <p>Requested Comp-off records will check in Requested Comp-off tab at the Leave History screen.</p>
              <p>Users will check Submitted, Withdrawn, Rejected records.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/50.png" alt="requested-comp-off-tab" />
              <h5 class="bld">Granted Tab</h5>
              <p>If approver accepted the requested comp-off, then the Status will be updated as “Approved”. That balance will get updated in the Leave Balance card.</p>
              <p>That record can see in the “Granted” tab.</p>
              <img src="../assets/Trainings/LMS_Offshore_FTE/39.png" alt="requested-comp-off-tab" />
          </div>
      </div>
      <div *jhiHasAnyAuthority="['LMS_OFFSHORE_VC_HOURLY','LMS_OFFSHORE_VC_MONTHLY']">
          <div class="training_m">
              <p class="bld">Login to Aperture :</p>
              <p>Example :</p>
              <p>Login ID: abc@gspann.com</p>
              <p>Password: XXXXX@12</p>
              <p class="bld">User logged in to the Aperture Home page.</p>
              <img src="../assets/Trainings/VC_Offshore/1.png" alt="Aperture dashboard" />
              <p class="bld">Click on Leaves</p>
              <p>User navigated to the leave dashboard.</p>
              <img src="../assets/Trainings/VC_Offshore/2.png" alt="leave-2" />
              <p>User able to see the LOP (Loss of Pay) Leave Balance card on Dashboard screen.</p>
              <img src="../assets/Trainings/VC_Offshore/3.png" alt="leave-3" />
              <h5 class="bld">Team Leaves</h5>
              <p>User will be able to view the team members who are on leave for the current week.</p>
              <img src="../assets/Trainings/VC_Offshore/4.png" alt="leave-4" />
              <h5 class="bld">Holidays in Dashboard:</h5>
              <p>User can see the Holidays based on their Deputed Location on Dashboard.</p>
              <p>View for all Locations >Will redirected to the Holiday Calendar screen.</p>
              <img src="../assets/Trainings/VC_Offshore/5.png" alt="leave-5" />
              <h5 class="bld">My Leaves:</h5>
              <p>User able to view the “My Leaves” on the left panel.</p>
              <p>From My Leaves dropdown, user Click & Navigate to the below mentioned screens.</p>
              <ul>
                  <li>Apply Leave</li>
                  <li>Leave History</li>
                  <li>Leave Calendar</li>
                  <li>Leave Policy</li>
              </ul>
              <img src="../assets/Trainings/VC_Offshore/6.png" alt="leave-6" />
              <h5 class="bld">Apply Leave:</h5>
              <p>Users navigate to the submit leave application screen in two ways.</p>
              <p>1. My Leaves dropdown> Apply Leave.</p>
              <p>2. “Apply Leave” button form the right-side Top of the dashboard.</p>
              <img src="../assets/Trainings/VC_Offshore/7.png" alt="leave-7" />
              <h5 class="bld">Submit Leave Application:</h5>
              <p>When users click on Apply Leave button, it will navigate to the “Submit leave application” screen.</p>
              <p>User able to view the pre-populated logged in user's name.</p>
              <img src="../assets/Trainings/VC_Offshore/8.png" alt="leave-8" />
              <h5 class="bld">Leave Type:</h5>
              <p>Users Click on Leave Type dropdown and select the LOP Leave type to apply the Leave.</p>
              <img src="../assets/Trainings/VC_Offshore/9.png" alt="leave-9" />
              <h5 class="bld">Flexible Weekends:</h5>
              <p>If the user has the availability for flexible weekends, then user need to select the Flexible Weekend. And user can select any 2 weekdays as Weekend.</p>
              <img src="../assets/Trainings/VC_Offshore/10.png" alt="leave-10" />
              <h5 class="bld">From Date & To date:</h5>
              <p>Users select the Leave duration using “from date & To date” calendars.</p>
              <img src="../assets/Trainings/VC_Offshore/12.png" alt="leave-12" />
              <img src="../assets/Trainings/VC_Offshore/13.png" alt="leave-13" />
              <h5 class="bld">Hours:</h5>
              <p>Hours will auto calculate Based On selected dates. (Holidays & Weekends will not calculate as Leave)</p>
              <img src="../assets/Trainings/VC_Offshore/14.png" alt="leave-14" />
              <p>Users can enter Hours, hours they required for the Leave.</p>
              <img src="../assets/Trainings/VC_Offshore/15.png" alt="leave-15" />
              <h5 class="bld">Approving Person:</h5>
              <p>If User is working for multiple projects, then select the Approver from the “Approving Person” dropdown.</p>
              <img src="../assets/Trainings/VC_Offshore/16.png" alt="leave-16" />
              <h5 class="bld">Notify Other Person:</h5>
              <p>If user working for multiple projects, then user can select the other persons from the “Notify Other Persons” which is not Mandatory. (Only 2 persons are allowed)</p>
              <img src="../assets/Trainings/VC_Offshore/17.png" alt="leave-17" />
              <img src="../assets/Trainings/VC_Offshore/18.png" alt="leave-18" />
              <h5 class="bld">Contact Details:</h5>
              <p>User can enter 10-digit valid mobile number which is not Mandatory.</p>
              <img src="../assets/Trainings/VC_Offshore/19.png" alt="leave-19" />
              <h5 class="bld">Reason:</h5>
              <p>Users provide the Reason for applying Leave which is mandatory. It allows 250 characters.</p>
              <img src="../assets/Trainings/VC_Offshore/20.png" alt="leave-20" />
              <h5 class="bld">Attachment:</h5>
              <p>While applying for the Leave> if user want to attach any Documents, then user can upload documents.</p>
              <p>It allows max size-10mb. (User can upload ZIP file for multiple documents).</p>
              <img src="../assets/Trainings/VC_Offshore/21.png" alt="leave-21" />
              <img src="../assets/Trainings/VC_Offshore/22.png" alt="leave-22" />
              <h5 class="bld">Reset:</h5>
              <p>If user wants to change all given information, then user click on “Reset” Button and Continue.</p>
              <img src="../assets/Trainings/VC_Offshore/23.png" alt="leave-23" />
              <img src="../assets/Trainings/VC_Offshore/24.png" alt="leave-24" />
              <h5 class="bld">Submit</h5>
              <p>If user fill all the Mandatory fields, then “Submit” button will enable.</p>
              <p>Users click on “Submit” button, to proceed.</p>
              <p>User is able to view the Successful message like “(Leave Type) Leave applied successfully”.</p>
              <img src="../assets/Trainings/VC_Offshore/25.png" alt="leave-25" />
              <img src="../assets/Trainings/VC_Offshore/26.png" alt="leave-26" />
              <p>After Applying the leave user will see the applied leave details on dashboard.</p>
              <img src="../assets/Trainings/VC_Offshore/27.png" alt="leave-27" />
              <h5 class="bld">View Details</h5>
              <p>User can check the applied leave details.</p>
              <p>Click on Ellipsis for viewing the “Details”, “Summary”, “Attachment” which uploaded for the leave request.</p>
              <img src="../assets/Trainings/VC_Offshore/28.png" alt="leave-28" />
              <img src="../assets/Trainings/VC_Offshore/29.png" alt="leave-29" />
              <img src="../assets/Trainings/VC_Offshore/30.png" alt="leave-30" />
              <img src="../assets/Trainings/VC_Offshore/31.png" alt="leave-31" />
              <h5 class="bld">Timesheet with Leave:</h5>
              <p>After applying the leave user can check the Timesheet with the Leave row in submitted status. (Unpaid).</p>
              <img src="../assets/Trainings/VC_Offshore/32.png" alt="leave-32" />
              <h5 class="bld">Withdraw:</h5>
              <p>If user wants to cancel the applied leave,then user withdraw the leave in 2 ways.</p>
              <p>1. From the Leave record > click on Ellipsis and Click on withdraw.</p>
              <p>2. From View details Pop-up> Click on Withdraw button.</p>
              <img src="../assets/Trainings/VC_Offshore/33.png" alt="leave-32" />
              <img src="../assets/Trainings/VC_Offshore/34.png" alt="leave-32" />
              <h5 class="bld">Leave History:</h5>
              <p>User can see the Complete leave history in this screen. Which are “submitted, Approved, Rejected, Withdrawn” leaves.</p>
              <p>Availed:</p>
              <p>All the submitted, Approved, Rejected, Withdrawn Leave records user can see in the Availed tab.</p>
              <img src="../assets/Trainings/VC_Offshore/35.png" alt="leave-32" />
              <h5 class="bld">Filters</h5>
              <p>User Search the records by using the respective filters.</p>
              <p>1. From date & To date</p>
              <p>Select From date and to date and click on “Search” Button, User will get the Records Fall in Between the Selected Dates.</p>
              <img src="../assets/Trainings/VC_Offshore/36.png" alt="leave-32" />
              <h5 class="bld">Leave Status:</h5>
              <p>Select Leave Status (Approved, Submit, Withdraw and Reject etc.,) and click on “Search” Button, User will get the Records Selected Leave Status.</p>
              <img src="../assets/Trainings/VC_Offshore/37.png" alt="leave-32" />
              <h5 class="bld">Holiday Calendar:</h5>
              <p>User see the Holidays Based on Logged in user’s Deputed location.</p>
              <img src="../assets/Trainings/VC_Offshore/38.png" alt="leave-32" />
              <h5 class="bld">Leave Policy</h5>
              <p>Users navigate Leave Policy screen in 2 ways.</p>
              <p>1. My leaves dropdown> Leave Policy link</p>
              <p>2. Leave Policy button from right side Top of the dashboard.</p>
              <img src="../assets/Trainings/VC_Offshore/39.png" alt="leave-32" />
          </div>
      </div>
  </mat-tab>
</mat-tab-group>
