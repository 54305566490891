import { Component, OnInit, ViewChildren, QueryList, Input, ElementRef, ViewChild } from '@angular/core';
import { NgbPopover, NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TimesheetService } from './../timesheet.service';

@Component({
    selector: 'jhi-week-timesheet-view',
    templateUrl: './week-timesheet-view.component.html',
    styleUrls: ['./week-timesheet-view.component.css']
})
export class WeekTimesheetViewComponent implements OnInit {
    timesheetData: any = {};
    weeklyTSData: any = {};
    @Input() weekObj: any = {};
    leaveTypes = [];
    imagData: Array<any>;
    currentallocationmodalRef: NgbModalRef;
    clientTimesheetImage: any;
    @ViewChild('currentAllocations') currentAllocationsModal: ElementRef;
    @ViewChildren('p') public popoverList: QueryList<NgbPopover>;
    private months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    constructor(private timesheetService: TimesheetService,
        private modalService: NgbModal ) { }
    ngOnInit() {
        this.imagData = [];
        setTimeout(() => { }, 1500);
        if (this.weekObj) {
            this.weekObj.timesheet.allocationWiseTimeSheets.forEach((timesheet, i) => {
                if (timesheet.status && timesheet.status.actor) {
                    if (this.imagData && this.imagData.length > 0) {
                        let check = false;
                        this.imagData.forEach(element => {
                            if (timesheet.status.actor.code === element.code) {
                                check = true;
                                this.imagData[i] = element;
                            }
                        });
                        // if (!check) {
                        //     this.timesheetService.getImage(timesheet.status.actor._links['itrack:image'].href).subscribe(img => {
                        //         this.imagData[i] = { name: timesheet.status.actor.name, code: timesheet.status.actor.code, img: (img) };
                        //     });
                        // }
                    } else {
                        // this.timesheetService.getImage(timesheet.status.actor._links['itrack:image'].href).subscribe(img => {
                        //     this.imagData[i] = { name: timesheet.status.actor.name, code: timesheet.status.actor.code, img: (img) };
                        // });
                    }
                } else {
                    this.imagData[i] = { name: '', code: '', img: '' };
                }
            });
        }
    }

    calculateDayHours(p, indx) {
        let hourss: any = 0;
        if (p && p.length > 0) {
            for (let i = 0; i < p.length; i++) {
                let h = parseFloat(p[i].hours[indx].hour);
                if (isNaN(h)) {
                    hourss += 0;
                } else {
                    hourss += h;
                }
            }
        }
        hourss = hourss > 8 ? hourss + '**' : hourss;
        hourss.toString();
        return hourss;
    }

    public getTime(timeString) {
        let hours = '';
        let timeArray = timeString.match(/[0-9]+/g);
            timeArray.forEach((ele, index) => {
                if (ele === '30') {
                    if (ele === '30' && index === 0 && (timeString.charAt(timeString.indexOf('30') + 2) === 'H')) {
                        hours = ele;
                    } else if (ele === '30' && index === 0 && (timeString.charAt(timeString.indexOf('30') + 2) === 'M')){
                        hours = '0.5';
                    } else {
                        hours = hours + '.5';
                    }
                } else {
                    hours = ele;
                }
            });
        return hours;
    }

    calculateTotalHours(p) {
        let hours = 0;
        if (p && p.length > 0) {
            for (let i = 0; i < p.length; i++) {
                if (p[i].hours.length > 0) {
                    for (let j = 0; j < p[i].hours.length; j++) {
                        let h = parseFloat(p[i].hours[j].hour);
                        if (isNaN(h)) {
                            hours += 0;
                        } else {
                            hours += h;
                        }
                    }
                }
            }
        }
        return hours;
    }

    public popupTrigger(): void {
        this.popoverList.forEach(function(popover) {
            if (popover.isOpen()) {
                popover.close();
            }
        });
    }

    trackByIndex(index: number, obj: any): any {
        return index;
    }
    openCurrentAllocations(currentAllocations, link) {
        if (link && link.length > 0) {
            this.timesheetService.getImage(link).subscribe((imageData: any) => {
                this.clientTimesheetImage = imageData;
                this.timesheetService.getTimesheetAttachment(imageData).subscribe(data => {
                    var url = window.URL.createObjectURL(data);
                    window.open(url);
                  });
            });
        }
    }
    closeCurrentAllocations() {
        this.currentallocationmodalRef.close();
    }
}
