<table id='weeklyTSRow' ng-if="name">
    <tr class="weekdays">
        <td class="first-child">Projects</td>
        <td *ngFor="let day of weekObj.daysData; let i = index"
            [ngClass]="{'holiday': day.code === 'HD','week-off': day.weekOffchecked}">
            {{day.day.substring(0, 3)}}{{' ('}}{{day.date}}{{')'}}</td>
        <td class="totalHoursHeading">Total</td>
        <td>Status</td>
    </tr>
    <tr *ngFor="let project of weekObj.projects let i = index" class='project-row' [hidden]="project.type === 'PDL' || project.type === 'UPL' || project.type === 'NBL'">
        <td class="first-child triangle">
            <span class="projectName-actorInfo" matTooltip="Client: {{project.allocationSummary.account}}, Allocation End Date: {{project.allocationSummary.endDate | date: 'dd-MM-yyyy'}}">{{project.name}}</span>
            <span id="triangle-topright" *ngIf="project.attachment"></span>
        </td>
        <td matTooltip="{{dayHour.remarks}}" *ngFor="let dayHour of project.hours; let k = index;"
            [ngClass]="{'holiday': weekObj.daysData[k].code === 'HD','week-off': weekObj.daysData[k].weekOffchecked}">
            <input matInput class='hours-input' maxlength="2" type="text" [(ngModel)]="dayHour.hour"
                [ngModelOptions]="{updateOn: 'blur'}" [ngbPopover]="popContent" placement="top" #p="ngbPopover"
                (click)="popupTrigger()" (document:click)="p.close()" (click)="$event.stopPropagation()"
                *ngIf="weekObj.status === 'Pending'" />
            <span *ngIf="weekObj.code === 'SUBMITTED' ||
            weekObj.code === 'PARTIALLY_SUBMITTED' ||
            weekObj.code === 'SAVED' ||
            weekObj.code === 'REJECTED' ||
            weekObj.code === 'APPROVED' ||
            weekObj.code === 'PARTIALLY_APPROVED' ||
            weekObj.code === 'PARTIALLY_APPROVED_REJECTED' ||
            weekObj.code === 'PARTIALLY_APPROVED_PENDING' ||
            weekObj.code === 'PARTIALLY_REJECTED_PENDING'">{{dayHour.hour}}</span>
            <span *ngIf="dayHour.comments != null && dayHour.comments.length>0">
                <i class="fa fa-comment" aria-hidden="true" placement="top" [ngbPopover]="popContentComment"
                    tabindex="0" triggers="mouseenter:mouseleave"></i>
            </span>
            <ng-template #popContent>
                <div>
                    <input matInput class='comments-input' type="text" [(ngModel)]="dayHour.comments"
                        (click)="$event.stopPropagation()">
                </div>
            </ng-template>
            <ng-template #popContentComment>
                {{dayHour.comments}}
            </ng-template>
        </td>
        <td>
            <span *ngIf="weekObj.code === 'SUBMITTED' ||
            weekObj.code === 'PARTIALLY_SUBMITTED' ||
            weekObj.code === 'SAVED' ||
            weekObj.code === 'REJECTED' ||
            weekObj.code === 'APPROVED' ||
            weekObj.code === 'PARTIALLY_APPROVED' ||
            weekObj.code === 'PARTIALLY_APPROVED_REJECTED' ||
            weekObj.code === 'PARTIALLY_APPROVED_PENDING' ||
            weekObj.code === 'PARTIALLY_REJECTED_PENDING'">{{getTime(project.totalHours)}}</span>

            <button class="btn edit-image-save" *ngIf="weekObj.timesheet && (weekObj.timesheet.allocationWiseTimeSheets[i].clientTimeSheetImageUrl !== null)" (click)="openCurrentAllocations(currentAllocations, weekObj.timesheet.allocationWiseTimeSheets[i].clientTimeSheetImageUrl)">
                <i class="material-icons" title="view file">open_in_browser</i>
            </button>
        </td>
        <td>
            <div class="projectWise-status" *ngIf="weekObj.timesheet.allocationWiseTimeSheets[i].status">
                <p class="{{weekObj.timesheet.allocationWiseTimeSheets[i].status.timesheetStatus.code}}"
                    *ngIf="weekObj.timesheet.allocationWiseTimeSheets[i].status">
                    <span
                        class="{{weekObj.timesheet.allocationWiseTimeSheets[i].status.timesheetStatus.code}}">&nbsp;{{weekObj.timesheet.allocationWiseTimeSheets[i].status.timesheetStatus.value}}&nbsp;</span>
                </p>
                <div *ngIf="((weekObj.timesheet.allocationWiseTimeSheets[i].status && weekObj.timesheet.allocationWiseTimeSheets[i].status.comments) ||
                 (weekObj.timesheet.allocationWiseTimeSheets[i].status && weekObj.timesheet.allocationWiseTimeSheets[i].status.lastUpdatedOn))
                 && (weekObj.timesheet.allocationWiseTimeSheets[i].status.timesheetStatus.code !== 'SUBMITTED'
                 && weekObj.timesheet.allocationWiseTimeSheets[i].status.timesheetStatus.code !== 'SAVED')">
                    <span
                        *ngIf="weekObj.timesheet.allocationWiseTimeSheets[i].status.actor && weekObj.timesheet.allocationWiseTimeSheets[i].status.actor.name">Manager:
                        <img alt="Manager-img" class="example-option-img hover-rsc-img"
                            *ngIf="imagData[i] && imagData[i].img.length;else actorImage" aria-hidden
                            src="{{imagData[i].img}}" height="30" />
                        <ng-template #actorImage>
                            <img class="example-option-img hover-rsc-img" aria-hidden
                                src="../../assets/User_Profile_Pic.png" height="30" alt="Manager-img" />
                        </ng-template>
                        <b>{{weekObj.timesheet.allocationWiseTimeSheets[i].status.actor.name}} -
                            {{weekObj.timesheet.allocationWiseTimeSheets[i].status.actor.code}}</b>
                    </span>
                    <br
                        *ngIf="weekObj.timesheet.allocationWiseTimeSheets[i].status && weekObj.timesheet.allocationWiseTimeSheets[i].status.lastUpdatedOn">
                    <span
                        *ngIf="weekObj.timesheet.allocationWiseTimeSheets[i].status && weekObj.timesheet.allocationWiseTimeSheets[i].status.lastUpdatedOn">
                        Last Updated On: <b>{{weekObj.timesheet.allocationWiseTimeSheets[i].status.lastUpdatedOn}}</b>
                    </span>
                    <br
                        *ngIf="weekObj.timesheet.allocationWiseTimeSheets[i].status && weekObj.timesheet.allocationWiseTimeSheets[i].status.comments">
                    <span
                        *ngIf="weekObj.timesheet.allocationWiseTimeSheets[i].status && weekObj.timesheet.allocationWiseTimeSheets[i].status.comments">
                        Comment: <b>{{weekObj.timesheet.allocationWiseTimeSheets[i].status.comments}}</b> </span>
                </div>
            </div>
        </td>
    </tr>
    <tr *ngFor="let project of weekObj.projects" class='leave-row'
        [hidden]="project.type != 'PDL' && project.type != 'UPL' &&  project.type != 'NBL'">
        <td class="first-child">
            <span>{{project.name}}</span>
            <i *ngIf="weekObj.status === 'Pending' " class="fa fa-times" aria-hidden="true"></i>
        </td>
        <td *ngFor="let dayHour of project.hours; let j = index; trackBy:trackByIndex;"
            [ngClass]="[weekObj.daysData[j].code === 'HD' ? 'holiday' : '']">
            <input matInput class='hours-input' maxlength="2" [(ngModel)]="dayHour.hour"
                [ngModelOptions]="{updateOn: 'blur'}"
                (focusout)="(dayHour.comments != null && dayHour.comments.length>0)" placement="top"
                [ngbPopover]="popContent" #p="ngbPopover" (document:click)="p.close()" (click)="popupTrigger()"
                (click)="$event.stopPropagation()" *ngIf="weekObj.status === 'Pending' ">
            <span *ngIf="weekObj.code === 'SUBMITTED' ||
            weekObj.code === 'PARTIALLY_SUBMITTED' ||
            weekObj.code === 'SAVED' ||
            weekObj.code === 'REJECTED' ||
            weekObj.code === 'APPROVED' ||
            weekObj.code === 'PARTIALLY_APPROVED' ||
            weekObj.code === 'PARTIALLY_APPROVED_REJECTED' ||
            weekObj.code === 'PARTIALLY_APPROVED_PENDING' ||
            weekObj.code === 'PARTIALLY_REJECTED_PENDING'">{{dayHour.hour}}</span>
            <span *ngIf="dayHour.comments != null && dayHour.comments.length>0">
                <i class="fa fa-comment" aria-hidden="true" placement="top" [ngbPopover]="popContentComment"
                    tabindex="0" triggers="mouseenter:mouseleave"></i>
            </span>
            <ng-template #popContent>
                <div>
                    <input matInput class='comments-input' type="text" [(ngModel)]="dayHour.comments"
                        [ngModelOptions]="{updateOn: 'blur'}" (click)="$event.stopPropagation()">
                </div>
            </ng-template>
            <ng-template #popContentComment>
                {{dayHour.comments}}
            </ng-template>
        </td>
        <td>
            <span *ngIf="weekObj.code === 'SUBMITTED' || weekObj.code === 'PARTIALLY_SUBMITTED' || weekObj.code === 'SAVED' || weekObj.code === 'REJECTED' || weekObj.code === 'APPROVED' || weekObj.code === 'PARTIALLY_APPROVED' || weekObj.code === 'PARTIALLY_APPROVED_REJECTED' || weekObj.code === 'PARTIALLY_APPROVED_PENDING' || weekObj.code === 'PARTIALLY_REJECTED_PENDING'">{{getTime(project.totalHours)}}</span>
            <i class="material-icons" [hidden]="!project.attachment">attach_file</i>
        </td>
        <td></td>
    </tr>
    <tr class='total-row'>
        <td class="first-child totalHoursHeading">
            <b>Total Hours Per Day</b>
        </td>
        <td *ngFor="let day of weekObj.daysData; let k = index; trackBy:trackByIndex;">
            <div *ngIf="weekObj.status === 'Pending' " placement="top" [ngbPopover]="commentNeeded" #p="ngbPopover" (click)="popupTrigger()" (document:click)="p.close()" (click)="$event.stopPropagation()">
                <input class='hours-input' maxlength="2" value="{{calculateDayHours(weekObj.projects,k)}}" disabled="true" />
            </div>
            <span *ngIf="weekObj.code === 'SUBMITTED' || weekObj.code === 'PARTIALLY_SUBMITTED' || weekObj.code === 'SAVED' || weekObj.code === 'REJECTED' || weekObj.code === 'APPROVED' || weekObj.code === 'PARTIALLY_APPROVED' || weekObj.code === 'PARTIALLY_APPROVED_REJECTED' || weekObj.code === 'PARTIALLY_APPROVED_PENDING' || weekObj.code === 'PARTIALLY_REJECTED_PENDING'">{{calculateDayHours(weekObj.projects,k)}}</span>
            <ng-template #commentNeeded>
                <div>
                    <input matInput class='' type="text" [(ngModel)]="day.comment" (click)="$event.stopPropagation()">
                </div>
            </ng-template>
            <span *ngIf="day.dailyComment != null && day.dailyComment.length>0">
                <i class="fa fa-comment" aria-hidden="true" placement="bottom" [ngbPopover]="totalHoursComment" triggers="mouseenter:mouseleave"></i>
                <ng-template #totalHoursComment class="thours-comments">
                    {{day.dailyComment}}
                </ng-template>
            </span>
        </td>
        <td>
            <span class='hours-input'>{{calculateTotalHours(weekObj.projects)}}</span>
        </td>
        <td></td>
    </tr>
</table>
<ng-template #currentAllocations>

    <div id="myModal2" class="modal2" style="display: block;">
        <span class="closeimg" (click)="closeCurrentAllocations()"
            onclick="document.getElementById('myModal2').style.display='none'">×</span>
        <img class="modal-content2" id="img02" [src]="clientTimesheetImage">
    </div>
</ng-template>
