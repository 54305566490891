import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TimesheetService } from '../timesheet-submit/timesheet.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { TimesheetApprovalsService } from 'app/timesheet-approvals/timesheet-approvals.service';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ReferralsService } from 'app/Referrals/referrals.service';
import { makeReferralPopup } from 'app/Referrals/submit-referral/submit-referral.component';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { PickDateAdapter, PICK_FORMATS } from 'app/validators/validator';
export const DATE_FORMATE_REQUEST = 'yyyy-MM-dd';
import { ResourceService } from 'app/resource/resource.service';
import { CommonDataService } from 'app/common-data.service';
@Component({
    selector: 'jhi-timesheet-view',
    templateUrl: './timesheet-view.component.html',
    styleUrls: ['./timesheet-view.component.css'],
    providers: [{ provide: DateAdapter, useClass: PickDateAdapter }, { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }]
})
export class TimesheetViewComponent implements OnInit {
    data: any = {};
    loadCur = true;
    loadDr = false;
    loadPrev = false;
    productID: any;
    weekDate: any;
    TimesheetData1: string;
    exportReportForm: FormGroup;
    statusSelectedOption = 'all';
    fromDateExport: any;
    tillDateExport: any;
    tableDataParamsExport: any[];
    resourceExportCode: string;
    mintillDate: any;
    tillDateStatus = true;
    tillDateStatusSelected: boolean;
    datePipe = new DatePipe('en-US');
    exportStatus = [
        { code: 'APPROVED', value: 'Approved', description: 'Approved Timesheet' },
        { code: 'REJECTED', value: 'Rejected', description: 'Rejected Timesheet' },
        { code: 'SAVED', value: 'Saved', description: 'Saved Timesheet' },
        { code: 'SUBMITTED', value: 'Submitted', description: 'Submitted Timesheet' },
        { code: 'PENDING', value: 'Pending', description: 'Pending for Submission' }
    ];
    public standard_hours: any;
    allJobs: any;
    allJobsLength: any;
    resourceDetails: any;
    constructor(private timesheetService: TimesheetService,
        private referralService: ReferralsService,
        private route: ActivatedRoute,
        private titleService: Title,
        private timesheetApprovalService: TimesheetApprovalsService,
        public dialog: MatDialog,
        private resourceService: ResourceService,
        private fb: FormBuilder,
        private commonService: CommonDataService) {
        this.titleService.setTitle('aperture - My Timesheet');
        this.exportReportForm = this.fb.group({
            ExportResource: new FormControl('', []),
            ExportProject: new FormControl('', []),
            tillDateExport: new FormControl({
                value: ''
            }, []),
            fromDateExport: new FormControl('', [])
        });
    }
    ngOnInit() {
        this.route.params.subscribe(params => {
            this.weekDate = params['id'];
            this.loadCurrent(); // reset and set based on new parameter this time
        });
        // this.referralService.getAlljobs('all', 'all', 'all').subscribe(res => {
        //     this.allJobs=res.jobList;
        //     this.allJobsLength=res.jobList.length;
        // });
        const resourcecode = sessionStorage.getItem('resourceCode');
        this.getResource(resourcecode); 
    }
    getResource(code?) {
        this.resourceService.getResourceDetails(code).subscribe(response => {
            this.resourceDetails = response;
        });
    }

    makeReferral (data): void {
        console.log('data', data);
        let dialogRef = this.dialog.open(makeReferralPopup, {
            width: '650px', height: '550px',
            data: data,
        });
    }
    loadCurrent() {
        this.loadCur = true;
        this.loadPrev = false;
        this.loadDr = false;
    }
    loadPrevious() {
        this.loadPrev = true;
        this.loadCur = false;
        this.loadDr = false;
    }
    loadDateRange() {
        this.loadDr = true;
        this.loadCur = false;
        this.loadPrev = false;
    }
    getOutputVal(status) {
        if (status) {
            this.data = status;
        }
    }

    fromDateChange(event) {
        this.fromDateExport = this.datePipe.transform(this.exportReportForm.get('fromDateExport').value, DATE_FORMATE_REQUEST);
        this.tillDateStatus = false;
        if (this.exportReportForm.get('tillDateExport').value &&
            (this.exportReportForm.get('tillDateExport').value.value === '' || this.exportReportForm.get('tillDateExport').value == null)) {
            this.tillDateStatusSelected = true;
        }
        if (this.exportReportForm.get('tillDateExport').value === null) {
            this.tillDateStatusSelected = true;
        }
        this.mintillDate = (this.exportReportForm.get('fromDateExport').value);
    }

    tillDateChange(event) {
        this.tillDateExport = this.datePipe.transform(this.exportReportForm.get('tillDateExport').value, DATE_FORMATE_REQUEST);
        this.tillDateStatusSelected = false;
    }

    resetexportReport() {
        this.exportReportForm.reset();
        this.statusSelectedOption = 'all';
        this.tillDateStatus = true;
        this.tillDateStatusSelected = false;
    }

    exportReport() {
        this.tableDataParamsExport = [];
        this.resourceExportCode = this.commonService.encrypt(sessionStorage.getItem('resourceCode'));
        this.tableDataParamsExport.push({ key: 'resource', value: this.resourceExportCode });
        this.tableDataParamsExport.push({ key: 'isResourceTimesheet', value: true });
        if (this.exportReportForm.get('fromDateExport').value) {
            this.tableDataParamsExport.push({ key: 'fromDate', value: this.fromDateExport });
        }
        if (this.exportReportForm.get('tillDateExport').value !== null && !this.tillDateStatus) {
            this.tableDataParamsExport.push({ key: 'tillDate', value: this.tillDateExport });
        }
        if (this.statusSelectedOption && this.statusSelectedOption !== 'all' && this.statusSelectedOption.length) {
            this.tableDataParamsExport.push({ key: 'status', value: this.statusSelectedOption });
        }
        this.timesheetApprovalService.export(this.tableDataParamsExport).subscribe(data => {
            saveAs(data, 'Timesheet Report.csv');
        });
    }

    getHours(shours) {
        this.standard_hours = shours;
    }
}
