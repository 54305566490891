import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable ,  Subject } from 'rxjs';
import { enviornmentAperture } from 'environments/environment';
import { map } from 'rxjs/operators';
import { CommonDataService } from 'app/common-data.service';
let httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  observe: 'response' as 'response'
};
@Injectable()
export class TimesheetService {
  private resourceCodeData = new Subject<any>();
  public resourceCodeData$ = this.resourceCodeData.asObservable();
  constructor(private http: HttpClient, private commonDataService: CommonDataService,) {}
  emitresourceCode(x: any){
    this.resourceCodeData.next(x);
  }

  getTimesheetData(date?, resourceCode?): Observable<any> {
    let url;
    if (resourceCode) {
      resourceCode = resourceCode;
    } else {
      resourceCode = sessionStorage.getItem('resourceCode');
    }
    resourceCode = this.commonDataService.encrypt(resourceCode);
    if (resourceCode !== null) {
      if (date) {
        url = 'api/v1/timesheets/weekly?date=' + date + '&resourceCode=' + resourceCode;
        url = 'api/v1/timesheets/weekly?date=';
        return this.http.post(enviornmentAperture.apiUrl + url, {resourceCode: resourceCode, date: date});
      } else {
        url = 'api/v1/timesheets/weekly?resourceCode=' + resourceCode;
         url = 'api/v1/timesheets/weekly';
        return this.http.post(enviornmentAperture.apiUrl + url, {resourceCode: resourceCode});
      }
    } else {
      if (date) {
        url = 'api/v1/timesheets/weekly?date=' + date;
      } else {
        url = 'api/v1/timesheets/weekly?'
      }
    }
    return this.http.get(enviornmentAperture.apiUrl + url);
  }

  getImage(uri): Observable<any> {
    return this.http.get(uri, { responseType: 'text' as 'json' }).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
 
  getDuplicateTimesheet(weekDate?, resourceCode?): Observable<any> {
      let  url = 'api/v1/timesheets/showResourceTimesheets?date=' + weekDate + '&resourceCode=' + this.commonDataService.encrypt(resourceCode);
      return this.http.get(enviornmentAperture.apiUrl + url);
  }
  
  removeDuplicate(recordId?): Observable<any> {
    let  url = 'api/v1/timesheets/deleteResourceTimesheet?timesheetId=' + recordId;
    return this.http.delete(enviornmentAperture.apiUrl + url);
  }

  getResourceTimesheetData(resourceCode?): Observable<any> {
    let url;
    if (resourceCode) {
      url = 'api/v1/timesheets/weekly?resourceCode=' + this.commonDataService.encrypt(resourceCode);
    } else {
      url = 'api/v1/timesheets/weekly?';
    }
    return this.http.get(enviornmentAperture.apiUrl + url);
  }

  getMethodRequest(uri , payload): Observable<any> {
    return this.http.post(uri, payload).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  getTimesheetAttachment(uri) {
      return this.http.get(uri, { responseType: 'blob' });
  }

  deleteMethodRequest(uri): Observable<any> {
    return this.http.delete(uri, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  patchMethodRequest(uri: string, data: object, timesheetId: number, resourceCode:string): Observable<any> {
    const currentData = {...data, resourceCode: resourceCode};
    return this.http.patch(uri, currentData, { observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  recallTimesheet (data: object, timesheetId: number, resourceCode?): Observable<any> {
    if (resourceCode) {
      resourceCode = resourceCode;
    } else {
    resourceCode = sessionStorage.getItem('resourceCode');
    }    
    const currentData = {...data, resourceCode: resourceCode}
    return this.http.patch(enviornmentAperture.apiUrl+'api/v1/timesheets/Recall/'+timesheetId, currentData, { observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
      return response; 
    }));
  }

  patchMethodRequestNonHateos(data: object, timesheetId: number, resourceCode?): Observable<any> {
    if (resourceCode) {
      resourceCode = resourceCode;
    } else {
    resourceCode = sessionStorage.getItem('resourceCode');
    }
    let uri = enviornmentAperture.apiUrl + 'api/v1/timesheets/' + timesheetId;
    const currentData = {...data, resourceCode: resourceCode}
    return this.http.patch(uri, currentData, { observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  putMethodRequest(uri, data, timesheetId): Observable<any> {
    let ur = uri + data;
    return this.http.put(ur, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  putMethodRequestNonHateos(data, timesheetId): Observable<any> {
    let ur =
      enviornmentAperture.apiUrl +
      'api/v1/timesheets/' +
      timesheetId +
      'timesheetStatus?timesheetActorType=RESOURCE&timesheetStatus=SUBMITTED&comments=' +
      data;
    return this.http.put(ur, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  getMonthlyTSData(resourceCode, month?): Observable<any> {
  let url;
  if (month) {
    url = 'api/v1/timesheets/monthly';
    return this.http.post(enviornmentAperture.apiUrl + url, {resourceCode: this.commonDataService.encrypt(resourceCode), month: month});
  } else {
    url = 'api/v1/timesheets/monthly';
    return this.http.post(enviornmentAperture.apiUrl + url, {resourceCode: this.commonDataService.encrypt(resourceCode)});
  }
  
}
saveSubmitTimesheet(data, resourceCode?): Observable<any> {
   const encryptedResourceCode = this.commonDataService.encrypt(resourceCode);
  let currentData = {...data ,resourceCode:encryptedResourceCode}
    let url = 'api/v1/timesheets';
    return this.http.post(enviornmentAperture.apiUrl + url, currentData)
  }

  getDaterange(from?, to?, page?, size?, resourceCode?): Observable<any> {

    let str = '';
    if (from) {
      str = str.length ? str + '&' : '?';
      str = str + 'fromDate=' + from;
    }
    if (to) {
      str = str.length ? str + '&' : '?';
      str = str + 'tillDate=' + to;
    }
    if (size) {
      str = str.length ? str + '&' : '?';
      str = str + 'size=' + size;
    }
    if (page) {
      str = str.length ? str + '&' : '?';
      str = str + 'page=' + page;
    }
    if (resourceCode) {
      str = str.length ? str + '&' : '?';
      str = str + 'resourceCode=' + resourceCode;
    }
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/timesheets/dateRange', {
      fromDate: from ? from : '',
      tillDate: to ? to : '',
      page: page ? page : '',
      resourceCode: resourceCode ? this.commonDataService.encrypt(resourceCode) : '',
      size: size ? size : ''
    }).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  pendingByDaterange(resourceCode): Observable<any> {
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/timesheets/pendingByDateRange' , {resourceCode: this.commonDataService.encrypt(resourceCode)}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  getReturnTimesheets(): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/timesheets/recent', {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  getTimeSheetDataById(id, resourceCode?, date?): Observable<any> {
    return this.http.post(enviornmentAperture.apiUrl +'api/v1/timesheets/weekly/' + id , {resourceCode: resourceCode, date: date}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  getWeekPendingSince(value): Observable<any> {
    return this.http
      .get(enviornmentAperture.apiUrl + 'api/v1/timesheets/pendingSinceMonths?monthsCount=' + value, {})
      .pipe(map((response: HttpResponse<any>) => {
        return response;
      }));
  }
  getMonthPendingSince(value): Observable<any> {
    let url;
    if (value) {
      url = 'api/v1/timesheets/pendingByMonth?month=' + value; 
    } else {
      url = 'api/v1/timesheets/pendingByMonth';
    }
    return this.http.get(enviornmentAperture.apiUrl + url);
  }
  getWeekPendingSinceByWeekOff(date_value): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/timesheets/weekly/' + date_value, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
}