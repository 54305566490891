import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ProjectService } from 'app/projects/projects.service';
import { FormBuilder, FormControl, FormGroup,Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { TimesheetService } from 'app/timesheet-submit/timesheet.service';
export const DATE_FORMATE_REQUEST = 'yyyy-MM-dd';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { PickDateAdapter, PICK_FORMATS } from 'app/validators/validator';
import { HttpClient } from '@angular/common/http';
import { ViewAccountService } from 'app/accounts/view-account/view-account.service';
import { LeaveManagementService } from 'app/leave-management-system/leave-management.service';
import { LeaveUpdateConfirmationDialogComponent } from 'app/leave-update-confirmation-dialog/leave-update-confirmation-dailog.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
    selector: 'jhi-duplicates-view',
    templateUrl: './duplicates-view.component.html',
    styleUrls: ['./duplicates-view.component.scss'],
    providers: [LeaveManagementService,{ provide: DateAdapter, useClass: PickDateAdapter }, { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }]
})
export class DuplicatesTimesheetComponent implements OnInit, OnDestroy {

    protected _onDestroy = new Subject<void>();
    exportReportForm: FormGroup;
    resourceCode: any;
    weekDate:any;
    datePipe = new DatePipe('en-US');
    duplicates = [];
    submited =false;
    timeOut = null;
    resourcesList: any = [];
    displayedColumns: string[] = ['position', 'id', 'action'];
    dataSource = [];
    onShoreStatus = false;
    offShoreStatus = false;
    favoriteSeason: String = undefined;
    leaveJobTypes: Array<string> = ["Leave Credit", "Leave Debit"];
    jobType: any;
    constructor(
        private titleService: Title,
        private timesheetService: TimesheetService,
        private fb: FormBuilder,
        private router: Router,
        private http: HttpClient,
        private viewAccountService: ViewAccountService,
        private LMSService: LeaveManagementService,
        public dialog: MatDialog
    ) {
        this.titleService.setTitle('aperture - Admin UI');
        this.exportReportForm = this.fb.group({
            resourceCode: new FormControl('', [Validators.required]),
            weekDate: new FormControl('', [Validators.required])
       });
    }

    ngOnInit() {
    }

    getDuplicateTimesheet() {

         this.submited =true;
          this.resourceCode = this.exportReportForm.get('resourceCode').value;
          this.weekDate    = this.datePipe.transform(this.exportReportForm.get('weekDate').value, DATE_FORMATE_REQUEST);
          
          this.timesheetService.getDuplicateTimesheet(this.weekDate, this.resourceCode).subscribe(allocationData => { 
             this.duplicates =allocationData;
             this.dataSource= [];
             for(let i=0;i<allocationData.length;i++){
               this.dataSource.push({position: i, id: allocationData[i], action: 'Delete'})
            }
        });
  
    }

    removeDuplicate(recordId){
        this.timesheetService.removeDuplicate(recordId).subscribe(allocationData => { 
           this.getDuplicateTimesheet();
       });
    }

    resetexportReport() {
        this.exportReportForm.reset();
        this.exportReportForm.get('ExportResourceType').setValue('');
        this.exportReportForm.get('weekDate').setValue('');
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    onResourceSelection(event) {
        this.resourceCode = event.option.value.code;
    }

    checkResourceAccountActive() {
        this.resourcesList.forEach((element, i) => {
            if (element.emailId) {
                element.showResource = element.emailId.charAt(element.emailId.length - 1) === 'm';
            }
        });
    }

    searchResource(searchValue) {
        if (searchValue) {
            if (this.timeOut) {
                clearTimeout(this.timeOut);
            }
            this.timeOut = setTimeout(() => {
                this.viewAccountService.searchResource(searchValue).subscribe(resourceList => {
                    this.resourcesList = resourceList['_embedded']['itrack:resourceListItemList'];
                    this.checkResourceAccountActive();
                    // this.resourcesList.forEach((element, i) => {
                    //     this.viewAccountService.getMethodRequest(element['_links']['itrack:image']['href']).subscribe((imgData: any) => {
                    //         this.resourcesList[i].image = imgData;
                    //     });
                    // });
                });
            }, 1000);
        } else {
            this.resourcesList = [];
        }
    }

    updateLeaveCredit(updateFor, jobType) {
        let dialogRef = this.dialog.open(LeaveUpdateConfirmationDialogComponent, {
			width: '40%',
			height: 'auto',
            data: {
                updateFor: updateFor,
                jobType: jobType
            }
		});
        dialogRef.afterClosed().subscribe(result => {
        });
    }
}