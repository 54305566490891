<div [ngClass]="{'no-actions': isLoading}">
    <div class="row pt-3">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 export-container" [class.mat-elevation-z8]="true">
            <div class="export-timesheet">
                <div class="export-header row">Export Timesheet Report</div>
                <div class="export-content"> 
                    <form [formGroup]="exportReportForm" novalidate>
                        <mat-form-field id="fullWidth">
                            <mat-select [(ngModel)]="accountselectedOptions" name="accountselectedOption" 
                            (selectionChange)="accountSelected($event)" [ngModelOptions]="{standalone: true}" 
                            placeholder="Account" multiple>
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="accountSelectSearchFilterCtrl"
                                    placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
                                </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let list of accountListFiltered | async" [value]="list.key">
                                    {{list.value}}
                                </mat-option>
                              </mat-select>
                        </mat-form-field>
                        <mat-form-field id="fullWidth">
                            <mat-select [(ngModel)]="projectselectedOptions" name="projectselectedOption"  
                             [ngModelOptions]="{standalone: true}"
                            placeholder="Project" multiple>
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="projectSelectSearchFilterCtrl"
                                    placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
                                </ngx-mat-select-search>
                                </mat-option>
                                
                                <mat-option *ngFor="let list of projectListFiltered | async" [value]="list.code">
                                    {{list.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field id="fullWidth">
                            <mat-select formControlName="ExportResourceType" placeholder="Resource Type"
                                [(ngModel)]="selectedResourceType">
                                <mat-option value="All">All</mat-option>
                                <mat-option value="IN">Offshore</mat-option>
                                <mat-option value="US">Onshore</mat-option>
                                <!-- <mat-option value="GB"></mat-option> -->
                            </mat-select>
                        </mat-form-field>
                        <mat-spinner class="spinner__loading" diameter="40" *ngIf="isLoading"></mat-spinner>
                        <mat-form-field id="fullWidth">
                            <mat-select  *ngIf = 'exportReportForm.get("ExportResourceType")?.value === "All"' formControlName="ExportEmploymentType" placeholder="Employment Type"
                            [(ngModel)]="selectedEmploymentType">
                            <mat-option *ngFor="let allEmployeeType of allEmployeeTypes" [value]="allEmployeeType.key">{{allEmployeeType.value}}</mat-option>
                        </mat-select>
                        <mat-select  *ngIf = 'exportReportForm.get("ExportResourceType")?.value === "US"' formControlName="ExportEmploymentType" placeholder="Employment Type"
                                [(ngModel)]="selectedEmploymentType">
                                <mat-option *ngFor="let onShoreEmployeeType of onShoreEmployeeTypes" [value]="onShoreEmployeeType.key">{{onShoreEmployeeType.value}}</mat-option>
                        </mat-select>
                        <mat-select   *ngIf = 'exportReportForm.get("ExportResourceType")?.value === "IN"'  formControlName="ExportEmploymentType" placeholder="Employment Type"
                                [(ngModel)]="selectedEmploymentType">
                                <mat-option *ngFor="let offShoreEmployeeType of offShoreEmployeeTypes" [value]="offShoreEmployeeType.key">{{offShoreEmployeeType.value}}</mat-option>
                        </mat-select>
                        </mat-form-field>
                        <mat-form-field id="fullWidth">
                            <mat-select [(ngModel)]="resourceselectedOptions" [ngModelOptions]="{standalone: true}"
                                placeholder="Resource" (selectionChange)="resourceSelected($event)" multiple>
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="resourceSelectSearchFilterCtrl"
                                    placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
                                </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let resource of resourceListFiltered | async" [value]="resource.key">
                                    {{resource.value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field id="fullWidth">
                            <mat-select [(ngModel)]="statusSelectedOption" [ngModelOptions]="{standalone: true}"
                                placeholder="Status">
                                <mat-divider></mat-divider>
                                <mat-option value='all'>All</mat-option>
                                <mat-divider></mat-divider>
                                <mat-option *ngFor="let status of exportStatus" [value]="status.code">
                                    {{status.value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field style="width: 50%;float: left;margin-right: 10px;">
                            <input matInput [matDatepicker]="picker" placeholder="From Date"
                                formControlName="fromDateExport" (dateChange)="fromDateChange($event)" readonly="true"
                                placement="bottom-right">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker disabled="false"></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field style="width:calc(50% - 10px)">
                            <input matInput [matDatepicker]="picker1" placeholder="Till Date"
                                formControlName="tillDateExport" [min]="mintillDate" [max]='maxtilldate'
                                (dateChange)="tillDateChange($event)" readonly="true">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1 [disabled]="tillDateStatus"></mat-datepicker>
                        </mat-form-field>
                    </form>
                    <div class="row tilldateerror" *ngIf="tillDateStatusSelected">Please select Till Date</div>
                    <div class="row btn-row">
                        <div class="col-md-6">
                            <button class="btn exportbtn" mat-raised-button color="primary"
                                (click)='resetexportReport()'>
                                <mat-icon>refresh</mat-icon> Reset
                            </button>
                        </div>
                          <div class="col-md-6" >
                                <button class="btn exportbtn" mat-raised-button color="primary" (click)='exportReport()'
                                    [disabled]="tillDateStatusSelected">
                                    <mat-icon><i class="material-icons">
                                            save_alt
                                        </i></mat-icon> Export
                                </button>
                          </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4"></div>
    </div>

</div>
