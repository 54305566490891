<div class="list-account">
    <div class="row">
        <div class="col-sm-5">
            <h2 id='main-heading'>Account</h2>
            <!-- <mat-form-field class="account-search">
                <input (keyup)="search($event.target.value)" matInput
                    placeholder="Search account (type customer name, account code, etc...)">
            </mat-form-field> -->
        </div>
        <div class="col-sm-4"></div>
        <div *jhiHasAnyAuthority="'ACCOUNTS_CREATE'" class="col-sm-3" style="padding-top: 15px;">

            <button class="add-acount-button" mat-raised-button skipLocationChange routerLink="/addaccount" color="primary">
                <mat-icon>add</mat-icon>Add Account
            </button>
        </div>
    </div>

    <div class="row">
        <form [formGroup]="accountFilterForm" class="col-12">
            <p class="text-center font-weight-bold">Use the search parameter(s) to populate the account list.</p>
            <mat-card class="card-display" style="display: flex;">
                <!-- <div class="col-12"> -->
                <div class="col-lg-2 col-md-2 col-sm-12 float-left">
                    <mat-form-field class="facet row-full-width">
                        <mat-select placeholder="Customer Entity Name" formControlName="entityName" #singleSelect>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="customerEntitySelectSearchFilterCtrl"
                                    placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option value="ALL">All</mat-option>
                            <mat-option *ngFor="let customerEntity of customerEntityListFiltered | async" [value]="customerEntity">{{customerEntity}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 float-left">
                    <mat-form-field class="facet row-full-width">
                        <mat-select placeholder="Customer Name" formControlName="customerName" #customerNameSelect>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="customerNameSearchFilterCtrl"
                                    placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option value="ALL">All</mat-option>
                            <mat-option *ngFor="let data of customerNameListFiltered | async" [value]="data">{{data}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 float-left">
                    <mat-form-field class="facet row-full-width">
                        <mat-select placeholder="Account Manager Name" formControlName="accountManagerName" #accountManagerSelect>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="accountManagerNameSearchFilterCtrl"
                                    placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option value="ALL">All </mat-option>
                            <mat-option *ngFor="let data of accountManagerListFiltered | async" [value]="data.code"> {{data.name}} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- </div> -->
                <!-- <div class="col-12"> -->

                <div class="col-lg-2 col-md-2 col-sm-12 float-left">
                    <mat-form-field class="facet row-full-width">
                        <mat-select placeholder="AR Owner Name" formControlName="AROwnerName" #arOwnerSelect>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="arOwnerNameSearchFilterCtrl"
                                    placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option value="ALL">All</mat-option>
                            <mat-option *ngFor="let owner of arOwnerListFiltered | async" [value]="owner.arOwnerCode"> {{owner.arOwnerName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 float-left mt-3">
                    <p class="mb-0">
                        <mat-checkbox formControlName="isTimeTrackingAvailable" (change)="checkstate($event)">Time Tracking Availability
                        </mat-checkbox>
                    </p>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 float-left mt-3">
                    <button mat-raised-button color="primary" (click)="searchFilters()">Search</button>
                    <button mat-raised-button class="bg-transparent" (click)="resetFilters()">Reset</button>
                </div>
                <!-- </div> -->
            </mat-card>
        </form>
        <div class="col-12">
            
        </div>
    </div>
</div>
<!-- <mat-spinner class="spinner__loading" diameter="40" *ngIf="isLoading"></mat-spinner> -->
<mat-table  [ngClass]="{'no-actions': isLoading}" [dataSource]="dataSource" matSort  [matSortActive]="sortActive" [matSortDirection]="sortDirection" class="mt-3 mat-elevation-z8" *ngIf="dataSource.data.length > 0">
   
    <ng-container matColumnDef="accountCode">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Code</mat-header-cell>
        <mat-cell *matCellDef="let element"><span class="mobile-label">Code :</span>
            <a (click)="goToAccountPage(element.accountCode)"> {{element.accountCode}} </a>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="customerName">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </mat-header-cell>
        <mat-cell *matCellDef="let element"><span class="mobile-label">Customer Name :</span> {{element.customerName}}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="customerEntity">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Customer Entity </mat-header-cell>
        <mat-cell *matCellDef="let element"><span class="mobile-label">Customer Entity :</span>
            {{element.customerEntity}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="countryName">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Country </mat-header-cell>
        <mat-cell *matCellDef="let element"><span class="mobile-label">Country :</span> {{element.countryName}}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="customerReportingManager">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Customer Contact Person</mat-header-cell>
        <mat-cell *matCellDef="let element"><span class="mobile-label">Customer Contact Person :</span>
            {{element.customerReportingManager}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="accountManagerName">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Manager</mat-header-cell>
        <mat-cell *matCellDef="let element"><span class="mobile-label">Manager :</span> {{element.accountManagerName}}
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<div class="text-center mt-0" *ngIf="dataSource.data.length === 0">
    <p>
        <ngb-alert type="info" [dismissible]="false">
            <strong>Info!</strong> No records found.
        </ngb-alert>
    </p>
</div>

<mat-paginator *ngIf="dataSource.data.length > 0" #listAccountsPaginator (page)="handlePageBottom($event)" [pageSize]="5"
 [pageIndex]="listAccountsPaginator.pageIndex" [length]="listAccountsPaginator.length"
 [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>

<button class="btn export-button" *ngIf="dataSource.data.length > 0" mat-raised-button color="primary"
    (click)='exportAccount()'>
    <i class="fas fa-download" style="padding-right: 5px"></i>Export Accounts
</button>
