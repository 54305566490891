<section class="section-class">
    <span class="check-circle material-icons">check_circle_outline</span>
    <span class="close material-icons" (click)="onNoClick()">close</span>
    <br>
    <br>
    <h4 class="thankyou-referral"><strong>Submission successful!</strong></h4>
</section>
<br>
<section class="thankyou-referral">
    <h6>Thank you for your referral. We appreciate your trust in us.</h6>
</section>
<br>
<section class="thankyou-referral">
    <!-- <p><b> You can check the status of your referral by clicking the below button</b></p>
    <button mat-button class="track-button">Track</button>
    <br>
    <br> -->
    <p class="open-positions" (click)="seeOpenPositions()">See open positions</p>
</section>