import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'jhi-range-picker',
    templateUrl: './range-picker.component.html',
    styleUrls: ['./range-picker.component.css']
})
export class RangePickerComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
