import { Component, OnInit, ViewChildren, EventEmitter, Output, Inject, QueryList, ElementRef, ViewChild, Input, OnChanges } from '@angular/core';
import { TimesheetService } from '../timesheet.service';
import { NgbPopover, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DEFAULT_DATE_FORMAT } from '../../app.constants';
import { DatePipe } from '@angular/common';
export const DATE_FORMATE = DEFAULT_DATE_FORMAT;
import { Observable } from 'rxjs';
import { fromEvent } from 'rxjs'
import * as _ from 'lodash';
import { ReferralsService } from 'app/Referrals/referrals.service';
import { makeReferralPopup } from 'app/Referrals/submit-referral/submit-referral.component';
import { Principal } from 'app/core';
import { CommonDataService } from 'app/common-data.service';
import * as moment from 'moment';
// import { PipeTransform, Pipe } from '@angular/core';
export interface DialogData {
    comment: string;
    isSubmitted: boolean;
}
@Component({
    selector: 'jhi-current-timesheet-view',
    templateUrl: './current-timesheet-view.component.html',
    styleUrls: ['./current-timesheet-view.css'],
    // providers: [{ provide: NgbDateAdapter, useClass: NgbDateMomentAdapter },
    //     { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    //     { provide: MAT_DATE_FORMATS, useValue: MY_COMPLETE_DATE_FORMATS },
    //     {provide: MAT_DATE_LOCALE, useValue: 'en-US'}
    // ],
})
export class CurrentTimesheetViewComponent implements OnInit, OnChanges {
    Object = Object;
    projects: Array<any>;
    currentDate;
    dateFromHome;
    weekDpModel;
    checked = [false, false, false, false, false, false, false];
    datePipe = new DatePipe('en-US');
    minDate;
    maxDate;
    comment: string;
    todayDate: any;
    leaveToAdd: any;
    leaveToShow: any;
    fileUploadedAllocationId: Array<any> = [];
    public currentWeekSubmit: any;
    jsAlertReplaceMessage: any;
    weekStatusCheck = false;
    timesheetActorImage = '';
    projectsActorImage: Array<any>;
    public weekObj: any = {};
    resumeSizeFlag = false;
    fileExtensionFlag = false;
    since_months = 1;
    clientTimesheetImage: any;
    public weekPendingSince: any = {};
    @Input('timesheetData1') newResourceCode: string;
    @Input('selfTimesheetView') selfTimesheetView = true;
    @Input('exitdate') exitDate: string;
    @Input('resourceDetails') resourceDetails: any;
    timesheetData1: any;
    @ViewChild('currentAllocations') currentAllocationsModal: ElementRef;
    private months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    leaveTypes = [];
    weekDates = [];
    allocationLinks = [];
    currentallocationmodalRef: NgbModalRef;
    selectedLeave;
    showTableData = false;
    showPendingCount = true;
    resumeLengthFlag = false;
    resumeData: Array<any> = [];
    isLeaveAdded = false;
    onSubmit = false;
    resourceImages: Array<any> = [];
    @ViewChildren('p') public popoverList: QueryList<NgbPopover>;
    @ViewChild('content') contentModal: ElementRef;
    weekDate: any;
    @Output() submittedToParent = new EventEmitter<boolean>();
    @Output() sHours: EventEmitter<any> = new EventEmitter();
    private standard_hours: any;
    tempTimesheetData: any = {};
    timesheetData: any = {};
    ifTimesheetModified = false;
    pendingActionMonthsLimit = 0;
    DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
    imageUploadFlag = false;
    recall = false;
    resourceCode: string;
    attachmentUploadFlag: boolean;
    openTimesheet = true;
    fixedweekoff = true;
    dailydetails: Array<any> = [];
    private scrollEvent;
    dateref: any;
    isOffShoreResource: boolean;
    isDelegateManager: boolean;
    isOnShoreResource: boolean;
    delegatemanagerIndexOne: any;
    allArrayStatus: any;
    saveStatusPresent: boolean;
    recallTimesheetObj: any;
    allJobs: any;
    isLoading: boolean;
    allJobsLength: any;
    delegateManagerArr: Array<any> = [];
    canEdit: boolean;
    canViewLMS: boolean = false;
    recallCommentSubmistion: boolean = false;
    timesheetViewerResourceCode:string ="";
    // transform(value, args:string[]) : any {
    //     let keys = [];
    //     for (let key in value) {
    //       keys.push(key);
    //     }
    //     return keys;
    // };
    constructor(
        private timesheetService: TimesheetService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private principal: Principal,
        private router: Router,
        private referralService: ReferralsService,
        private commonData: CommonDataService
    ) { }

    ngOnInit () {
        this.setCanEdit();
        // this.referralService.getAlljobs('all', 'all', 'all','all',true).subscribe(res => {
        //     this.allJobs=res.jobList;
        //     this.allJobsLength=res.jobList.length;
        // });
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.weekDate = params['id'].split('_')[0];
            }
            if (params['resourceCode']) {
                this.resourceCode = params['resourceCode'];
            }
        });
        if (!this.resourceCode) {
            this.resourceCode = sessionStorage.getItem('resourceCode');
        }
        this.dateFromHome = sessionStorage.getItem('data');
        if (this.dateFromHome !== null) {
            this.weekDate = this.dateFromHome;
        }
        if (this.exitDate) {
            this.getTimeSheetData(this.exitDate);
        } else {
            this.getTimeSheetData(this.weekDate);
        }
        this.getWeekPendingData(1);
        this.clientTimesheetImage = null;
        sessionStorage.removeItem('data');
        this.scrollEvent = fromEvent(window, 'scroll').subscribe((e: any) => {
            if (e.target.documentElement && e.target.documentElement.scrollTop >= 289) {
                this.calenderOpen(this.dateref, e.target.documentElement.scrollTop);
            }
        });
        this.timesheetService.resourceCodeData$.subscribe(
            (data) => {
                this.getTimeSheetData();
            }
        );
        this.principal.identity().then(account => {
            if (account.authorities.includes('LMS')) {
                this.canViewLMS = true;
            }
		});
    }

    ngOnChanges() {
        if (this.timesheetData1 === undefined) {
            this.timesheetData1 = this.newResourceCode;
        } else if (this.timesheetData1 !== this.newResourceCode) {
            this.timesheetData1 = this.newResourceCode;
            if (this.exitDate) {
                this.getTimeSheetData(this.exitDate);
            } else {
                this.getTimeSheetData();
            }
        }
    }
    makeReferral (data): void {
        if(this.canEdit) {
        let dialogRef = this.dialog.open(makeReferralPopup, {
            width: '650px', height: '550px',
            data: data,
        });
    }
    }
    redirectTo (jobs) {
        let newjobName=jobs.jobTitle.replace(/\s+/g, '-');
        let url=`https://www.gspann.com/jobs/open-positions/${ newjobName }`;
        window.open(url,'_blank');
    }
    checkDecimalOccurence(val, charCode) {
        let a = {};
        if (val.toString().length >= '1') {
            for (let i = 0; i < val.toString().length; i++) {
                if (!a[val[i]]) {
                    a[val[i]] = 1;
                } else {
                    a[val[i]] = a[i] + 1;
                }
            }
            return (a['.'] === 1 && charCode === 46) || (a['.'] === 1 && val.indexOf('.5') !== -1 && charCode === 53) ? false : true;
        }
        return true;
    }
    numberOnly(event) {
        let charCode = event.which ? event.which : event.keyCode;
        if (event.currentTarget.value.indexOf('.') !== -1) {
            if (!this.checkDecimalOccurence(event.currentTarget.value, charCode)) {
                return false;
            } else if (charCode !== 53 && charCode !== 46) {
                return false;
            }
            return true;
        } else {
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                return false;
            }
            return true;
        }
    }
    getTimeSheetData(date?) {
        if (isNaN(date)) {
            this.showTableData = false;
            if (date) {
                this.leaveTypes = [];
            }
            if (this.timesheetData1) {
                // this.leaveTypes = [];
                let selectedResource;
                this.timesheetService.getTimesheetData(date, this.timesheetData1).subscribe(timesheetData => {
                    selectedResource = timesheetData.allocations[0].resource.code;
                    this.updateDelegateManager(timesheetData.allocations, selectedResource);
                    this.isLeaveAdded = false;
                    this.timesheetData = this.parseModel(timesheetData);
                    this.createInitialTimesheetData();
                    this.showTableData = true;
                    if (this.weekObj.projects) {
                        for (let i = 0; i < this.weekObj.projects.length; i++) {
                            let project = this.weekObj.projects[i];
                            for (let j = 0; j < project.hours.length; j++) {
                                if (timesheetData.weekMetaData && timesheetData.weekMetaData.dayDetails && timesheetData.weekMetaData.dayDetails[j]) {
                                   project.hours[j].remarks = timesheetData.weekMetaData.dayDetails[j].remarks; 
                                }else{
                                    project.hours[j].remarks ='';
                                }
                            }
                        }
                    }
                    if (this.timesheetData.status && this.timesheetData.status.actor) {
                        // this.timesheetService.getImage(this.timesheetData.status.actor._links['itrack:image'].href).subscribe((imageData: any) => {
                        //     this.timesheetActorImage = imageData;
                        // });
                    }
                });
            } else {
                this.timesheetService.getTimesheetData(date).subscribe(timesheetData => {
                    this.isLeaveAdded = false;
                    this.timesheetData = this.parseModel(timesheetData);
                    this.createInitialTimesheetData();
                    if (this.weekObj.projects) {
                        for (let i = 0; i < this.weekObj.projects.length; i++) {
                            let project = this.weekObj.projects[i];
                            for (let j = 0; j < project.hours.length; j++) {
                                if (timesheetData.weekMetaData && timesheetData.weekMetaData.dayDetails && timesheetData.weekMetaData.dayDetails[j]) {
                                   project.hours[j].remarks = timesheetData.weekMetaData.dayDetails[j].remarks; 
                                }else{
                                    project.hours[j].remarks ='';
                                }
                            }
                        }
                    }
                    this.showTableData = true;
                    if (this.timesheetData.status && this.timesheetData.status.actor) {
                        // this.timesheetService.getImage(this.timesheetData.status.actor._links['itrack:image'].href).subscribe((imageData: any) => {
                        //     this.timesheetActorImage = imageData;
                        // });
                    }
                });
            }
        } else {
            this.getTimeSheetDataById(date);
        }
    }
    checkReadonly(startDate, endDate, currentDayDate) {
        let result = false;
        if (!(currentDayDate >= startDate)) {
            result = true;
        }
        if (!(endDate !== null && currentDayDate <= endDate)) {
            result = true;
        }
        return result;
    }
    getTimeSheetDataHateoas(link, buttonType) {
        this.clientTimesheetImage = null;
        this.fileUploadedAllocationId = [];
        if (link) {
            this.showTableData = false;
            this.leaveTypes = [];

            if(this.route.component.length == 8){
                this.timesheetViewerResourceCode = this.commonData.encrypt(sessionStorage.getItem('resourceCode'));
            } else if (this.route.component.length == 14) {
                this.timesheetViewerResourceCode = this.commonData.encrypt(this.commonData.resourceCode);
            }   

            let payload = {
                resourceCode: this.timesheetViewerResourceCode,
                date: this.weekObj.nextWeekStartDate,
            }
            if (buttonType === 'prev') {
                payload.date =this.weekObj.previousWeekStartDate
            }
            this.timesheetService.getMethodRequest(link, payload).subscribe(timesheetData => {
                this.isLeaveAdded = false;
                this.timesheetData = this.parseModel(timesheetData);
                this.createInitialTimesheetData();
                this.showTableData = true;
                if (this.timesheetData.status && this.timesheetData.status.actor) {
                    // this.timesheetService.getImage(this.timesheetData.status.actor._links['itrack:image'].href).subscribe((imageData: any) => {
                    //     this.timesheetActorImage = imageData;
                    // });
                }
                                if (this.weekObj.projects) {
                    for (let i = 0; i < this.weekObj.projects.length; i++) {
                        let project = this.weekObj.projects[i];
                        for (let j = 0; j < project.hours.length; j++) {
                            if (timesheetData.weekMetaData && timesheetData.weekMetaData.dayDetails && timesheetData.weekMetaData.dayDetails[j]) {
                               project.hours[j].remarks = timesheetData.weekMetaData.dayDetails[j].remarks; 
                            }else{
                                project.hours[j].remarks ='';
                            }
                        }
                    }
                }
            });
        }
    }
    updateTimesheetData(date?) {
        this.showTableData = false;
        this.leaveTypes = [];
        date = (moment(date.value).format('YYYY-MM-DD'));
        if (this.timesheetData1) {
            this.timesheetService.getTimesheetData(date, this.timesheetData1).subscribe(timesheetData => {
                this.timesheetData = this.parseModel(timesheetData);
                this.tempTimesheetData = this.timesheetData;
                this.createInitialTimesheetData();
                this.showTableData = true;
            });
        } else {
            this.timesheetService.getTimesheetData(date).subscribe(timesheetData => {
                this.timesheetData = this.parseModel(timesheetData);
                this.tempTimesheetData = this.timesheetData;
                this.createInitialTimesheetData();
                this.showTableData = true;
            });
        }
    }
    getTimeSheetDataById(id, resourceCode?) {
        this.showTableData = false;
        this.leaveTypes = [];
        this.timesheetService.getTimeSheetDataById(id, resourceCode).subscribe(timesheetData => {
            this.timesheetData = this.parseModel(timesheetData);
            this.tempTimesheetData = this.timesheetData;
            this.createInitialTimesheetData();
            if (this.weekObj.projects) {
                for (let i = 0; i < this.weekObj.projects.length; i++) {
                    let project = this.weekObj.projects[i];
                    for (let j = 0; j < project.hours.length; j++) {
                        if (timesheetData.weekMetaData && timesheetData.weekMetaData.dayDetails && timesheetData.weekMetaData.dayDetails[j]) {
                           project.hours[j].remarks = timesheetData.weekMetaData.dayDetails[j].remarks; 
                        }else{
                            project.hours[j].remarks ='';
                        }
                    }
                }
            }
            this.showTableData = true;
            // if (this.timesheetData.status && this.timesheetData.status.actor) {
            //     this.timesheetService.getImage(this.timesheetData.status.actor._links['itrack:image'].href).subscribe((imageData: any) => {
            //         this.timesheetActorImage = imageData;
            //     });
            // }
        });
    }
    parseModel(timesheetData) {
        this.weekObj.flexibleWeekends = false;
        this.weekDates = [];
        this.allocationLinks = [];
        if (timesheetData.timesheet) {
            if (timesheetData.timesheet.allocationWiseTimeSheets.length > 0) {
                timesheetData.timesheet.allocationWiseTimeSheets[0].dailyEntries.forEach(entry => {
                    this.weekDates.push(entry);
                });
            }
            timesheetData.timesheet.allocationWiseTimeSheets.forEach(alloc => {
                if (alloc['_links']) {
                    this.allocationLinks.push({ allocId: alloc.allocationSummary.allocationId, href: alloc['_links']['itrack:delete'].href });
                }
            });
        } else {
            timesheetData.weekMetaData.dayDetails.forEach(entry => {
                this.weekDates.push(entry);
            });
        }
        this.weekObj.code = timesheetData.status.timesheetStatus.code;
        this.weekObj.status = timesheetData.status.timesheetStatus.value;
        this.weekObj.statusObj = timesheetData.status;
        this.weekObj.description = timesheetData.status.timesheetStatus.description;
        this.weekObj.dayMetaData = timesheetData.weekMetaData.dayDetails;
        let timeEntries = {
            hours: '',
            comments: ''
        };
        if (this.weekObj.code === 'PENDING_SUBMISSION' || this.weekObj.code === 'PENDING' || this.weekObj.code === 'SAVED') {
            let dailyEntries = [];
            let obj = {
                date: '',
                comments: '',
                timeEntries: (timeEntries)
            };
            timesheetData.weekMetaData.dayDetails.forEach(day => {
                obj.date = day.date;
                dailyEntries.push(obj);
            });
            if (this.weekObj.code === 'PENDING_SUBMISSION' || this.weekObj.code === 'PENDING' || this.weekObj.code === 'SAVED') {
                timesheetData.allocations.forEach(project => {
                    project.dailyEntries = dailyEntries;
                    if (project.flexibleWeekends) {
                        this.weekObj.flexibleWeekends = true;
                    }
                });
                if (this.weekObj.flexibleWeekends === true) {
                    this.fixedweekoff = false;
                } else {
                    this.fixedweekoff = true;
                }
            }
            timesheetData.weekMetaData.dayDetails.forEach(day => {
                day.comments = '';
                day.timeEntries = timeEntries;
            });
        } else if (
            this.weekObj.code === 'SUBMITTED' ||
            this.weekObj.code === 'REJECTED' ||
            this.weekObj.code === 'APPROVED' ||
            this.weekObj.code === 'SAVED' ||
            this.weekObj.code === 'REJECTED' ||
            this.weekObj.code === 'APPROVED' ||
            this.weekObj.code === 'PARTIALLY_APPROVED_REJECTED' ||
            this.weekObj.code === 'PARTIALLY_APPROVED' ||
            this.weekObj.code === 'PARTIALLY_SUBMITTED' ||
            this.weekObj.code === 'PARTIALLY_APPROVED_PENDING' ||
            this.weekObj.code === 'PARTIALLY_APPROVED_REJECTED_PENDING' ||
            this.weekObj.code === 'PARTIALLY_REJECTED_PENDING' ||
            this.weekObj.code === 'PARTIALLY_REJECTED'
        ) {
            timesheetData.timesheet.allocationWiseTimeSheets[0].dailyEntries.forEach(day => {
                day.timeEntries = timeEntries;
            });
            if (this.weekObj.code !== 'APPROVED' || this.weekObj.code !== 'SUBMITTED') {
                timesheetData.allocations.forEach(project => {
                    if (project.flexibleWeekends) {
                        this.weekObj.flexibleWeekends = true;
                    }
                });
                if (this.weekObj.flexibleWeekends === true) {
                    this.fixedweekoff = false;
                } else {
                    this.fixedweekoff = true;
                }
            }
        }
        return timesheetData;
    }

    trackByIndex(index: number, obj: any): any {
        return index;
    }
    getWeekPendingData(value) {
        this.showPendingCount = true;
        this.timesheetService.getWeekPendingSince(value).subscribe(weekPendingSince => {
            this.weekPendingSince = weekPendingSince;
            this.weekStatusCheck = weekPendingSince.weekStatuses.length ? true : false;
            this.showPendingCount = false;
            this.pendingActionMonthsLimit = weekPendingSince.pendingActionMonthsLimit;
        });
    }

    openCurrentAllocations(currentAllocations, link) {
        if (link && link.length > 0) {
            this.timesheetService.getImage(link).subscribe((imageData: any) => {
                this.clientTimesheetImage = imageData;
                this.timesheetService.getTimesheetAttachment(imageData).subscribe(data => {
                    // saveAs(data, 'TimesheetAttachment');
                    // var blob = new Blob([link]);
                    let url = window.URL.createObjectURL(data);
                    window.open(url);
                });
            });
        }
    }

    closeCurrentAllocations() {
        this.currentallocationmodalRef.close();
    }

    getImages(link) {
        // return this.timesheetService.getImage(link).subscribe((imageData: any) => {
        //     return imageData;
        // });
    }

    createImages(data) {
        this.projectsActorImage = [];
        data.forEach((project, i) => {
            if (project.status&&project.status.actor) {
                // this.timesheetService.getImage(project.status.actor._links['itrack:image'].href).subscribe((imageData: any) => {
                //     this.projectsActorImage[i] = { resourceCode: project.status.actor.code, img: imageData };
                // });
            } else {
                this.projectsActorImage[i] = { resourceCode: '', img: '' };
            }
        });
    }

    createImagesFromAllocations(data, allocations) {
        this.projectsActorImage=[];
        this.saveStatusPresent=false;
        data.forEach(element => {
            allocations.forEach((project, i) => {
                if (project.allocationId===element.allocationSummary.allocationId) {
                    this.allArrayStatus=element.status.timesheetStatus.code;
                    if (this.allArrayStatus) {
                        if (this.allArrayStatus==='SAVED') {
                            this.saveStatusPresent=true;
                        }
                    }
                    if (element.status&&element.status.actor) {
                        // this.timesheetService.getImage(element.status.actor._links['itrack:image'].href).subscribe((imageData: any) => {
                        //     this.projectsActorImage[element.status.actor.code] = { resourceCode: element.status.actor.code, img: imageData };
                        // });
                    } else {
                        this.projectsActorImage[i] = { resourceCode: '', img: '' };
                    }
                } else {
                    this.projectsActorImage[i] = { resourceCode: '', img: '' };
                }
            });
        });
    }

    createInitialTimesheetData () {
        this.weekDates = [];
        if (this.timesheetData.timesheet) {
            // this.timesheetData.timesheet.allocationWiseTimeSheets[0].dailyEntries.forEach(entry => {
            this.timesheetData.timesheet.totalEntries.forEach(entry => {
                this.weekDates.push(entry);
            });
        } else {
            this.timesheetData.weekMetaData.dayDetails.forEach(entry => {
                this.weekDates.push(entry);
            });
        }
        if (this.timesheetData.timesheet) {
            this.weekObj.timesheet = this.timesheetData.timesheet;
        } else {
            this.weekObj.timesheet = false;
        }
        if (this.weekObj.code === 'PENDING_SUBMISSION' || this.weekObj.code === 'PENDING') {
            this.createImages(this.timesheetData.allocations);
            this.weekObj.projects = this.createProjectsModel(this.timesheetData.allocations);
            this.weekObj.daysData = this.createDaysData(this.timesheetData.weekMetaData.dayDetails);
        } else if (
            // this.weekObj.code === 'SUBMITTED' ||
            this.weekObj.code === 'SAVED' ||
            this.weekObj.code === 'REJECTED' ||
            this.weekObj.code === 'APPROVED' ||
            this.weekObj.code === 'PARTIALLY_APPROVED_REJECTED' ||
            this.weekObj.code === 'PARTIALLY_APPROVED' ||
            this.weekObj.code === 'PARTIALLY_SUBMITTED' ||
            this.weekObj.code === 'PARTIALLY_APPROVED_PENDING' ||
            this.weekObj.code === 'PARTIALLY_APPROVED_REJECTED_PENDING' ||
            this.weekObj.code === 'PARTIALLY_REJECTED_PENDING' ||
            this.weekObj.code === 'PARTIALLY_REJECTED'
        ) {
            this.createImagesFromAllocations(this.timesheetData.timesheet.allocationWiseTimeSheets, this.timesheetData.allocations);
            this.weekObj.projects = this.createProjectsModel(
                this.timesheetData.allocations,
                this.timesheetData.timesheet.allocationWiseTimeSheets
            );
            // this.weekObj.daysData = this.createDaysData(this.timesheetData.timesheet.allocationWiseTimeSheets[0].dailyEntries);
            this.weekObj.daysData = this.createDaysData(this.timesheetData.timesheet.totalEntries);
        } else if (this.weekObj.code === 'SUBMITTED') {
            this.createImages(this.timesheetData.timesheet.allocationWiseTimeSheets);
            this.weekObj.projects = this.createProjectsModel(
                this.timesheetData.allocations,
                this.timesheetData.timesheet.allocationWiseTimeSheets
            );
            this.weekObj.daysData = this.createDaysData(this.timesheetData.timesheet.totalEntries);
            // this.weekObj.daysData = this.createDaysData(this.timesheetData.timesheet.allocationWiseTimeSheets[0].dailyEntries);
        }
        this.weekObj.links = this.timesheetData._links;
        this.weekObj.weekName = this.timesheetData.weekMetaData.weekDetails.weekName;
        this.weekObj.actions = this.timesheetData.actions;
        this.weekObj.startDate = this.timesheetData.weekMetaData.weekDetails.weekStartDate;
        this.todayDate = new Date();
        let priorDate = new Date(this.todayDate.getTime() - 60 * 24 * 60 * 60 * 1000);
        this.weekObj.endDate = this.timesheetData.weekMetaData.weekDetails.weekEndDate;
        this.weekObj.weeklyStandardHours = this.timesheetData.timesheetSettings.weekSettings.weeklyStandardHours;
        this.weekObj.dailyStandardHours = this.timesheetData.timesheetSettings.weekSettings.dailyStandardHours;
        this.weekObj.commentsAdded = true;
        this.weekObj.leaves = [];
        this.weekObj.previousWeekEnabled = this.timesheetData.previousWeek && this.timesheetData.previousWeek !== null ? true : false;
        this.weekObj.previousWeekStartDate = this.weekObj.previousWeekEnabled && this.timesheetData.previousWeek.week.weekStartDate;
        this.weekObj.nextWeekEnabled = this.timesheetData.nextWeek && this.timesheetData.nextWeek !== null ? true : false;
        this.weekObj.nextWeekStartDate = this.weekObj.nextWeekEnabled && this.timesheetData.nextWeek.week.weekStartDate;
        this.weekObj.next_week_date_param = false;
        this.weekObj.previous_week_date_param = false;
        this.minDate = new Date(this.timesheetData.timesheetSettings.timesheetsWindow.windowStartDate);
        this.maxDate = new Date(this.timesheetData.timesheetSettings.timesheetsWindow.windowEndDate);
        this.weekDpModel = new Date(this.timesheetData.weekMetaData.weekDetails.weekStartDate);
        this.weekObj.lastUpdatedOn = this.timesheetData.status.lastUpdatedOn;
        if (new Date(this.weekObj.startDate) <= this.todayDate) {
            this.currentWeekSubmit = true;
        } else {
            this.currentWeekSubmit = false;
        }
        if (new Date(this.weekObj.startDate) <= priorDate) {
            if (this.selfTimesheetView === true) {
                this.openTimesheet = false;
            }
        } else {
            this.openTimesheet = true;
        }
        if (this.timesheetData.status.lastUpdatedOn !== null) {
            this.timesheetData.status.lastUpdatedOn = this.timesheetData.status.lastUpdatedOn.substring(
                0,
                this.timesheetData.status.lastUpdatedOn.lastIndexOf(' ')
            );
            this.weekObj.lastUpdatedOn = this.timesheetData.status.lastUpdatedOn;
        }
        if (this.timesheetData._links['itrack:previous_week'] !== undefined) {
            this.weekObj.previous_week_date_param = this.timesheetData._links['itrack:previous_week']['href'];
        }
        if (this.timesheetData._links['itrack:next_week'] !== undefined) {
            this.weekObj.next_week_date_param = this.timesheetData._links['itrack:next_week']['href'];
        }
        this.standard_hours = {
            week: this.timesheetData.timesheetSettings.weekSettings.weeklyStandardHours,
            day: this.timesheetData.timesheetSettings.weekSettings.dailyStandardHours
        };
        this.sHours.emit(this.standard_hours);
        if (this.isLeaveAdded) {
            let noOfProj = this.timesheetData.timesheet.allocationWiseTimeSheets.length;
            for (let i = 0; i < noOfProj; i++) {
                if (
                    this.timesheetData.timesheet.allocationWiseTimeSheets[i].allocationSummary.wbs.project.type.key === 'PDL' &&
                    this.timesheetData.timesheet.allocationWiseTimeSheets[i].leaveType
                ) {
                    this.leaveToAdd = this.leaveTypes.findIndex(l => {
                        return l.type === this.timesheetData.timesheet.allocationWiseTimeSheets[i].allocationSummary.wbs.project.type.key;
                    });
                    this.weekObj.projects.push(this.leaveTypes[this.leaveToAdd]);
                    this.leaveTypes.splice(this.leaveToAdd, 1);
                } else if (
                    this.timesheetData.timesheet.allocationWiseTimeSheets[i].allocationSummary.wbs.project.type.key === 'UPL' &&
                    this.timesheetData.timesheet.allocationWiseTimeSheets[i].leaveType
                ) {
                    this.leaveToAdd = this.leaveTypes.findIndex(l => {
                        return l.type === this.timesheetData.timesheet.allocationWiseTimeSheets[i].allocationSummary.wbs.project.type.key;
                    });
                    this.weekObj.projects.push(this.leaveTypes[this.leaveToAdd]);
                    this.leaveTypes.splice(this.leaveToAdd, 1);
                } 
                else if (
                    this.timesheetData.timesheet.allocationWiseTimeSheets[i].allocationSummary.wbs.project.type.key === 'NBL' &&
                    this.timesheetData.timesheet.allocationWiseTimeSheets[i].leaveType
                ) {
                    this.leaveToAdd = this.leaveTypes.findIndex(l => {
                        return l.type === this.timesheetData.timesheet.allocationWiseTimeSheets[i].allocationSummary.wbs.project.type.key;
                    });
                    this.weekObj.projects.push(this.leaveTypes[this.leaveToAdd]);
                    this.leaveTypes.splice(this.leaveToAdd, 1);
                }

            }
        }
    }
    createDaysData(daysData) {
        let daysViewModel = [];
        if (daysData.length > 0) {
            daysData.forEach((dayObj, index) => {
                let dModel: any = {};
                let splittedDateObj = dayObj.date.split('-');
                dModel.date = splittedDateObj[2];
                dModel.month = this.months[parseInt(splittedDateObj[1], 10) - 1];
                dModel.day = dayObj.day.substr(0, 3);
                dModel.remarks = dayObj.remarks;
                dModel.code = dayObj.type.code;

                if (this.weekObj.code !== 'SUBMITTED') {
                    let flexibleWeekends = this.weekObj.projects.filter((allocation: any) => allocation.allocationSummary.flexibleWeekends).length;
                    if (flexibleWeekends >= 1) {
                        dModel.weekOff = true;
                        dModel.checked = dayObj.weekOff;
                    } else {
                        if (dayObj.day === 'SUNDAY' || dayObj.day === 'SATURDAY') {
                            dModel.checked = true;
                        } else {
                            dModel.checked = false;
                        }
                    }
                } else {
                    let flexibleWeekends = this.weekObj.projects.filter((allocation: any) => allocation.allocationSummary.flexibleWeekends).length;
                    if (flexibleWeekends >= 1) {
                        dModel.checked = dayObj.weekOff;
                    } else {
                        if (dayObj.day === 'SUNDAY' || dayObj.day === 'SATURDAY') {
                            dModel.checked = true;
                        } else {
                            dModel.checked = false;
                        }
                    }
                }
                if (this.weekObj.timesheet) {
                    dModel.dailyComment = this.weekObj.timesheet.totalEntries[index].dailyComment;
                    dModel.comment = dModel.dailyComment;
                } else {
                    dModel.dailyComment = null;
                }

                daysViewModel.push(dModel);
            });
        }
        return daysViewModel;
    }
    createProjectHoursPerDayModel(i) {
        let hoursPerDayModel = [];
        let currentProj = this.weekObj.projects;
        currentProj.forEach(proj => {
            let timeObj: any = {};
            timeObj.comments = proj.hours[i].comments;
            if (proj.hours[i].hour.toString().indexOf('.5') !== -1) {
                timeObj.hours = parseInt(proj.hours[i].hour, 10).toString();
                timeObj.minutes = '30';
            } else {
                timeObj.hours = proj.hours[i].hour;
                timeObj.minutes = '0';
            }
            timeObj.allocationId = proj.allocationId;
            hoursPerDayModel.push(timeObj);
        });
        return hoursPerDayModel;
    }
    createHourEntriesModel() {
        if (this.timesheetData.timesheet) {
            this.dailydetails = this.timesheetData.timesheet.totalEntries;
        } else {
            this.dailydetails = this.timesheetData.weekMetaData.dayDetails;
        }
        let hourEntries = [];
        for (let i = 0; i < 7; i++) {
            let entryObj: any = {};
            if (
                // this.weekObj.daysData[i].dailyComment &&
                // this.weekObj.daysData[i].dailyComment.length !== 0 &&
                // this.weekObj.daysData[i].comment.length !== 0
                this.weekObj.daysData[i].dailyComment === this.weekObj.daysData[i].comment
            ) {
                entryObj.comments = this.weekObj.daysData[i].dailyComment;
            } else {
                // else if(this.weekObj.daysData[i].dailyComment !== this.weekObj.daysData[i].comment){
                //   entryObj.comments = this.weekObj.daysData[i].comment;
                // }
                entryObj.comments = this.weekObj.daysData[i].comment;
                // entryObj.weekoffChecked = this.weekObj.daysData[i].checked;
            }
            entryObj.date = this.dailydetails[i].date;
            entryObj.dayType = this.dailydetails[i].type.code;
            if (this.timesheetData.status.timesheetStatus.code !== 'APPROVED') {
                entryObj.weekoffChecked = this.weekObj.daysData[i].checked;
            } else if (this.timesheetData.status.timesheetStatus.code !== 'PENDING_SUBMISSION' || this.timesheetData.status.timesheetStatus.code !== 'PENDING') {
                entryObj.weekOff = this.dailydetails[i].weekOff;
            }
            entryObj.timeEntries = this.createProjectHoursPerDayModel(i);
            hourEntries.push(entryObj);
        }

        return hourEntries;
    }
    saveSubmitData(action, dailyentries) {
        if (action === 'SAVE') {
            action = 'SAVED';
        } else if (action === 'SUBMIT') {
            action = 'SUBMITTED';
        }
        let data = {
            timesheetStatus: {
                status: action,
                comments: this.comment && this.comment.length > 0 ? this.comment : ''
            },
            clientTimeSheetDocumentDTO: [],
            week: {
                dailyEntries: dailyentries
            }
        };

        if (this.resumeData.length) {
            this.resumeData.forEach(element => {
                let obj = {
                    allocationId: element.allocationId,
                    filename: element.filename,
                    data: element.data
                };
                data.clientTimeSheetDocumentDTO.push(obj);
            });
        } else {
            delete data.clientTimeSheetDocumentDTO;
        }
        data.week.dailyEntries.forEach(day => {
            day.timeEntries.forEach(timeEntry => {
                if (timeEntry.hours === '') {
                    timeEntry.hours = 0;
                }
            });
        });
        if (this.timesheetData1) {
            this.resourceCode = this.timesheetData1;
        } else {
            this.resourceCode = sessionStorage.getItem('resourceCode');
        }
        this.timesheetService.saveSubmitTimesheet(data, this.resourceCode).subscribe(response => {
            let obj, message, messageType;
            if (response) {
                if (response.headers?.get('X-Itrack-Info')) {
                    message = response.headers?.get('X-Itrack-Info');
                    messageType = 'success';
                } else if (response.headers?.get('X-itrack-warning')) {
                    message = response.headers?.get('X-itrack-warning');
                    messageType = 'warning';
                } else if (response.headers?.get('X-itrack-error')) {
                    message = response.headers?.get('X-itrack-error');
                    messageType = 'danger';
                }
                obj = {
                    submitted: true,
                    displayLabel: message,
                    messageType: (messageType)
                };
                this.submittedToParent.emit(obj);
            }
            this.getTimeSheetDataById(parseInt(response.timesheetId, 10), this.commonData.encrypt(this.resourceCode));
            this.getWeekPendingData(this.since_months);
        });
        this.resumeData = [];
    }

    commentsCheck(dailyEntries, totalEntries, LeaveAdded, action) {
        let morethan8Hours_count = 0;
        let stanardHours_count = 0;
        let leaveDay_Count = 0;
        let leavedayTotalHours = 0;
        let we_Count = 0,
            hd_Count = 0,
            wd_Count = 0,
            retVal = true;
        let nullHoursComment = false;
        let isRemarks = false;
        for (let i = 0; i < this.weekObj.projects.length; i++) {
            if (this.weekObj.projects[i].hours) {
                for (let j = 0; j < this.weekObj.projects[i].hours.length; j++) {
                    if (this.weekObj.projects[i].hours[j].remarks !== '') {
                        isRemarks = true;
                    }
                }
            } 
        }
        for (let day of dailyEntries) {
            let totalHoursInaDay = 0;
            let totalHoursInaDayCommentCheck = true;
            day.timeEntries.forEach(timeEntry => {
                if (timeEntry.hours === '') {
                    timeEntry.hours = 0;
                }
                if (we_Count === 0 && day.weekoffChecked && timeEntry.hours > 0 && !day.comments) {
                    if (leaveDay_Count === 0) {
                        we_Count++;
                        this.jsAlertReplaceMessage = `Comment is mandatory for weekoff work hours.
            Enter the day comment in row 'Total Hours Per Day' for the date ${ this.datePipe.transform(day.date, DATE_FORMATE) }`;
                        this.modalService.open(this.contentModal);
                        retVal = false;
                        return;
                    }
                    return;
                }
                // if (wd_Count === 0 && day.dayType === 'WD' && timeEntry.hours > 24 && !timeEntry.comments) {
                if (wd_Count === 0 && !day.weekoffChecked && timeEntry.hours > 24 && !timeEntry.comments) {
                    wd_Count++;
                    this.jsAlertReplaceMessage = `You are not allowed to enter more than 24 total day hours.
          Enter the day comment in row 'Total Hours Per Day' for the date ${ this.datePipe.transform(day.date, DATE_FORMATE) }`;
                    this.modalService.open(this.contentModal);
                    retVal = false;
                    return false;
                }
                if (timeEntry.minutes === '30') {
                    totalHoursInaDay += Number(timeEntry.hours) + .5;
                } else {
                    totalHoursInaDay += Number(timeEntry.hours);
                }

                if (parseInt(timeEntry.hours, 10) > 8 && timeEntry.comments) {
                    totalHoursInaDayCommentCheck = false;
                } else if (parseInt(timeEntry.hours, 10) > 8 && !timeEntry.comments) {
                    totalHoursInaDayCommentCheck = true;
                }
            });
            if (totalEntries) {
                totalEntries.forEach(entry => {
                    if (entry.date === day.date && entry.dailyComment) {
                        totalHoursInaDayCommentCheck = false;
                    }
                });
            }
            if (morethan8Hours_count === 0 && totalHoursInaDay > 8 && !day.comments && totalHoursInaDayCommentCheck) {
                morethan8Hours_count++;
                this.jsAlertReplaceMessage = `Comment is mandatory when worked for more than 8 hours on the same day.
        Enter the day comment in row 'Total Hours Per Day' for the date ${ this.datePipe.transform(day.date, DATE_FORMATE) }`;
                this.modalService.open(this.contentModal);
                retVal = false;
                return false;
            }
            if (morethan8Hours_count === 0 && totalHoursInaDay < 8 && !day.comments && totalHoursInaDayCommentCheck && !this.weekObj.flexibleWeekends && day.dayType === 'WD') {
                morethan8Hours_count++;
                this.jsAlertReplaceMessage = `Comment is mandatory when worked for less than 8 hours on the same day.
        Enter the day comment in row 'Total Hours Per Day' for the date ${ this.datePipe.transform(day.date, DATE_FORMATE) }`;
                this.modalService.open(this.contentModal);
                retVal = false;
                return false;
            }
            if (morethan8Hours_count === 0 && totalHoursInaDay < 8 && !day.comments && totalHoursInaDayCommentCheck && this.weekObj.flexibleWeekends && !day.weekoffChecked && !isRemarks) {
                morethan8Hours_count++;
                this.jsAlertReplaceMessage = `Comment is mandatory when worked for less than 8 hours on the same day.
        Enter the day comment in row 'Total Hours Per Day' for the date ${ this.datePipe.transform(day.date, DATE_FORMATE) }`;
                this.modalService.open(this.contentModal);
                retVal = false;
                return false;
            }
            if (stanardHours_count === 0 && day.dayType !== 'HD' && totalHoursInaDay < 8 && totalHoursInaDayCommentCheck && !day.comments && !this.weekObj.flexibleWeekends && day.dayType === 'WD') {
                stanardHours_count++;
                this.jsAlertReplaceMessage = `Comment is mandatory when worked for less than 8 hours on the same day.
        Enter the day comment in row 'Total Hours Per Day' for the date ${ this.datePipe.transform(day.date, DATE_FORMATE) }`;
                this.modalService.open(this.contentModal);
                retVal = false;
                return false;
            }
            if (stanardHours_count === 0 && day.dayType !== 'HD' && totalHoursInaDay < 8 && totalHoursInaDayCommentCheck && !day.comments && this.weekObj.flexibleWeekends && !day.weekoffChecked) {
                stanardHours_count++;
                this.jsAlertReplaceMessage = `Comment is mandatory when worked for less than 8 hours on the same day.
        Enter the day comment in row 'Total Hours Per Day' for the date ${ this.datePipe.transform(day.date, DATE_FORMATE) }`;
                this.modalService.open(this.contentModal);
                retVal = false;
                return false;
            }
            if (hd_Count === 0 && day.dayType === 'HD' && totalHoursInaDay > 0 && !day.comments) {
                hd_Count++;
                this.jsAlertReplaceMessage = `Comment is mandatory for holiday.
        Enter the day comment in row 'Total Hours Per Day' for the date ${ this.datePipe.transform(day.date, DATE_FORMATE) }`;
                this.modalService.open(this.contentModal);
                retVal = false;
                return false;
            }
            // if (this.weekObj.projects && this.weekObj.projects.length) {
            //     for (let i = 0; i < this.weekObj.projects.length; i++) {
            //         if (
            //             !nullHoursComment &&
            //             this.weekObj.projects[i].startDate <= day.date &&
            //             totalHoursInaDay === 0 &&
            //             // !day.comments &&
            //             // day.dayType === 'WD'
            //             !day.weekoffChecked &&
            //             day.dayType !== 'HD'
            //         ) {
            // //             this.jsAlertReplaceMessage = `Comment is mandatory when Total working hours per day is 0.
            // // Enter the day comment in row 'Total Hours Per Day' for the date ${this.datePipe.transform(day.date, DATE_FORMATE)}`;
            // this.jsAlertReplaceMessage = `Total working hours per day cannot be 0.
            //    Please add Leaves hours or Non Billable Working hours for the date ${this.datePipe.transform(day.date, DATE_FORMATE)}`;
            //             this.modalService.open(this.contentModal);
            //             retVal = false;
            //             nullHoursComment = true;
            //             return false;
            //         }
            //     }
            // }
        }
        for (let i = 0; i < this.weekObj.projects.length; i++) {
            if (action === 'SUBMIT' && this.weekObj.projects[i].attachment === true && this.weekObj.projects[i].download === '') {
                if (Array.isArray(this.weekObj.projects[i].hours)) {
                    //  this.weekObj.projects[i].hours.map((obj) => {
                    // if(obj.hour == 0 && obj.comments === '') {
                    //     event.stopPropagation();
                    //     this.jsAlertReplaceMessage = `Clients time tracking attachment is mandatory for Project: ${this.weekObj.projects[i].name}`;
                    //     this.modalService.open(this.contentModal);
                    //     retVal = false;
                    //     return false;
                    // }
                    for (let j = 0; j < this.weekObj.projects[i].hours.length; j++) {
                        if (parseInt(this.weekObj.projects[i].hours[j].hour) > 0 && (this.fileUploadedAllocationId.indexOf(this.weekObj.projects[i].allocationId) < 0)) {
                            event.stopPropagation();
                            this.jsAlertReplaceMessage = `Client time tracking attachment is mandatory for Project: ${ this.weekObj.projects[i].name }`;
                            this.modalService.open(this.contentModal);
                            retVal = false;
                            return false;
                        }
                    }
                    // return false
                    // })
                }
            }
        }
        return retVal ? true : false;
    }
    saveOrSubmit(action) {
        let totalHours = this.calculateTotalHours(this.weekObj.projects);
        let dailyEntries = this.createHourEntriesModel();
        let stanardHours = this.weekObj.weeklyStandardHours;
        if (action === 'SAVE') {
            // let commentsValiditySave;
            // if (this.timesheetData.timesheet) {
            //     commentsValiditySave = this.commentsCheck(dailyEntries, this.timesheetData.timesheet.totalEntries, this.isLeaveAdded, action);
            // } else {
            //     commentsValiditySave = this.commentsCheck(dailyEntries, false, this.isLeaveAdded, action);
            // }

            // if (commentsValiditySave && action === 'SAVE') {
            //     this.saveSubmitData(action, dailyEntries);
            // }
            this.saveSubmitData(action, dailyEntries);
        }
        // if (!(totalHours < stanardHours) && action !== 'SAVE') {
        if (action !== 'SAVE') {
            let commentsValidity;
            if (this.timesheetData.timesheet) {
                commentsValidity = this.commentsCheck(dailyEntries, this.timesheetData.timesheet.totalEntries, this.isLeaveAdded, action);
            } else {
                commentsValidity = this.commentsCheck(dailyEntries, false, this.isLeaveAdded, action);
            }
            if (commentsValidity) {
                // if (action === 'SUBMIT' && totalHours < stanardHours) {
                //   this.jsAlertReplaceMessage = 'Minimum 40 hours are required per week';
                //   this.modalService.open(this.contentModal);
                // } else if (action === 'SUBMIT' && totalHours >= stanardHours) {
                if (action === 'SUBMIT') {
                    // if (totalHours < stanardHours) {
                    //   this.jsAlertReplaceMessage = 'Minimum 40 hours are required per week';
                    //   this.modalService.open(this.contentModal);
                    //   return 0;
                    // } else {

                    if (this.timesheetData.status.comments !== null && this.timesheetData.status.comments.length) {
                        this.comment = this.timesheetData.status.comments;
                    } else {
                        this.comment = '';
                    }
                    let dialogRef = this.dialog.open(DialogConfirmBodyComponent, {
                        width: '412px',
                        data: { comment: this.comment, isSubmitted: false },
                        position: { top: '130px' }
                    });
                    dialogRef.afterClosed().subscribe(response => {
                        this.comment = response;
                        if (dialogRef.componentInstance.data.isSubmitted) {
                            this.saveSubmitData(action, dailyEntries);
                        }
                    });
                    // }
                }
                // } else if (action === 'SUBMIT' && totalHours > stanardHours) {
                //   let dialogRef = this.dialog.open(DialogConfirmBodyComponent, {
                //     width: '412px',
                //     position: { top: '130px' }
                //   });
                //   dialogRef.afterClosed().subscribe(response => {
                //     if (response) {
                //       this.saveSubmitData(action, dailyEntries);
                //     }
                //   });
                // }
            } // commentsValidity
        }
        //  else if (action !== 'SAVE') {
        //   this.jsAlertReplaceMessage = 'Minimum 40 hours are required per week';
        //   this.modalService.open(this.contentModal);
        //   return 0;
        // }
    }

    checkIftimesheetModified(check,recallStatus=false) {
        this.ifTimesheetModified = false;
        let currentDailyEntries = this.createHourEntriesModel();
        let dailyEntries: Array<any> = [];
        let dailyEntryObj: any = {};
        let updateTimeEntries: Array<any> = [];
        let timeEntry: any = {};
        let nullHoursComment = false;
        let retVal = true;
        let morethan8Hours_count = 0;
        let stanardHours_count = 0;
        let wd_Count = 0;
        let hd_Count = 0;
        let we_Count=0;
        for (let day in this.weekObj.daysData) {
            if (currentDailyEntries[day] && currentDailyEntries[day].weekOff !== this.weekObj.daysData[day].checked) {
                this.ifTimesheetModified = true;
            }
            currentDailyEntries[day]['weekOff'] = this.weekObj.daysData[day].checked;
        }
        for (let dayEntry of currentDailyEntries) {
            let di = 0;
            if (check === 'submit') {
                let total = 0;
                if (dayEntry.timeEntries.length > 0) {
                    for (let i = 0; i < dayEntry.timeEntries.length; i++) {
                        if (dayEntry.timeEntries[i].minutes === '30') {
                            dayEntry.timeEntries[i].hours = dayEntry.timeEntries[i].hours + '.5';
                            total += Number(dayEntry.timeEntries[i].hours);
                        } else {
                            total += Number(dayEntry.timeEntries[i].hours);
                        }
                    }
                }
                if (this.weekObj.projects && this.weekObj.projects.length) {
                    for (let i = 0; i < this.weekObj.projects.length; i++) {
                        if (
                            !nullHoursComment &&
                            this.weekObj.projects[i].startDate <= dayEntry.date &&
                            total < 8 &&
                            !(dayEntry.dayType === 'HD' ||
                                dayEntry.dayType === 'WE') && !this.weekObj.flexibleWeekends &&
                            !dayEntry.weekOff && !dayEntry.comments
                        ) {
                            this.jsAlertReplaceMessage = `Comment is mandatory when worked for less than 8 hours on the same day.
                            Enter the day comment in row 'Total Hours Per Day' for the date ${ this.datePipe.transform(dayEntry.date, DATE_FORMATE) }`;
                            this.modalService.open(this.contentModal);
                            retVal = false;
                            return false;
                        }
                        if (
                            !nullHoursComment &&
                            this.weekObj.projects[i].startDate <= dayEntry.date &&
                            total < 8 && this.weekObj.flexibleWeekends &&
                            !dayEntry.weekOff && !dayEntry.comments
                        ) {
                            this.jsAlertReplaceMessage = `Comment is mandatory when worked for less than 8 hours on the same day.
                            Enter the day comment in row 'Total Hours Per Day' for the date ${ this.datePipe.transform(dayEntry.date, DATE_FORMATE) }`;
                            this.modalService.open(this.contentModal);
                            retVal = false;
                            return false;
                        }
                        if (morethan8Hours_count === 0 && total <= 24 && total > 8 && !dayEntry.comments) {
                            morethan8Hours_count++;
                            this.jsAlertReplaceMessage = `Comment is mandatory when worked for more than 8 hours on the same day.
                  Enter the day comment in row 'Total Hours Per Day' for the date ${ this.datePipe.transform(dayEntry.date, DATE_FORMATE) }`;
                            this.modalService.open(this.contentModal);
                            retVal = false;
                            return false;
                        }
                        if (stanardHours_count === 0 && !dayEntry.weekOff && dayEntry.dayType !== 'HD' && total < 8 && total > 0 && !dayEntry.comments) {
                            stanardHours_count++;
                            this.jsAlertReplaceMessage = `Comment is mandatory when worked for less than 8 hours on the same day.
                               Enter the day comment in row 'Total Hours Per Day' for the date ${ this.datePipe.transform(dayEntry.date, DATE_FORMATE) }`;
                            this.modalService.open(this.contentModal);
                            retVal = false;
                            return false;
                        }
                    }
                }

                // if (stanardHours_count === 0 && !dayEntry.weekOff && dayEntry.dayType !== 'HD' && total < 8 && total > 0) {
                //     stanardHours_count++;
                //     this.jsAlertReplaceMessage = `Total working hours cannot be less than 8 hours on the same day.
                // Please add Leaves hours or Non Billable Working hours for the date ${this.datePipe.transform(dayEntry.date, DATE_FORMATE)}`;
                //     this.modalService.open(this.contentModal);
                //     retVal = false;
                //     return false;
                // }
                if (wd_Count === 0 && dayEntry.dayType === 'WD' && total > 24) {
                    wd_Count++;
                    this.jsAlertReplaceMessage = 'You are not allowed to enter more than 24 total day hours';
                    this.modalService.open(this.contentModal);
                    retVal = false;
                    return false;
                }
                if (we_Count === 0 && dayEntry.weekOff && total > 0 && !dayEntry.comments) {
                    we_Count++;
                    this.jsAlertReplaceMessage = `Comment is mandatory for weekoff work hours.
            Enter the day comment in row 'Total Hours Per Day' for the date ${ this.datePipe.transform(dayEntry.date, DATE_FORMATE) }`;
                    this.modalService.open(this.contentModal);
                    retVal = false;
                    return false;
                }
                if (hd_Count === 0 && dayEntry.dayType === 'HD' && total > 0 && !dayEntry.comments) {
                    hd_Count++;
                    this.jsAlertReplaceMessage = `Comment is mandatory for holiday.
          Enter the day comment in row 'Total Hours Per Day' for the date ${ this.datePipe.transform(dayEntry.date, DATE_FORMATE) }`;
                    this.modalService.open(this.contentModal);
                    retVal = false;
                    return false;
                }
            }
            updateTimeEntries = [];
            dailyEntryObj = {};
            dayEntry.timeEntries.forEach((looptTimeEntry, ti) => {
                timeEntry = {};
                if (
                    this.timesheetData.timesheet.allocationWiseTimeSheets[ti] !== undefined &&
                    this.timesheetData.timesheet.allocationWiseTimeSheets[ti].dailyEntries[di].comments !== looptTimeEntry.comments
                ) {
                    timeEntry.comments = looptTimeEntry.comments;
                } else if (this.timesheetData.timesheet.allocationWiseTimeSheets[ti] === undefined && looptTimeEntry.comments !== '') {
                    timeEntry.comments = looptTimeEntry.comments;
                }
                if (
                    this.timesheetData.timesheet.allocationWiseTimeSheets[ti] !== undefined &&
                    this.convertHoursToNumber(this.timesheetData.timesheet.allocationWiseTimeSheets[ti].dailyEntries[di].hours) !==
                    parseInt(looptTimeEntry.hours, 10)
                ) {
                    timeEntry.hours = parseInt(looptTimeEntry.hours, 10);
                    timeEntry.minutes = looptTimeEntry.minutes;
                } else {
                    timeEntry.hours = parseInt(looptTimeEntry.hours, 10);
                    timeEntry.minutes = looptTimeEntry.minutes;
                }
                if (timeEntry.hours === 0 || timeEntry.hours > 0 || timeEntry.comments) {
                    this.ifTimesheetModified = true;
                    timeEntry.allocationId = looptTimeEntry.allocationId;
                    timeEntry.hours = parseInt(looptTimeEntry.hours, 10);
                    timeEntry.minutes = looptTimeEntry.minutes;
                    timeEntry.comments = looptTimeEntry.comments;
                    updateTimeEntries.push(timeEntry);
                }
            });
            let ute = Object.keys(updateTimeEntries);
            let uteLen = ute.length;
            if (dayEntry.comments !== this.timesheetData.timesheet.totalEntries[di].dailyComment) {
                this.ifTimesheetModified = true;
            }
            if (uteLen || this.ifTimesheetModified) {
                dailyEntryObj.date = dayEntry.date;
                dailyEntryObj.dayType = dayEntry.dayType;
                dailyEntryObj.comments = dayEntry.comments;
                dailyEntryObj.timeEntries = [];
                updateTimeEntries.forEach(entry => {
                    dailyEntryObj.timeEntries.push(entry);
                });
            } else if (!uteLen && dailyEntryObj.comments) {
                dailyEntryObj.date = dayEntry.date;
            }
            let deo = Object.keys(dailyEntryObj);
            let deoLen = deo.length;
            if (deoLen) {
                dailyEntries.push(dailyEntryObj);
            }
            di++;
        }
        let local_status = 'SAVED';
        if (this.ifTimesheetModified && check === 'submit') {
            local_status = 'SUBMITTED';
        }
        for (let day in this.weekObj.daysData) {
            if (dailyEntries[day] && dailyEntries[day].weekOff !== this.weekObj.daysData[day].checked) {
                this.ifTimesheetModified = true;
            }
            if (dailyEntries[day]) {
                dailyEntries[day]['weekoffChecked'] = this.weekObj.daysData[day].checked;
            }

        }
        let updateTimesheetObj: any = {
            timesheetStatus: {
                status: local_status
            },
            clientTimeSheetDocumentDTO: [],
            week: {
                dailyEntries: (dailyEntries)
            }
        };
        if (this.resumeData.length) {
            this.resumeData.forEach(element => {
                let obj = {
                    allocationId: element.allocationId,
                    filename: element.filename,
                    data: element.data
                };
                updateTimesheetObj.clientTimeSheetDocumentDTO.push(obj);
                this.ifTimesheetModified = true;
            });
        } else {
            delete updateTimesheetObj.clientTimeSheetDocumentDTO;
        }
        for (let i = 0; i < this.weekObj.projects.length; i++) {
            if (check === 'submit' && this.weekObj.projects[i].attachment === true && this.weekObj.projects[i].download === '') {
                if (Array.isArray(this.weekObj.projects[i].hours)) {
                    for (let j = 0; j < this.weekObj.projects[i].hours.length; j++) {
                        if (parseInt(this.weekObj.projects[i].hours[j].hour) >= 0 && (this.fileUploadedAllocationId.indexOf(this.weekObj.projects[i].allocationId) < 0)) {
                            event.stopPropagation();
                            this.jsAlertReplaceMessage = `Client time tracking attachment is mandatory for Project: ${ this.weekObj.projects[i].name }`;
                            this.modalService.open(this.contentModal);
                            retVal = false;
                            return false;
                        }
                    }
                }
            }
        }
        if (this.timesheetData1) {
            this.resourceCode = this.commonData.encrypt(this.timesheetData1);
        } else {
            this.resourceCode = this.commonData.encrypt(sessionStorage.getItem('resourceCode'));
        }
        if (check==='recall') {
            this.recallTimesheetObj=updateTimesheetObj;
        }
        if (check === 'update') {
            if (this.ifTimesheetModified) {
                if (this.weekObj.links['itrack:update'] && this.weekObj.links['itrack:update'].href) {
                    this.timesheetService
                        .patchMethodRequest(this.weekObj.links['itrack:update'].href, updateTimesheetObj, this.timesheetData.timesheet.timesheetId, this.resourceCode)
                        .subscribe(result => {
                            this.getTimeSheetDataById(parseInt(this.timesheetData.timesheet.timesheetId, 10), this.resourceCode);
                            this.getWeekPendingData(this.since_months);
                        });
                } else {
                    this.timesheetService
                        .patchMethodRequestNonHateos(updateTimesheetObj, this.timesheetData.timesheet.timesheetId, this.resourceCode)
                        .subscribe(result => {
                            this.getTimeSheetDataById(parseInt(this.timesheetData.timesheet.timesheetId, 10), this.resourceCode);
                            this.getWeekPendingData(this.since_months);
                        });
                }
            } else {
                this.jsAlertReplaceMessage = 'You have made no changed in order to save timeheet.';
                this.modalService.open(this.contentModal);
                return true;
            }
        } else if (check === 'submit') {
            if (this.ifTimesheetModified && retVal) {
                /* rejected timesheet comments removed from dialog box */
                // if (this.timesheetData.status.comments && this.timesheetData.status.comments.length) {
                //     this.comment = this.timesheetData.status.comments;
                // } else {
                //     this.comment = '';
                // }
                let dialogRef = this.dialog.open(DialogConfirmBodyComponent, {
                    width: '412px',
                    data: { comment: this.comment, isSubmitted: false },
                    position: { top: '130px' }
                });
                dialogRef.afterClosed().subscribe(response => {
                    this.comment = response;
                    if (dialogRef.componentInstance.data.isSubmitted) {
                        updateTimesheetObj.timesheetStatus.comments=this.comment;
                        if (this.weekObj.links['itrack:update'] && this.weekObj.links['itrack:update'].href) {
                            if (this.weekObj&&this.weekObj.status==='Submitted') {
                                // updateTimesheetObj.action='RECALLED';
                                this.timesheetService
                                .patchMethodRequest(this.weekObj.links['itrack:update'].href, updateTimesheetObj, this.timesheetData.timesheet.timesheetId,this.resourceCode)
                                .subscribe(result => {
                                    this.getTimeSheetDataById(parseInt(this.timesheetData.timesheet.timesheetId, 10), this.resourceCode);
                                    this.getWeekPendingData(this.since_months);
                                });
                            } else {
                                // updateTimesheetObj.action='SUBMITTED';
                                this.timesheetService
                                .patchMethodRequest(this.weekObj.links['itrack:update'].href, updateTimesheetObj, this.timesheetData.timesheet.timesheetId,this.resourceCode)
                                .subscribe(result => {
                                    this.getTimeSheetDataById(parseInt(this.timesheetData.timesheet.timesheetId, 10), this.resourceCode);
                                    this.getWeekPendingData(this.since_months);
                                });
                            }
                        } else {
                            this.timesheetService
                                .patchMethodRequestNonHateos(updateTimesheetObj, this.timesheetData.timesheet.timesheetId, this.resourceCode)
                                .subscribe(result => {
                                    this.getTimeSheetDataById(parseInt(this.timesheetData.timesheet.timesheetId, 10), this.resourceCode);
                                    this.getWeekPendingData(this.since_months);
                                });
                        }
                    }
                });
            } else if (retVal) {
                if (this.timesheetData.status.comments !== null && this.timesheetData.status.comments.length) {
                    this.comment = this.timesheetData.status.comments;
                } else {
                    this.comment = '';
                }
                let dialogRef = this.dialog.open(DialogConfirmBodyComponent, {
                    width: '412px',
                    data: { comment: this.comment, isSubmitted: false },
                    position: { top: '130px' }
                });
                dialogRef.afterClosed().subscribe(response => {
                    this.comment = response;
                    if (dialogRef.componentInstance.data.isSubmitted) {
                        if (this.weekObj.links['itrack:submit'] && this.weekObj.links['itrack:submit'].href) {
                            this.timesheetService
                                .putMethodRequest(this.weekObj.links['itrack:submit'].href, this.comment, this.timesheetData.timesheet.timesheetId)
                                .subscribe(result => {
                                    this.getTimeSheetDataById(parseInt(this.timesheetData.timesheet.timesheetId, 10));
                                    this.getWeekPendingData(this.since_months);
                                });
                        } else {
                            this.timesheetService.putMethodRequestNonHateos(this.comment, this.timesheetData.timesheet.timesheetId).subscribe(result => {
                                this.getTimeSheetDataById(parseInt(this.timesheetData.timesheet.timesheetId, 10));
                                this.getWeekPendingData(this.since_months);
                            });
                        }
                    }
                });
            }
        }
        this.resumeData = [];
        if(recallStatus) {
            this.recall = true;
            this.recallCommentSubmistion =true;
        } else 
        this.recall = false;
    }

    convertHoursToNumber(hours_string: string) {
        return parseInt(hours_string.replace(hours_string.charAt(hours_string.length - 1), '').replace('PT', ''), 10);
    }

    submitStatusUpdate() {
        this.checkIftimesheetModified('submit');
    }

    updateTimesheet() {
        this.checkIftimesheetModified('update');
    }

    onRecallsubmitStatusUpdate() {
        this.checkIftimesheetModified('submit',true);
    }

    leavesAfterSaved(alloc, dataObj) {
        let dataObj_leaves = {
            code: '',
            allocationId: '',
            name: '',
            tooltip: '',
            attachment: '',
            type: '',
            hours: [],
            totalHours: ''
        };
        dataObj_leaves.code = alloc.wbs.project.identifier.key;
        dataObj_leaves.allocationId = alloc.allocationId;
        dataObj_leaves.name = alloc.wbs.project.identifier.value + ' (' + alloc.occupancy + '%)';
        dataObj_leaves.tooltip = dataObj.code + ' - ' + dataObj.name;
        dataObj_leaves.attachment = alloc.clientTimeTracking;
        dataObj_leaves.type = alloc.wbs.project.type.key;
        dataObj_leaves.hours = [
            { hour: 0, comments: '' },
            { hour: 0, comments: '' },
            { hour: 0, comments: '' },
            { hour: 0, comments: '' },
            { hour: 0, comments: '' },
            { hour: 0, comments: '' },
            { hour: 0, comments: '' }
        ];
        let projObj_leaves = {
            code: dataObj_leaves.code,
            allocationId: alloc.allocationId,
            name: dataObj_leaves.name,
            tooltip: dataObj_leaves.tooltip,
            attachment: dataObj_leaves.attachment,
            hours: dataObj_leaves.hours,
            type: dataObj_leaves.type,
            totalHours: dataObj_leaves.totalHours
        };
        this.leaveTypes.push(projObj_leaves);
    }

    createProjectsModel(projects, timesheetAllocationWiseTimeSheets?) {
        let projectsModel=[];
        this.leaveTypes=[];
        let condition,
            UPL = true,
            PDL = true,
            NBL=true;
            this.isDelegateManager=false;
            this.isOffShoreResource=false;
            this.isOnShoreResource=false;

        if (projects.length > 0) {
            projects.forEach((project, index) => {
                let dataObj = {
                    code: '',
                    allocationId: '',
                    name: '',
                    tooltip: '',
                    startDate: '',
                    endDate: '',
                    attachment: '',
                    download: '',
                    type: '',
                    hours: [],
                    totalHours: '0',
                    status: {},
                    actorImage: '',
                    allocationSummary: {}
                };
                if (this.weekObj.code === 'PENDING_SUBMISSION' || this.weekObj.code === 'PENDING') {
                    this.isLeaveAdded = false;
                    let pendingStatus = {
                        timesheetStatus: {
                            code: 'PENDING'
                        },
                    };
                    condition = project.wbs.project.type.key !== 'PDL' && project.wbs.project.type.key !== 'UPL' && project.wbs.project.type.key !== 'NBL';
                    dataObj.code = project.wbs.project.identifier.key;
                    dataObj.allocationId = project.allocationId;
                    dataObj.name = project.wbs.project.identifier.value + ' (' + project.occupancy + '%)';
                    dataObj.tooltip = dataObj.code + ' - ' + dataObj.name;
                    dataObj.attachment = project.clientTimeTracking;
                    dataObj.startDate = project.startDate;
                    dataObj.endDate = project.endDate;
                    dataObj.type = project.wbs.project.type.key;
                    dataObj.status = pendingStatus;
                    dataObj.hours = [
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' }
                    ];
                    dataObj.allocationSummary=project;
                    if (project.wbs.project.delegateManagers!==null) {
                        this.delegatemanagerIndexOne=project.wbs.project.delegateManagers[0];
                        this.isDelegateManager=true;
                    } else if (project.wbs.project.delegateManagers===null) {
                        this.isDelegateManager=false;
                        if (project.resource.code.substring(0, 2)==='IN') {
                            this.isOffShoreResource=true;
                        } else {
                            this.isOnShoreResource=true;
                        }
                    }
                } else if (
                    this.weekObj.code === 'SUBMITTED' ||
                    this.weekObj.code === 'SAVED' ||
                    this.weekObj.code === 'REJECTED' ||
                    this.weekObj.code === 'APPROVED' ||
                    this.weekObj.code === 'PARTIALLY_APPROVED_REJECTED' ||
                    this.weekObj.code === 'PARTIALLY_APPROVED' ||
                    this.weekObj.code === 'PARTIALLY_SUBMITTED' ||
                    this.weekObj.code === 'PARTIALLY_APPROVED_PENDING' ||
                    this.weekObj.code === 'PARTIALLY_APPROVED_REJECTED_PENDING' ||
                    this.weekObj.code === 'PARTIALLY_REJECTED_PENDING' ||
                    this.weekObj.code === 'PARTIALLY_REJECTED'
                ) {
                    condition = project.wbs.project.type.key !== 'PDL' && project.wbs.project.type.key !== 'UPL' && project.wbs.project.type.key !== 'NBL';
                    dataObj.code = project.wbs.project.identifier.key;
                    dataObj.allocationId = project.allocationId;
                    dataObj.name = project.wbs.project.identifier.value + ' (' + project.occupancy + '%)';
                    dataObj.tooltip = dataObj.code + ' - ' + dataObj.name;
                    dataObj.attachment = project.clientTimeTracking;
                    dataObj.startDate = project.startDate;
                    dataObj.endDate = project.endDate;
                    dataObj.type = project.wbs.project.type.key;

                    dataObj.hours = [
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' }
                    ];

                    dataObj.allocationSummary = project;
                    if (timesheetAllocationWiseTimeSheets&&timesheetAllocationWiseTimeSheets.length) {
                        timesheetAllocationWiseTimeSheets.forEach(element => {
                            if (element.allocationSummary.wbs.project.delegateManagers!==null) {
                                this.delegatemanagerIndexOne=element.allocationSummary.wbs.project.delegateManagers[0];
                                this.isDelegateManager=true;
                            } else if (element.allocationSummary.wbs.project.delegateManagers===null) {
                                this.isDelegateManager=false;
                                if (element.allocationSummary.resource.code.substring(0, 2)==='IN') {
                                    this.isOffShoreResource=true;
                                } else {
                                    this.isOnShoreResource=true;
                                }
                            }
                            if (element.allocationSummary.allocationId === project.allocationId && element.clientTimeSheetImageUrl) {
                                dataObj.download = element.clientTimeSheetImageUrl;
                            }
                        });
                    }

                    if (timesheetAllocationWiseTimeSheets && timesheetAllocationWiseTimeSheets.length) {
                        timesheetAllocationWiseTimeSheets.forEach(element => {
                            if (element.allocationSummary.allocationId === project.allocationId) {
                                element.status.lastUpdatedOn = element.status.lastUpdatedOn.substring(0, element.status.lastUpdatedOn.lastIndexOf(' '));
                                dataObj.status = element.status;
                                dataObj.hours = [];
                                element.dailyEntries.forEach(day => {
                                    if (day.hours.indexOf('30') !== -1) {
                                        if (day.hours.indexOf('H') !== -1) {
                                            let hour = day.hours.substring(2, day.hours.indexOf('H')) + '.5';
                                            dataObj.hours.push({
                                                hour: (hour),
                                                comments: day.comments
                                            });
                                        } else {
                                            if (day.hours.indexOf('M') !== -1) {
                                                let hour = '0.5';
                                                dataObj.hours.push({
                                                    hour: (hour),
                                                    comments: day.comments
                                                });
                                            }
                                        }
                                    } else {
                                        let hour = day.hours.replace(day.hours.charAt(day.hours.length - 1), '').replace('PT', '');
                                        dataObj.hours.push({
                                            hour: (hour),
                                            comments: day.comments
                                        });
                                    }
                                });
                                if (element.projectTotalHours.indexOf('30') !== -1) {
                                    dataObj.totalHours = element.projectTotalHours.
                                        substring(2, element.projectTotalHours.indexOf('H')) + '.5';
                                } else {
                                    dataObj.totalHours = element.projectTotalHours
                                        .replace(element.projectTotalHours.charAt(element.projectTotalHours.length - 1), '')
                                        .replace('PT', '');
                                }
                            }
                        });
                    }
                    if (
                        (this.weekObj.code === 'SAVED' ||
                            this.weekObj.code === 'SUBMITTED' ||
                            this.weekObj.code === 'REJECTED' ||
                            this.weekObj.code === 'APPROVED' ||
                            this.weekObj.code === 'PARTIALLY_APPROVED_REJECTED' ||
                            this.weekObj.code === 'PARTIALLY_APPROVED' ||
                            this.weekObj.code === 'PARTIALLY_SUBMITTED' ||
                            this.weekObj.code === 'PARTIALLY_APPROVED_PENDING' ||
                            this.weekObj.code === 'PARTIALLY_APPROVED_REJECTED_PENDING' ||
                            this.weekObj.code === 'PARTIALLY_REJECTED_PENDING' || this.weekObj.code === 'PARTIALLY_REJECTED') || (dataObj.type === 'PDL' || dataObj.type === 'UPL' || dataObj.type === 'NBL')
                    ) {
                        this.isLeaveAdded = true;
                    } else {
                        this.isLeaveAdded = false;
                    }
                    if (dataObj.type === 'PDL') {
                        PDL = false;
                    }
                    if (dataObj.type === 'UPL') {
                        UPL = false;
                    }
                    if (dataObj.type === 'NBL') {
                        NBL = false;
                    }

                    if (
                        parseInt(index + 1, 10) === parseInt(projects.length, 10) &&
                        this.weekObj.code === 'SAVED' &&
                        UPL === true &&
                        PDL === true &&
                        NBL === true &&
                        this.isLeaveAdded === false
                    ) {
                        projectsModel.forEach(prj => {
                            if (prj.code === 'UPL') {
                                UPL = false;
                            }
                            if (prj.code === 'PDL') {
                                PDL = false;
                            }
                            if (prj.code === 'NBL') {
                                NBL = false;
                            }
                        });
                        if (UPL === true) {
                            this.timesheetData.allocations.forEach(alloc => {
                                if (alloc.wbs.project.type.key === 'UPL') {
                                    this.leavesAfterSaved(alloc, dataObj);
                                }
                            });
                        }
                        if (PDL === true) {
                            this.timesheetData.allocations.forEach(alloc => {
                                if (alloc.wbs.project.type.key === 'PDL') {
                                    this.leavesAfterSaved(alloc, dataObj);
                                }
                            });
                        }
                        if (NBL === true) {
                            this.timesheetData.allocations.forEach(alloc => {
                                if (alloc.wbs.project.type.key === 'NBL') {
                                    this.leavesAfterSaved(alloc, dataObj);
                                }
                            });
                        }
                    } else if (
                        parseInt(index + 1, 10) === parseInt(projects.length, 10) &&
                        this.weekObj.code === 'SAVED' &&
                        UPL === true &&
                        dataObj.type !== 'UPL'
                    ) {
                        projectsModel.forEach(prj => {
                            if (prj.code === 'UPL') {
                                UPL = false;
                            }
                        });
                        if (UPL === true) {
                            this.timesheetData.allocations.forEach(alloc => {
                                if (alloc.wbs.project.type.key === 'UPL') {
                                    this.leavesAfterSaved(alloc, dataObj);
                                }
                            });
                        }
                    } else if (
                        parseInt(index + 1, 10) === parseInt(projects.length, 10) &&
                        this.weekObj.code === 'SAVED' &&
                        PDL === true &&
                        dataObj.type !== 'PDL'
                    ) {
                        projectsModel.forEach(prj => {
                            if (prj.code === 'PDL') {
                                PDL = false;
                            }
                        });
                        if (PDL === true) {
                            this.timesheetData.allocations.forEach(alloc => {
                                if (alloc.wbs.project.type.key === 'PDL') {
                                    this.leavesAfterSaved(alloc, dataObj);
                                }
                            });
                        }
                    } else if (
                        parseInt(index + 1, 10) === parseInt(projects.length, 10) &&
                        this.weekObj.code === 'SAVED' &&
                        NBL === true &&
                        dataObj.type !== 'NBL'
                    ) {
                        projectsModel.forEach(prj => {
                            if (prj.code === 'NBL') {
                                NBL = false;
                            }
                        });
                        if (NBL === true) {
                            this.timesheetData.allocations.forEach(alloc => {
                                if (alloc.wbs.project.type.key === 'NBL') {
                                    this.leavesAfterSaved(alloc, dataObj);
                                }
                            });
                        }
                    }
                }
                let projObj = {
                    code: dataObj.code,
                    allocationId: dataObj.allocationId,
                    name: dataObj.name,
                    tooltip: dataObj.tooltip,
                    startDate: dataObj.startDate,
                    endDate: dataObj.endDate,
                    attachment: dataObj.attachment,
                    download: dataObj.download,
                    hours: dataObj.hours,
                    type: dataObj.type,
                    totalHours: dataObj.totalHours,
                    status: dataObj.status,
                    actorImage: dataObj.actorImage.length ? dataObj.actorImage : '',
                    allocationSummary: dataObj.allocationSummary
                };
                if (condition) {
                    projectsModel.push(projObj);
                } else {
                    this.leaveTypes.push(projObj);
                }
            });
            if (this.resourceDetails && this.resourceDetails.employmentTypeCode) {
                const resource = this.resourceDetails.employmentTypeCode.key;
                const plIndex = this.leaveTypes.findIndex(x => x.type === 'PDL');
                if (plIndex !== -1 && (resource === 'SC' || resource === 'DC') && !this.resourceCode.includes('IN')) {
                    this.leaveTypes.splice(plIndex, 1);
                }
                /* Commented on purpose, will be removed while before merging the PR  */
                // const nblIndex = this.leaveTypes.findIndex(x => x.type === 'NBL');
                // if (nblIndex !== -1 && (resource === 'SC' || resource === 'DC') && !this.resourceCode.includes('IN')) {
                //     this.leaveTypes.splice(nblIndex, 1);
                // }
            }
        }
        return projectsModel;
    }

    calculateProjectHours(a) {
        let hours = 0;
        if (a && a.length > 0) {
            for (let i = 0; i < a.length; i++) {
                let h = Number(a[i].hour);
                if (isNaN(h)) {
                    hours += 0;
                } else {
                    hours += h;
                }
            }
        }
        return hours;
    }
    calculateDayHours(p, indx, check) {
        let hourss: any = 0;
        let h, codeLeave;
        if (p && p.length > 0) {
            for (let i = 0; i < p.length; i++) {
                h = Number(p[i].hours[indx].hour);
                codeLeave = p[i].code.substring(0, 3);
                h = Number(p[i].hours[indx].hour);
                if (isNaN(h)) {
                    hourss += 0;
                } else {
                    hourss += h;
                }
            }
        }
        if (check) {
            hourss = hourss > 8 ? hourss + '**' : hourss;
            hourss.toString();
        }
        return hourss;
    }
    checkIfCommentMandatory(p, indx) {
        let check = true;
        let hourss: any = 0;
        if (p && p.length > 0) {
            for (let i = 0; i < p.length; i++) {
                let h = parseInt(p[i].hours[indx].hour, 10);
                if (h > 8) {
                    check = false;
                }
            }
        }
        return check;
    }
    calculateTotalHours(p) {
        let hours = 0;
        if (p && p.length > 0) {
            for (let i = 0; i < p.length; i++) {
                if (p[i] && p[i].hours.length > 0) {
                    for (let j = 0; j < p[i].hours.length; j++) {
                        let h = Number(p[i].hours[j].hour);
                        if (isNaN(h)) {
                            hours += 0;
                        } else {
                            hours += h;
                        }
                    }
                }
            }
        }
        return hours;
    }
    selectLeave(event) {
        if(this.canEdit) {
            this.leaveToAdd = this.leaveTypes.findIndex(l => {
                return l.name === event.source.value;
            });
            this.weekObj.projects.push(this.leaveTypes[this.leaveToAdd]);
            this.leaveTypes.splice(this.leaveToAdd, 1);
            this.isLeaveAdded = true;
            event.source.value = '';
            this.selectedLeave = '';
        }
    }
    getWeek(d, dowOffset) {
        dowOffset = typeof dowOffset === 'number' ? dowOffset : 0; // default dowOffset to zero
        let newYear = new Date(d.getFullYear(), 0, 1);
        let day = newYear.getDay() - dowOffset; // the day of week the year begins on
        day = day >= 0 ? day : day + 7;
        let daynum =
            Math.floor((d.getTime() - newYear.getTime() - (d.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;
        let weeknum;
        if (day < 4) {
            weeknum = Math.floor((daynum + day - 1) / 7) + 1;
            if (weeknum > 52) {
                let nYear = new Date(d.getFullYear() + 1, 0, 1);
                let nday = nYear.getDay() - dowOffset;
                nday = nday >= 0 ? nday : nday + 7;
                weeknum = nday < 4 ? 1 : 53;
            }
        } else {
            weeknum = Math.floor((daynum + day - 1) / 7);
        }
        return weeknum;
    }

    clearLeave(project) {
        if (this.timesheetData1) {
            this.resourceCode = this.timesheetData1;
        } else {
            this.resourceCode = sessionStorage.getItem('resourceCode');
        }
        if ((this.timesheetData.status.timesheetStatus.code === 'SAVED' && this.allocationLinks && this.allocationLinks.length) || (this.timesheetData.status.timesheetStatus.code === 'PARTIALLY_APPROVED_REJECTED_PENDING' && this.allocationLinks && this.allocationLinks.length) || (this.timesheetData.status.timesheetStatus.code === 'REJECTED' && this.allocationLinks && this.allocationLinks.length) || (this.timesheetData.status.timesheetStatus.code === 'PARTIALLY_APPROVED' && this.allocationLinks && this.allocationLinks.length) || (this.timesheetData.status.timesheetStatus.code === 'PARTIALLY_APPROVED_REJECTED' && this.allocationLinks && this.allocationLinks.length) || (this.timesheetData.status.timesheetStatus.code === 'PARTIALLY_APPROVED_PENDING' && this.allocationLinks && this.allocationLinks.length) || (this.timesheetData.status.timesheetStatus.code === 'PARTIALLY_REJECTED_PENDING' && this.allocationLinks && this.allocationLinks.length) ||
            ((this.timesheetData.status.timesheetStatus.code === 'SUBMITTED' && this.recall === true) && this.allocationLinks && this.allocationLinks.length) ||
            (this.timesheetData.status.timesheetStatus.code === 'PARTIALLY_SUBMITTED' && this.allocationLinks && this.allocationLinks.length)) {
            this.allocationLinks.forEach(element => {
                if (project.allocationId === element.allocId) {
                    this.timesheetService.deleteMethodRequest(element.href).subscribe(response => {
                        this.getTimeSheetDataById(parseInt(this.timesheetData.timesheet.timesheetId, 10), this.resourceCode);
                        this.getWeekPendingData(this.since_months);
                    });
                } else {
                    let leavesToRemove = this.weekObj.projects.findIndex(l => {
                        return l.name === project.name;
                    });
                    this.weekObj.projects.splice(leavesToRemove, 1);
                    this.leaveTypes.push(project);
                    if (this.weekObj.projects.length === 2) {
                        this.isLeaveAdded = false;
                    }
                }
            });
        } else {
            let leavesToRemove = this.weekObj.projects.findIndex(l => {
                return l.name === project.name;
            });

            this.weekObj.projects.splice(leavesToRemove, 1);
            this.leaveTypes.push(project);
            if (this.weekObj.projects.length === 2) {
                this.isLeaveAdded = false;
            }
        }
        for (let j = 0; j < project.hours.length; j++) {
            project.hours[j].hour = 0;
        }
    }
    focusOutFunction(event, flag, dateobj) {
        let target, id, ids, projectID, dayID;
        if (event) {
            target = event.target || event.srcElement;
            id = target.id;
            ids = target.id.split('-');
            projectID = ids[0];
            dayID = ids[1];
            if (this.weekObj.projects[projectID].hours[dayID].hour === '' || this.weekObj.projects[projectID].hours[dayID].hour === '0') {
                this.weekObj.projects[projectID].hours[dayID].hour = 0;
            }
            let prj_len = this.weekObj.projects.length;
            let total = 0;
            for (let i = 0; i < prj_len; i++) {
                if (this.weekObj.projects[i].hours[dayID].hour === '.') {
                    this.weekObj.projects[i].hours[dayID].hour = '0';
                } else if (this.weekObj.projects[i].hours[dayID].hour === '.5') {
                    this.weekObj.projects[i].hours[dayID].hour = '0.5';
                } else if (this.weekObj.projects[i].hours[dayID].hour.toString().indexOf('.') !== -1 && this.weekObj.projects[i].hours[dayID].hour.length === 2) {
                    this.weekObj.projects[i].hours[dayID].hour = parseInt(this.weekObj.projects[i].hours[dayID].hour, 10);
                }
                if (parseInt(this.weekObj.projects[i].hours[dayID].hour, 10) !== 0 && parseInt(this.weekObj.projects[i].hours[dayID].hour, 10) < 24) {
                    total = total + parseInt(this.weekObj.projects[i].hours[dayID].hour, 10);
                    if (total > 24) {
                        this.weekObj.projects[projectID].hours[dayID].hour = 0;
                        event.stopPropagation();
                        this.jsAlertReplaceMessage = 'You are not allowed to enter more than 24 total day hours';
                        this.modalService.open(this.contentModal);
                        return false;
                    }
                } else if (parseInt(this.weekObj.projects[i].hours[dayID].hour, 10) > 24) {
                    total = total + parseInt(this.weekObj.projects[i].hours[dayID].hour, 10);
                    if (total > 24) {
                        this.weekObj.projects[projectID].hours[dayID].hour = 0;
                        event.stopPropagation();
                        this.jsAlertReplaceMessage = 'You are not allowed to enter more than 24 total day hours on project ' + this.weekObj.projects[i].name;
                        this.modalService.open(this.contentModal);
                        return false;
                    }
                }
            }

            if ((this.weekObj.projects[projectID].type === 'UPL' || this.weekObj.projects[projectID].type === 'PDL')) {
                let leaveTotal = 0;
                for (let i = 0; i < prj_len; i++) {
                    if (parseInt(this.weekObj.projects[i].hours[dayID].hour, 10) !== 0 && parseInt(this.weekObj.projects[i].hours[dayID].hour, 10) < 24 && (this.weekObj.projects[i].type === 'UPL' || this.weekObj.projects[i].type === 'PDL')) {
                        leaveTotal = leaveTotal + parseInt(this.weekObj.projects[i].hours[dayID].hour, 10);
                        if (leaveTotal > 8) {
                            event.stopPropagation();
                            this.jsAlertReplaceMessage = `Paid and Unpaid Leave cannot be more than 8 hours for the same day for the date ${ this.datePipe.transform(dateobj, DATE_FORMATE) }`;
                            this.modalService.open(this.contentModal);
                            this.weekObj.projects[projectID].hours[dayID].hour = 0;
                            return false;
                        }

                    }
                }
                // if (this.weekObj.projects[projectID].type === 'UPL' && this.weekObj.projects[projectID].hours[dayID].hour > 0) {
                //     for (let i = 0; i < prj_len; i++) {
                //         if (this.weekObj.projects[i].type === 'PDL' && this.weekObj.projects[i].hours[dayID].hour > 0) {
                //             event.stopPropagation();
                //             this.jsAlertReplaceMessage = 'Paid and Unpaid Leave cannot be applied for the same day';
                //             this.modalService.open(this.contentModal);
                //             this.weekObj.projects[projectID].hours[dayID].hour = '';
                //         }
                //     }
                // }
                // if (this.weekObj.projects[projectID].type === 'PDL' && this.weekObj.projects[projectID].hours[dayID].hour > 0) {
                //     for (let i = 0; i < prj_len; i++) {
                //         if (this.weekObj.projects[i].type === 'UPL' && this.weekObj.projects[i].hours[dayID].hour > 0) {
                //             event.stopPropagation();
                //             this.jsAlertReplaceMessage = 'Paid and Unpaid Leave cannot be applied for the same day';
                //             this.modalService.open(this.contentModal);
                //             this.weekObj.projects[projectID].hours[dayID].hour = '';
                //         }
                //     }
                // }

                // if (this.weekObj.projects[projectID].type === 'PDL' && this.weekObj.projects[projectID - 1].type === 'UPL') {
                //     if (this.weekObj.projects[projectID].hours[dayID].hour > 0 && this.weekObj.projects[projectID - 1].hours[dayID].hour > 0) {
                //         event.stopPropagation();
                //         this.jsAlertReplaceMessage = 'Paid and Unpaid Leave cannot be applied for the same day';
                //         this.modalService.open(this.contentModal);
                //         this.weekObj.projects[projectID].hours[dayID].hour = '';
                //     }
                // }

                if (this.weekObj.projects[projectID].hours[dayID].hour === '') {
                    this.weekObj.projects[projectID].hours[dayID].hour = 0;
                }

                if (this.weekObj.projects[projectID].hours[dayID].hour > 24) {
                    this.weekObj.projects[projectID].hours[dayID].hour = 0;
                    this.jsAlertReplaceMessage = 'You are not allowed to enter more than 24 hours';
                    this.modalService.open(this.contentModal);
                }
            } else if (event && flag) {
                let total = 0;
                if (event) {
                    target = event.target || event.srcElement;
                    id = target.id;
                    ids = target.id.split('-');
                    projectID = ids[0];
                    dayID = ids[1];
                    let leaveValueCheck = false;
                    for (let i = 0; i < this.weekObj.projects.length; i++) {
                        if (this.weekObj.projects[i].hours[dayID].hour === '') {
                            total += 0;
                            this.weekObj.projects[i].hours[dayID].hour = 0;
                        } else {
                            total += parseInt(this.weekObj.projects[i].hours[dayID].hour, 10);
                        }

                        let allocTSStatus = [];
                       let allocTS =  this.weekObj.timesheet.allocationWiseTimeSheets;
                       allocTS.forEach(loop=> {
                        if (loop.leaveType) {
                            allocTSStatus.push(loop.status.timesheetStatus.code);
                        }
                       });
                        if ((this.weekObj.projects[i].type === 'UPL' || this.weekObj.projects[i].type === 'PDL')) {
                            if (allocTSStatus[0] !== 'APPROVED' && allocTSStatus[0] !== 'SUBMITTED')
                                if (this.weekObj.projects[i].hours[dayID].hour !== 0) {
                                    leaveValueCheck = true;
                                }
                        }
                    }

                    if (!(total <= this.weekObj.dailyStandardHours) && leaveValueCheck) {
                        this.weekObj.projects[projectID].hours[dayID].hour = 0;
                        event.stopPropagation();
                        this.jsAlertReplaceMessage = 'You are not allowed to enter more than 8 total day hours on leave day';
                        this.modalService.open(this.contentModal);
                    }
                }
            }
        }
    }
    public popupTrigger(num): void {
        if (num) {
            let target = num.target || num.srcElement;
            let id = target.id;
            let ids = target.id.split('-');
            let projectID = ids[0];
            let dayID = ids[1];
            if (this.weekObj.projects[projectID].hours[dayID].hour === 0 || this.weekObj.projects[projectID].hours[dayID].hour === '0') {
                this.weekObj.projects[projectID].hours[dayID].hour = '';
            }
        }
        this.popoverList.forEach(popover => {
            if (popover.isOpen()) {
                popover.close();
            }
        });
    }

    public popupTriggerTotal(): void {
        this.popoverList.forEach(popover => {
            if (popover.isOpen()) {
                popover.close();
            }
        });
    }

    changeListener($event, allocationId) {
        let _validFileExtensions = ['jpg', 'jpeg', 'bmp', 'png', 'xls', 'xlsx', 'pdf', 'doc', 'docx', 'txt'];
        let files = $event.target.files;
        for (let i = 0; i < files.length; i++) {
            let file: File = files[i];
            let fileType = file.name.substring(file.name.lastIndexOf('.') + 1);
            if (_validFileExtensions.indexOf(fileType.toLowerCase()) === -1) {
                event.stopPropagation();
                this.jsAlertReplaceMessage =
                    "Please give valid file type. Type supported ['jpg', 'jpeg', 'bmp', 'png', 'xls', 'xlsx', 'pdf', 'doc', 'docx', 'txt']";
                this.modalService.open(this.contentModal);
                this.fileExtensionFlag = true;
                return;
            } else {
                this.fileExtensionFlag = false;
            }

            if (file && file.size >= 10024000) {
                event.stopPropagation();
                this.jsAlertReplaceMessage = 'File size is exeeded to 5 MB. Please choose a file below 5 MB';
                this.modalService.open(this.contentModal);
                this.resumeSizeFlag = true;
                return;
            } else {
                this.resumeSizeFlag = false;
            }

            if (file && file.name.length >= 100) {
                event.stopPropagation();
                this.jsAlertReplaceMessage = 'File name is too long. File name should be less than 100 characters';
                this.modalService.open(this.contentModal);
                this.resumeLengthFlag = true;
                return;
            } else {
                this.resumeLengthFlag = false;
            }
            if (file && file.name.length) {
                this.imageUploadFlag = true;
            }
            this.fileUploadedAllocationId.push(allocationId);

            this.isLoading=true;
            let obj = {
                filename: file.name,
                allocationId: (allocationId),
                data: ''
            };
            let myReader: FileReader = new FileReader();
            myReader.onload = e => {
                obj.data = myReader.result as string;
                this.resumeData.push(obj);
                this.jsAlertReplaceMessage = 'Attachment Uploaded';
                this.modalService.open(this.contentModal);
                this.attachmentUploadFlag = true;
                this.isLoading=false;
            };
            myReader.readAsDataURL(file);
         
        }
    }

    recallTimesheet () {
        this.checkIftimesheetModified('recall');
        this.timesheetService.recallTimesheet(this.recallTimesheetObj, this.timesheetData.timesheet.timesheetId, this.resourceCode).subscribe(res => {
        });
        this.recall = true;
    }

    getCheckboxes() {
        this.weekObj.daysData.filter(x => x.checked === true).map(x => x);
    }

    updateDelegateManager(allocationsArray, selectedResource) {
        allocationsArray.forEach((item) => {
            if (item.wbs.project.allocationedResourceDelegateManagers != null && item.resource.code === selectedResource) {
                for (let key in item.wbs.project.allocationedResourceDelegateManagers) {
                    if (key === selectedResource) {
                        // this.delegateManagerArr.push(item.wbs.project.allocationedResourceDelegateManagers[key])
                        console.log("Key", item.wbs.project.allocationedResourceDelegateManagers[key]);
                    }
                }
                // this.delegateManagerArr.push(item.wbs.project.allocationedResourceDelegateManagers)
            }
        })
        // console.log(this.delegateManagerArr);
    }

    calenderOpen(e, scrollTop) {
        this.dateref = e;
        if (scrollTop >= 289) {
            document.getElementById(e._overlay._overlayContainer._containerElement.lastChild.id).style.top = '-1.6875px';
        }
    }

    async setCanEdit() {
        this.canEdit = !this.router.url.includes('/resource/view-resource') || (await this.principal.hasAnyAuthority(['RESOURCES_TIMESHEET_EDIT']));
    }

    hasAuthority() {
        return !this.canEdit;
    }
}

@Component({
    selector: 'jhi-dialog-confirm-body',
    templateUrl: './dialog-confirm-body.component.html',
    styles: [
        `.confirm-submit {
      background-color: #0EB3F7;
      color: white;
    }
    .cancel-submit{
      background-color: lightgray;
    }.remain_warn{
        margin: 0;
        text-align: right;
        position: relative;
        top: -15px;
        color: #999;
    }`
    ]
})
export class DialogConfirmBodyComponent {
    remainingcommentLen = 250;
    constructor(public dialogRef: MatDialogRef<DialogConfirmBodyComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }
    onNoClick(): void {
        this.dialogRef.close();
        this.dialogRef.componentInstance.data.isSubmitted = false;
    }
    submitted(): void {
        this.dialogRef.componentInstance.data.isSubmitted = true;
    }
    lenChar($event) {
        this.remainingcommentLen = 250 - $event.target.value.length;
    }
}
