<div>
    <p class="group daterange-pending-since" aria-label="ToolTip" *ngIf="WeekTimeSheetObj && WeekTimeSheetObj.length">
        <jhi-pending-component [month]="monthObj.currentMonth" [dateRange]="dateRange" [monthPendingSince]="monthPendingSince"
            (onDatePicked)="routeWeekTS($event)"></jhi-pending-component>
    </p>
</div>
<div class="main-container color-code" id="prevTSView" *ngIf="WeekTimeSheetObj && WeekTimeSheetObj.length">
    <div class="row week-name-row">
        <div class="col-md-2 col-sm-12 ">
            <p class="group" aria-label="ToolTip" style="padding: 0px;">
                <span class="from_to_text">From</span>
                <span class="week-date">{{fromDateString}}</span>
                <input matInput [matDatepicker]="fromDate" [(ngModel)]="fromDateModel"
                    class="mat-date-input" (dateChange)="updateTimesheetDataFrom($event)" [max]="toMaxDate">
                <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                <mat-datepicker #fromDate></mat-datepicker>
            </p>
        </div>
        <div class="col-md-2 col-sm-12 ">
            <p class="group" aria-label="ToolTip" style="padding: 0px;">
                <span class="from_to_text">To</span>
                <span class="week-date">{{toDateString}}</span>
                <input matInput [matDatepicker]="toDate" (dateChange)="updateTimesheetDataTo($event)" [(ngModel)]="toDateModel"
                    class="mat-date-input" [min]="fromMinDate">
                    <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                    <mat-datepicker #toDate></mat-datepicker>
            </p>
        </div>
        <div class="col-md-4 col-sm-12">
        </div>
        <div class="col-md-6 col-sm-12"></div>
    </div>
    <mat-divider></mat-divider>
    <div class="row Desktop_timesheet" *ngIf="WeekTimeSheetObj && WeekTimeSheetObj.length">
        <div class="col-md-12" *ngFor="let weekDataTS of WeekTimeSheetObj; let i = index;">
            <div class="timesheet-weekname-div">
                <span class="timesheet-weekName" (click)="weekDataTS.weekName  && pickDate(weekDataTS.startDate)">
                    {{ weekDataTS.weekName }}
                </span>
            </div>
            <div class="timesheet-status projectWise-status daterange-status">
                <p>
                    <span class="status-code"><b>Status: <span class="status-color-{{weekDataTS.code}}">{{weekDataTS.status}}</span> </b></span>
                    
                </p>
                <div *ngIf="weekDataTS.code && weekDataTS.description">
                    <span><b>Description: </b>{{weekDataTS.description}}</span>
                </div> 
                <span class="timesheet-lastUpdatedOn">Last Updated On: {{weekDataTS.lastUpdatedOn}}</span>
            </div>
            <jhi-week-timesheet-view [weekObj]="weekDataTS"></jhi-week-timesheet-view>
            <p></p>
        </div>
        <div class="col-md-6"></div>
        <div class="col-md-6 custom-pagination" *ngIf="searchTableDataPage">
            <span class="text">Items per page:&nbsp;</span>
            <mat-form-field>
                <mat-select [(ngModel)]="paginationSelection" [ngModelOptions]="{standalone: true}" (selectionChange)="paginationFilter()">
                    <mat-option [value]="pagination[0]">2</mat-option>
                    <mat-option [value]="pagination[1]" *ngIf="searchTableDataPage.totalRecords >= 2">4</mat-option>
                    <mat-option [value]="pagination[2]" *ngIf="searchTableDataPage.totalRecords >= 4">6</mat-option>
                    <mat-option [value]="pagination[3]" *ngIf="searchTableDataPage.totalRecords >= 6">8</mat-option>
                    <mat-option [value]="pagination[4]" *ngIf="searchTableDataPage.totalRecords >= 8">10</mat-option>
                    <mat-option [value]="pagination[5]" *ngIf="searchTableDataPage.totalRecords >= 10">12</mat-option>
                    <mat-option [value]="pagination[6]" *ngIf="searchTableDataPage.totalRecords >= 12">14</mat-option>
                    <mat-option [value]="pagination[7]" *ngIf="searchTableDataPage.totalRecords >= 14">16</mat-option>
                </mat-select>
            </mat-form-field>
            <span class="pageNumber-indicator">
                {{(searchTableDataPage.currentPage*paginationSelection) + 1}}-
                <span *ngIf="((searchTableDataPage.currentPage+1)*paginationSelection) >= searchTableDataPage.totalRecords">{{searchTableDataPage.totalRecords}}</span>
                <span *ngIf="((searchTableDataPage.currentPage+1)*paginationSelection) < searchTableDataPage.totalRecords">{{(searchTableDataPage.currentPage+1)*paginationSelection}}</span>
                of
                <span>{{searchTableDataPage.totalRecords}}</span>
            </span>
            <div class="next-prev">
                <span (click)="jumpPageTo(0)" [ngClass]="{'left-pipe': true,'disable-click': searchTableDataPage.currentPage === 0,'enable-click': searchTableDataPage.currentPage >= 1 }">
                    <span class="pipe">|</span>
                    <i class="fa fa-angle-left pipe-left" aria-hidden="true"></i>
                </span>
                <i (click)="jumpPageTo(searchTableDataPage.currentPage-1)" [ngClass]="{'fa': true, 'fa-angle-left': true, 'disable-click': !(searchTableDataPage.hasPrevious),'enable-click': (searchTableDataPage.hasPrevious) }"
                    aria-hidden="true"></i>
                <span>&nbsp;</span>
                <i (click)="jumpPageTo(searchTableDataPage.currentPage+1)" [ngClass]="{'fa': true, 'fa-angle-right': true, 'disable-click': !(searchTableDataPage.hasNext),'enable-click': (searchTableDataPage.hasNext) }"
                    aria-hidden="true"></i>
                <span (click)="jumpPageTo(searchTableDataPage.totalPages-1)" [ngClass]="{'right-pipe': true,'disable-click': searchTableDataPage.isLast,'enable-click': !searchTableDataPage.isLast }">
                    <i class="fa fa-angle-right pipe-right" aria-hidden="true"></i><span class="pipe">|</span>
                </span>
            </div>
        </div>
    </div>



    <div class="mobile_timesheet">
        <div class="timesheet_monthly_view">
            <!-- <h3>Weeks of {{monthObj.currentMonth}}</h3> -->
                <div class="sheet_view"  *ngFor="let weekDataTS of WeekTimeSheetObj; let i = index;">
                    <div class="week_view">
                        {{ weekDataTS.weekName }}
                    </div>
                    <div class="ttl_hrs">{{weekDataTS.timesheet.grandTotal | formatDate}}.00</div>
                    <div class="week_sheet_status"><span class="status-color-{{weekDataTS.code}}">{{weekDataTS.status}}</span></div>
                </div>
            </div>
    </div>
</div>