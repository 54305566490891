<div class="row parent_container_height">
  <div class="col-12">
    <h4 class="refer-friends">Refer Friends. Earn Rewards.</h4>
    <span *jhiHasAnyAuthority="'TALENT_ACQUISITION'">
      <button *ngIf="jobNotificationTab" class="schedule-btn" mat-raised-button color="primary" (click)="editMailNotification(editjobNotification)">Schedule
      </button>
    </span>
  </div>
  <mat-tab-group (selectedTabChange)="tabChanged($event)" [disableRipple]="true" [(selectedIndex)]="selectedIndex" class="col-12">
    <mat-tab label="OPEN POSITIONS ({{openPositionsTotal}})">
      <mat-card class="mat-card-class mat-elevation-z3">
      <!-- Search Text field -->
      <div class="col-sm-12 col-md-4 col-lg-4 float_columns">
        <mat-form-field class="form_field_class search-field-width">
          <span class="material-icons search-icon" matPrefix>&nbsp;search&nbsp; </span><input matInput
            [(ngModel)]="searchField" placeholder="Search for a Position" #input>
        </mat-form-field>
      </div>
      <!-- Locations -->
      <div class="col-sm-12 col-md-2 col-lg-2 float_columns">
        <mat-form-field class="form_field_class">
          <mat-select name="location" [(ngModel)]="jobLocation" placeholder="Location">
            <mat-option *ngFor="let locs of locationsData" [value]="locs">{{locs}} </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- Practices -->

        <div class="col-sm-12 col-md-2 col-lg-2 float_columns">
          <mat-form-field class="form_field_class">
            <mat-select name="practice" [(ngModel)]="practice" placeholder="Practices">
              <mat-option *ngFor="let p of practicesList" [value]="p.parentPractices">{{p.parentPractices}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
       <!-- Job Experience -->
        <div class="col-sm-12 col-md-2 col-lg-2 float_columns">
          <mat-form-field class="form_field_class">
            <input matInput (keypress)="validateNumber
            ($event)" [(ngModel)]="jobExperience" placeholder="Minimum Experience">
          </mat-form-field>
        </div>

      <div class="col-sm-12 col-md-2 col-lg-1 float_columns">
        <button class="search-button-row" mat-raised-button
          (click)="searchData()">Search</button>
      </div>
      <div class="col-sm-12 col-md-2 col-lg-1 float_columns">
        <button class="clear-button-row" mat-raised-button (click)="resetData()">Clear
          All</button>
      </div>
      <br>
      <!-- <div class="row float_columns"> -->
      <div class="col-12 float_columns" *ngIf="openPositionsDataSource.data.length == 0">
        <h4 class="no-records-found-class">No records
          found.</h4>
      </div>
      <!-- </div> -->
      <div class="col-12 float_columns table_columns">
        <mat-table [dataSource]="openPositionsDataSource" *ngIf="openPositionsDataSource.data.length > 0"
          class="mat-elevation-z0 table-class" matSort>
          <ng-container matColumnDef="srNo">
            <mat-header-cell *matHeaderCellDef class="cellColor"> Sr No.
            </mat-header-cell>
            <mat-cell *matCellDef="let row;let i=index"> {{i+1}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="jobTitle">
            <mat-header-cell *matHeaderCellDef class="cellColor"> Positions
            </mat-header-cell>
            <mat-cell *matCellDef="let row"> <span class="row-job-title" (click)="redirectTo(row)"> {{row.jobTitle}}
              </span>
              <span *ngIf="row.hotFlag">
                <button class="hot-flag-icon">HOT</button>
              </span>
              <br>
              <span>{{row.skills}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="locations">
            <mat-header-cell *matHeaderCellDef class="cellColor"> Locations
            </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.locations}}</mat-cell>
          </ng-container>
          <!-- new coloum added  -->
          <ng-container matColumnDef="practices">
            <mat-header-cell *matHeaderCellDef class="cellColor"> Practices
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span *ngIf="row.practices === null">
                N/A
              </span>
              <span #elseBlock>
                {{row.practices}}
              </span>
            </mat-cell>
          </ng-container>

            <!-- Experience -->
            <ng-container matColumnDef="expMin">
              <mat-header-cell *matHeaderCellDef class="cellColor"> Experience
              </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{row.expMin}}
                <span *ngIf="row.expMax === 0">+ Years</span>
                <span *ngIf="row.expMax !== 0"> - {{row.expMax}} Years</span>
              </mat-cell>
            </ng-container>
            <!-- Date created -->
            <ng-container matColumnDef="publishDate">
              <mat-header-cell *matHeaderCellDef class="cellColor"> Date Created
              </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{row.publishDate}} </mat-cell>
            </ng-container>

            <!-- Reward -->
            <ng-container matColumnDef="reward">
              <mat-header-cell *matHeaderCellDef class="cellColor"> Rewards
              </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{row.reward}} </mat-cell>
            </ng-container>

          <ng-container matColumnDef="makereferralButton">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <!-- <mat-input-underline mat-form-field-underline></mat-input-underline> -->
            <mat-cell *matCellDef="let row">
              <button mat-stroked-button class="referral-button" (click)="makeReferral(row)">Make a
                Referral</button>

            </mat-cell>

          </ng-container>


          <!-- <ng-container matColumnDef="share">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
           <mat-cell *matCellDef="let row">
              <button mat-icon-button [matMenuTriggerFor]="share" aria-label="share_icon_button">
                <span class="material-icons">
                  share
                </span>
              </button>
              <mat-menu #share="matMenu">
                <button mat-menu-item>
                  <span class="material-icons" style="vertical-align: middle;color: royalblue; font-size: 28px;">
                    facebook
                  </span> &nbsp;<span>Facebook</span>
                </button>

                <button mat-menu-item>
                  <span><img src="../../assets/tweet.png" alt="twitter"
                      style="width: 24px; position: relative;top: 10px;vertical-align: super;border-radius: 4px;"></span>&nbsp;
                  <span>Twitter</span>
                </button>

                <button mat-menu-item>
                  <span><img src="../../assets/images.png" alt="twitter"
                      style="width: 24px; position: relative;top: 10px;vertical-align: super;border-radius: 3px;"></span>
                  &nbsp;
                  <span>LinkedIn</span>
                </button>

                <button mat-menu-item>
                  <span class="material-icons" style="vertical-align: middle;color: darkgray;">
                    email
                  </span> &nbsp;
                  <span>Email</span>
                </button>
              </mat-menu>
            </mat-cell>
          </ng-container> -->

            <!-- Edit and delete options -->
            <ng-container matColumnDef="hotJobs">
              <mat-header-cell *matHeaderCellDef>
                <mat-checkbox checked (change)="hotJobsClicked($event)" style="color: white;vertical-align: text-top;">
                  Hot Jobs</mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <ng-container *jhiHasAnyAuthority="'TALENT_ACQUISITION'">
                  <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu">
                    <button (click)="openPositionsDataSources(row,editAllocationModel)" mat-menu-item>
                      <span>Edit</span>
                    </button>
                    <button (click)="deletejobss(row,deleteConfirm)" mat-menu-item>
                      <span>Delete</span>
                    </button>
                  </mat-menu>
                </ng-container>
              </mat-cell>
            </ng-container>
            <!-- On demand notification icon -->
            <ng-container matColumnDef="onDemand">
              <mat-header-cell *matHeaderCellDef> </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <ng-container *jhiHasAnyAuthority="'TALENT_ACQUISITION'">
                  <img class="on-demand-referral-icon" src="../../assets/referral (1).png" (click)="plusreferral(row)"
                    alt="notification">
                </ng-container>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns" class="header-css"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="matRow-css">
            </mat-row>
          </mat-table>
        </div>
        <div class="col-12 float_col_paginator">
          <mat-paginator #paginator [length]="openPositionsTotal" [showFirstLastButtons]="true"
            [pageSizeOptions]="[5,10,25,100]"></mat-paginator>
        </div>
      </mat-card>
      </mat-tab>
      <mat-tab label="MY REFERRALS ({{myReferralsTotal}})">
        <mat-card class="mat-card-class">
        <div class="col-12 float_columns table_columns">
          <h4 class="my-heading">My Referrals</h4>

        <div class="col-12 my-referrals-not-found" *ngIf="myReferralsDataSource.data.length == 0">
          <p>No referrals found.</p>
        </div>
        <mat-table [dataSource]="myReferralsDataSource" *ngIf="myReferralsDataSource.data.length > 0"
          class="mat-elevation-z0 table-class" matSort>

            <ng-container matColumnDef="mysrNo">
              <mat-header-cell *matHeaderCellDef class="cellColor"> Sr No.
              </mat-header-cell>
              <mat-cell *matCellDef="let row;let i=index"> {{i+1}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef class="cellColor"> Referral Name
              </mat-header-cell>
              <mat-cell *matCellDef="let row"> <span class="row-job-title">{{row.name}}</span> </mat-cell>
            </ng-container>

            <ng-container matColumnDef="jobTitle">
              <mat-header-cell *matHeaderCellDef class="cellColor"> Position
                Applied for
              </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{row.jobTitle}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="creation_date">
              <mat-header-cell *matHeaderCellDef class="cellColor"> Date Created
              </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{row.creation_date}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="myRewards">
              <mat-header-cell *matHeaderCellDef class="cellColor"> My Rewards
              </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{row.rewards}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="applicantStatus">
              <mat-header-cell *matHeaderCellDef class="cellColor"> Status
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <!-- <span *ngIf="row.applicantStatus === null; else elseBlock"> - </span> -->
                <span> {{row.applicantStatus}}</span>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns2" class="header-css">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns2;" class="matRow-css">
            </mat-row>
          </mat-table>
          <mat-paginator #paginator2 *ngIf="myReferralsDataSource.data.length > 0" [length]="myReferralsTotal"
            [showFirstLastButtons]="true" [pageSizeOptions]="[5,10,25,100]"></mat-paginator>
        </div>
      </mat-card>
      </mat-tab>
      <mat-tab label="UPLOAD JOBS" *jhiHasAnyAuthority="'TALENT_ACQUISITION'">
        <div *ngIf="showerrorMessage">
          <ngb-alert class="success-message" type="success" [dismissible]="true" (close)="showerrorMessage=false;">
            <strong>{{uploadSuccessMessage}}</strong>
          </ngb-alert>
      </div>
        <mat-card class="mat-card-class">
        <div class="col-12">
          <div class="dark-overlay" *ngIf="isLoading">
            <mat-spinner class="loader" strokeWidth="3" [diameter]="50"></mat-spinner>
        </div>
          <div [ngClass]="{'no-actions': isLoading}">
          <h4>Upload Jobs</h4>
          <div class="row upload-row">
            <div class="col-6 float-class">
              <input type="file" accept=".xlsx,.xls"
                id="job-upload" (change)="handleFileInput($event.target.files)" style="outline: none;" />
              <!-- <span  id="delete-icon" class="close-icon material-icons" (click)="removeFile($event)">close</span> -->
              <img style="margin-left: 12px;" id="file-upload" *ngIf="exxcelFileSelected" (click)="removeFile($event)"
                src="../../assets/delete.png" class="cancel-icon" alt="Cancel" />
                <mat-error *ngIf="!exxcelFileSelected">Please upload an excel file only
                </mat-error>
            </div>
            <div class="col-6 float-class upload-file-div">
              <button mat-raised-button (click)="uploadExcelFile()" class="submit-button-dialog"
                [disabled]="!exxcelFileSelected">Upload File</button>
            </div>
          </div>
          </div>
        </div>
      </mat-card>
      </mat-tab>
      <mat-tab label="JOB NOTIFICATION" *jhiHasAnyAuthority="'TALENT_ACQUISITION'">
        <mat-card class="mat-card-class">
      <!-- Search Text field -->
      <div class="col-sm-12 col-md-4 col-lg-4 float_columns">
        <mat-form-field class="form_field_class search-field-width">
          <span class="material-icons search-icon" matPrefix>&nbsp;search&nbsp; </span><input matInput
            [(ngModel)]="searchField" placeholder="Search for a Position" #input>
        </mat-form-field>
      </div>
      <!-- Practices -->

      <div class="col-sm-12 col-md-2 col-lg-2 float_columns">
        <mat-form-field class="form_field_class">
          <mat-select name="practice" [(ngModel)]="practice" placeholder="Practices">
            <mat-option *ngFor="let p of practicesList" [value]="p.parentPractices">{{p.parentPractices}} </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-2 col-lg-1 float_columns">
        <button class="search-button-row" mat-raised-button
          (click)="searchData()">Search</button>
      </div>
      <div class="col-sm-12 col-md-2 col-lg-1 float_columns">
        <button class="clear-button-row" mat-raised-button (click)="resetData()">Clear
          All</button>
      </div>
      <br>
      <div class="col-12 float_columns" *ngIf="mailDataSource.data.length == 0">
        <h4 class="no-records-found-class">No records
          found.</h4>
      </div>

      <!-- Sr No. -->
      <div class="col-12 float_columns table_columns">
        <mat-table #table matSort [dataSource]="mailDataSource" *ngIf="mailDataSource.data.length > 0"
          class="mat-elevation-z0 table-class">
          <ng-container matColumnDef="srNo" class="job-srNo">
            <mat-header-cell *matHeaderCellDef class="cellColor"> Sr No.
            </mat-header-cell>
            <mat-cell *matCellDef="let row;let i=index"> {{i+1}} </mat-cell>
          </ng-container>

          <!-- Job Title -->
          <ng-container matColumnDef="jobTitle">
            <mat-header-cell *matHeaderCellDef class="cellColor"> Positions
            </mat-header-cell>
            <mat-cell *matCellDef="let row"> <span class="row-job-title" (click)="redirectTo(row)"> {{row.jobTitle}}
              </span>
              <span *ngIf="row.hotFlag">
                <button class="hot-flag-icon">HOT</button>
              </span>
              <br>
              <span>{{row.skills}}</span>
            </mat-cell>
          </ng-container>
          <!-- Practices  -->
          <ng-container matColumnDef="practices">
            <mat-header-cell *matHeaderCellDef class="cellColor"> Practices
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span *ngIf="row.practices === null">
                N/A
              </span>
              <span #elseBlock>
                {{row.practices}}
              </span>
            </mat-cell>
          </ng-container>

          <!-- Date created -->
          <ng-container matColumnDef="publishDate">
            <mat-header-cell *matHeaderCellDef class="cellColor"> Date Created
            </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.publishDate}} </mat-cell>
          </ng-container>

          <!-- Notification date -->
          <ng-container matColumnDef="jobNotificationDate">
            <mat-header-cell mat-header-cell *matHeaderCellDef class="cellColor">Notification Date
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span *ngIf="row.jobNotificationDate === null">
                N/A
              </span>
              <span #elseBlock>
                {{row.jobNotificationDate}}
              </span>
            </mat-cell>
          </ng-container>
          <!-- Notification checked -->
          <ng-container matColumnDef="notificationType">
            <mat-header-cell *matHeaderCellDef class="cellColor" class="txt-align-class">
              <mat-checkbox class="example-margin" [checked]="isChecked()" [indeterminate]="isIndeterminate()"
                (change)="$event ? toggleAll($event) : null">
              </mat-checkbox>
            </mat-header-cell>

            <mat-cell *matCellDef="let row" class="txt-align-class">
              <mat-checkbox *ngIf="row.notifiedBefore" (click)="$event.stopPropagation()"
                [checked]="row.notifiedBefore" disabled>
              </mat-checkbox>
              <mat-checkbox *ngIf="!row.notifiedBefore" (click)="$event.stopPropagation()" (change)="$event ? toggle(row, $event) : null"
                [checked]="exists(row)">
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <!-- Unschedule notification -->
          <ng-container matColumnDef="unschedule">
            <mat-header-cell *matHeaderCellDef class="cellColor"> Unschedule
            </mat-header-cell>
            <mat-cell *matCellDef="let row"> 
              <span *ngIf="row.notifiedBefore">
              <button mat-raised-button color="primary" (click)="unscheduleJobNotification(row)">Unschedule</button></span>
             </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="mailDisplayedColumns" class="header-css"></mat-header-row>
          <mat-row *matRowDef="let row; columns: mailDisplayedColumns;" class="matRow-css">
          </mat-row>
        </mat-table>
      </div>
      <div class="col-12 float_col_paginator">
        <mat-paginator #paginator3 [length]="mailPositionsTotal" [showFirstLastButtons]="true"
          [pageSizeOptions]="[20,40,60,80,100]"></mat-paginator>
      </div>
    </mat-card>
    </mat-tab>
  </mat-tab-group>

    <ng-template #deleteConfirm let-delete="dismiss">
      <div class="modal-body view-mode modal-md">
        Are you sure that you want to delete this position?
      </div>
      <div class="modal-footer">
        <button mat-raised-button aria-label="Close" (click)="delete('Cross click')">Cancel</button>
        <button mat-raised-button color="primary" (click)="deletejob()">Delete</button>
      </div>
    </ng-template>

    <ng-template #editAllocationModel let-revise="dismiss">
      <div class="row">
        <div class="col-12">
          <span class="close-icon material-icons" (click)="revise('Cross click')">close</span>
        </div>
      </div>
      <div class="modal-body view-mode">
        <form [formGroup]="jobedit" novalidate>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-11 position-title">
                    <mat-form-field class="example-full-width">
                        <input matInput placeholder="Position Name" formControlName="jobTitle">
                    </mat-form-field>
                </div>
            </div>
          <div class="container">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6 float-class">
                        <mat-form-field class="example-full-width">
                            <input matInput formControlName="expMin" placeholder="MinExp" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 float-class">
                        <mat-form-field class="example-full-width">
                            <input matInput formControlName="expMax" placeholder="ExpMax" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 float-class">
                        <mat-form-field class="example-full-width">
                            <textarea style="height: 81px;" matInput formControlName="jobDesc" placeholder="JobDesc" ></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 float-class">
                        <p>
                            <mat-form-field class="example-full-width">
                                <input matInput formControlName="reward" placeholder="Reward">
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field class="example-full-width">
                                <mat-select name="location" formControlName="locationsField" (selectionChange)="selectPANIndia($event)" placeholder="Location" multiple>
                                    <mat-option value="Pan India">Pan India</mat-option>
                                    <mat-option *ngFor="let locs of excludePanIndia" [disabled]="disableOtherOptions" [value]="locs">{{locs}} </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </p>
                    </div>
					<div class="col-sm-12 col-md-6 col-lg-6 float-class">
						<p>
							<mat-form-field class="example-full-width">
								<textarea matInput formControlName="skills" placeholder="Skills"></textarea>
							</mat-form-field>
						</p>
					</div>
				</div>
            </div>
          </div>
        </form>
      </div>
  <div class="modal-footer">
    <button mat-raised-button color="primary" (click)="updatejob()">Update</button>
    <button mat-raised-button aria-label="Close"color="primary" (click)="revise('Cross click')">Cancel</button>
  </div>
</ng-template>
</div>
<div *ngIf="testSuccessMessage">
  <ngb-alert *ngIf="!closed" class="success-message" type="success" [dismissible]="true" (close)="closed=true">
    <strong>{{testSuccessMessage}}</strong>
  </ngb-alert>
</div>

<div *ngIf="testFailureMessage">
  <ngb-alert *ngIf="!errorClosed" class="success-message" type="danger" [dismissible]="true" (close)="errorClosed=true">
    <strong>{{testFailureMessage}}</strong>
  </ngb-alert>
</div>
<ng-template #editjobNotification let-revise="dismiss">
  <div class="row">
    <div class="col-12">
      <span class="close-icon material-icons" (click)="revise('Cross click')">close</span>
    </div>
  </div>
  <div class="modal-body view-mode">
    <div class="row">
      <div class="col-12">
        <form [formGroup]="jobNotificationForm" novalidate>
            <input type="text" matInput formControlName="jobTitle" readonly>
            <p></p>
            <div class="row">
              <div class="col-6 float-class">
                <p>
                  <mat-form-field>
                    <input matInput [matDatepicker]="picker1" required formControlName="weekStartDate" readonly="true" (dateChange)="startDateChange($event)" placeholder="Week Start Date">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                    <mat-error *ngIf="jobNotificationForm.get('weekStartDate').hasError('required')">This is a required field.</mat-error>
                  </mat-form-field>
                </p>
              </div>
              <div class="col-6 float-class">
                <p>
                  <mat-form-field>
                    <input matInput [matDatepicker]="picker2" required formControlName="weekEndDate" placeholder="Week End Date" readonly="true" [min]="minEndDate">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                    <mat-error *ngIf="jobNotificationForm.get('weekEndDate').hasError('required')">This is a required field.</mat-error>
                  </mat-form-field>
                </p>
              </div>
            </div>
        </form>
      </div>
    </div>
  </div>
    
<div class="modal-footer">
  <button mat-raised-button color="primary" [disabled]="!jobNotificationForm.valid" (click)="updateEmailNotification()">Schedule Job</button>
  <button mat-raised-button aria-label="Close"color="primary" (click)="revise('Cross click')">Cancel</button>
</div>
</ng-template>

<div *ngIf="scheduledNotificationMessage">
  <ngb-alert *ngIf="!scheduledClose" class="success-message" type="success" [dismissible]="true">
    <strong>{{scheduledNotificationMessage}}</strong>
  </ngb-alert>
</div>

<div *ngIf="unscheduledNotificationMessage">
  <ngb-alert *ngIf="!unscheduledClose" class="success-message" type="success" [dismissible]="true">
    <strong>{{unscheduledNotificationMessage}}</strong>
  </ngb-alert>
</div>