import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'jhi-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    setTimeout(() => {
      this.redirectToHome();
    }, 10000);
  }

  redirectToHome() {
    this.router.navigate(['']);
  }

  closeWindow() {
    let win = window.open('about:blank', '_self');
    win.close();
  }
}
