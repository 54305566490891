 
<div class="upload-leave-data col-10 float-right mr-0">
    <div class="col-12" *ngIf="showFileTypeError">
        <ngb-alert class="comp-off-message holiday-message" type="info" [dismissible]="true" (close)="closeErrorAndReset()">
          <strong><i class="fa-solid fa-circle-info"></i>Please select Excel file to uppload.</strong>
        </ngb-alert>
    </div>
    <h4 class="upload-leave-data-heading ml-3">Bulk Upload Designations </h4>
    <div class="row pb-5 col-12">
        <mat-card class="col-12 mr-12">
            <mat-card-header>
                <mat-card-title><h5>Upload File:</h5></mat-card-title>
            </mat-card-header>
            <form [formGroup]="uploadLeaveDataForm" #uploadDataForm="ngForm" class="col">
                <div class="upload_leave m-3">
                    <input type="file" hidden accept=".xlsx,.xls" formControlName="newFileUpload" id="leave-upload" (change)="handleFileInput($event)" style="outline: none;" />
                    <label for="leave-upload" class="custom-file-upload">
                        <i class="fas fa-plus-circle"></i> Upload All Designations data from CSV file
                    </label>
                    <span>{{displayFileName}}</span><i class="fa fa-trash pl-2 remove-icon" aria-hidden="true" *ngIf="isFileSelected" (click)="removeFile()"></i>
                    <mat-error *ngIf="incorrectFileFormatLeave">Please select file of .csv format.
                    </mat-error>
                </div>
                <mat-card-actions>
                    <button class="font-weight-bold" [disabled]="!uploadLeaveDataForm.valid || !isFileSelected" color="primary" mat-raised-button (click)="uploadFileData('designationsData')">Submit</button>
                </mat-card-actions>
            </form>
        </mat-card>
    </div>
</div>
<div class="dark-overlay" *ngIf="load">
    <mat-spinner class="loader" strokeWidth="3" [diameter]="50"></mat-spinner>
</div>