import { Component, OnInit, OnDestroy } from '@angular/core';
import { JhiEventManager } from 'ng-jhipster';
import { LoginService, Principal, Account, CSRFService } from 'app/core';
import { TimesheetService } from '../timesheet-submit/timesheet.service';
import { AllocationsService } from '../allocations/allocations.service';
import { ProfileService } from '../layouts/profiles/profile.service';
import { ResourceService } from '../resource/resource.service';

import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { Title } from '@angular/platform-browser';
import { ReferralsService } from 'app/Referrals/referrals.service';
import { MatDialog } from '@angular/material/dialog';
import { makeReferralPopup } from 'app/Referrals/submit-referral/submit-referral.component';
import { CommonDataService } from 'app/common-data.service';
@Component({
    selector: 'jhi-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.css'],
    providers: [TimesheetService, AllocationsService, ProfileService]
})
export class HomeComponent implements OnInit, OnDestroy {
    account: Account;
    resourceCode: any;
    myTimesheetPendingCount = 0;
    myTimesheetPendingWeekStatus: any;
    resourceDetails: any;
    myAllocations: any;
    projectAllocatedCount = 0;
    projectAllocatedOccupancy = 0;
    imageUrl = '';
    date;
    profileServiceSubscription: Subscription;
    allocationsServiceSubscription: Subscription;
    timesheetServiceSubscription: Subscription;
    resourceServiceSubscription: Subscription;
    eventManagerSubscription: Subscription;
    apertureAdminResourceCode = 'IN10000'; // This is default resource code for itrack/aperture admin
    browserVersion: any;
    browserName: any;
    allJobs: any;
    allJobsLength: any;

    constructor(
        private titleService: Title,
        private principal: Principal,
        private loginService: LoginService,
        private eventManager: JhiEventManager,
        private timesheetService: TimesheetService,
        private allocationsService: AllocationsService,
        private profileService: ProfileService,
        private resourceService: ResourceService,
        private router: Router,
        private referralService: ReferralsService,
        public dialog: MatDialog,
        private csrfService: CSRFService,
        private commonService: CommonDataService
    ) {
        this.titleService.setTitle('aperture');
    }

    ngOnInit() {
        this.browserCheck();
        this.principal.identity().then(account => {
            this.account = account;
            this.resourceCode = account.resourceCode;
            if (this.resourceCode !== this.apertureAdminResourceCode) {
                if (account.imageUrl) {
                    this.profileServiceSubscription = this.profileService.getResourceImage(account.imageUrl).subscribe(imageDetails => {
                        this.imageUrl = imageDetails;
                        this.profileService.profileImageUrl = imageDetails;
                    });
                }
                let tableDataParams = {};
                tableDataParams['resource'] =  this.commonService.encrypt(account.resourceCode);
                tableDataParams['status']=  'ACTIVE';
                tableDataParams['size'] =  '10';
                tableDataParams['isAllocationInfo'] =  false;
                this.allocationsServiceSubscription = this.allocationsService.searchAllocationsListing(tableDataParams).subscribe(allocations => {
                    this.myAllocations = allocations.content;
                    if (this.myAllocations && this.myAllocations.length > 0) {
                        this.projectAllocatedCount = this.myAllocations.length;
                        this.myAllocations.forEach(allocation => {
                            this.projectAllocatedOccupancy = this.projectAllocatedOccupancy + allocation.occupancy;
                        });
                    }
                });
                this.timesheetServiceSubscription = this.timesheetService.getWeekPendingSince(1).subscribe(pendingTimesheets => {
                    this.myTimesheetPendingCount = pendingTimesheets.pendingCount;
                    this.myTimesheetPendingWeekStatus = pendingTimesheets.weekStatuses;
                });
                this.resourceServiceSubscription = this.resourceService.getResourceDetails(this.resourceCode).subscribe(response => {
                    this.resourceDetails = response;
                });
            }
            let data = {
                resourceCode: this.commonService.encrypt(account.resourceCode),
                browserName: this.browserName,
                browserVersion: this.browserVersion
            };
            this.resourceService.saveBrowserDetails(data).subscribe(details => {
            });
        });
        this.csrfService.getCSRF()
        // this.referralService.getAlljobs('all', 'all', 'all','all',true).subscribe(res => {
        //     this.allJobs=res.jobList;
        //     this.allJobsLength=res.jobList.length;
        // });
        // this.registerAuthenticationSuccess();
    }

    registerAuthenticationSuccess() {
        this.eventManagerSubscription = this.eventManager.subscribe('authenticationSuccess', message => {
            this.principal.identity().then(account => {
                this.account = account;
            });
        });
    }
    redirectTo(jobs) {
        let newjobName = jobs.jobTitle.replace(/\s+/g, '-');
        let url = `https://www.gspann.com/jobs/open-positions/${newjobName}`;
        window.open(url, '_blank');
    }

    makeReferral(data): void {
        let dialogRef = this.dialog.open(makeReferralPopup, {
            width: '650px', height: '550px',
            data: data,
        });
        // this.router.navigate(['/referrals/submit-referrals']);
    }
    getTimeSheetDataWiseData(event) {
        this.date = event.week.weekStartDate;
        sessionStorage.setItem('data', this.date);
        this.router.navigate(['/timesheet-view'], { skipLocationChange: true });
    }
    getmfadocument() {
        this.resourceService.mfadownloadReport().subscribe(searchMD => {
            saveAs(searchMD, 'Registering Microsoft Authenticator.pdf');
        });
    }

    isAuthenticated() {
        return this.principal.isAuthenticated();
    }

    login() {
        this.loginService.login();
    }

    exportBrowserReoprt() {
        this.resourceService.exportBrowserReport().subscribe(searchMD => {
            saveAs(searchMD, 'ResourceBrowserReport.xlsx');
        });
    }

    serviceworkerDeregisration() {
        console.log('Deregistration');
        navigator.serviceWorker.getRegistrations()
            .then(registrations => {
                registrations.forEach(registration => {
                    registration.unregister();
                });
            });

        navigator.serviceWorker.getRegistrations().then(function (registrations) {
            for (let registration of registrations) {
                registration.unregister();
            }
        });

        if (window.navigator && navigator.serviceWorker) {
            navigator.serviceWorker.getRegistrations()
                .then(function (registrations) {
                    for (let registration of registrations) {
                        registration.unregister();
                    }
                });
        }

        if ('caches' in window) {
            caches.keys()
                .then(function (keyList) {
                    return Promise.all(keyList.map(function (key) {
                        return caches.delete(key);
                    }));
                });
        }

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                for (let registration of registrations) {
                    // unregister service worker
                    registration.unregister();
                }
            });
        }
    }

    browserCheck() {
        'use strict';
        let module = {
            options: [],
            header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor],
            dataos: [
                { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
                { name: 'Windows', value: 'Win', version: 'NT' },
                { name: 'iPhone', value: 'iPhone', version: 'OS' },
                { name: 'iPad', value: 'iPad', version: 'OS' },
                { name: 'Kindle', value: 'Silk', version: 'Silk' },
                { name: 'Android', value: 'Android', version: 'Android' },
                { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
                { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
                { name: 'Macintosh', value: 'Mac', version: 'OS X' },
                { name: 'Linux', value: 'Linux', version: 'rv' },
                { name: 'Palm', value: 'Palm', version: 'PalmOS' }
            ],
            databrowser: [
                { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
                { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
                { name: 'Safari', value: 'Safari', version: 'Version' },
                { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
                { name: 'Opera', value: 'Opera', version: 'Opera' },
                { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
                { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
            ],
            init: function () {
                let agent = this.header.join(' '),
                    os = this.matchItem(agent, this.dataos),
                    browser = this.matchItem(agent, this.databrowser);
                return { os: os, browser: browser };
            },
            matchItem: function (string, data) {
                let i = 0,
                    j = 0,
                    html = '',
                    regex,
                    regexv,
                    match,
                    matches,
                    version;
                for (i = 0; i < data.length; i += 1) {
                    regex = new RegExp(data[i].value, 'i');
                    match = regex.test(string);
                    if (match) {
                        regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                        matches = string.match(regexv);
                        version = '';
                        if (matches) { if (matches[1]) { matches = matches[1]; } }
                        if (matches) {
                            matches = matches.split(/[._]+/);
                            for (j = 0; j < matches.length; j += 1) {
                                if (j === 0) {
                                    version += matches[j] + '.';
                                } else {
                                    version += matches[j];
                                }
                            }
                        } else {
                            version = '0';
                        }
                        return {
                            name: data[i].name,
                            version: parseFloat(version)
                        };
                    }
                }
                return { name: 'unknown', version: 0 };
            }
        };
        let e = module.init(),
            debug = '';
        debug += 'os.name = ' + e.os.name;
        debug += ' os.version = ' + e.os.version;
        debug += ' browser.name = ' + e.browser.name;
        debug += ' browser.version = ' + e.browser.version;
        debug += ' navigator.userAgent = ' + navigator.userAgent;
        debug += ' navigator.appVersion = ' + navigator.appVersion;
        debug += ' navigator.platform = ' + navigator.platform;
        debug += ' navigator.vendor = ' + navigator.vendor;
        this.browserName = e.browser.name;
        this.browserVersion = e.browser.version;
    }

    goToMyProfile(resourceCode) {
        this.commonService.resourceCode = resourceCode;
        this.router.navigate(['/resource/view-resource'], { skipLocationChange: true })
    }

    ngOnDestroy() {
        if (this.profileServiceSubscription) {
            this.profileServiceSubscription.unsubscribe();
        }
        if (this.allocationsServiceSubscription) {
            this.allocationsServiceSubscription.unsubscribe();
        }
        if (this.timesheetServiceSubscription) {
            this.timesheetServiceSubscription.unsubscribe();
        }
        if (this.eventManagerSubscription) {
            this.eventManagerSubscription.unsubscribe();
        }
    }
}
