<mat-card class='main-container logout text-center'>
    <div class="row">
        <div class="col-md-12">
            <p><i class="fa fa-ban fa-2x" aria-hidden="true"></i></p>
            <h1>Forbidden</h1>
            
            <h2>We are sorry you have no access to this page. Please contact your administrator.</h2>
        </div>
        <br/>
        <div class="col-md-12">
            <a id="closeWindow" (click)="redirectToHome()">Redirect to Home page in 10 secs.</a>
        </div>
        <div class="col-md-12">
            <a id="closeWindow" (click)="closeWindow()">Close Window</a>
        </div>
    </div>
</mat-card>
