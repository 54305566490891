import { Injectable } from '@angular/core';

@Injectable()
export class NotificationsService {
  notificationData: Array<any> = [];
  constructor() {}

  show(data) {
    let uniq = {};
    if (data.length) {
      this.notificationData = data.filter(obj => !uniq[obj.id] && (uniq[obj.id] = true));
    } else {
      this.notificationData = [];
    }
  }

  closeAlert(alert) {
    let index: number = this.notificationData.indexOf(alert);
    this.notificationData.splice(index, 1);
    localStorage.setItem('', JSON.stringify(this.notificationData));
  }

  clearAll() {
    this.notificationData = [];
    localStorage.removeItem('');
    localStorage.setItem('', JSON.stringify(this.notificationData));
  }
}
