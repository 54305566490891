import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'jhi-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
    panelOpenState = false;
    constructor(private titleService: Title) {
        this.titleService.setTitle(`aperture - FAQ's`);
    }

    ngOnInit() {
    }

}
