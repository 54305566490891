import { Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from 'app/core/login/login.service';
import { StateStorageService } from 'app/core/auth/state-storage.service';
import { tap } from 'rxjs/operators';

export class AuthExpiredInterceptor implements HttpInterceptor {
  constructor(private stateStorageService: StateStorageService, private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap(
      (event: HttpEvent<any>) => {},
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 && err.url && !err.url.includes('/api/account')) {
            let destination = this.stateStorageService.getDestinationState();
            if (destination !== null) {
              let to = destination.destination;
              let toParams = destination.params;
              if (to.name === 'accessdenied') {
                this.stateStorageService.storePreviousState(to.name, toParams);
              }
            } else {
              this.stateStorageService.storeUrl('/');
            }

            let loginService: LoginService = this.injector.get(LoginService);
            loginService.login();
          }
        }
      }
    ));
  }
}
