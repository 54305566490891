import { Directive, AfterViewInit, ElementRef, AfterContentInit } from '@angular/core';

@Directive({
  selector: '[jhiAutoFocus]'
})
export class AutoFocusDirective implements AfterContentInit {
  constructor(private el: ElementRef) {}

  ngAfterContentInit() {
    setTimeout(() => {
      this.el.nativeElement.focus();
    }, 0);
  }
}
