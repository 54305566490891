import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { map } from 'rxjs/operators';
import { enviornmentAperture } from 'environments/environment';

@Injectable()
export class AuthServerProvider {
    constructor(private http: HttpClient, @Inject(DOCUMENT) private document: any) {}

    logout(): Observable<any> {
        // logout from the server
        return this.http.get(enviornmentAperture.keycloakURL + '/realms/aperture/protocol/openid-connect/logout').pipe(map((response: HttpResponse<any>) => {
            // to get a new csrf token call the api
            // this.http.get(enviornmentAperture.apiUrl + 'api/account').subscribe(() => {}, () => {});
            this.document.location.href = 'https://login.microsoftonline.com/common/oauth2/logout';
            return response;
        })
        );
    }
}
