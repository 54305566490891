import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { enviornmentAperture } from 'environments/environment';
import { CommonDataService } from 'app/common-data.service';
let httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    observe: 'response' as 'response'
};
@Injectable()
export class TimesheetApprovalsService {
    constructor(private http: HttpClient, private CommonDataService: CommonDataService) { }
    getSearchMetaData(isDMCheckBoxClicked,date?): Observable<any> {
        let url;
        if (date) {
            url = 'api/v1/timesheets/approver/weekly/searchMetaData?date=' + date + '&isDMCheckBoxClicked=' + isDMCheckBoxClicked;
        } else {
            url = 'api/v1/timesheets/approver/weekly/searchMetaData?isDMCheckBoxClicked=' + isDMCheckBoxClicked;
        }
        return this.http.get(enviornmentAperture.apiUrl + url);
    }

    getSearchMetaDataDaterange(isDMCheckBoxClicked,fromDate?, toDate?): Observable<any> {
        let url;
        let str = '';
        if (fromDate) {
            str = str.length ? str + '&' : '?';
            str = str + 'fromDate=' + fromDate;
        }
        if (toDate) {
            str = str.length ? str + '&' : '?';
            str = str + 'tillDate=' + toDate;
        }
        if ((fromDate || toDate) && str && str.length) {
            url = 'api/v1/timesheets/approver/dateRange/searchMetaData' + str + '&isDMCheckBoxClicked=' + isDMCheckBoxClicked;
        } else {
            url = 'api/v1/timesheets/approver/dateRange/searchMetaData?isDMCheckBoxClicked=' + isDMCheckBoxClicked;
        }
        return this.http.get(enviornmentAperture.apiUrl + url);
    }
    getSearchMetaDataForMonth(isDMCheckBoxClicked,date?): Observable<any> {
        let url;
        if (date) {
            url = 'api/v1/timesheets/approver/monthly/searchMetaData?month=' + date + '&isDMCheckBoxClicked=' + isDMCheckBoxClicked;
        } else {
            url = 'api/v1/timesheets/approver/monthly/searchMetaData?isDMCheckBoxClicked=' + isDMCheckBoxClicked;
        }
        return this.http.get(enviornmentAperture.apiUrl + url);
    }

    bulkApproveOrReject(data): Observable<any> {
        return this.http.put(enviornmentAperture.apiUrl + 'api/v1/timesheets/bulkApproval', data, {}).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    putApproveOrReject(data): Observable<any> {
        let ur = data.uri + data.comments;
    const requesturl=ur.includes(enviornmentAperture.apiUrl)?ur:enviornmentAperture.apiUrl+ur
        return this.http.put(requesturl,{ observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    putRecallTimesheet(data): Observable<any> {
        let ur = data.uri + data.comments;
        return this.http.put(enviornmentAperture.apiUrl + ur, { observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    getHateoas(uri): Observable<any> {
        return this.http.get(uri, { responseType: 'text' as 'json' }).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    getReturnTimesheets(currentCheck, previousCheck, nextCheck, isDMCheckBoxClicked, resourceType?): Observable<any> {
        let filterData = JSON.parse(localStorage.getItem('filters'));
        let apifilters = '';
        apifilters = '?resourceType=' + resourceType;
        if (this.updateTimesheetData(filterData.datefilter) && currentCheck) {
            apifilters += '&date=' + this.updateTimesheetData(filterData.datefilter);
        } else if (this.updateTimesheetData(filterData.datefilter) && previousCheck) {
            apifilters += '&month=' + filterData.datefilter;
        } else if (this.updateTimesheetData(filterData.fromDate) && this.updateTimesheetData(filterData.toDate) && nextCheck) {
            apifilters += '&fromDate=' + filterData.fromDate;
            apifilters = apifilters + '&tillDate=' + filterData.toDate;
        }
            apifilters +='&isDMCheckBoxClicked=' + isDMCheckBoxClicked;
        if (filterData.resourceFilter && filterData.resourceFilter !== 'all') {
            apifilters += '&resource=' + this.CommonDataService.encrypt(filterData.resourceFilter);
        }
        if (filterData.ProjetFilter && filterData.ProjetFilter !== 'all') {
            apifilters += '&project=' + filterData.ProjetFilter;
        }
        if (filterData.statusFilter && filterData.statusFilter !== 'all') {
            apifilters += '&status=' + filterData.statusFilter;
        }
        if (filterData.page === 0 || parseInt(filterData.page, 10) >= 1) {
            apifilters += '&page=' + filterData.page;
        }
        if (filterData.size) {
            apifilters += '&size=' + filterData.size;
        }
        if (currentCheck) {
            return this.http.get(enviornmentAperture.apiUrl + 'api/v1/timesheets/approver/weekly' + apifilters, {}).pipe(map((response: HttpResponse<any>) => {
                return response;
            }));
        } else if (previousCheck) {
            return this.http.get(enviornmentAperture.apiUrl + 'api/v1/timesheets/approver/monthly' + apifilters, {}).pipe(map((response: HttpResponse<any>) => {
                return response;
            }));
        } else if (nextCheck) {
            return this.http.get(enviornmentAperture.apiUrl + 'api/v1/timesheets/approver/dateRange' + apifilters, {}).pipe(map((response: HttpResponse<any>) => {
                return response;
            }));
        }
    }

    updateTimesheetData(date?) {
        date = new Date(date);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();

        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        date = year + '-' + month + '-' + dt;
        return date;
    }
    export(tableDataParams) {
        tableDataParams.push({ key: 'isResourceTimesheet', value: true });
        let construct_uri = '';
        tableDataParams.forEach((element, i) => {
            let specialChar;
            i === 0 ? (specialChar = '?') : (specialChar = '&');
            construct_uri = construct_uri + specialChar + element.key + '=' + element.value;
        });

        return this.http.get(enviornmentAperture.apiUrl + 'api/v1/timesheets/exportTimesheets' + construct_uri, { responseType: 'blob' });
    }

    getAllocationWiseTimesheet(timesheetId): Observable<any> {
        return this.http.get(enviornmentAperture.apiUrl + 'api/v1/timesheets/getAllocationWiseTimesheetsByTimeSheetId?timeSheetId=' + timesheetId);
    }
}
