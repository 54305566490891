import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { errorRoute, navbarRoute } from 'app/layouts';
import { DEBUG_INFO_ENABLED } from 'app/app.constants';
import { ListAccountsComponent } from 'app/accounts/list-accounts/list-accounts.component';
import { AddAccountComponent } from 'app/accounts/add-account/add-account.component';
import { ViewAccountComponent } from 'app/accounts/view-account/view-account.component';
import { HomeComponent } from 'app/home/home.component';
import { UserRouteAccessService } from 'app/core';
import { LogoutComponent } from 'app/logout/logout.component';
import { InvalidUserComponent } from 'app/invalid-user/invalid-user.component';
import { ForbiddenComponent } from 'app/forbidden/forbidden.component';
import { NotFoundComponent } from 'app/not-found/not-found.component';
import { FaqComponent } from './faq/faq.component';
import { ExportTimesheetComponent } from './export-timesheet/export-timesheet.component';
import { DuplicatesTimesheetComponent } from './duplicates-view/duplicates-view.component';
import { TrainingsComponent } from './trainings/trainings.component';
import { PrivacyPolicyComponent } from './privacypolicy/privacypolicy.component';
import { DesignationsUploadComponent } from './designations-upload/designations-upload.component';
// import { HolidaysComponent } from './LMS/holidays/holidays.component';

let LAYOUT_ROUTES = [navbarRoute, ...errorRoute];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        ...LAYOUT_ROUTES,
        {
          path: '',
          component: HomeComponent
        },
        {
          path: 'logout',
          component: LogoutComponent
        },
        {
          path: 'faqs',
          component: FaqComponent
        },
        {
          path: 'calculator',
          loadChildren:() => import("./cost-calculator/cost-calculator.module").then(m => m.CostCalculatorModule)
        },
        {
          path: 'invalidLogin',
          component: InvalidUserComponent
        },
        {
          path: 'forbidden',
          component: ForbiddenComponent
        },
        {
          path: 'admin',
          loadChildren: () => import('./admin/admin.module').then(m => m.Itrack2AdminModule)
        },
        {
          path: 'projects',
          loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule)
        },
        {
          path: 'resource',
          loadChildren: () => import('./resource/resource.module').then(m => m.ResourceModule),
          canActivate: [UserRouteAccessService],
          data: {
            authorities: ['USER','user']
          }
        },
        {
          path: 'master-database',
          loadChildren: () => import('./master-database/master-database.module').then(m => m.MasterDatabaseModule)
        },
        {
          path: 'leave-management',
          loadChildren:() => import('./leave-management-system/leave-management.module').then(m=>m.LeaveManagementModule)
        },
        {
          path: 'referrals',
          loadChildren: () => import('./Referrals/referrals.module').then(m => m.ReferralsModule)
        },
        {
          path: 'listaccounts',
          component: ListAccountsComponent
        },
        {
          path: 'addaccount',
          component: AddAccountComponent
        },
        {
          path: 'privacy-policy',
          component: PrivacyPolicyComponent
        },
        {
          path: 'view-account',
          component: ViewAccountComponent
        },
        {
          path: 'trainings',
          component: TrainingsComponent
        },
        {
          path: 'privacypolicy',
          component: PrivacyPolicyComponent
        },
        {
          path: 'Allocations',
          loadChildren: () => import('./allocations/allocations.module').then(m => m.AllocationsModule)
        },
        {
          path: 'statement-of-work',
          loadChildren: () => import('./statement-of-work/statement-of-work.module').then(m => m.StatementOfWorkModule)
        },
        {
          path: 'timesheet-export',
          component: ExportTimesheetComponent
        },
        {
          path: 'duplicates-view',
          component: DuplicatesTimesheetComponent
        },
        {
          path: 'designations-upload',
          component:  DesignationsUploadComponent
        },
        {
          path: 'timesheet-view',
          loadChildren: () => import('./timesheet-submit/timesheet-submit.module').then(m => m.TimeSheetSubmitModule),
          data: {
            authorities: ['ROLE_ADMIN']
          }
        },
        // {
        //   path: 'timesheet-view',
        //   component: TimesheetViewComponent,
        //   data: {
        //     authorities: ['ROLE_ADMIN']
        //   }
        // },
        {
          path: 'timesheet-view/:id',
          loadChildren: () => import('./timesheet-submit/timesheet-submit.module').then(m => m.TimeSheetSubmitModule),
          data: {
            authorities: ['ROLE_ADMIN']
          }
        },
        {
          path: 'timesheet-approvals',
          loadChildren: () => import('./timesheet-approvals/timesheet-approvals.module').then(m => m.TimesheetApprovalsModule),
          data: {
            authorities: ['ROLE_ADMIN']
          }
        },
        {
          path: '**',
          component: NotFoundComponent
        }
      ],
      { useHash: true, enableTracing: DEBUG_INFO_ENABLED }
    )
  ],
  exports: [RouterModule]
})
export class Itrack2AppRoutingModule {}
