import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchResourceComponent } from '../resource/search-resource/search-resource.component';
import { ResourceService } from '../resource/resource.service';
import { CommonDataService } from 'app/common-data.service';
@Component({
  selector: 'jhi-sub-bench-selection-dialog',
  templateUrl: './sub-bench-selection-dialog.component.html',
  styleUrls: ['./sub-bench-selection-dialog.component.scss']
})
export class SubBenchSelectionDialogComponent implements OnInit {
  benchStatusList: any;
  resourceCode: any;
  selectedBenchStatus: any;
  load: boolean = false;

  constructor(
    @Inject (MAT_DIALOG_DATA) public subBenchOptions: any,
    private resourceService: ResourceService,
    public dialogRef: MatDialogRef<SearchResourceComponent>,
    private commonService: CommonDataService
  ) { }

  ngOnInit(): void {
    this.benchStatusList = this.subBenchOptions.benchType;
    this.resourceCode = this.subBenchOptions.resourceCode;
  }

  subBenchStatusSelected(event) {
    this.selectedBenchStatus = event.value;
  }

  sendSubBenchStatus() {
    this.load = true;
    let data = {
        "resourceCode": this.commonService.encrypt(this.resourceCode),
        "subBenchType": this.selectedBenchStatus
    }
    this.resourceService.subBenchStatusPost(data).subscribe(response => {
      this.load = false;
      this.closeDialog();
    }, error => {
      this.load = false;
    })
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
