import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'jhi-trainings',
    templateUrl: './trainings.component.html',
    styleUrls: ['./trainings.component.css']
})
export class TrainingsComponent implements OnInit {

    constructor(private titleService: Title) {
        this.titleService.setTitle('aperture - Trainings');
    }
    ngOnInit() {

    }

}
