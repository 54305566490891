<p *ngFor="let activity of historyData">
    <mat-card class="example-card history-card">
        <mat-card-header>
            <div mat-card-avatar>
                <img src="../assets/User_Profile_Pic.png" height=50 width=50 />
            </div>
            <mat-card-title>
                <strong>
                    {{activity.modifiedBy}}
                    <span *ngIf="activity.revisionType === 'ADD'">Added</span>
                    <span *ngIf="activity.revisionType === 'MOD'">Modified</span>
                    a {{task}}
                </strong>
            </mat-card-title>
            <mat-card-subtitle>
                {{activity.updatedOn | timezone}}
                <!-- {{activity.updatedOn | date: DEFAULT_DATE_FORMAT}} &nbsp;
                {{activity.updatedOn | date:'shortTime'}} -->
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="history-card-content">
            <div class="row padding">
                <div class="col-sm-4">
                    <span class="history-fields">Field Name</span>
                </div>
                <div class="col-sm-4">
                    <span class="history-fields">Old Value</span>
                </div>
                <div class="col-sm-4">
                    <span class="history-fields">New Value</span>
                </div>
            </div>
            <div class="row padding" *ngFor="let field of activity.activities">
                <div class="col-sm-4">
                    <span><strong>{{field.fieldName}}</strong></span>
                </div>
                <div class="col-sm-4">
                    <span><strong>{{field.oldValue}}</strong></span>
                </div>
                <div class="col-sm-4">
                    <span><strong>{{field.newValue}}</strong></span>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</p>
