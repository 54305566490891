import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { enviornmentAperture } from 'environments/environment';
@Injectable()
export class ViewAccountService {
  constructor(private http: HttpClient) {}

  viewAccountDetails(accountCode): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/accounts/getAccountById/' + accountCode);
  } 
  checkstatesow(accountCode): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/sows/getMaxSowEndDate?accountCode=' + accountCode);
  }
  getLocationAndAccountManagerList(accountCode): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/accounts/initEditAccount/' + accountCode);
  }
  updateAccountDetails(data): Observable<any> {
    return this.http.put(enviornmentAperture.apiUrl + 'api/v1/accounts/updateAccount', data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  deleteAccount(accountCode): Observable<any> {
    return this.http.delete(enviornmentAperture.apiUrl + 'api/v1/accounts/deleteAccount/' + accountCode);
  }
  getAllProjectsByAccountId(accountCode): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/projects/getAccountsProjects/' + accountCode);
  }
  getAccountHistory(accountCode): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/audit/account/' + accountCode);
  }
  searchResource(searchValue): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/quickSearch?searchParam=' + searchValue);
  }
  invalidUser(): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/invalidLogin');
  }
  getMethodRequest(uri): Observable<any> {
    return this.http.get(uri, { responseType: 'text' as 'json' }).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
}
