import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from 'app/material.module';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { TimesheetViewComponent } from 'app/timesheet-submit/timesheet-view.component';
import { PreviousTimesheetViewComponent } from 'app/timesheet-submit/previous-timesheet-view/previous-timesheet-view.component';
import { CurrentTimesheetViewComponent } from 'app/timesheet-submit/current-timesheet-view/current-timesheet-view.component';
import {
  SubmitTimesheetLegendViewComponent,
  DialogContentExampleDialogComponent
} from 'app/timesheet-submit/current-timesheet-view/submit-timesheet-legend-view/submit-timesheet-legend-view.component';
import { TimesheetService } from 'app/timesheet-submit/timesheet.service';
import { EllipsisPipe } from 'app/timesheet-submit/previous-timesheet-view/ellipsis.pipe';
import { DateSuffixPipe } from 'app/timesheet-submit/datesuffix.pipe';
import { timesheetState } from 'app/timesheet-submit/timesheet-submit.route';
import { DialogConfirmBodyComponent } from 'app/timesheet-submit/current-timesheet-view/current-timesheet-view.component';
import { FormatDataPipe } from 'app/timesheet-submit/format-date.pipe';
import { PendingComponent } from 'app/timesheet-submit/pending-component/pending-component.component';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { WeekTimesheetViewComponent } from 'app/timesheet-submit/week-timesheet-view/week-timesheet-view.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ReactiveFormsModule } from '@angular/forms';
import { MonthPickerComponent } from 'app/timesheet-submit/month-picker/month-picker.component';
import { RangePickerComponent } from './range-picker/range-picker.component';
import { SelfTimesheetDaterangeComponent } from './self-timesheet-daterange/self-timesheet-daterange.component';
import { AutoFocusDirective } from './auto-focus.directive';
import { SubmitReferralComponent } from 'app/Referrals/submit-referral/submit-referral.component';
// import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';

let routes = [
  {
    path: '',
    data: {
      authorities: ['ROLE_ADMIN']
    },
    component: TimesheetViewComponent
  }
];
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY'
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    MaterialModule,
    OwlDateTimeModule,
    ReactiveFormsModule,
    OwlNativeDateTimeModule,
    NgxDocViewerModule,
    RouterModule.forChild(routes),
    RouterModule.forChild(timesheetState),
    // Ng4LoadingSpinnerModule.forRoot()
  ],
  declarations: [
    TimesheetViewComponent,
    PreviousTimesheetViewComponent,
    CurrentTimesheetViewComponent,
    SubmitTimesheetLegendViewComponent,
    EllipsisPipe,
    DateSuffixPipe,
    DialogConfirmBodyComponent,
    FormatDataPipe,
    PendingComponent,
    WeekTimesheetViewComponent,
    DialogContentExampleDialogComponent,
    MonthPickerComponent,
    RangePickerComponent,
    SelfTimesheetDaterangeComponent,
    AutoFocusDirective
  ],
  entryComponents: [DialogConfirmBodyComponent, DialogContentExampleDialogComponent],
  providers: [
    TimesheetService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }, SubmitReferralComponent
  ],
  exports: [
    TimesheetViewComponent,
    PreviousTimesheetViewComponent,
    CurrentTimesheetViewComponent,
    SubmitTimesheetLegendViewComponent,
    EllipsisPipe,
    DateSuffixPipe,
    DialogConfirmBodyComponent,
    FormatDataPipe,
    PendingComponent,
    WeekTimesheetViewComponent,
    DialogContentExampleDialogComponent,
    MonthPickerComponent,
    RangePickerComponent,
    SelfTimesheetDaterangeComponent,
    AutoFocusDirective
  ]
})
export class TimeSheetSubmitModule {}
