import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TimesheetService } from '../timesheet.service';
import { Router } from '@angular/router';

@Component({
  selector: 'jhi-pending-component',
  templateUrl: './pending-component.component.html',
  styleUrls: ['./pending-component.css']
})
export class PendingComponent implements OnInit {
  selWeekTS = 0;
  @Input() monthPendingSince: any = {};
  @Input() month: any;
  @Input() dateRange: any;
  @Output() onDatePicked: EventEmitter<any> = new EventEmitter<any>();
  constructor(private timesheetService: TimesheetService, private router: Router) {}
  ngOnInit() {}

  public pickDate(date: any): void {
    let d = new Date();
    let n = d.valueOf();
    let url = 'timesheet-view/' + date + '_' + n;
    this.router.navigateByUrl(url, {skipLocationChange: true});
    this.onDatePicked.emit(date);
  }
}
