import { Component, OnInit } from '@angular/core';
import { LoginService } from 'app/core';

@Component({
    selector: 'jhi-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
    constructor(private loginService: LoginService) {}

    ngOnInit() {}

    login() {
        this.loginService.login();
    }
}
