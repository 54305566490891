import { Routes } from '@angular/router';

// import { listProjectsRoute } from './list-projects/list-projects.route';
// import { addProjectRout } from './add-project/add-project.route';
// import { viewProjectRoute } from './view-project/view-project.route';
// import { searchResourceRoute } from './search-resource/search-resource.route';
// const PROJECTS_ROUTES = [listProjectsRoute, addProjectRout, viewProjectRoute, searchResourceRoute];
const PROJECTS_ROUTES = [];

export const timesheetState: Routes = [
    {
        path: '',
        children: PROJECTS_ROUTES,
        data: {
            authorities: ['ROLE_ADMIN']
        }
    }
];
