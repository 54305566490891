<mat-card class='main-container logout text-center'>
    <div class="row">
        <div class="col-md-12">
            <p><img src="../assets/404.png"/></p>
            <h1>Error 404</h1>
            <h2>Looks like page doesn't exist</h2>
        </div>
        <br/>
        <div class="col-md-12">
            <a id="closeWindow" (click)="closeWindow()">Close Window</a>
        </div>
    </div>
</mat-card>
