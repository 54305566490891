<div class="row parent_container_height">
	<div class="col-12">
		<p style="font-size: 28px;font-weight: 600;color: #34495E;">Application - {{candidateName}}</p>
	</div>
	<mat-tab-group>
	<mat-tab label="DETAILS">
		<mat-card class="mat-tab-class">
			<div class="col-8" style="margin-left: 20px;float: left;">
				<p style="font-size: 20px;font-weight: 700;color: #34495E;">Application Status</p>
			</div>
			<form [formGroup]="referralApplicationForm" class="col-8 float_columns" style="padding-left:20px">
				<div class="row row_display">
				<div class="col-sm-12 col-lg-4 col-md-6 float_columns">
					<mat-form-field class="form_field_class">
							<input matInput placeholder="Recruiter's Email" [matAutocomplete]="email"
								required jhiEmailValidator
								(keyup)="autoPopulateEmail($event.target.value)"
								formControlName="recruiter">
							<mat-autocomplete autoActiveFirstOption #email="matAutocomplete"
								required>
								<mat-option *ngFor="let email of emailList" [value]="email">
									{{email}}
								</mat-option>
							</mat-autocomplete>
							<mat-error *ngIf="referralApplicationForm.get('recruiter').hasError('required')">
								This
								is required field</mat-error>
							<mat-error *ngIf="referralApplicationForm.get('recruiter').errors?.pattern">
								Please enter a valid email</mat-error>
							<mat-error
								*ngIf="referralApplicationForm.get('recruiter').errors?.jhiEmailValidator">
								Email Address does not exist</mat-error>

					</mat-form-field>
				</div>
				<div class="col-sm-12 col-lg-4 col-md-6 float_columns">
					<mat-form-field class="form_field_class">
						<mat-select placeholder="Status" formControlName="status" required>
							<!-- <mat-option> -->
								<!-- <ngx-mat-select-search [formControl]="departmentSearchFilterCtrl"
									placeholderLabel="Search" noEntriesFoundLabel="'no record found'">
								</ngx-mat-select-search> -->
							<!-- </mat-option> -->
							<mat-option *ngFor="let stat of status" [value]="stat.status">{{stat.status}}
							</mat-option>
						</mat-select>
						<mat-error *ngIf="referralApplicationForm.get('status').hasError('required')">
							This
							is a required field.</mat-error>
					</mat-form-field>
				</div>
				<div class="col-sm-12 col-lg-4 col-md-6 float_columns">
					<mat-form-field class="form_field_class">
						<input matInput [matDatepicker]="picker" formControlName="creationDate"
							placeholder="Action Completion Date" readonly required>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
						<mat-error *ngIf="referralApplicationForm.get('creationDate').hasError('required')">
							This
							is a required field.</mat-error>
					</mat-form-field>
				</div>
				</div>
				<div class="row row_display">
					<div class="col-12 float_columns" style="width: 100%; margin-left: 20px;">
						<p style="font-size: 20px;font-weight: 700;color: #34495E;">Basic Info</p>
					</div>
				</div>
				<div class="row row_display">
					<div class="col-sm-12 col-md-6 col-lg-4 float_columns">
						<p>
							<mat-form-field class="form_field_class">
								<input matInput placeholder="Applicant Name" formControlName="applicantName" required
									maxlength="100">
								<mat-error *ngIf="referralApplicationForm.get('applicantName').hasError('required')">
									This
									is a required field</mat-error>
							</mat-form-field>
						</p>
						<p>
							<mat-form-field class="form_field_class">
								<input matInput placeholder="Primary Contact Number"
									formControlName="primaryContactNumber" required maxlength="14">
								<mat-error
									*ngIf="referralApplicationForm.get('primaryContactNumber').hasError('required')">
									This
									is a required field</mat-error>
							</mat-form-field>
						</p>
					</div>
					<div class="col-sm-12 col-md-6 col-lg-4 float_columns">
						<p>
							<mat-form-field class="form_field_class">
								<input matInput placeholder="Position Applied for" formControlName="positionAppliedFor"
									required maxlength="100">
								<mat-error
									*ngIf="referralApplicationForm.get('positionAppliedFor').hasError('required')">
									This
									is a required field</mat-error>
							</mat-form-field>
						</p>

						<p>
							<mat-form-field class="form_field_class">
								<input matInput placeholder="Secondary Contact Number"
									formControlName="secondaryContactNumber" maxlength="14">
							</mat-form-field>
						</p>

					</div>
					<div class="col-sm-12 col-md-6 col-lg-4 float_columns">
						<p>
							<mat-form-field class="form_field_class">
								<input matInput placeholder="Primary Email ID" formControlName="primaryEmail" required
									maxlength="100">
								<mat-error *ngIf="referralApplicationForm.get('primaryEmail').hasError('required')">
									This
									is a required field</mat-error>
							</mat-form-field>
						</p>
						<!-- <p>
							<mat-form-field class="form_field_class">
								<input matInput placeholder="Secondary Email" formControlName="secondaryEmail"
									maxlength="14">
							</mat-form-field>
						</p> -->
					</div>
					<!-- <div class="col-12" style="float: right;">
						<mat-divider vertical class="vertical_divide"></mat-divider>
					</div> -->
				</div>
			</form>
			<form [formGroup]="referralApplicationForm" class="col-8 float_columns">
				<div class="col-8">
					<p>
						<button (click)="downloadResume()" class="resume_btn" mat-raised-button>
							<img src="../../assets/outline_download_black_24dp.png" alt="Download"> Download Resume
						</button>
					</p>
				</div>
				<div class="row row_display">
					<div class="col-12 float_columns" style="width: 100%; margin-left: 20px;">
						<p style="font-size: 20px;font-weight: 700;color: #34495E;">Referred By</p>
					</div>
				</div>

				<div class="row row_display">
					<div class="col-sm-12 col-md-6 col-lg-4 float_columns">
						<p>
							<mat-form-field class="form_field_class">
								<input matInput placeholder="Employee Name" formControlName="employeeName"
									maxlength="100" required>
								<mat-error *ngIf="referralApplicationForm.get('employeeName').hasError('required')">
									This is a required field.</mat-error>
							</mat-form-field>
						</p>
						<p>
							<mat-form-field class="form_field_class">
								<input matInput [matDatepicker]="refSubDate" formControlName="employeeReferralSubDate"
									placeholder="Referral Submission Date" readonly required>
								<mat-datepicker-toggle matSuffix [for]="refSubDate"></mat-datepicker-toggle>
								<mat-datepicker startView="multi-year" #refSubDate></mat-datepicker>
								<mat-error
									*ngIf="referralApplicationForm.get('employeeReferralSubDate').hasError('required')">
									This
									is a required field.</mat-error>
							</mat-form-field>
						</p>
					</div>
					<div class="col-sm-12 col-md-6 col-lg-4 float_columns">
						<p>
							<mat-form-field class="form_field_class">
								<input matInput placeholder="Employee ID" formControlName="employeeID">
							</mat-form-field>
						</p>
					</div>
					<div class="col-sm-12 col-md-6 col-lg-4 float_columns">
						<p>
							<mat-form-field class="form_field_class">
								<input matInput placeholder="Email ID" formControlName="employeeEmail" required>
								<mat-error *ngIf="referralApplicationForm.get('employeeEmail').hasError('required')">
									This is a required field.</mat-error>
							</mat-form-field>
						</p>
					</div>
				</div>
				<div class="row row_display" style="float: right;">
					<div class="col-12 float_columns" style="width: 100%;padding: 20px 0;">
						<button mat-raised-button class="cancel_btn">Cancel</button>
						<button mat-raised-button class="save_btn" (click)="updateApplicantDetails()" [disabled]="!referralApplicationForm.valid">Save</button>
					</div>
				</div>
			</form>
			<div class="col-1 float_columns">
			</div>
			<aside class="col-3 float_columns" style="position: absolute;right: 0;">
				<h4>Comments</h4>
				<div *ngIf="commentsPosted" id="add-comments">
					<div *ngIf="commentsOfApplicant.length > 0">
						<div *ngFor="let comments of commentsOfApplicant">
							<div>
								<span style="display: inline;"> <b>{{comments.comment_by}}</b> </span>
								<span style="float: right;">{{comments.auditDate | date}}</span>
							</div>
							<div>
							<p>{{comments.comment}}</p>
							</div>
							<div>
								<p style="float: right;">
									<span style="margin-right: 15px;"><a (click)="commentEdit(comments)">Edit</a></span>
									<span><a (click)="deleteComment(comments)">Delete</a></span>
								</p>
							</div>
							<br>
						</div>
					</div>
				</div>

				<p>
					<textarea style="box-sizing: border-box;
							border: 2px solid #ccc;
							width:100%; height: 150px;
							border-radius: 4px;
							background-color: #f8f8f8;
							font-size: 16px;
							resize: none;" [(ngModel)]="commentArea" placeholder="Write a comment" maxlength="250">
					</textarea>
				</p>
				<p>
					<button mat-raised-button class="post-comment" id="post-btn" [disabled]="!commentArea" (click)="postComment()">Post</button>
					<button mat-raised-button class="post-comment" id="update-btn" [disabled]="!commentArea" (click)="editComment()">Update</button>
				</p>
			</aside>
		</mat-card>
	</mat-tab>
		<!-- <mat-tab label="HISTORY">Screens not provided</mat-tab> -->
	</mat-tab-group>
</div>