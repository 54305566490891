import { NgModule } from '@angular/core';

import { Itrack2SharedLibsModule, JhiAlertComponent, JhiAlertErrorComponent } from './';
import { AuditComponent } from 'app/audit/audit.component';
import { MaterialModule } from 'app/material.module';

import { TimezonePipe } from './util/timezone.pipe';

@NgModule({
  imports: [Itrack2SharedLibsModule, MaterialModule],
  declarations: [JhiAlertComponent, JhiAlertErrorComponent, AuditComponent, TimezonePipe],
  providers: [],
  exports: [Itrack2SharedLibsModule, JhiAlertComponent, JhiAlertErrorComponent, AuditComponent, TimezonePipe]
})
export class Itrack2SharedCommonModule {}
