<div id='main-cont' style="margin-top: 22px;">
    <div class="row">
        <div class="col-sm-3 left-align">
            <h1 id='main-heading' *ngIf="loadCur">My Timesheet</h1>
            <h1 id='main-heading' *ngIf="loadPrev">My Timesheets</h1>
            <h1 id='main-heading' *ngIf="loadDr">My Timesheets</h1>
        </div>
        <div class="col-sm-6"></div>
        <!-- <div class="col-sm-6 alert alert-{{data.messageType}} text-center submitted" (document:click)="data.submitted=false" *ngIf="data.submitted">
            <strong>{{data.displayLabel}}</strong>
        </div> -->
        <!-- <div class="col-sm-3 submit-timesheet-lg">
           <jhi-submit-timesheet-legend style="float:right" [standardHours]="standard_hours"></jhi-submit-timesheet-legend> 
        </div> -->
        <div class="col-md-3 mbl_height" style="height:60px;">
            <div id="exportTimesheetbox">
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title class="exportTitle">
                                Export Timesheet Report
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <form [formGroup]="exportReportForm" novalidate>
                            <mat-form-field id="fullWidth">
                                <mat-select [(ngModel)]="statusSelectedOption" [ngModelOptions]="{standalone: true}"
                                    placeholder="Status">
                                    <mat-divider></mat-divider>
                                    <mat-option *ngFor="let status of exportStatus" [value]="status.code">
                                        {{status.value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field style="width: 50%;float: left;margin-right: 10px;">
                                <input matInput [matDatepicker]="picker" placeholder="From Date"
                                    formControlName="fromDateExport" (dateChange)="fromDateChange($event)" readonly="true"
                                    placement="bottom-right">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker disabled="false"></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field style="width:calc(50% - 10px)">
                                <input matInput [matDatepicker]="picker1" placeholder="Till Date"
                                    formControlName="tillDateExport" [min]="mintillDate"
                                    (dateChange)="tillDateChange($event)" readonly="true">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1 [disabled]="tillDateStatus"></mat-datepicker>
                            </mat-form-field>
                        </form>
                        <div class="row tilldateerror" *ngIf="tillDateStatusSelected">Please select Till Date</div>
                        <div class="row">
                            <div class="col-md-6">
                                <button class="btn exportbtn" mat-raised-button color="primary"
                                    (click)='resetexportReport()'>
                                    <mat-icon>refresh</mat-icon> Reset
                                </button>
                            </div>
                            <div class="col-md-6">
                                <button class="btn exportbtn" mat-raised-button color="primary" (click)='exportReport()'
                                    [disabled]="tillDateStatusSelected">
                                    <mat-icon><i class="material-icons">
                                            save_alt
                                        </i></mat-icon> Export
                                </button>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
    <div class="row main-legend">
        <div class="col-md-5">
            <div class="btn-group weekly-monthly-toggle" role="group" aria-label="Basic example">
                <button type="button" [ngClass]="{'active': loadCur }" (click)="loadCurrent();" class="btn btn-secondary">Weekly</button>
                <span class="headingborder"></span>
                <button type="button" [ngClass]="{'active': loadPrev }" (click)="loadPrevious();" class="btn btn-secondary">Monthly</button>
                <span class="headingborder"></span>
                <button type="button" [ngClass]="{'active': loadDr }" (click)="loadDateRange();" class="btn btn-secondary">Date
                    Range</button>
            </div>
        </div>
        <div class="col-md-7 submit-timesheet-lg">
            <jhi-submit-timesheet-legend style="float:right" [standardHours]="standard_hours"></jhi-submit-timesheet-legend>
        </div>
        <div class="col-md-12 main-timesheets-submit">
            <jhi-current-timesheet-view *ngIf="loadCur" [resourceDetails]="resourceDetails" (sHours)="getHours($event)" (submittedToParent)="getOutputVal($event)"></jhi-current-timesheet-view>
            <jhi-previous-timesheet-view *ngIf="loadPrev"></jhi-previous-timesheet-view>
            <jhi-self-timesheet-daterange *ngIf="loadDr"></jhi-self-timesheet-daterange>
        </div>
    </div>
</div>
