<mat-card class="main-container color-code">
    <div class="row week-name-row">
        <div class="col-md-4 col-sm-12 date-selector">
            <!-- <p class="group" style="width: 90%"> -->
            <p class="group">
                <span class="from_to_text weekly-text">This Week</span>
                <img
                    src="../../assets/left-enable.png"
                    alt="Left chevron enabled"
                    (click)="weekObj.previousWeekEnabled && getTimeSheetDataHateoas(this.weekObj.previous_week_date_param, 'prev')"
                    [style.cursor]="weekObj.previousWeekEnabled ? 'pointer' : 'not-allowed'"
                    [style.display]="weekObj.previousWeekEnabled ? 'inline-block' : 'none'"
                    height="25"
                />
                <img
                    src="../../assets/left-disable.png"
                    alt="Left chevron disabled"
                    [style.cursor]="weekObj.previousWeekEnabled ? 'pointer' : 'not-allowed'"
                    [style.display]="!weekObj.previousWeekEnabled ? 'inline-block' : 'none'"
                    height="25"
                />
                <span class="week-date">{{weekObj.weekName}}</span>
                <img
                    src="../../assets/right-enable.png"
                    disabed
                    alt="Right chevron enabled"
                    (click)="weekObj.nextWeekEnabled && getTimeSheetDataHateoas(this.weekObj.next_week_date_param, 'next')"
                    [style.cursor]="weekObj.nextWeekEnabled ? 'pointer' : 'not-allowed'"
                    [style.display]="weekObj.nextWeekEnabled ? 'inline-block' : 'none'"
                    height="25"
                />
                <img
                    src="../../assets/right-disable.png"
                    disabed
                    alt="Right chevron disabled"
                    [style.cursor]="weekObj.nextWeekEnabled ? 'pointer' : 'not-allowed'"
                    [style.display]="weekObj.nextWeekEnabled ? 'none' : 'inline-block'"
                    height="25"
                />

                <input matInput (dateChange)="updateTimesheetData($event)" [min]="minDate" [max]="maxDate" [matDatepicker]="weekDatepicker" [(ngModel)]="weekDpModel" class="mat-date-input" />
                <mat-datepicker-toggle matSuffix [for]="weekDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #weekDatepicker (opened)="calenderOpen(weekDatepicker,0)"></mat-datepicker>
            </p>
        </div>
        <div class="col-md-4 col-sm-12 pending-btn"></div>
        <div class="col-md-4 col-sm-12 weeklyPending-metadata">
            <div class="timesheet-status projectWise-status weekly" *ngIf="weekObj.status">
                <p>
                    <span class="status-code"><b>Status: </b>
                    <span class="status-color-{{weekObj.code}}">{{weekObj.status}}</span>
                    </span> 
                </p>
                <div *ngIf="(weekObj && weekObj.description)">
                    <span *ngIf="weekObj && weekObj.description"> <b>Description:</b> {{weekObj.description}}</span>
                </div>
                <span class="timesheet-lastUpdatedOn" *ngIf="weekObj.lastUpdatedOn"> Last Updated On: {{weekObj.lastUpdatedOn}}</span>
            </div>
        </div>
    </div>
    <div id="viewBy">
        <div class="requestFilters">
            <table id="weeklyTSRow" *ngIf="showTableData">
                <tr class="weekdays">
                    <td class="first-child">Projects</td>
                    <td *ngFor="let day of weekObj.daysData; let i = index" id="weekdayth{{i}}" [ngClass]="{'holiday': day.code === 'HD','week-off': day.checked}">
                        <mat-checkbox
                            class="week-off-check"
                            [(ngModel)]="day.checked"
                            (change)="getCheckboxes()"
                            *ngIf="(day.weekOff && this.weekObj.code != 'PARTIALLY_APPROVED' && this.weekObj.code != 'APPROVED') || (weekObj.flexibleWeekends && recall)"
                        >
                        </mat-checkbox>
                        {{day.day.substring(0,3)}}{{' ('}}{{ day.date}}{{')'}}
                        <span *ngIf="!fixedweekoff">*</span>
                    </td>
                    <td class="totalHoursHeading">Total</td>
                    <td>Status</td>
                </tr>
                <tr *ngFor="let project of weekObj.projects let i=index;" class="project-row" [hidden]="project.type === 'PDL' || project.type === 'UPL' || project.type === 'NBL'">
                    <td class="first-child triangle Project1">
                        <span class="projectName-actorInfo">{{project.name}}</span>
                        <div class="project-actor-info" *ngIf="project.allocationSummary">
                            <p *ngIf="project.allocationSummary"><b>Client:</b> {{project.allocationSummary.account}}</p>
                            <p *ngIf="project.allocationSummary && project.allocationSummary.wbs"></p>
                            <p *ngIf="project.allocationSummary.wbs"><b>Project:</b> {{project.name}}</p>
                            <p *ngIf="project.allocationSummary.wbs.project.delegateManagerName !== null"><b>Delegate Manager:</b> {{project.allocationSummary.wbs.project.delegateManagerName}}</p>
                            <p *ngIf="project.allocationSummary.wbs.project.delegateManagerName == null">
                                <b>Manager:</b>
                                {{project.allocationSummary.wbs.project.offorOnshoreManagerAccordingToResourceCode}}
                            </p>
                            <span *ngIf="project.allocationSummary.wbs"> <b>SOW: </b>{{project.allocationSummary.wbs.sowSummary.sowNumber}} </span>
                            <br />
                            <span *ngIf="project.allocationSummary.occupancy"> <b>Occupancy:</b> {{project.allocationSummary.occupancy}}% </span><br />
                            <span *ngIf="project.allocationSummary.startDate">
                                <!-- <span>
                                    <b>Start Date:</b> {{project.allocationSummary.startDate | date:'dd-MM-yyyy'}}
                                </span><br /> -->
                                <span *ngIf="project.allocationSummary.endDate">
                                    <b>End Date:</b>
                                    {{project.allocationSummary.endDate | date:'dd-MM-yyyy'}}
                                </span>
                                <br *ngIf="project.allocationSummary.endDate" />
                                <br *ngIf="project.allocationSummary.wbs" />
                                <!-- <span *ngIf="project.allocationSummary.wbs">
                                    <b>Remaining Hours:</b>
                                    {{project.allocationSummary.wbs.hoursSummary.remainingHours}}
                                </span> -->
                            </span>
                        </div>
                        <span id="triangle-topright" *ngIf="project.attachment"></span>
                    </td>
                    <td
                        matTooltip="{{dayHour.remarks}}"
                        [style.border]="( (dayHour.hour > 0 && weekObj.daysData[k].code == 'WE' && dayHour.comments && dayHour.comments.length === 0) ||
                        (weekObj.daysData[k].code === 'HD' && dayHour.comments && dayHour.comments.length === 0 && dayHour.hour > 0) ||
                         (weekObj.daysData[k].code === 'WD' && dayHour.comments && dayHour.comments.length === 0 && dayHour.hour > 8) ) ? '2px solid red' : '1px solid lightgray' "
                        *ngFor="let dayHour of project.hours; let k = index;"
                        id="weekday{{k}}"
                        [ngClass]="{'holiday': weekObj.daysData[k].code === 'HD','week-off': weekObj.daysData[k].checked,'week-off-added': weekObj.daysData[k].weekOffchecked}"
                    >
                        <input
                            [readonly]="hasAuthority()"
                            [hidden]="!( (project.startDate <= weekDates[k].date && project.endDate === null) ||
                            (project.startDate <= weekDates[k].date && project.endDate !== null && project.endDate >= weekDates[k].date) )"
                            id="{{i}}{{'-'}}{{k}}"
                            matInput
                            class="hours-input time-input"
                            max="24"
                            maxlength="4"
                            type="text"
                            [(ngModel)]="dayHour.hour"
                            [ngModelOptions]="{updateOn: 'blur'}"
                            [ngbPopover]="popContent"
                            placement="bottom"
                            #p="ngbPopover"
                            triggers="dblclick"
                            (click)="popupTrigger($event)"
                            (document:click)="p.close()"
                            (keypress)="numberOnly($event)"
                            (click)="$event.stopPropagation()"
                            [autoClose]="'outside'"
                            (focusout)="focusOutFunction($event,isLeaveAdded,weekDates[k].date)"
                            *ngIf="
                            (
                                weekObj.code === 'PENDING_SUBMISSION'    || 
                                weekObj.code === 'PENDING'
                            ) ||
    
                            (
                                (
                                    weekObj.code === 'SAVED' || 
                                    weekObj.code === 'REJECTED' || 
                                    weekObj.code === 'PARTIALLY_APPROVED_REJECTED' || 
                                    weekObj.code === 'PARTIALLY_REJECTED_PENDING' || 
                                    weekObj.code === 'PARTIALLY_REJECTED' ||
                                    weekObj.code === 'PARTIALLY_APPROVED_PENDING' ||
                                    weekObj.code === 'PARTIALLY_APPROVED' || 
                                    weekObj.code === 'PARTIALLY_APPROVED_REJECTED_PENDING' ||
                                    (weekObj.code === 'SUBMITTED' || weekObj.code === 'PARTIALLY_SUBMITTED')
                                ) 
                               && 
                                ( 
                                    (
                                    
                                        project.status && project.status.timesheetStatus 
                                    
                                        && 
                                        
                                        (
                                        project.status.timesheetStatus.code === 'REJECTED' ||
                                        project.status.timesheetStatus.code === 'PARTIALLY_APPROVED_REJECTED' || 
                                        project.status.timesheetStatus.code === 'PARTIALLY_REJECTED_PENDING' || 
                                        project.status.timesheetStatus.code === 'SAVED' || 
                                        (weekObj.code !='PARTIALLY_APPROVED_PENDING' &&  project.status.timesheetStatus.code === 'SUBMITTED' && recall === true )
                                        )
                                    ) 
                                    
                                    ||     
                                
                                    !project.status.timesheetStatus 
                                    
                                )
                            )
                      "
                        />

                        <span
                            class="disabled-readonly"
                            *ngIf="!( 
                                (project.startDate <= weekDates[k].date && project.endDate === null) ||
                                (project.startDate <= weekDates[k].date && project.endDate !== null && project.endDate >= weekDates[k].date) 
                                )"
                        >
                            {{dayHour.hour}}
                        </span>
                        <span
                            [hidden]="!( (project.startDate <= weekDates[k].date && project.endDate === null) ||
                                       (project.startDate <= weekDates[k].date && project.endDate !== null && project.endDate >= weekDates[k].date) )"
                            *ngIf="
                                       (weekObj.code === 'SUBMITTED' && recall === false) || 
                                       (weekObj.code === 'PARTIALLY_SUBMITTED' && project.status.timesheetStatus.code != 'SAVED') ||
                                        weekObj.code === 'APPROVED' || 
                                       (
                                           (
                                               weekObj.code === 'REJECTED' || 
                                               weekObj.code === 'PARTIALLY_APPROVED_REJECTED' || 
                                               weekObj.code === 'PARTIALLY_REJECTED_PENDING' ||
                                               weekObj.code === 'PARTIALLY_APPROVED_PENDING' ||
                                               weekObj.code === 'PARTIALLY_APPROVED_REJECTED_PENDING' || 
                                               weekObj.code === 'SAVED' || 
                                               weekObj.code === 'PARTIALLY_REJECTED' 
                                           ) 
                                           
                                           && 
                                          (
                                               (
                                                   project.status && project.status.timesheetStatus && 
                                                   !( 
                                                       project.status.timesheetStatus.code === 'REJECTED' ||
                                                       project.status.timesheetStatus.code === 'PARTIALLY_APPROVED_REJECTED' || 
                                                       project.status.timesheetStatus.code === 'SAVED' || 
                                                       project.status.timesheetStatus.code === 'PARTIALLY_REJECTED_PENDING'
                                                   )
                                               ) 
                                               
                                               &&
   
                                               (
                                                   project.status.timesheetStatus.code === 'APPROVED' ||
                                                   (project.status.timesheetStatus.code === 'SUBMITTED')
                                               )
                                           )
                                       )"
                        >
                            {{dayHour.hour}}
                        </span>
                        <span *ngIf="dayHour.comments !== null && dayHour.comments.length > 0">
                            <i class="fa fa-comment" aria-hidden="true" placement="bottom" [ngbPopover]="popContentComment" tabindex="0" triggers="mouseenter:mouseleave"></i>
                        </span>
                        <ng-template #popContent>
                            <div>
                                <mat-form-field>
                                    <textarea matInput class="comments-input" maxlength="250" rows="3" cols="40" type="text" [(ngModel)]="dayHour.comments" (click)="$event.stopPropagation()"></textarea>
                                </mat-form-field>
                            </div>
                        </ng-template>
                        <ng-template #popContentComment>
                            {{dayHour.comments}}
                        </ng-template>
                    </td>
                    <td class="attachment-td">
                        <span
                            *ngIf="weekObj.code === 'PENDING_SUBMISSION' || weekObj.code === 'PENDING' || weekObj.code === 'SAVED' ||
                        ((weekObj.code === 'REJECTED' || weekObj.code === 'PARTIALLY_APPROVED_REJECTED'
                            || weekObj.code === 'PARTIALLY_REJECTED_PENDING' || weekObj.code === 'PARTIALLY_REJECTED' ) && ((project.status && project.status.timesheetStatus && (project.status.timesheetStatus.code === 'REJECTED' ||
                            project.status.timesheetStatus.code === 'PARTIALLY_APPROVED_REJECTED' || project.status.timesheetStatus.code === 'PARTIALLY_REJECTED_PENDING')) || !project.status.timesheetStatus ))"
                        >
                            <!-- <input [readonly]="hasAuthority()" class='hours-input' maxlength="2" value="{{calculateProjectHours(project.hours)}}"
                                disabled="true" /> -->
                            {{calculateProjectHours(project.hours)}}
                        </span>
                        <span
                            *ngIf="weekObj.code === 'SUBMITTED' || weekObj.code === 'APPROVED' ||
                        weekObj.code === 'PARTIALLY_APPROVED' || weekObj.code === 'PARTIALLY_SUBMITTED' || weekObj.code === 'PARTIALLY_APPROVED_PENDING' || weekObj.code === 'PARTIALLY_APPROVED_REJECTED_PENDING' || ((weekObj.code === 'REJECTED' || weekObj.code === 'PARTIALLY_APPROVED_REJECTED'
                        || weekObj.code === 'PARTIALLY_REJECTED_PENDING' || weekObj.code === 'PARTIALLY_REJECTED' ) && ((project.status && project.status.timesheetStatus && !(project.status.timesheetStatus.code === 'REJECTED' ||
                        project.status.timesheetStatus.code === 'PARTIALLY_APPROVED_REJECTED' || project.status.timesheetStatus.code === 'PARTIALLY_REJECTED_PENDING')) && project.status.timesheetStatus )) "
                        >
                            {{calculateProjectHours(project.hours)}}
                        </span>
                        <div
                            *ngIf="project.attachment &&
                            (weekObj.code === 'PENDING_SUBMISSION' || weekObj.code === 'PENDING' || weekObj.code === 'SAVED' || weekObj.code === 'PARTIALLY_APPROVED_REJECTED' || weekObj.code === 'REJECTED' || weekObj.code === 'PARTIALLY_SUBMITTED' || (weekObj.code === 'PARTIALLY_REJECTED_PENDING' && saveStatusPresent === true)
                        ||  (weekObj.code === 'PARTIALLY_APPROVED_PENDING' && saveStatusPresent === true) || weekObj.code === 'PARTIALLY_APPROVED_REJECTED_PENDING' ||  weekObj.code === 'PARTIALLY_REJECTED' || (weekObj.code === 'SUBMITTED' && recall === true))"
                            id="upload_button"
                            class="edit-image-save"
                        >
                            <label>
                                <input [readonly]="hasAuthority()" type="file" (change)="changeListener($event, project.allocationId)" />
                                <i class="material-icons" [hidden]="(!(weekObj.code === 'PENDING_SUBMISSION' || weekObj.code === 'PENDING') && !(project.status))" title="upload file">attach_file</i>
                            </label>
                        </div>
                        <button class="btn edit-image-save" *ngIf="(project.download)" (click)="openCurrentAllocations(currentAllocations, project.download)">
                            <!-- <i class="material-icons">file_download</i> -->
                            <i class="material-icons" title="view file">open_in_browser</i>
                        </button>

                        <!-- <mat-error *ngIf="fileExtensionFlag && project.attachment">Please give valid file type.['jpg', 'jpeg', 'bmp', 'png', 'xls', 'xlsx', 'pdf', 'doc', 'docx', 'txt']</mat-error>
                        <mat-error *ngIf="resumeSizeFlag && project.attachment">File size is exeeded to 100KB. Please
                            choose a file below 100KB</mat-error>
                        <mat-error *ngIf="resumeLengthFlag && project.attachment">File name is too long. File name
                            should
                            be less than 100 characters</mat-error> -->
                    </td>
                    <td>
                        <div class="projectWise-status" *ngIf="project.status">
                            <p *ngIf="project.status && project.status.timesheetStatus" class="{{project.status.timesheetStatus.code}}">
                                <span class="{{project.status.timesheetStatus.code}}"> &nbsp;{{project.status.timesheetStatus.value}}&nbsp;</span>
                            </p>
                            <div *ngIf="(project.status && project.status.comments) || (project.status && project.status.lastUpdatedOn)">
                                <span *ngIf="project.status.actor && project.status.actor">
                                    <b>Last Updated by: </b>
                                </span>
                                <img
                                    class="example-option-img hover-rsc-img"
                                    *ngIf="project.status.actor && projectsActorImage[project.status.actor.code];else projectActorImage"
                                    aria-hidden
                                    [src]="projectsActorImage[project.status.actor.code].img"
                                    height="25"
                                    [alt]="project.status.actor.name"
                                />
                                <ng-template #projectActorImage>
                                    <img *ngIf="project.status.actor && project.status.actor.name" class="example-option-img hover-rsc-img" aria-hidden src="../../assets/User_Profile_Pic.png" height="25" />
                                </ng-template>
                                <span *ngIf="project.status.actor && project.status.actor"> &nbsp;&nbsp;{{project.status.actor.name}}&nbsp;-&nbsp;{{project.status.actor.code}}</span>
                                <br *ngIf="project.status.actor && project.status.actor" />
                                <span *ngIf="project.status && project.status.lastUpdatedOn">
                                    <b>Last Updated On:</b>
                                    {{project.status.lastUpdatedOn}}
                                </span>
                                <br *ngIf="project.status && project.status.comments" />
                                <span class="status-comments" *ngIf="project.status && project.status.comments"><b>Comment:</b> {{project.status.comments}}</span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr *ngFor="let project of weekObj.projects let i=index;" class="leave-row" [hidden]="project.type != 'UPL'">
                    <td class="first-child">
                        <span class="Desktop_timesheet">{{project.name}}</span>
                        <span class="mobile_timesheet">{{project.type}}</span>
                        <i
                            *ngIf="weekObj.code === 'PENDING_SUBMISSION'|| weekObj.code === 'PENDING' || weekObj.code === 'SAVED' || project.status.timesheetStatus && (project.status.timesheetStatus.code === 'SAVED' || project.status.timesheetStatus.code === 'REJECTED')"
                            class="fa fa-times"
                            aria-hidden="true"
                            (click)="clearLeave(project)"
                        ></i>
                    </td>
                    <td matTooltip="{{dayHour.remarks}}" *ngFor="let dayHour of project.hours; let j = index; trackBy:trackByIndex;" [ngClass]="[weekObj.daysData[j].code === 'HD' ? 'holiday' : '']">
                        <input
                            [readonly]="hasAuthority()"
                            id="{{i}}{{'-'}}{{j}}"
                            matInput
                            class="hours-input time-input"
                            maxlength="4"
                            [(ngModel)]="dayHour.hour"
                            [ngModelOptions]="{updateOn: 'blur'}"
                            (click)="popupTrigger($event)"
                            (keypress)="numberOnly($event)"
                            (click)="$event.stopPropagation()"
                            (focusout)="focusOutFunction($event,isLeaveAdded,weekDates[j].date)"
                            *ngIf="  
                                 weekObj.code === 'PENDING_SUBMISSION' || 
                                 weekObj.code === 'PENDING' || 
                                 weekObj.code === 'SAVED' || 
                                 weekObj.code === 'REJECTED'  || 
                                 (weekObj.code === 'PARTIALLY_REJECTED' &&  !project.status.timesheetStatus) || 
                                 
                                 project.status.timesheetStatus && 
                                   (
                                       project.status.timesheetStatus.code === 'SAVED' || 
                                       project.status.timesheetStatus.code === 'REJECTED' || 
                                       (weekObj.code !='PARTIALLY_APPROVED_PENDING' &&  project.status.timesheetStatus.code === 'SUBMITTED' && recall === true )
                                   ) || 
                                    (weekObj.code === 'SUBMITTED' && recall === true) || 
                                    (
                                        weekObj.code === 'PARTIALLY_REJECTED_PENDING' || 
                                        project.status.timesheetStatus && project.status.timesheetStatus.code !== 'SUBMITTED' && 
                                        project.status.timesheetStatus.code !== 'APPROVED' && 
                                        project.status.timesheetStatus.code !== 'SAVED'
                                    )
                                    
                                    ||     
                                    
                                    !project.status.timesheetStatus 
                                    
                                    "
                        />
                        <span
                            *ngIf=" (
                                   weekObj.code === 'APPROVED' || 
                                   weekObj.code === 'SUBMITTED' || 
                                   weekObj.code === 'PARTIALLY_APPROVED' || 
                                   weekObj.code === 'PARTIALLY_SUBMITTED' ||
                                   weekObj.code === 'PARTIALLY_REJECTED' ||
                                   weekObj.code === 'PARTIALLY_APPROVED_PENDING' || 
                                   weekObj.code === 'PARTIALLY_APPROVED_REJECTED' ||
                                   weekObj.code === 'PARTIALLY_APPROVED_REJECTED_PENDING' 
                                  
                                ) 
                                && 
                                project.status.timesheetStatus 
                                && 
                                (  
                                    project.status.timesheetStatus.code === 'APPROVED' || 
                                    project.status.timesheetStatus.code === 'SUBMITTED' ||
                                    (project.status.timesheetStatus.code === 'SUBMITTED' && recall === false) ||
                                    (  
                                         weekObj.code === 'PARTIALLY_REJECTED_PENDING' && 
                                         project.status.timesheetStatus && 
                                         project.status.timesheetStatus.code === 'SUBMITTED'
                                    )
                                )"
                        >
                            <span
                                *ngIf="!(
                                       weekObj.code === 'SUBMITTED' &&  
                                       project.status.timesheetStatus.code === 'SUBMITTED'  && 
                                       recall === true)
                                       "
                            >
                                {{dayHour.hour}}
                            </span>
                        </span>
                    </td>
                    <td>
                        <span *ngIf='weekObj.code==="PENDING_SUBMISSION" || weekObj.code === "PENDING" || weekObj.code==="SAVED" || (weekObj.code === "SUBMITTED" && recall === true)'>
                            <input [readonly]="hasAuthority()" class="hours-input" maxlength="2" value="{{calculateProjectHours(project.hours)}}" disabled="true" />
                        </span>
                        <span
                            *ngIf="(weekObj.code === 'SUBMITTED' && recall === false) ||
                            weekObj.code === 'REJECTED' ||
                            weekObj.code === 'APPROVED' ||
                            weekObj.code === 'PARTIALLY_APPROVED_REJECTED' ||
                            weekObj.code === 'PARTIALLY_APPROVED_PENDING' || weekObj.code === 'PARTIALLY_APPROVED_REJECTED_PENDING' || weekObj.code === 'PARTIALLY_APPROVED' || weekObj.code === 'PARTIALLY_SUBMITTED' || weekObj.code === 'PARTIALLY_REJECTED_PENDING' ||  weekObj.code === 'PARTIALLY_REJECTED'"
                        >
                            {{calculateProjectHours(project.hours)}}
                        </span>
                        <i class="material-icons" [hidden]="!project.attachment">attach_file</i>
                    </td>
                    <!-- <td></td> -->
                    <td>
                        <div class="projectWise-status">
                            <p *ngIf="project.status && project.status.timesheetStatus" class="{{project.status.timesheetStatus.code}}">
                                <span class="{{project.status.timesheetStatus.code}}"> &nbsp;{{project.status.timesheetStatus.value}}&nbsp;</span>
                            </p>
                        </div>
                    </td>
                </tr>
                <tr *ngFor="let project of weekObj.projects let i=index;" class="leave-row" [hidden]="project.type != 'PDL'">
                    <td class="first-child">
                        <span class="Desktop_timesheet">{{project.name}}</span>
                        <span class="mobile_timesheet">{{project.type}}</span>
                        <i
                            *ngIf="weekObj.code === 'PENDING_SUBMISSION'|| weekObj.code === 'PENDING' || weekObj.code === 'SAVED' || project.status.timesheetStatus && (project.status.timesheetStatus.code === 'SAVED' || project.status.timesheetStatus.code === 'REJECTED')"
                            class="fa fa-times"
                            aria-hidden="true"
                            (click)="clearLeave(project)"
                        ></i>
                    </td>
                    <td matTooltip="{{dayHour.remarks}}" *ngFor="let dayHour of project.hours; let j = index; trackBy:trackByIndex;" [ngClass]="[weekObj.daysData[j].code === 'HD' ? 'holiday' : '']">
                        <input
                            [readonly]="hasAuthority()"
                            id="{{i}}{{'-'}}{{j}}"
                            matInput
                            class="hours-input time-input"
                            maxlength="4"
                            [(ngModel)]="dayHour.hour"
                            [ngModelOptions]="{updateOn: 'blur'}"
                            (click)="popupTrigger($event)"
                            (keypress)="numberOnly($event)"
                            (click)="$event.stopPropagation()"
                            (focusout)="focusOutFunction($event,isLeaveAdded,weekDates[j].date)"
                            *ngIf="  
                                weekObj.code === 'PENDING_SUBMISSION' || 
                                weekObj.code === 'PENDING' || 
                                weekObj.code === 'SAVED' || 
                                weekObj.code === 'REJECTED' || 
                                (weekObj.code === 'PARTIALLY_REJECTED' &&  !project.status.timesheetStatus) || 
                            
                                project.status.timesheetStatus && 
                                (
                                    project.status.timesheetStatus.code === 'SAVED' || 
                                    project.status.timesheetStatus.code === 'REJECTED' || 
                                    (weekObj.code !='PARTIALLY_APPROVED_PENDING' &&  project.status.timesheetStatus.code === 'SUBMITTED' && recall === true )
                                ) || 
                                
                                weekObj.code === 'SUBMITTED' && recall === true ||

                                (   weekObj.code === 'PARTIALLY_REJECTED_PENDING' || 
                                    project.status.timesheetStatus && project.status.timesheetStatus.code !== 'SUBMITTED' && 
                                    project.status.timesheetStatus.code !== 'APPROVED' && project.status.timesheetStatus.code !== 'SAVED'
                                )
                                
                                ||     
                                
                                !project.status.timesheetStatus 
                          "
                        />
                        <span
                            *ngIf="( 
                                   weekObj.code === 'APPROVED' || 
                                   weekObj.code === 'SUBMITTED' || 
                                   weekObj.code === 'PARTIALLY_APPROVED' || 
                                   weekObj.code === 'PARTIALLY_SUBMITTED' ||
                                   weekObj.code === 'PARTIALLY_REJECTED' ||
                                   weekObj.code === 'PARTIALLY_APPROVED_PENDING' || 
                                   weekObj.code === 'PARTIALLY_APPROVED_REJECTED' ||
                                   weekObj.code === 'PARTIALLY_APPROVED_REJECTED_PENDING' 
                                ) 
                                && 
                                project.status.timesheetStatus  
                                &&  
                                (  project.status.timesheetStatus.code === 'APPROVED' ||  
                                   project.status.timesheetStatus.code === 'SUBMITTED' ||
                                   (project.status.timesheetStatus.code === 'SUBMITTED' && recall === false) || 
                                   ( weekObj.code === 'PARTIALLY_REJECTED_PENDING' && 
                                     project.status.timesheetStatus && 
                                     project.status.timesheetStatus.code === 'SUBMITTED'
                                   )
                                ) 
                                "
                        >
                            <span
                                *ngIf="!(
                                           weekObj.code === 'SUBMITTED' &&  
                                           project.status.timesheetStatus.code === 'SUBMITTED'  && 
                                           recall === true)
                                        "
                            >
                                {{dayHour.hour}}
                            </span>
                        </span>
                    </td>
                    <td>
                        <span *ngIf='weekObj.code==="PENDING_SUBMISSION"|| weekObj.code === "PENDING" || weekObj.code==="SAVED" || (weekObj.code === "SUBMITTED" && recall === true)'>
                            <input [readonly]="hasAuthority()" class="hours-input" maxlength="2" value="{{calculateProjectHours(project.hours)}}" disabled="true" />
                        </span>
                        <span
                            *ngIf="(weekObj.code === 'SUBMITTED' && recall === false) ||
                            weekObj.code === 'REJECTED' ||
                            weekObj.code === 'APPROVED' ||
                            weekObj.code === 'PARTIALLY_APPROVED_REJECTED' ||
                            weekObj.code === 'PARTIALLY_SUBMITTED' || 
                            weekObj.code === 'PARTIALLY_APPROVED' || weekObj.code === 'PARTIALLY_APPROVED_PENDING' || weekObj.code === 'PARTIALLY_APPROVED_REJECTED_PENDING' ||
                            weekObj.code === 'PARTIALLY_REJECTED_PENDING' ||  weekObj.code === 'PARTIALLY_REJECTED'"
                        >
                            {{calculateProjectHours(project.hours)}}
                        </span>
                        <i class="material-icons" [hidden]="!project.attachment">attach_file</i>
                    </td>
                    <!-- <td></td> -->
                    <td>
                        <div class="projectWise-status">
                            <p *ngIf="project.status && project.status.timesheetStatus" class="{{project.status.timesheetStatus.code}}">
                                <span class="{{project.status.timesheetStatus.code}}"> &nbsp;{{project.status.timesheetStatus.value}}&nbsp;</span>
                            </p>
                        </div>
                    </td>
                </tr>
                <tr *ngFor="let project of weekObj.projects let i=index;" class="leave-row" [hidden]="project.type != 'NBL'">
                    <td class="first-child">
                        <span class="Desktop_timesheet">{{project.name}}</span>
                        <span class="mobile_timesheet">{{project.type}}</span>
                        <i
                            *ngIf="weekObj.code === 'PENDING_SUBMISSION'|| weekObj.code === 'PENDING' || weekObj.code === 'SAVED' || project.status.timesheetStatus && (project.status.timesheetStatus.code === 'SAVED' || project.status.timesheetStatus.code === 'REJECTED')"
                            class="fa fa-times"
                            aria-hidden="true"
                            (click)="clearLeave(project)"
                        ></i>
                    </td>
                    <td matTooltip="{{dayHour.remarks}}" *ngFor="let dayHour of project.hours; let j = index; trackBy:trackByIndex;" [ngClass]="[weekObj.daysData[j].code === 'HD' ? 'holiday' : '']">
                        <input
                            [readonly]="hasAuthority()"
                            id="{{i}}{{'-'}}{{j}}"
                            matInput
                            class="hours-input time-input"
                            maxlength="4"
                            [(ngModel)]="dayHour.hour"
                            [ngModelOptions]="{updateOn: 'blur'}"
                            (click)="popupTrigger($event)"
                            (keypress)="numberOnly($event)"
                            (click)="$event.stopPropagation()"
                            (focusout)="focusOutFunction($event,isLeaveAdded,weekDates[j].date)"
                            *ngIf="
                               weekObj.code === 'PENDING_SUBMISSION' || 
                               weekObj.code === 'PENDING' || 
                               weekObj.code === 'SAVED' || 
                               weekObj.code === 'REJECTED' || 
                               (weekObj.code === 'PARTIALLY_REJECTED' &&  !project.status.timesheetStatus) || 

                               
                               project.status.timesheetStatus 
                               && 
                               (
                                  project.status.timesheetStatus.code === 'SAVED' || 
                                  project.status.timesheetStatus.code === 'REJECTED' || 
                                  (weekObj.code !='PARTIALLY_APPROVED_PENDING' &&  project.status.timesheetStatus.code === 'SUBMITTED' && recall === true )
                                ) || 
                                
                                weekObj.code === 'SUBMITTED' && recall === true || 
                                (
                                    weekObj.code === 'PARTIALLY_REJECTED_PENDING' || 
                                    
                                    project.status.timesheetStatus && 
                                    project.status.timesheetStatus.code !== 'SUBMITTED' && 
                                    project.status.timesheetStatus.code !== 'APPROVED' && 
                                    project.status.timesheetStatus.code !== 'SAVED'
                                )

                                ||     
                                    
                                    !project.status.timesheetStatus 
                            "
                        />
                        <span
                            *ngIf=" (
                                     weekObj.code === 'APPROVED' || 
                                     weekObj.code === 'SUBMITTED' || 
                                     weekObj.code === 'PARTIALLY_APPROVED' || 
                                     weekObj.code === 'PARTIALLY_SUBMITTED' ||
                                     weekObj.code === 'PARTIALLY_REJECTED' ||
                                     weekObj.code === 'PARTIALLY_APPROVED_PENDING' || 
                                     weekObj.code === 'PARTIALLY_APPROVED_REJECTED' ||
                                     weekObj.code === 'PARTIALLY_APPROVED_REJECTED_PENDING' 
                                 ) 
                                 && 
                                 project.status.timesheetStatus 
                                 && 
                                 (  
                                     project.status.timesheetStatus.code === 'APPROVED' || 
                                     project.status.timesheetStatus.code === 'SUBMITTED' ||
                                     (project.status.timesheetStatus.code === 'SUBMITTED' && recall === false) ||
                                     (
                                          weekObj.code === 'PARTIALLY_REJECTED_PENDING' && 
                                          project.status.timesheetStatus && 
                                          project.status.timesheetStatus.code === 'SUBMITTED'
                                     )
                                 )"
                        >
                            <span
                                *ngIf="!(
                                              weekObj.code === 'SUBMITTED' &&  
                                              project.status.timesheetStatus.code === 'SUBMITTED'  && 
                                              recall === true)"
                            >
                                {{dayHour.hour}}
                            </span>
                        </span>
                    </td>
                    <td>
                        <span *ngIf='weekObj.code==="PENDING_SUBMISSION" || weekObj.code === "PENDING" || weekObj.code==="SAVED" || (weekObj.code === "SUBMITTED" && recall === true)'>
                            <input [readonly]="hasAuthority()" class="hours-input" maxlength="2" value="{{calculateProjectHours(project.hours)}}" disabled="true" />
                        </span>
                        <span
                            *ngIf="(weekObj.code === 'SUBMITTED' && recall === false) ||
                            weekObj.code === 'REJECTED' ||
                            weekObj.code === 'APPROVED' ||
                            weekObj.code === 'PARTIALLY_APPROVED_REJECTED' ||
                            weekObj.code === 'PARTIALLY_SUBMITTED' || 
                            weekObj.code === 'PARTIALLY_APPROVED' || weekObj.code === 'PARTIALLY_APPROVED_PENDING' || weekObj.code === 'PARTIALLY_APPROVED_REJECTED_PENDING' ||
                            weekObj.code === 'PARTIALLY_REJECTED_PENDING' ||  weekObj.code === 'PARTIALLY_REJECTED'"
                        >
                            {{calculateProjectHours(project.hours)}}
                        </span>
                        <i class="material-icons" [hidden]="!project.attachment">attach_file</i>
                    </td>
                    <!-- <td></td> -->
                    <td>
                        <div class="projectWise-status">
                            <p *ngIf="project.status && project.status.timesheetStatus" class="{{project.status.timesheetStatus.code}}">
                                <span class="{{project.status.timesheetStatus.code}}"> &nbsp;{{project.status.timesheetStatus.value}}&nbsp;</span>
                            </p>
                        </div>
                    </td>
                </tr>
                <tr class="total-row">
                    <td class="first-child totalHoursHeading">
                        <b>Total Hours Per Day</b>
                    </td>
                    <td
                        *ngFor="let day of weekObj.daysData; let k = index; trackBy:trackByIndex;"
                        [style.border]="( (calculateDayHours(weekObj.projects,k, 0) > weekObj.dailyStandardHours) && (day.comment != null && day.comment.length === 0 ) ) ? '2px solid red' : '1px solid lightgray' "
                    >
                        <div
                            id="{{k}}"
                            placement="bottom"
                            [ngbPopover]="commentNeeded"
                            #p="ngbPopover"
                            triggers="click"
                            class="totalDayHoursDiv"
                            (click)="popupTriggerTotal()"
                            (document:click)="p.close()"
                            [autoClose]="'outside'"
                            (click)="$event.stopPropagation()"
                        >
                            <span id="{{k}}">{{calculateDayHours(weekObj.projects,k, 1)}}</span>
                        </div>
                        <span *ngIf="day.comment != null && day.comment.length > 0 ">
                            <i class="fa fa-comment" aria-hidden="true" placement="bottom" [ngbPopover]="popCommentNeeded" triggers="mouseenter:mouseleave"></i>
                        </span>
                        <ng-template #commentNeeded>
                            <div>
                                <mat-form-field>
                                    <textarea jhiAutoFocus matInput cols="40" rows="3" maxlength="250" type="text" [(ngModel)]="day.comment" (click)="$event.stopPropagation()"></textarea>
                                </mat-form-field>
                            </div>
                        </ng-template>
                        <ng-template #popCommentNeeded>
                            {{day.comment}}
                        </ng-template>
                    </td>
                    <td>
                        <span class="hours-input">{{calculateTotalHours(weekObj.projects)}}</span>
                    </td>
                    <td></td>
                </tr>
            </table>
        </div>
    </div>
    <ng-template #currentAllocations>
        <div id="myModal2" class="modal2" style="display: block;">
            <span class="closeimg" (click)="closeCurrentAllocations()" onclick="document.getElementById('myModal2').style.display='none'">�</span>
            <img class="modal-content2" id="img02" [src]="clientTimesheetImage" />
        </div>
    </ng-template>
    <div
        class="leave-section"
        *ngIf='(weekObj.code ==="PENDING_SUBMISSION" || weekObj.code === "PENDING" || weekObj.code === "SAVED" || weekObj.code === "PARTIALLY_REJECTED_PENDING" || weekObj.code === "PARTIALLY_REJECTED"  || weekObj.code === "PARTIALLY_APPROVED_REJECTED" || weekObj.code === "REJECTED" || (weekObj.code === "SUBMITTED" && recall === true)) && openTimesheet'
    >
        <mat-select placeholder="Add Leaves" [(value)]="selectedLeave" (selectionChange)="selectLeave($event)" class="pending-entries leave-section-left" *ngIf="!canViewLMS">
            <mat-option *ngFor="let leave of leaveTypes;" [value]="leave.name">{{leave.name}}</mat-option>
        </mat-select>
        <mat-select placeholder="Add Leaves" [(value)]="selectedLeave" (selectionChange)="selectLeave($event)" class="pending-entries leave-section-left" *ngIf="canViewLMS">
            <mat-option value="NonBillable Working Hrs (100%)">NonBillable Working Hrs (100%)</mat-option>
        </mat-select>
    </div>

    <mat-divider
        *ngIf='weekObj.code === "PENDING_SUBMISSION" || weekObj.code === "PENDING" || weekObj.code === "SAVED" || weekObj.code === "PARTIALLY_REJECTED_PENDING" || weekObj.code === "PARTIALLY_APPROVED_REJECTED" || weekObj.code === "REJECTED" || (weekObj.code === "SUBMITTED" && recall === true)'
    >
    </mat-divider>
    <div class="mobile_timesheet">
        <div class="timesheet_monthly_view total_hrs_view">
            <div class="sheet_view">
                <div class="week_view">
                    Total Hours for Week
                </div>
                <div class="ttl_hrs">{{calculateTotalHours(weekObj.projects)}}.00</div>
            </div>
        </div>
    </div>
    <div class="spacer" *ngIf="openTimesheet"></div>
    <div class="spacer" *ngIf='(weekObj.code === "PENDING_SUBMISSION" || weekObj.code === "PENDING") && !fixedweekoff'>* Please select the week-off for the week. Week-Off once saved/submitted cannot be removed for the week.</div>
    <div>
        <div class="col-6" style="float: left; padding: 20px;">
            <mat-card-title class="title mb-0">
                <strong>Refer & Earn</strong>
            </mat-card-title>
            <div class="my-timesheets">
                <div class="pending-details">
                    <div *ngFor="let jobs of allJobs;let i=index">
                        <div *ngIf="i<=2">
                            <div class="detail-separator"></div>
                            <div>
                                <div class="detail row" style="padding-bottom: 0;">
                                    <div class="col">
                                        <div class="detail-desc">
                                            <span class="row-job-title" (click)="redirectTo(jobs)"> <b>{{jobs.jobTitle}}</b> </span> &nbsp;
                                            <span *ngIf="jobs.hotFlag">
                                                <button style="background-color: #efa522; color: #ffffff; opacity: 100%; border-radius: 25px; font-size: 12px; font-weight: 700; border: none;">HOT</button> <br />
                                                <b> Reward Points - {{jobs.reward}}</b>
                                            </span>
                                            <p>
                                                Location - {{jobs.locations}} | Experience- {{jobs.expMin}}<span *ngIf="jobs.expMax === 0">+ Years</span>
                                                <span *ngIf="jobs.expMax !== 0"> - {{jobs.expMax}} years</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col" style="line-height: 70px;">
                                        <div class="detail-action">
                                            <a class="submit_referral_link" (click)="makeReferral(jobs)"> Make a Referral </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="allJobsLength >3" class="col-4" style="margin-top: 20px;">
                <button mat-button class="btn btn-primary btn-block" style="color: white;" skipLocationChange routerLink="/referrals/submit-referrals"><strong>See All Positions</strong></button>
            </div>
        </div>
        <div class="col-6" style="float: left;" *ngIf="openTimesheet && canEdit" id="button-container">
            <button
                mat-raised-button
                [ngClass]="{'imageuploading': isLoading}"
                id="save-button"
                (click)="updateTimesheet()"
                *ngIf='weekObj.code === "PARTIALLY_REJECTED_PENDING" || weekObj.code === "PARTIALLY_APPROVED_REJECTED" || weekObj.code === "REJECTED" || weekObj.code === "PARTIALLY_APPROVED_REJECTED_PENDING" || weekObj.code === "PARTIALLY_REJECTED" || weekObj.code === "PARTIALLY_APPROVED" || weekObj.code === "PARTIALLY_SUBMITTED" || (weekObj.code === "PARTIALLY_APPROVED_PENDING" && saveStatusPresent === true)'
            >
                Save
            </button>
            <button
                mat-raised-button
                [ngClass]="{'imageuploading': isLoading}"
                id="submit-button"
                (click)="submitStatusUpdate()"
                *ngIf='weekObj.code === "PARTIALLY_REJECTED_PENDING" || weekObj.code === "PARTIALLY_APPROVED_REJECTED" || weekObj.code === "REJECTED" || weekObj.code === "PARTIALLY_APPROVED_REJECTED_PENDING" || weekObj.code === "PARTIALLY_REJECTED" || weekObj.code === "PARTIALLY_APPROVED" || weekObj.code === "PARTIALLY_SUBMITTED" || (weekObj.code === "PARTIALLY_APPROVED_PENDING" && saveStatusPresent === true)'
            >
                Submit
            </button>
            <button mat-raised-button [ngClass]="{'imageuploading': isLoading}" id="save-button" (click)="saveOrSubmit('SAVE')" *ngIf='weekObj.code === "PENDING_SUBMISSION" && weekObj.links && weekObj.links["itrack:create"].href'>
                Save
            </button>
            <button mat-raised-button [ngClass]="{'imageuploading': isLoading}" id="save-button" (click)="updateTimesheet()" *ngIf='weekObj.code === "PENDING" && weekObj.links && weekObj.links["itrack:update"].href'>
                Save
            </button>

            <button
                mat-raised-button
                [ngClass]="{'imageuploading': isLoading}"
                id="submit-button"
                (click)="submitStatusUpdate()"
                *ngIf='weekObj.code === "PENDING" && weekObj.links && weekObj.links["itrack:update"].href && currentWeekSubmit'
            >
                Submit
            </button>
            <button
                mat-raised-button
                [ngClass]="{'imageuploading': isLoading}"
                id="submit-button"
                (click)="saveOrSubmit('SUBMIT')"
                *ngIf='weekObj.code === "PENDING_SUBMISSION" && weekObj.links && weekObj.links["itrack:create"].href && currentWeekSubmit'
            >
                Submit
            </button>
            <button mat-raised-button [ngClass]="{'imageuploading': isLoading}" id="save-button" (click)="updateTimesheet()" *ngIf='weekObj.code === "SAVED" && weekObj.links && weekObj.links["itrack:update"].href'>Save</button>
            <button mat-raised-button [ngClass]="{'imageuploading': isLoading}" id="submit-button" (click)="submitStatusUpdate()" *ngIf='weekObj.code === "SAVED" && weekObj.links && weekObj.links["itrack:submit"].href && currentWeekSubmit'>
                Submit
            </button>
            <button mat-raised-button [ngClass]="{'imageuploading': isLoading}" id="submit-button" (click)="recallTimesheet()" *ngIf='weekObj.code === "SUBMITTED" && recall === false'>Recall Timesheet</button>
            <button mat-raised-button [ngClass]="{'imageuploading': isLoading}" id="submit-button" (click)="onRecallsubmitStatusUpdate()" *ngIf='weekObj.code === "SUBMITTED" && recall === true && recallCommentSubmistion==false'>
                Submit
            </button>
            <button mat-raised-button [ngClass]="{'imageuploading': isLoading}" id="submit-button" (click)="submitStatusUpdate()" *ngIf='weekObj.code === "SUBMITTED" && recall === true && recallCommentSubmistion==true'>
                Submit
            </button>
        </div>
    </div>
    <ng-template #content let-c="close" let-d="dismiss">
        <div class="modal-body">
            <div class="mod-body-custom">{{jsAlertReplaceMessage}}</div>
        </div>
        <button autofocus type="button" class="btn btn-light js-alert-replace" (click)="c('Close click')">Close</button>
    </ng-template>
</mat-card>
<div class="dark-overlay" *ngIf="load">
    <mat-spinner class="loader" strokeWidth="3" [diameter]="50"></mat-spinner>
</div>
