<jhi-page-ribbon></jhi-page-ribbon>
<div *ngIf = "isLoggedIn">
    <router-outlet name="navbar"></router-outlet>
</div>
<div *ngIf = "!isLoggedIn" class="itrack-plain-nav">
    <span>aperture</span>
</div>
<div class="container-fluid" [ngClass]="{'no-padding-left': isLeavePage}">
    <div class="card jh-card" [ngClass]="{'no-padding-top': router.url === '/leave-management/leave-dashboard'}">
        <router-outlet (activate)="changeOfRoutes()"></router-outlet>
        <router-outlet name="popup"></router-outlet>
    </div>
    <!-- <ng4-loading-spinner> </ng4-loading-spinner> -->
</div>
<jhi-footer></jhi-footer>
<jhi-notifications></jhi-notifications>
