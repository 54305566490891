<div class="container-privacy-policy">
   <h1>Privacy Policy</h1>

   <p>GSPANN Technologies, Inc has built the Aperture Mobile app as a Commercial app. This ‘SERVICE’ is provided by
      GSPANN Technologies, Inc and is intended for use as is.
      This page will help inform visitors regarding our policies with the collection, use, and disclosure of ‘Personal
      Information’ if anyone decides to use our service.

      By choosing to use our Service, you agree to the collection and use of information in relation to this policy. The
      personal information that we collect is used for providing and improving the Service. We will not use or share
      your information with anyone except as described in this Privacy Policy.</p>

   <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible
      at Aperture Mobile unless otherwise defined in this Privacy Policy.</p>

   <h2>Information Collection and Use</h2>

   <p>For a better experience, while using our Service, we may require you to provide us with certain personally
      identifiable information. The information that we request will be retained by us and used as described in this
      privacy policy.

      The app does use third-party services that may collect information used to identify you.</p>

   <p>Link to the privacy policy of third-party service providers used by the app:</p>
   <ul>
      <li>
         <a href="https://www.google.com/policies/privacy/" target="_blank">Google Play Services</a>
      </li>
      <li>
         <a href="https://firebase.google.com/policies/analytics" target="_blank">Google Analytics for Firebase</a>
      </li>
      <li>
         <a href="https://firebase.google.com/support/privacy/" target="_blank">Firebase Crashlytics</a>
      </li>
   </ul>

   <h2>Log Data</h2>
   <p>We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and
      information (through third-party products) on your phone called Log Data. This Log Data may include information
      such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of
      the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>

   <h2>Cookies</h2>
   <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are
      sent to your browser from the websites that you visit and are stored on your device's internal memory.</p>

   <p>This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that
      use “cookies” to collect information and improve their services. You have the option to either accept or refuse
      these cookies and know when a cookie is being sent to your device. If you choose to refuse these cookies, you may
      not be able to use some portions of this Service.</p>

   <h2>Service Providers</h2>
   <h5>We may employ third-party companies and individuals due to the following reasons:</h5>
   <ul>
      <li>To facilitate our Service;</li>
      <li>To provide the Service on our behalf;</li>
      <li>To perform Service-related services; or</li>
      <li>To assist us in analysing how our Service is used.</li>
   </ul>
   <p>We want to inform users of this service that these third parties have access to their Personal Information. The
      reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use
      the information for any other purpose.</p>

   <h2>Security</h2>
   <p>We value your trust in providing us with your personal information, thus we are striving to use commercially
      acceptable means of protecting it. But remember that no method of transmission over the internet, or method of
      electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

   <h2>Changes to This Privacy Policy</h2>
   <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any
      changes. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

   <p><strong>This policy is effective as of 2023-02-07</strong></p>

   <h2>Contact Us</h2>

   <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a
         herf="mailto:aperture.admin@gspann.com.">aperture.admin@gspann.com</a>.</p>
</div>