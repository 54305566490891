import { Component, Inject, OnInit } from '@angular/core';
import { LeaveManagementService } from 'app/leave-management-system/leave-management.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DuplicatesTimesheetComponent } from 'app/duplicates-view/duplicates-view.component';

@Component({
  selector: 'jhi-leave-update-confirmation-dialog',
  templateUrl: './leave-update-confirmation-dialog.component.html',
  providers: [LeaveManagementService]
})
export class LeaveUpdateConfirmationDialogComponent implements OnInit {
  displayJobType: any;
  local: any;
  loading: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DuplicatesTimesheetComponent>,
    private LMSService: LeaveManagementService,
    ) { }

  ngOnInit(): void {
    this.displayJobType = this.data.jobType
    this.local = this.data.updateFor
  }
  proceedWithLeaveUpdate () {
    this.loading = true;
    if (this.data.jobType === 'Leave Credit') {
      if (this.data.updateFor === 'onShore') {
          this.LMSService.updateOnShoreLeaveCredit().subscribe(response => {
            this.dialogRef.close('onShoreCredit');
            this.loading = false;
          });
        } else if (this.data.updateFor === 'offShore') {
          this.LMSService.updateOffShoreLeaveCredit().subscribe(response => {
            this.dialogRef.close('offShoreCredit');
            this.loading = false;
          });
        }
    } else if (this.data.jobType === 'Leave Debit') {
      if (this.data.updateFor === 'onShore') {
        this.LMSService.updateOnShoreLeaveDebit().subscribe(response => {
          this.dialogRef.close('onShoreDebit');
          this.loading = false;
        });
      } else if (this.data.updateFor === 'offShore') {
        this.LMSService.updateOffShoreLeaveDebit().subscribe(response => {
          this.dialogRef.close('offShoreDebit');
          this.loading = false;
        });
      }
    }
  }
  close () {
    this.dialogRef.close();
  }
}
