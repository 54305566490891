import { MatTableDataSource } from "@angular/material/table"
export const populateSortDataAccessort = (dataSource: MatTableDataSource<any>) => {
    dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
        if (typeof data[sortHeaderId] === 'string') {
          return data[sortHeaderId].trim().toLowerCase();
        }
     
        return data[sortHeaderId];
      };
    return dataSource;
}