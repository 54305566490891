import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

// import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { NotificationsService } from 'app/interceptors/notifications/notifications.service';
import { LoginService } from 'app/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  notificationData: any = [];

  constructor(
    // private spinnerService: Ng4LoadingSpinnerService,
    private notificationService: NotificationsService,
    private loginService: LoginService,
    private router: Router
  ) {
    localStorage.setItem('notificationData', JSON.stringify(this.notificationData));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.notificationData = JSON.parse(localStorage.getItem('notificationData'));
    // start our loader here
    // this._loadingBar.start();
    // this.spinner.show();
    // this.spinnerService.show();
    this.notificationData = [];

    return next.handle(req).pipe(tap(
      (event: HttpEvent<any>) => {
        // if the event is for http response

        if (event instanceof HttpResponse) {
          // stop our loader here
          if (event.headers.get('x-itrack-info')) {
            let notification: any = {};
            notification.message = event.headers.get('x-itrack-info');
            notification.type = 'success';
            notification.icon = 'fa fa-check-circle';
            this.notificationData.push(notification);
          }

          if (event.headers.get('x-itrack-warning')) {
            let notification: any = {};
            notification.message = event.headers.get('x-itrack-warning');
            notification.type = 'warning';
            notification.icon = 'fa fa-exclamation-triangle';
            this.notificationData.push(notification);
          }

          if (event.headers.get('x-aperture-leave-info')) {
            let notification: any = {};
            notification.message = event.headers.get('x-aperture-leave-info');
            notification.type = 'success';
            notification.icon = 'fa fa-check-circle';
            this.notificationData.push(notification);
          }

          // if (event.headers.get('x-itrack-error')) {
          //   let notification: any = {};
          //   notification.message = event.headers.get('x-itrack-error');
          //   notification.type = 'danger';
          //   notification.icon = 'fa fa-times-circle';
          //   this.notificationData.push(notification);
          // }
          localStorage.setItem('notificationData', JSON.stringify(this.notificationData));
          this.notificationService.show(this.notificationData);
          // this.spinnerService.hide();
        }
      },
      (err: any) => {
        if (err.status === 401) {
          this.loginService.login();
          return;
        }

        if (err.status === 403) {
          this.router.navigate(['forbidden']);
          return;
        }
        if (err.status === 404) {
          if (err.headers.get('x-itrack-error')) {
            let notification: any = {};
            notification.message = err.headers.get('x-itrack-error');
            notification.type = 'danger';
            notification.icon = 'fa fa-times-circle';
            this.notificationData.push(notification);
          }
          localStorage.setItem('notificationData', JSON.stringify(this.notificationData));
          this.notificationService.show(this.notificationData);
          // this.spinnerService.hide();
        }


        if (err.error) {
          let notification: any = {};
          if (err.error.message === 'error.http.500' && err.error.detail) {
            notification.message = err.error.detail;
          } else {
            notification.message = err.error.message;
          }
          notification.type = 'danger';
          notification.icon = 'fa fa-times-circle';
          this.notificationData.push(notification);
          this.notificationService.show(this.notificationData);
        }

        if (err.headers.get('x-itrack-info')) {
          let notification: any = {};
          notification.message = err.headers.get('x-itrack-info');
          notification.type = 'success';
          this.notificationData.push(notification);
        }

        if (err.headers.get('x-itrack-warning')) {
          let notification: any = {};
          notification.message = err.headers.get('x-itrack-warning');
          notification.type = 'warning';
          this.notificationData.push(notification);
        }

        // if (err.headers.get('x-itrack-error')) {
        //   let notification: any = {};
        //   notification.message = err.headers.get('x-itrack-error');
        //   notification.type = 'danger';
        //   this.notificationData.push(notification);
        // }

        localStorage.setItem('notificationData', JSON.stringify(this.notificationData));
        this.notificationService.show(this.notificationData);
        // this.spinnerService.hide();
        // if any error (not for just HttpResponse) we stop our loader bar
        // this.spinnerService.hide();
      }
    ));
  }
}
