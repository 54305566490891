<div mat-dialog-content style="text-align: center;">
    <p>Please enter comment</p>
    <mat-form-field style="width: 100%;">
        <textarea matInput [(ngModel)]="data.comment" (keyup)="lenChar($event)" maxlength="250" rows="6" cols="50" placeholder="Enter your comment here"> </textarea>
        
    </mat-form-field>
    <p class="remain_warn">Remaining character {{remainingcommentLen}}</p>
</div>

<mat-dialog-actions style="float: right;">
    <!-- <button mat-button (click)="onNoClick()" style="margin: 0 auto;">Cancel</button>
        <button mat-button [disabled]="data.type === 'REJECT' && !data.comment" (click)="submitted()" [mat-dialog-close]="data.comment"
            style="margin: 0 auto;">Submit</button> -->

    <button mat-button class="cancel-submit" (click)="onNoClick()">Cancel</button>
    <button mat-button class="confirm-submit" (click)="submitted()" [mat-dialog-close]="data.comment">Submit</button>
</mat-dialog-actions>