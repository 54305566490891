<div class="input-group fdms-mp-input align">
    <input type="text" [hidden]=true readonly value="{{model|date: 'MM/yyyy'}}" class="form-control" placeholder="{{config}}" />
    <span class="input-group-addon align" (click)="onCalendarIconClick()"><i class="fa fa-calendar iconstyle"></i></span>
    <div class="fdms-month-picker" *ngIf="__monthPicker.state=='open'">
        <div class="row col-md-12 d-title">{{__monthPicker.selectedMonth}}, {{__monthPicker.selectedYear}}</div>
        <mat-divider _ngcontent-c6="" class="mat-divider" role="separator" aria-orientation="horizontal"></mat-divider>
        <div class="row col-md-12 d-nav">
            <div class="col-md-3">
                <span [ngClass]="[previousYear == false ? 'disabled' : '']" [innerHTML]="previous" (click)="onPrevYearSelection()">
                </span>
            </div>
            <div class="col-md-6">
                <span (click)="switchToYearMode()">{{__monthPicker.displayYear}}</span>
            </div>
            <div class="col-md-3">
                <span [ngClass]="[nextYear == false ? 'disabled' : '']" (click)="onNextYearSelection()">></span>
            </div>
        </div>
        <div class="row col-md-12 d-selection-wraper" *ngIf="monthSelection">
            <div *ngFor="let item of __monthPicker.selectionItems;let i=index" [ngClass]="__monthPicker.selectedMonthIndex == i ? 'month-sel' : ''"
                class="col-md-3 d-selection-item">
                <span (click)="onselectionItemClick(item)">{{item.text}}</span>
            </div>
        </div>
        <div class="row col-md-12 d-selection-wraper" *ngIf="yearSelection">
            <div *ngFor="let item of __monthPicker.selectionItems;let i=index" [ngClass]="{'month-sel': (item.text >= minYearNumber && item.text <= maxYearNumber) }"
                class="col-md-3 d-selection-item">
                <span [ngClass]="{'disableCell': !(item.text >= minYearNumber && item.text <= maxYearNumber) }" (click)="onselectionItemClick(item)">
                    {{item.text}}
                </span>
            </div>
        </div>
    </div>