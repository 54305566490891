import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { enviornmentAperture } from 'environments/environment';
let httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  observe: 'response' as 'response'
};
@Injectable()
export class StatementOfWorkService {
  constructor(private http: HttpClient) {}
  getSearchSows(): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/sows/searchSOWsMetaData', {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  searchSowsListing(tableDataParams): Observable<any> {
    let construct_uri = '';
    tableDataParams.forEach((element, i) => {
      let specialChar;
      i === 0 ? (specialChar = '?') : (specialChar = '&');
      construct_uri = construct_uri + specialChar + element.key + '=' + element.value;
    });
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/sows' + construct_uri, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  searchSowsByProject(tableDataParams): Observable<any> {
    let construct_uri = '';
    tableDataParams.forEach((element, i) => {
      let specialChar;
      i === 0 ? (specialChar = '?') : (specialChar = '&');
      construct_uri = construct_uri + specialChar + element.key + '=' + element.value;
    });
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/sows/sowsByProject' + construct_uri, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  getAddSowMetadata(): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/sows/initAddSOWPage', {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  getSow(uri): Observable<any> {
    return this.http.get(uri, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  getImage(uri): Observable<any> {
    return this.http.get(uri, { responseType: 'text' as 'json' }).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  getSowWBS(id) {
    return this.http
      .get(enviornmentAperture.apiUrl + 'api/v1/sows/' + id + '?sowResultType=DETAILS_INCLUDING_WBSES', {})
      .pipe(map((response: HttpResponse<any>) => {
        return response;
      }));
  }
  addSow(data): Observable<any> {
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/sows', data, { observe: 'response' }).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  extendSow(data): Observable<any> {
    return this.http
      .post(enviornmentAperture.apiUrl + 'api/v1/sows/' + data.sowId + '/childSows', data, { observe: 'response' })
      .pipe(map((response: HttpResponse<any>) => {
        return response;
      }));
  }
  updateSow(sowNumber, data): Observable<HttpResponse<any>> {
    return this.http.put(enviornmentAperture.apiUrl + 'api/v1/sows/' + sowNumber, data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  getSowHistory(requestSowId): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/audit/sow/' + requestSowId);
  }
  getWBSMetaData(accountCode): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/sows/initEditWBSPage/' + accountCode).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  checkProjectEditWBS(wbsId): Observable<any> {
    return this.http
      .get(enviornmentAperture.apiUrl + 'api/v1/sows/' + wbsId + '/checkProjectUpdationStatus', { responseType: 'text' as 'json' })
      .pipe(map((response: HttpResponse<any>) => {
        return response;
      }));
  }
  updateWbs(sowId, wbsId, data): Observable<HttpResponse<any>> {
    return this.http.put(enviornmentAperture.apiUrl + 'api/v1/sows/' + sowId + '/wbses/' + wbsId, data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  // checkResourceType(sowId,resourceType): Observable<any> {
  //   return this.http
  //     .get(enviornmentAperture.apiUrl + 'api/v1/sows/'+sowId+'/checkResourceType?resourceType='+resourceType, { responseType: 'text' as 'json' })
  //     .map((response: HttpResponse<any>) => {
  //       return response;
  //     });
  // }
  deleteWBS(sowId, wbsId): Observable<any> {
    return this.http.delete(enviornmentAperture.apiUrl + 'api/v1/sows/' + sowId + '/wbses/' + wbsId, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  addWBSToSow(sowId, data): Observable<any> {
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/sows/' + sowId + '/wbses', data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  copyWBSToSow(sowId, data): Observable<any> {
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/sows/' + sowId + '/wbses', data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  exportSowtListing(tableDataParams): Observable<any> {
    let construct_uri = '';
    tableDataParams.forEach((element, i) => {
      let specialChar;
      i === 0 ? (specialChar = '?') : (specialChar = '&');
      construct_uri = construct_uri + specialChar + element.key + '=' + element.value;
    });
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/sows/export' + construct_uri, { responseType: 'blob' });
  }
}
