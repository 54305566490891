<div class="leave-update-confirmation">
    <h2 id='main-heading'>Warning</h2>
    <p>
        Do you want to execute <strong>{{displayJobType}}</strong> balance job for <strong>{{local}}</strong>? Because this will impact all the user's leave balance. Think again before execute.
    </p>
    <div class="col-12 text-right">
        <button mat-raised-button color="primary"(click)='proceedWithLeaveUpdate()' class="mr-3">Proceed</button>
        <button mat-raised-button color="primary" (click)='close()'>Cancel</button>
    </div>
</div>
<div class="dark-overlay" *ngIf="loading">
    <mat-spinner class="loader" strokeWidth="3" [diameter]="50"></mat-spinner>
</div>