<div class="faqs">
    <div class="row">
        <div class="col">
            <h2 id='main-heading'>Frequently Asked Questions</h2>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-card class="mat-card">
                <mat-card-content>
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    How to fill timesheet?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ol>
                                <li> Click on the timesheet tab and select 'My Timesheet' from the dropdown menu.</li>
                                <li> User will navigate to current week timesheet by default.</li>
                                <li> Fill the TS.</li>
                                <li> Click on Monthly/Date range tab and check the pending week timesheet on the top
                                    right of the page.</li>
                                <li> Select pending week timesheet and fill timesheet.</li>
                                <li> User needs to fill daily working hours starting from Monday to Friday (Correct
                                    hours)</li>
                                <li>If user has worked for more than & less than 8 hours a day, then he/she must enter
                                    comments as justification.</li>
                                <li>User can’t enter more then 24 hrs any day.</li>
                            </ol>
                        </mat-expansion-panel>
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    How to apply leaves?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ol>
                                <li> Users select the correct leave type from Add Leaves dropdown.</li>
                                <li>User needs to fill specific number of hours if he/she takes leave in weekdays</li>
                            </ol>
                        </mat-expansion-panel>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Timesheet status?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ol>
                                <li> Saved :- User have already saved his/her timesheet but not submitted.</li>
                                <li> Submitted :- User already submitted timesheet.</li>
                                <li> Approved :- After approved from user's respective manager, it will show Approved
                                    status.</li>
                                <li> Partially Approved :- When user is engaged with 2-3 projects and one manager
                                    approved your timesheet and pending from other managers.</li>
                                <li> Partially Rejected :- When user is engaged with 2-3 projects and one manager
                                    approved his/her timesheet and rejected from other managers.</li>
                                <li>Rejected:- After rejected from user's respective manager, it will show rejected
                                    status.</li>
                            </ol>
                        </mat-expansion-panel>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    How to update the submitted timesheet?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ol>
                                <li> Saved timesheet can be updated.</li>
                                <li>Submitted timesheet can be updated from Recall button.</li>
                                <li> Once manager rejected the timesheet then user can update the timesheet.</li>
                            </ol>
                        </mat-expansion-panel>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    How to approve/reject the timesheets by manager?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ol>
                                <li> When the user submitted the timesheet then respective manager/approver will get the
                                    Timesheet in Timesheet approval page.</li>
                                <li> Manager/approver will navigate to timesheet approval page</li>
                                <li> Select correct projects/resource to approve or reject that timesheet with a
                                    relevant comment
                                </li>
                            </ol>
                        </mat-expansion-panel>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    What happens when approver rejects the timesheet?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p> Status will change to Rejected, fill and submit timesheet again..</p>
                        </mat-expansion-panel>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    How to contact Aperture Admin?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>Open GSPANN helpdesk or <a href="https://gspann.freshservice.com/"
                                    target="_blank" style="color: #0EB3F7;">Click here </a>and fill all the required
                                details and choose "Help Topic" as "aperture - helpdesk". Email communication can be
                                done with ar@gspann.com or krishna.garg@gspann.com. Please note that
                                aperture.admin@gspann.com is not been monitored physically.</p>
                        </mat-expansion-panel>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    How to Export Timesheet Report?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ol>
                                <li> Navigate to My Timesheet page.</li>
                                <li> Click Export Timesheet Report , select status and select Start date and Till date
                                    and click export button.</li>
                            </ol>
                        </mat-expansion-panel>

                    </mat-accordion>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>