import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'jhi-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
  closeWindow() {
    let win = window.open('about:blank', '_self');
    win.close();
  }
}
