import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { enviornmentAperture } from 'environments/environment';
import { createRequestOption } from 'app/shared/util/request-util';
import { IUser } from './user.model';

@Injectable()
export class UserService {
    private resourceUrl = enviornmentAperture.apiUrl + 'api/users';

    constructor(private http: HttpClient) {}

    query(req?: any): Observable<HttpResponse<IUser[]>> {
        let options = createRequestOption(req);
        return this.http.get<IUser[]>(this.resourceUrl, { params: options, observe: 'response' });
    }
}
