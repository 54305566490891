import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MasterDatabaseService } from '../master-database.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
declare global {
    interface String {
        initCap(): string;
    }
}

@Component({
    selector: 'jhi-add-office-locations',
    templateUrl: './add-office-locations.component.html',
    styleUrls: ['./add-office-locations.component.css'],
})

export class AddOfficeLocationsComponent implements OnInit {
    eLocations;
    allCountries;
    allStates;
    selectedData;
    selectedOption: number;
    selectedCompany: number;
    selectedCountry: string;
    selectedState: string;
    selectedCity: number;
    countryName: string;
    cityName: string;
	isAddOffice = false;

    selectedCityId: number;
    displayedColumns: string[] = ['companyName', 'countryCode', 'stateName', 'cityName', 'options'];
    dataSource: MatTableDataSource<any>;
    isValidFormSubmitted: boolean = null;
    addGOfficeFlag = false;
    addWOfficeFlag = false;
    countryList: any = [];
    stateList: any = [];
    states: any[];
    cities: any[];
    cityList: any[];

    userForm = new FormGroup({
        selectOffice: new FormControl('', Validators.required),
        gspann_companyId: new FormControl({ value: '', disabled: true }),
        whisk_companyId: new FormControl({ value: '', disabled: true }),
        countryCode: new FormControl('', Validators.required),
        stateName: new FormControl('', Validators.required),
        cityName: new FormControl('', Validators.required)
    });
    @ViewChild(MatPaginator) paginator: MatPaginator;
    cityID: any;
    countryModel: string;
	companies: any;

    displayFn(option) {
        if (option && option.countryName) {
            return option.countryName;
        } else {
            return option;
        }
    }

    constructor(private masterDataService: MasterDatabaseService,
        private titleService: Title,
        public dialog: MatDialog, private fb: FormBuilder) {
        this.titleService.setTitle('aperture - Offices');
        this.userForm.get('countryCode').valueChanges.subscribe(x => {
            let self = this;
            this.allCountries = this.countryList.filter(function(option) {
                if (x && x.countryName) {
                    x = x.countryName;
                }
                let filterValue = x ? x.toLowerCase() : '';
                return option.countryName.toLowerCase().includes(filterValue);
            });
            if (x === '') {
                this.userForm.get('stateName').setValue('');
                this.userForm.get('cityName').setValue('');
                this.selectedState = '';

            }
        });
        this.userForm.get('stateName').valueChanges.subscribe(x => {
            let self = this;
            this.states = this.stateList.filter(function(option) {
                if (x && x.value) {
                    x = x.value;
                }
                let filterValue = x ? x.toLowerCase() : '';
                return option.stateName.toLowerCase().includes(filterValue);
            });
            if (x === '') {
                this.userForm.get('cityName').setValue('');
            }
        });
        this.userForm.get('cityName').valueChanges.subscribe(x => {
            let self = this;
            if (this.cityList) {
                this.cities = this.cityList.filter(function(option) {
                    if (x && x.value) {
                        x = x.value;
                    }
                    let filterValue = x ? x.toLowerCase() : '';
                    return option.cityName.toLowerCase().includes(filterValue);
                });
            }
        });
    }

    ngOnInit() {
        this.getAllOffices();
        this.getExistingLocations();
    }

    getAllOffices() {
        this.masterDataService.getAllOffices().subscribe(res => {
			this.companies = res.companies;
            this.allCountries = res.locationsList;
            let countrySort = this.allCountries.sort(function(a, b) {
                if (a.countryName > b.countryName) {
                    return 1;
                }
                if (a.countryName < b.countryName) {
                    return -1;
                } else {
                    return 0;
                }
            });
            let removeIndex = this.allCountries.map(function(item) {
                return item.countryName;
            }).indexOf('ALL');
            if (removeIndex >= 0) {
                this.allCountries.splice(removeIndex, 1);
            }
            this.countryList = res.locationsList.slice();
        });
    }

    getExistingLocations() {
        this.masterDataService.getExistingLocation().subscribe(res => {
            this.eLocations = res;
            this.dataSource = new MatTableDataSource(this.eLocations);
            this.dataSource.paginator = this.paginator;
        });

    }

    countrySelected(data, name) {
        if (name === 'country') {
            this.countryList.filter(option => {
                if (option.countryName === data.option.value.countryName) {
                    this.states = option.states;
                    let stateSort = this.states.sort(function(a, b) {
                        if (a.stateName > b.stateName) {
                            return 1;
                        }
                        if (a.stateName < b.stateName) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    this.stateList = option.states.slice();
                    return option.states;
                }

            });
        } else {
            if (name === 'state') {
                this.stateList.filter(option => {
                    if (option.stateName === data.option.value) {
                        this.cities = option.cities;
                        let citySort = this.cities.sort(function(a, b) {
                            if (a.cityName > b.cityName) {
                                return 1;
                            }
                            if (a.cityName < b.cityName) {
                                return -1;
                            } else {
                                return 0;
                            }
                        });
                        this.cityList = option.cities.slice();
                        return option.cities;
                    }

                });
            }
        }
    }

    stateSelected(data) {
    }

    citySelected1(data) {
        this.cityID = data.cityId;
    }

    addOfficeForm() {
        String.prototype.initCap = function() {
            return this.toLowerCase().replace(/(?:^|\b)[a-z]/g, function(m) {
                return m.toUpperCase();
            });
        };
		this.isAddOffice = true;
		setTimeout(()=>this.isAddOffice = false,2000);
        this.selectedData = this.userForm.getRawValue();
        this.cityName = this.userForm.get('cityName').value.trim().initCap();
        let newOffice = {
            cityId: this.cityID? this.cityID: '',
            companyId: this.userForm.get('selectOffice').value.company.key,
            name: this.cityName,
            state: {
                country: {
                    code: this.userForm.get('countryCode').value.countryCode,
                    name: this.userForm.get('countryCode').value.countryName,
                },
                name: this.userForm.get('stateName').value.trim().initCap(),
            }
        };
        // this.cityID ? newOffice.cityId = this.cityID : '';
        this.masterDataService.createOffice(newOffice).subscribe(res => {
            this.getExistingLocations();
        });
        this.userForm.reset();
		this.closeOfficeForm();
        this.getExistingLocations();
    }

    closeOfficeForm() {
        this.userForm.reset();
    }

    Search(event: Event) {
        let filteValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filteValue.trim().toLowerCase();
    }

    openDialog(location): void {

        let dialogRef = this.dialog.open(EditOfficeComponent, {
            width: '150rem',
            height: '20rem',
            data: {
                selectedOption: this.selectedOption,
                companyId: location.companyId,
                companyName: location.companyName,
                countryCode: location.countryCode,
                countryName: location.countryName,
                stateName: location.stateName,
                cityName: location.cityName,
                cityId: location.cityId,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.getExistingLocations();
        });
    }
}

@Component({
    selector: 'EditOffice',
    templateUrl: 'EditOffice.html',
    styleUrls: ['./add-office-locations.component.css'],

})
export class EditOfficeComponent implements OnInit {
    allCountries;
    updatedData;

    selectedCountry = this.data.countryCode;
    countryName = this.data.countryName;
    selectedState = this.data.stateName;
    selectedCity = this.data.cityName;
    cityId = this.data.cityId;
    countryList: any = [];
    EditForm = new FormGroup({
        companyId: new FormControl({ value: '', disabled: true }),
        companyName: new FormControl({ value: '', disabled: true }),
        countryCode: new FormControl({ value: '', disabled: true }),
        stateName: new FormControl({ value: '', disabled: true }),
        cityName: new FormControl('', Validators.required)
    });
    constructor(
        public dialofRef: MatDialogRef<EditOfficeComponent>,
        private masterDataService: MasterDatabaseService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        this.getAllLocations();
    }

    getAllLocations() {
        this.masterDataService.getAllOffices().subscribe(res => {
            this.allCountries = res.locationsList;
            this.countryList = res.locationsList.slice();
        });
    }
    stateSelected(data) {
    }

    citySelected(data) {
        this.cityId = data.cityId;
    }
    countrySelected(data) {
        this.countryName = data.countryName;
    }

    Edit() {

        let updateOffice;
        updateOffice = {
            cityId: this.cityId,
            name: this.selectedCity,
            companyId: this.data.companyId,
            state: {
                name: this.selectedState,
                country: {
                    code: this.selectedCountry,
                    name: this.countryName,
                }
            }
        };
        this.masterDataService.updateOffice(updateOffice).subscribe(res => {
            this.Close();
        });
        this.getAllLocations();

    }

    Close() {
        this.dialofRef.close();
    }
}
