import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Principal } from 'app/core';
// import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ReferralsService } from '../referrals.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { PickDateAdapter, PICK_FORMATS } from 'app/validators/validator';
export const DATE_FORMATE = 'yyyy-MM-dd';

@Component({
    selector: 'jhi-submit-referral',
    templateUrl: 'submit-referral.component.html',
    styleUrls: ['./submit-referral.component.scss'],
    providers: [{ provide: DateAdapter, useClass: PickDateAdapter }, { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }]
})
export class SubmitReferralComponent implements OnInit, AfterViewInit {

    displayedColumns: string[] = ['srNo', 'jobTitle', 'locations', 'practices', 'expMin', 'publishDate', 'reward', 'makereferralButton', 'hotJobs','onDemand'];
    displayedColumns2: string[] = ['mysrNo', 'name', 'jobTitle', 'creation_date', 'myRewards', 'applicantStatus'];
    mailDisplayedColumns: string[] = ['srNo', 'jobTitle', 'practices', 'publishDate', 'jobNotificationDate', 'notificationType','unschedule'];
    openPositionsDataSource = new MatTableDataSource([]);
    myReferralsDataSource = new MatTableDataSource([]);
    mailDataSource = new MatTableDataSource([]);
    uploadResume: any;
    locationsData: any;
    isLoading: boolean = false;
    position: any;
    jobLocation: any;
    practices: any;
    jobExperience: any;
    allComplete: boolean = false;
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('paginator2') paginator2: MatPaginator;
    @ViewChild('paginator3') paginator3: MatPaginator;
    allJobs: any;
    closed = false;
    scheduledClose = false;
    unscheduledClose = false;
    resourceCode: string;
    getMyReferrals: any;
    filteredJobs: any;
    searchField: any;
    selectedIndex: number = 0;
    openPositionsTotal: any;
    mailPositionsTotal: any;
    myReferralsTotal: any;
    showFirstLastButtons = true;
    allJobsUnique: any;
    locationGroup = [];
    allExperienceUnique: any;
    expGroup = [];
    allTitlesArray = [];
    jobToUpload: File;
    jobsToUpload: boolean;
    jobFileSelected: boolean;
    displayJobFileName: string;
    jobFilesSize: number = 10485760;
    fileData: string;
    openPositions: any[];
    exxcelFileSelected: boolean = false;
    referredPositions: any[];
    resCode: string;
    checkHotJobs: boolean;
    searchRecordsFounds: boolean;
    tableSort: any[];
    locSort: any;
    jobedit: FormGroup;
    jobNotificationForm: FormGroup;
    editAllocModelRef: NgbModalRef;
    mailNotificationModelRef: NgbModalRef;
    deleteAllocModelRef: NgbModalRef;
    dialogRef: NgbModalRef;
    jobupdate: Object;
    jobName: any;
    singleAllocData: any;
    jobtitle: any;
    currentjob: any;
    testSuccessMessage: any;
    scheduledNotificationMessage: any;
    unscheduledNotificationMessage: any;
    testFailureMessage: any;
    errorClosed = false;
    practicesList: any;
    practice: any;
    uploadClosed = false;
    uploadSuccessMessage: any;
    locationSelected: any;
    disableOtherOptions: boolean;
    excludePanIndia = [];
    selected3 = [];
    datePipe = new DatePipe('en-US');
    jobArr: any[];
    todaysDate = this.datePipe.transform(new Date(),DATE_FORMATE);
    jobNotificationTab: boolean;
    minEndDate = new Date();
    alreadyNotifiedArray = [];
    showerrorMessage: boolean = false;
    @ViewChild(MatSort) sort3: MatSort;
    incorrectFileFormat: boolean = false;
    incorrectFileSize: boolean = false;
    @ViewChild(MatSort)
    set sort(value: MatSort) {
        this.mailDataSource.sort = value;
    }
    constructor(public dialog: MatDialog,
        private referralService: ReferralsService,
        private router: Router,
        // private spinnerService: Ng4LoadingSpinnerService,
        private principal: Principal,
        private modalService: NgbModal,
        private fb: FormBuilder,
        private titleService: Title,
        private route: ActivatedRoute) {
        this.titleService.setTitle('aperture - Referrals');
        this.jobedit = this.fb.group({
            expMax: new FormControl('', []),
            expMin: new FormControl('', []),
            jobDesc: new FormControl('', []),
            reward: new FormControl('', []),
            skills: new FormControl('', []),
            jobId: new FormControl('', []),
            jobTitle: new FormControl('', []),
            locationsField: new FormControl(''),
        });
        this.jobNotificationForm = this.fb.group({
            notificationDate: new FormControl(''),
            jobId: this.fb.array([]),
            id: new FormControl(''),
            notificationType: new FormControl(''),
            weekEndDate: new FormControl('', [Validators.required]),
            weekStartDate: new FormControl('', [Validators.required]),
            jobTitle: new FormControl('')
        });
    }

    makeReferral(row): void {
        let dialogRef = this.dialog.open(makeReferralPopup, {
            width: '650px', height: '550px',
            data: row,
        });
    }
    tabChanged(event) {
        switch (event.index) {
            case 0: {
                this.getAllOpenPositions();
                this.jobNotificationTab = false;
                break;
            }
            case 1: {
                this.getMyAllReferrals();
                this.jobNotificationTab = false;
                break;
            }
            case 2: {
                this.jobNotificationTab = false;
                break;
            }
            case 3: {
                this.jobNotificationTab = true;
                break;
            }
        }
    }

    toggle(item, event: MatCheckboxChange) {
        if (event.checked) {
            this.selected3.push(item);
        } else {
            let index = this.selected3.indexOf(item);
            if (index >= 0) {
                this.selected3.splice(index, 1);
            }
        }
    }

    exists(item) {
        return this.selected3.indexOf(item) > -1;
    };

    isIndeterminate() {
        return (this.selected3.length > 0 && !this.isChecked());
    };

    isChecked() {
        return this.selected3.length === this.mailDataSource.data.length;
    };

    toggleAll(event: MatCheckboxChange) {
        if (event.checked) {
            this.mailDataSource.connect().value.forEach(row => {
                this.selected3.push(row);
            });
        } else {
            this.selected3.length = 0;
        }
    }

    startDateChange(event) {
        this.minEndDate = event.value;
    }


    ngOnInit(): void {
        this.exxcelFileSelected = false;
        this.uploadClosed = false;
        this.closed = false;
        this.scheduledClose = false;
        this.unscheduledClose = false;
        this.errorClosed = false;
        this.jobNotificationTab = false;
        setTimeout(()=>this.unscheduledClose = true,0);
        setTimeout(()=>this.scheduledClose = true,0);
        setTimeout(()=>this.closed = true,0);
        setTimeout(()=>this.errorClosed = true,0);
        setTimeout(()=>this.uploadClosed = true,0);
        this.principal.identity().then(account => {
            sessionStorage.setItem('resourceCode', account.resourceCode);
            this.resourceCode = account.resourceCode;
        });
        this.resCode = sessionStorage.getItem('resourceCode');
        // let resourceCodes=sessionStorage.getItem('resourceCode');
        // this.jobName=this.data.jobTitle;
        this.openPositionsDataSource = new MatTableDataSource([]);
        this.mailDataSource = new MatTableDataSource([]);
        this.myReferralsDataSource = new MatTableDataSource([]);
        this.getAllOpenPositions();
        this.getMyAllReferrals();
        this.getParentPractices();
        this.getAllLocations();

    }
    unscheduleJobNotification(row) {
        this.referralService.unscheduleJobNotification(row.jobId).subscribe(res=> {
                this.unscheduledNotificationMessage = res.messeage;
                setTimeout(()=>this.unscheduledClose = true,1000);
                setTimeout(()=>this.scheduledClose = true,0);
                this.ngOnInit();
                this.jobNotificationTab = true;
        });
    }
    hotJobsClicked(event) {
        if (event.checked) {
            this.tableSort = this.openPositionsDataSource.data.sort((c, d) => Number(d.hotFlag) - Number(c.hotFlag));
        } else {
            this.tableSort = this.openPositionsDataSource.data.sort((c, d) => Number(c.hotFlag) - Number(d.hotFlag));
        }
    }
    getAllLocations() {
        this.referralService.getAllLocations().subscribe(res => {
            this.locationsData = res.locationList;
            this.excludePanIndia = res.locationList;
        });
    }
    plusreferral(row) {
        this.router.navigate([`../plus-referral-details/${row.jobId}`],{relativeTo: this.route, skipLocationChange: true});
    }
    getAllOpenPositions () {
        this.referralService.getAlljobs('all', 'all', 'all','all', false).subscribe(res => {
            this.allJobs=res.jobList;
            this.searchField=undefined;
            this.jobExperience=undefined;
            this.jobLocation=undefined;
            this.practice=undefined;
            res.jobList.forEach(result => {
                this.locationGroup.push(result.locations);
                var test = this.locationGroup.includes('/');
                this.expGroup.push(result.expMin);
                this.allTitlesArray.push(result.jobTitle);
            });
            this.allExperienceUnique = Array.from(new Set(this.expGroup));
            this.openPositionsDataSource = new MatTableDataSource(res.jobList);
            this.mailDataSource = new MatTableDataSource(res.jobList);
            this.openPositions = this.openPositionsDataSource.data;
            this.openPositionsTotal = res.jobList.length;
            this.mailPositionsTotal = res.jobList.length;
            this.openPositionsDataSource.paginator = this.paginator;
            this.mailDataSource.paginator = this.paginator3;
            let distantFuture = new Date(8640000000000000);
            let firstSort = 'jobNotificationDate'
            let sortedMailTable =  this.mailDataSource.data.sort((a,b)=> {
                let dateA = a[firstSort] ? new Date(a[firstSort]) : distantFuture;
                let dateB = b[firstSort] ? new Date(b[firstSort]) : distantFuture;
                return dateA.getTime() - dateB.getTime();
              });
            this.mailDataSource.sort = this.sort;
        });
    }

    ngAfterViewInit() {
        // this.referralService.getAlljobs('all', 'all', 'all', 'all', false).subscribe(res => {
        //     this.mailDataSource = new MatTableDataSource(res.jobList);
        //     this.mailDataSource.paginator = this.paginator3;
        //     this.mailDataSource.sort = this.sort3;
        //     console.log('sort3 2',this.mailDataSource, this.sort3);
        // });
    }
    selectPANIndia(event) {
        let locations = event.value;
        if (locations.includes('Pan India')) {
            this.disableOtherOptions = true;
            locations = ['Pan India'];
            this.jobedit.get('locationsField').setValue(locations);
        } else if (!(locations.includes('Pan India'))) {
            this.disableOtherOptions = false;
        }
    }
    getParentPractices() {
        this.referralService.getAllParentPractices().subscribe(res => {
            this.practicesList = res.practicesDTOList;
        });
    }

    getMyAllReferrals() {
        this.referralService.getMyApplicant(this.resCode).subscribe(res => {
            this.getMyReferrals = res;
            this.myReferralsDataSource = new MatTableDataSource(res.applicantDetailsList);
            this.referredPositions = this.myReferralsDataSource.data;
            this.myReferralsTotal = res.applicantDetailsList.length;
            this.myReferralsDataSource.paginator = this.paginator2;
        });
    }
    searchData() {
        let jobs, search, location, practices;
        this.jobLocation === undefined ? location = 'all' : location = this.jobLocation;
        this.practice === undefined ? practices = 'all' : practices = this.practice;
        this.searchField === undefined ? search = 'all' : search = encodeURIComponent(this.searchField);
        this.jobExperience === undefined ? jobs = 'all' : jobs = this.jobExperience;
        this.referralService.getAlljobs(jobs, search, location, practices, false).subscribe(res => {
            this.filteredJobs = res.jobList;
            this.openPositionsDataSource = new MatTableDataSource(res.jobList);
            this.openPositionsTotal = res.jobList.length;
            this.openPositionsDataSource.paginator = this.paginator;
            this.mailDataSource = new MatTableDataSource(res.jobList);
            this.mailPositionsTotal = res.jobList.length;
            this.mailDataSource.paginator = this.paginator3;
        });
    }
    redirectTo(row) {
        let newjobName = row.jobTitle.replace(/\s+/g, '-');
        let url = `https://www.gspann.com/jobs/open-positions/${newjobName}`;
        window.open(url, '_blank');
    }
    resetData() {
        this.searchField = undefined;
        this.jobExperience = undefined;
        this.jobLocation = undefined;
        this.practice = undefined;
        this.ngOnInit();
    }
    validateNumber(event) {
        let charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    handleFileInput(files: FileList) {
        this.isLoading = true;
        this.jobToUpload = files.item(0);
        this.jobFileSelected = true;
        this.displayJobFileName = this.jobToUpload.name;
        this.jobsToUpload = true;
        if ((this.jobToUpload.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || this.jobToUpload.type === 'application/vnd.ms-excel' || this.jobToUpload.type === 'application/vnd.ms-excel.sheet.binary.macroEnabled.12' || this.jobToUpload.type === 'application/vnd.ms-excel.sheet.macroEnabled.12')) {
            this.exxcelFileSelected = true;
        } else {
            this.exxcelFileSelected = false;
            this.isLoading = false;
            return;
        }
        if (this.jobToUpload.size <= this.jobFilesSize) {
            this.incorrectFileSize = false;
        } else {
            this.incorrectFileSize = true;
            return;
        }
        if (this.exxcelFileSelected && this.incorrectFileSize ===false) {
            let blob = new Blob([this.jobToUpload], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            this.fileData = window.URL.createObjectURL(blob);
            setTimeout(() => {
                this.isLoading = false;
            }, 500);
        }
    }

    uploadExcelFile() {
        this.isLoading = true;
        this.referralService.uploadJobs(this.jobToUpload).subscribe(res => {
            this.uploadSuccessMessage = res.messeage;
            this.showerrorMessage = true;
            setTimeout(()=>this.uploadClosed = true,1000);
            this.tabChanged(0);
            (<HTMLInputElement>document.getElementById('job-upload')).value = "";
            this.isLoading = false;
            this.exxcelFileSelected = false;
        });
    }
    removeFile(e) {
        (<HTMLInputElement>document.getElementById('job-upload')).value = "";
        this.exxcelFileSelected = false;
    }
    openPositionsDataSources(allocation, editAllocModel) {
        this.editAllocModelRef = this.modalService.open(editAllocModel, { backdrop: 'static', keyboard: false, size: 'lg', windowClass: 'hide' });
        if (this.excludePanIndia.includes('Pan India')) {
            for (let i = 0; i < this.excludePanIndia.length; i++) {
                if (this.excludePanIndia[i] === 'Pan India') {
                    this.excludePanIndia.splice(i, 1);
                }
            }
        }
        if (allocation.locations['0'] === 'Pan India') {
            this.disableOtherOptions = true;
        } else if (!(allocation.locations['0'] === 'Pan India')) {
            this.disableOtherOptions = false;
        }
        this.jobedit.get('expMax').setValue(allocation.expMax);
        this.jobedit.get('expMin').setValue(allocation.expMin);
        this.jobedit.get('jobDesc').setValue(allocation.jobDesc);
        this.jobedit.get('reward').setValue(allocation.reward);
        this.jobedit.get('skills').setValue(allocation.skills);
        this.jobedit.get('jobId').setValue(allocation.jobId);
        this.jobedit.get('jobTitle').setValue(allocation.jobTitle);
        this.jobedit.get('locationsField').setValue(allocation.locations);
    }

    editMailNotification(openModel) {
        this.jobNotificationForm.reset();
        let jobArr = [];
        this.mailNotificationModelRef = this.modalService.open(openModel, { backdrop: 'static', keyboard: false, size: 'lg', windowClass: 'hide' });
        if((this.selected3.length !== 0)) {
            this.selected3.forEach(res=> {
                jobArr.push(res.jobId);
            });
            this.jobArr = jobArr;
        }
       let removeDuplicateID = jobArr.filter( function( item, index, inputArray ) {
            return inputArray.indexOf(item) == index;
        });
        this.jobArr = jobArr; 
        this.jobNotificationForm.patchValue({jobId: removeDuplicateID});
        removeDuplicateID = [];
        jobArr = [];
        this.selected3 = [];
        
    }
    updateEmailNotification() {
        let payLoad = {};
       let filteredJobID = this.jobArr.filter( function( item, index, inputArray ) {
            return inputArray.indexOf(item) == index;
        });
        payLoad = {
            jobId: filteredJobID,
            notificationType: 'Scheduled',
            weekEndDate: this.datePipe.transform(this.jobNotificationForm.get('weekEndDate').value, DATE_FORMATE),
            weekStartDate: this.datePipe.transform(this.jobNotificationForm.get('weekStartDate').value, DATE_FORMATE),
        }
        this.referralService.newjobNotification(payLoad).subscribe(res => {
            this.scheduledNotificationMessage = res.messeage;
            setTimeout(()=>this.scheduledClose = true,1000);
            setTimeout(()=>this.unscheduledClose = true,0);
            filteredJobID = [];
            this.isIndeterminate();
            this.ngOnInit();
            this.mailNotificationModelRef.close();
            this.jobNotificationTab = true;
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }

    updatejob(){
        let obj={
            expMax:this.jobedit.get('expMax').value,
            expMin:this.jobedit.get('expMin').value,
            jobDesc:this.jobedit.get('jobDesc').value,
            reward:this.jobedit.get('reward').value,
            skills:this.jobedit.get('skills').value,
            jobId:this.jobedit.get('jobId').value,
            jobTitle: this.jobedit.get('jobTitle').value,
            location: this.jobedit.get('locationsField').value
        }
        this.referralService.updatejob(obj).subscribe(res => {
            this.testSuccessMessage = res.messeage;
            setTimeout(()=>this.closed = true,1000);
            this.ngOnInit();
            this.editAllocModelRef.close();
        });
    }

    deletejobss(row, editAllocModel) {
        this.editAllocModelRef = this.modalService.open(editAllocModel, { backdrop: 'static', keyboard: false, size: 'lg', windowClass: 'hide' });
        this.currentjob = row;
    }
    deletejob() {
        this.referralService.deleteCommentjobs(this.currentjob.jobId).subscribe(res => {
            this.testFailureMessage = res.messeage;
            this.ngOnInit();
            this.editAllocModelRef.close();
        });
    }
}
@Component({
    // tslint:disable-next-line: component-selector
    selector: 'makeReferralPopup',
    templateUrl: 'makeReferralPopup.html',
    styleUrls: ['./submit-referral.component.scss']
})
// tslint:disable-next-line: component-class-suffix
export class makeReferralPopup implements OnInit {
    resumeSizeFlag: boolean;
    resumeData: any;
    resumeLengthFlag: boolean;
    resumeLabel: string;
    resumeTypeFlag: boolean;
    resourceService: any;
    fileName = '';
    jobName: any;
    public makeReferralForm: FormGroup = this.fb.group({
        fullName: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(200)]),
        primaryTelNumber: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
        altContactNumber: new FormControl('', [Validators.minLength(10), Validators.maxLength(10)]),
        messageArea: new FormControl(''),
        jobId: new FormControl(''),
        jobTitle: new FormControl(''),
        applicantId: new FormControl(''),
        applicantStatus: new FormControl(''),
        resourceCode: new FormControl(''),
        resumePath: new FormControl(''),
        creation_date: new FormControl(''),
        fileDetails: new FormControl(''),
        rewards: new FormControl(''),

    });
    fileToUpload: File;
    fileSelected: boolean;
    displayFileName: string;
    filestoUpload: boolean;
    filesSize = 10485760;
    largeFileSize: boolean;
    fileData: string;
    formFileSelected: boolean;
    isLoading: boolean;
    constructor(
        public dialogRef: MatDialogRef<makeReferralPopup>, private referralService: ReferralsService,
        public dialog: MatDialog, private fb: FormBuilder, private submitComponent: SubmitReferralComponent,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    submitReferral() {
        this.makeReferralForm.patchValue({
            jobId: this.data.jobId,
            jobTitle: this.data.jobTitle,
            applicantStatus: 'L1 Yet To Schedule',
            resourceCode: sessionStorage.getItem('resourceCode'),
            fileDetails: this.fileToUpload,
            rewards: this.data.reward
        });
        setTimeout(() => this.isLoading = true, 6000);
        this.referralService.saveApplicantDetails('save', this.makeReferralForm.value).subscribe(res => {
            this.formFileSelected = false;
            this.isLoading = false;
            this.openDialog();
        });
        // this.openDialog();
    }
    resetData() {
        (<HTMLInputElement>document.getElementById('file-upload')).value = "";
        this.formFileSelected = false;
        this.makeReferralForm.reset();
    }
    openDialog(): void {
        this.onNoClick();
        let dialogBox = this.dialog.open(successfulSubmissionComponent, {
            width: '400px', height: '450px',
        });
        dialogBox.afterClosed().subscribe(res => {
            this.submitComponent.ngOnInit();
        });
    }
    ngOnInit(): void {
        let resourceCode = sessionStorage.getItem('resourceCode');
        this.jobName = this.data.jobTitle;
    }
    validateNumber(event) {
        let charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
    handleFileInput(files: FileList) {
        this.isLoading = true;
        this.fileToUpload = files.item(0);
        this.fileSelected = true;
        this.displayFileName = this.fileToUpload.name;
        this.filestoUpload = true;
        if ((this.fileToUpload.type === 'application/pdf' || this.fileToUpload.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || this.fileToUpload.type === 'application/msword' || this.fileToUpload.type === 'application/ms-doc' || this.fileToUpload.type === 'application/doc') && this.fileToUpload.size <= this.filesSize) {
            this.formFileSelected = true;
            let blob = new Blob([this.fileToUpload], {
                type: 'application/pdf'
            });
            this.fileData = window.URL.createObjectURL(blob);
            setTimeout(() => {
                this.isLoading = false;
            }, 1000);
        } else {
            this.formFileSelected = false;
        }
    }
    removeFile(e) {
        (<HTMLInputElement>document.getElementById('file-upload')).value = "";
        this.formFileSelected = false;
    }
}

@Component({
    selector: 'successfulSubmissionComponent',
    templateUrl: 'successfulSubmission.component.html',
    styleUrls: ['./submit-referral.component.scss']
})
export class successfulSubmissionComponent {
    constructor(public dialogRef: MatDialogRef<successfulSubmissionComponent>, private router: Router) { }
    seeOpenPositions() {
        this.dialogRef.close();
        this.router.navigate(['/referrals/submit-referrals'],{skipLocationChange: true});
    }
    onNoClick() {
        this.dialogRef.close();
    }
}
