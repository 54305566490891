import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { enviornmentAperture } from 'environments/environment';
import { ProfileInfo } from './profile-info.model';
import { map } from 'rxjs/operators';

@Injectable()
export class ProfileService {
  private profileInfoUrl = enviornmentAperture.apiUrl + 'api/profile-info';
  private profileInfo: Promise<ProfileInfo>;
  @Output() public profilePicChanged: EventEmitter<any> = new EventEmitter();
  error: any;
  profileImageUrl: any;
  constructor(private http: HttpClient) {}

  getProfileInfo(): Promise<ProfileInfo> {
    if (!this.profileInfo) {
      this.profileInfo = this.http
        .get<ProfileInfo>(this.profileInfoUrl, { observe: 'response' })
        .pipe(map((res: HttpResponse<ProfileInfo>) => {
          let data = res.body;
          let pi = new ProfileInfo();
          pi.activeProfiles = data.activeProfiles;
          pi.ribbonEnv = data.ribbonEnv;
          pi.inProduction = data.activeProfiles.includes('prod');
          pi.swaggerEnabled = data.activeProfiles.includes('swagger');
          return pi;
        }))
        .toPromise();
    }
    return this.profileInfo;
  }

  getResourceImage(url): Observable<any> {
    return this.http.get(url, { responseType: 'text' as 'json' });
  }

  getAppVersion(): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/version', { responseType: 'text' as 'json' });
  }
}
