import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { TimesheetService } from './../timesheet.service';
import { WeekTimesheetViewComponent } from '../week-timesheet-view/week-timesheet-view.component';
import * as _moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
export const DATE_FORMATE = 'dd MMM yyyy';
export const DATE_FORMATE_FROM_TO = 'yyyy-MM-dd';
import { CommonDataService } from 'app/common-data.service';
@Component({
    selector: 'jhi-self-timesheet-daterange',
    templateUrl: './self-timesheet-daterange.component.html',
    styleUrls: ['./self-timesheet-daterange.component.scss']
})
export class SelfTimesheetDaterangeComponent implements OnInit {
    fromDateModel;
    toDateModel;
    dateRange: any = {
        check: true
    };
    fromDateString;
    toDateString;
    datePipe = new DatePipe('en-US');
    weekData: any;
    weekObj: any = {};
    fromMinDate: any;
    fromMaxDate: any;
    toMinDate: any;
    toMaxDate: any;
    monthObj: any = {};
    monthData: any;
    public monthPendingSince: any = {};
    WeekTimeSheetObj:any = [];
    paginationSelection: number;
    pagination: Array<any> = [2, 4, 6, 8, 10, 12, 14, 16];
    timesheetData: any;
    leaveTypes = [];
    searchTableDataPage: any;
    timesheetActorImage: Array<any>;
    page: number;
    resourceCode: any;
    private standard_hours: any;
    private months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    @ViewChild(WeekTimesheetViewComponent) WeekTScomponent: WeekTimesheetViewComponent;
    constructor(
        private timesheetService: TimesheetService, 
        private router: Router,  
        private route: ActivatedRoute,
        private commonData: CommonDataService) { }
    ngOnInit() {
        this.route.params.subscribe(params => {
            if (params.resourceCode) {
                this.resourceCode = params.resourceCode;
            }
        });
        this.paginationSelection = 2;
        setTimeout(() => {

            if(this.route.component.length == 8){
                this.resourceCode = sessionStorage.getItem('resourceCode');
            } else if (this.route.component.length == 14) {
                this.resourceCode = this.commonData.resourceCode; 
            } 
            if (!this.resourceCode) {
                this.resourceCode = sessionStorage.getItem('resourceCode');
            }
            this.getTimesheets();
        }, 2000);
    }
    getTimesheets() {
        this.timesheetActorImage = [];
        this.timesheetService.getDaterange(this.fromDateModel, this.toDateModel, this.page, this.paginationSelection, this.resourceCode).subscribe(response => {
            this.standard_hours = {
                week: response.timesheetSettings.weekSettings.weeklyStandardHours,
                day: response.timesheetSettings.weekSettings.dailyStandardHours
            };
            this.fromDateModel = response.dateRange.startDate;
            this.fromMinDate = response.dateRange.startDate;
            this.fromMaxDate = response.dateRange.endDate;
            this.toMinDate = response.dateRange.startDate;
            //this.toMaxDate = response.dateRange.endDate;
            var oneWeekAgo = new Date(response.dateRange.endDate);
            oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
            this.toMaxDate = this.datePipe.transform(oneWeekAgo, DATE_FORMATE_FROM_TO);
            var oneWeekAhead = new Date(response.dateRange.startDate);
                oneWeekAhead.setDate(oneWeekAhead.getDate() + 7);
            this.fromMinDate = this.datePipe.transform(oneWeekAhead, DATE_FORMATE_FROM_TO);
            if (this.fromDateModel) {
                this.toMinDate = this.fromDateModel;
            }
            this.searchTableDataPage = response.page;
            this.toDateModel = response.dateRange.endDate;
            this.fromDateString = this.datePipe.transform(response.dateRange.startDate, DATE_FORMATE);
            this.toDateString = this.datePipe.transform(response.dateRange.endDate, DATE_FORMATE);
            this.dateRange.fromDate = this.fromDateString;
            this.dateRange.toData = this.toDateString;
            this.monthData = response;
            response.timesheets.forEach(weekTSData => {
                if (weekTSData.status && weekTSData.status.actor) {
                    if (this.timesheetActorImage && this.timesheetActorImage.length > 0) {
                        let check = false;
                        this.timesheetActorImage.forEach(element => {
                            if (weekTSData.status.actor.code === element.code) {
                                check = true;
                                this.timesheetActorImage.push(element);
                            }
                        });
                        // if (!check) {
                        //     this.timesheetService.getImage(weekTSData.status.actor._links['itrack:image'].href).subscribe((imageData: any) => {
                        //         this.timesheetActorImage.push({ name: weekTSData.status.actor.name, code: weekTSData.status.actor.code, img: imageData });
                        //     });
                        // }
                    } else {
                        // this.timesheetService.getImage(weekTSData.status.actor._links['itrack:image'].href).subscribe((imageData: any) => {
                        //     this.timesheetActorImage.push({ name: weekTSData.status.actor.name, code: weekTSData.status.actor.code, img: imageData });
                        // });
                    }
                } else {
                    this.timesheetActorImage.push({ name: '', code: '', img: '' });
                }
            });
            this.getMonthPickData();
            this.getWeeksData(response.timesheets);
        });
        this.getMonthPendingSince();
    }

    getMonthPendingSince() {
        this.timesheetService.pendingByDaterange(this.resourceCode).subscribe(monthPendingSince => {
            this.monthPendingSince = monthPendingSince;
        });
    }

    public routeWeekTS(date: any): void {
        // console.log('Picked date: ', date);
    }
    paginationFilter() {
        this.getTimesheets();
    }

    public pickDate(date: any): void {
        let d = new Date();
        let n = d.valueOf();
        let url = 'timesheet-view/' + date + '_' + n;
        this.router.navigateByUrl(url,{skipLocationChange: true});
    }

    jumpPageTo(pageNumber) {
        this.page = pageNumber;
        this.getTimesheets();
    }

    updateTimesheetDataTo(event) {
        let dd = this.datePipe.transform(event.value, DATE_FORMATE_FROM_TO);
        this.toDateModel = dd;
        this.getTimesheets();
    }

    updateTimesheetDataFrom(event) {
        let dd = this.datePipe.transform(event.value, DATE_FORMATE_FROM_TO);
        this.fromDateModel = dd;
        this.getTimesheets();
    }

    getMonthPickData() {
        this.monthObj.previousMonthEnabled = this.monthData.page.hasPrevious;
        this.monthObj.nextMonthEnabled = this.monthData.page.hasNext;
    }
    getWeeksData(monthTSData) {
        this.WeekTimeSheetObj = [];
        for (let weekTSData of monthTSData) {
            let weekParseData: any = this.bindweekData(weekTSData);
            let s = _.cloneDeep(weekParseData);
            s.projects.forEach(project => {
                project.hours.forEach((element, index) => {
                    if (weekTSData.weekMetaData && weekTSData.weekMetaData.dayDetails && weekTSData.weekMetaData.dayDetails[index]) {
                        element.remarks = weekTSData.weekMetaData.dayDetails[index].remarks; 
                     }else{
                        element.remarks ='';
                     }
                });
            });
            this.WeekTimeSheetObj.push(s);
        }
    }

    bindweekData(weeklyTSdata) {
        this.timesheetData = this.parseModel(weeklyTSdata);
        this.weekData = this.createInitialTimesheetData();
        return this.weekData;
    }

    parseModel(timesheetData) {
        this.weekObj.code = timesheetData.status.timesheetStatus.code;
        this.weekObj.status = timesheetData.status.timesheetStatus.value;
        this.weekObj.statusObj = timesheetData.status;
        this.weekObj.description = timesheetData.status.timesheetStatus.description;
        let timeEntries = {
            hours: '',
            comments: ''
        };
        timesheetData.timesheet.allocationWiseTimeSheets[0].dailyEntries.forEach(day => {
            day.timeEntries = timeEntries;
        });
        return timesheetData;
    }

    createInitialTimesheetData() {
        if (this.timesheetData.timesheet) {
            this.weekObj.timesheet = this.timesheetData.timesheet;
        } else {
            this.weekObj.timesheet = false;
        }
        this.weekObj.projects = this.createProjectsModel(this.timesheetData.timesheet.allocationWiseTimeSheets);
        // this.weekObj.daysData = this.createDaysData(this.timesheetData.timesheet.allocationWiseTimeSheets[0].dailyEntries);
        this.weekObj.daysData = this.createDaysData(this.timesheetData.timesheet.totalEntries);
        this.weekObj.weekName = this.timesheetData.weekMetaData.weekDetails.weekName;
        this.weekObj.actions = this.timesheetData.status.actions;
        this.weekObj.startDate = this.timesheetData.weekMetaData.weekDetails.weekStartDate;
        this.weekObj.endDate = this.timesheetData.weekMetaData.weekDetails.weekEndDate;
        this.weekObj.weeklyStandardHours = this.timesheetData.weekMetaData.weekDetails.weeklyStandardHours;
        this.weekObj.commentsAdded = true;
        this.weekObj.leaves = [];
        this.weekObj.previousWeekEnabled = this.timesheetData.previousWeek && this.timesheetData.previousWeek != null ? true : false;
        this.weekObj.previousWeekStartDate = this.weekObj.previousWeekEnabled && this.timesheetData.previousWeek.week.weekStartDate;
        this.weekObj.nextWeekEnabled = this.timesheetData.nextWeek && this.timesheetData.nextWeek != null ? true : false;
        this.weekObj.nextWeekStartDate = this.weekObj.nextWeekEnabled && this.timesheetData.nextWeek.week.weekStartDate;
        this.weekObj.next_week_date_param = false;
        this.weekObj.previous_week_date_param = false;
        this.weekObj.lastUpdatedOn = this.timesheetData.status.lastUpdatedOn;
        // if (this.timesheetData.status.lastUpdatedOn !== null) {
        //     this.timesheetData.status.lastUpdatedOn = this.timesheetData.status.lastUpdatedOn.substring(
        //         0,
        //         this.timesheetData.status.lastUpdatedOn.lastIndexOf(' ')
        //     );
        //     this.weekObj.lastUpdatedOn = this.timesheetData.status.lastUpdatedOn;
        // }
        this.weekObj.lastUpdatedOn = this.timesheetData.status.lastUpdatedOn.substring(
            0,
            this.timesheetData.status.lastUpdatedOn.lastIndexOf(' ')
        );
        return this.weekObj;
    }

    createProjectsModel(projects) {
        let projectsModel = [];
        let condition;
        if (projects.length > 0) {
            projects.forEach(project => {
                let dataObj = {
                    code: '',
                    tooltip: '',
                    name: '',
                    attachment: '',
                    type: '',
                    hours: [],
                    totalHours: '',
                    status: {},
                    allocationSummary: {}
                };
                if (this.weekObj.code === 'PENDING_SUBMISSION') {
                    condition = project.wbs.project.type.key !== 'PDL' && project.wbs.project.type.key !== 'UPL';
                    dataObj.code = project.wbs.project.identifier.key;
                    dataObj.name = project.wbs.project.identifier.value + ' (' + project.occupancy + '%)';
                    dataObj.tooltip = dataObj.code + ' _ ' + dataObj.name;
                    dataObj.attachment = project.clientTimeTracking;
                    dataObj.type = project.wbs.project.type.key;
                    dataObj.hours = [
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' },
                        { hour: 0, comments: '' }
                    ];
                    dataObj.allocationSummary = project;
                } else if (
                    this.weekObj.code === 'SUBMITTED' ||
                    this.weekObj.code === 'PARTIALLY_SUBMITTED' ||
                    this.weekObj.code === 'SAVED' ||
                    this.weekObj.code === 'REJECTED' ||
                    this.weekObj.code === 'APPROVED' ||
                    this.weekObj.code === 'PARTIALLY_APPROVED_REJECTED' ||
                    this.weekObj.code === 'PARTIALLY_APPROVED' ||
                    this.weekObj.code === 'PARTIALLY_APPROVED_PENDING' ||
                    this.weekObj.code === 'PARTIALLY_REJECTED_PENDING'
                ) {
                    condition = true;
                    dataObj.code = project.allocationSummary.wbs.project.identifier.key;
                    dataObj.name = project.allocationSummary.wbs.project.identifier.value + ' (' + project.allocationSummary.occupancy + '%)';
                    dataObj.tooltip = dataObj.code + ' _ ' + dataObj.name;
                    dataObj.attachment = project.allocationSummary.clientTimeTracking;
                    dataObj.type = project.allocationSummary.wbs.project.type.key;
                    project.status.lastUpdatedOn = project.status.lastUpdatedOn.substring(0, project.status.lastUpdatedOn.lastIndexOf(' '));
                    dataObj.status = project.status;
                    project.dailyEntries.forEach(day => {
                        let hour = this.getTime(day.hours);;
                        dataObj.hours.push({ hour: (hour), comments: day.comments });
                    });
                    if (project.allocationSummary) {
                        dataObj.allocationSummary = project.allocationSummary;
                    }
                    dataObj.totalHours = project.projectTotalHours;
                }
                if (condition) {
                    let projObj = {
                        code: dataObj.code,
                        name: dataObj.name,
                        tooltip: dataObj.tooltip,
                        hours: dataObj.hours,
                        type: dataObj.type,
                        totalHours: dataObj.totalHours,
                        allocationSummary: dataObj.allocationSummary
                    };
                    projectsModel.push(projObj);
                } else {
                    let projObj = {
                        code: dataObj.code,
                        tooltip: dataObj.tooltip,
                        name: dataObj.name,
                        hours: dataObj.hours,
                        type: dataObj.type,
                        totalHours: dataObj.totalHours,
                        status: dataObj.status
                    };
                    this.leaveTypes.push(projObj);
                }
            });
        }
        return projectsModel;
    }

    public getTime(timeString) {
        let hours = '';
        let timeArray = timeString.match(/[0-9]+/g);
            timeArray.forEach((ele, index) => {
                if (ele === '30') {
                    if (ele === '30' && index === 0 && (timeString.charAt(timeString.indexOf('30') + 2) === 'H')) {
                        hours = ele;
                    } else if (ele === '30' && index === 0 && (timeString.charAt(timeString.indexOf('30') + 2) === 'M')){
                        hours = '0.5';
                    } else {
                        hours = hours + '.5';
                    }
                } else {
                    hours = ele;
                }
            });
        return hours;
    }

    createDaysData(daysData) {
        let daysViewModel = [];
        if (daysData.length > 0) {
            daysData.forEach((dayObj, index) => {
                let dModel: any = {};
                let splittedDateObj = dayObj.date.split('-');
                dModel.date = splittedDateObj[2];
                dModel.month = this.months[parseInt(splittedDateObj[1], 10) - 1];
                dModel.day = dayObj.day.substr(0, 3);
                dModel.remarks = dayObj.remarks;
                dModel.code = dayObj.type.code;
                dModel.weekOffchecked = dayObj.weekOff;
                if (this.weekObj.timesheet) {
                    dModel.dailyComment = this.weekObj.timesheet.totalEntries[index].dailyComment;
                } else {
                    dModel.dailyComment = null;
                }
                daysViewModel.push(dModel);
            });
        }
        return daysViewModel;
    }
}
