<div class="containers">
    <div mat-dialog-actions class="close">
        <button mat-button mat-dialog-close> <mat-icon>close</mat-icon> </button>
    </div>
    <div class="download-app-main-container">
        <div class="container-item1">
            <img src="../assets/download-app/PhoneMockup@1x.png" class="mobile-image" alt="phone image">
        </div>
        <div class="container-item2">
            <div>
                <p class="container-heading">Download the Aperture App</p>
                <p class="container-heading1">Hey! We are pleased to announce the launch of our newest
                    Aperture app, now available for both iOS and Android!
                </p>
            </div>
            <p class="container-heading-IOS mt-4">
                Download for iOS
            </p>
            <div class="scanners mb-4">
                <img src="../assets/download-app/IOS_Scanner.png" class="ios-scanner" alt="IOS Scanner">
                <div class="or">OR</div>
                <div>
                    <p class="browser-instructions">On Mobile Browser, Click the below button</p>
                    <a href="https://apps.apple.com/app/aperture-mobile-app/id6446077186">
                        <img src="../assets/download-app/AppleStoreButton@1x.png" class="ios-playstore"
                            alt="IOS Playstore">
                    </a>
                </div>
            </div>
            <p class="container-heading-Android">
                Download for Android
            </p>
            <div class="scanners">
                <img src="../assets/download-app/Android_Scanner.png" class="android-scanner" alt="Android-scanner">
                <div class="or">OR</div>
                <div>
                    <p class="browser-instructions">On Mobile Browser, Click the below button</p>
                    <a href="https://play.google.com/store/apps/details?id=com.gspann.aperture&pli=1"><img
                            src="../assets/download-app/PlayStoreButton@1x.png" class="android-playstore"
                            alt="Android Playstore"></a>
                </div>
            </div>
        </div>
    </div>
</div>