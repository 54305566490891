import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'jhi-privacypolicy',
    templateUrl: './privacypolicy.component.html',
    styleUrls: ['./privacypolicy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

    constructor(private titleService: Title) {
        this.titleService.setTitle('Aperture - Privacy Policy');
    }
    ngOnInit() {

    }

}
