import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { enviornmentAperture } from 'environments/environment';
import { Account } from '../user/account.model';

@Injectable()
export class AccountService {
  constructor(private http: HttpClient) {}

  get(): Observable<HttpResponse<Account>> {
    return this.http.get<Account>(enviornmentAperture.apiUrl + 'api/account', { observe: 'response' });
  }

  save(account: any): Observable<HttpResponse<any>> {
    return this.http.post(enviornmentAperture.apiUrl + 'api/account', account, { observe: 'response' });
  }
}
