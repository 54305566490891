import { AbstractControl, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NativeDateAdapter } from '@angular/material/core';
import { DEFAULT_DATE_FORMAT } from '../app.constants';

export function autocompleteSelectionValidator(control: AbstractControl) {
    let selection = control.value;
    if (typeof selection === 'string') {
        return { noresult: true };
    }
    return null;
}

export class AutoCompleteObjectValidators {
    static mustBeInList = (list: Array<any>, key: string) => {
      return (control: FormControl) => {
        if (control.value) {
            let isInside: boolean;
            let valueToCompare: string | boolean =
            typeof control['value'] === 'object'
                ? control['value'][key].toLowerCase()
                : typeof control['value'] === 'string'
                ? control['value'].toLowerCase()
                : false;
            isInside = list.some(entry => {
            let currentValue: string = entry[key].toLowerCase();
            return currentValue === valueToCompare;
            });
            if (isInside) {
            return null;
            } else {
            return { mustBeInList: true};
            }
        }
      };
    }
}
export let PICK_FORMATS = {
    parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' }
    }
};
export class PickDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: Object): string {
        let newDate= new Date(date);
        if (newDate.getTimezoneOffset() > 0) {
            newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
        }
        if (displayFormat === 'input') {
            return new DatePipe('en-US').transform(newDate, DEFAULT_DATE_FORMAT);
        } else {
            return newDate.toDateString();
        }
    }
}
