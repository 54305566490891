<mat-card class='main-container logout text-center'>
    <div class="row">
        <div class="col-md-12">
            <p><i class="fa fa-warning fa-2x" aria-hidden="true"></i></p>
            <h2>There was a problem with your request. User Does not exist.</h2>
        </div>
        <br/>
        <div class="col-md-12">
            <a id="closeWindow" (click)="closeWindow()">Close Window</a>
        </div>
    </div>
</mat-card>
