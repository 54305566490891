import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminUploadLeaveDataComponent } from '../admin-upload-leave-data/admin-upload-leave-data.component';

@Component({
  selector: 'app-upload-leave-confimation-modal',
  templateUrl: './upload-leave-confimation-modal.component.html',
  styleUrls: ['./upload-leave-confimation-modal.component.scss']
})
export class UploadLeaveConfimationModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AdminUploadLeaveDataComponent>,
  ) { }

  ngOnInit(): void {
  }

  closeDialog(action) {
		this.dialogRef.close(action);
	}  

}
