import { Injectable } from '@angular/core';
import { encIV, salt } from './app.constants';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class CommonDataService {
  resourceCode: any;
  accountCode: any;
  projectCode: any;
  constructor() { }
  encrypt(data: string): string {
    const ciphertext = CryptoJS.AES.encrypt(data, salt, {
        iv: encIV
    }).toString().trim();
    const replacedCipher = ciphertext.replace(/\//g, 'ForwardSlash').replace(/\+/g, 'PlusSign');
    return replacedCipher;
  }
}