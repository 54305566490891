import { Component } from '@angular/core';
import { TimezonePipe } from '../../shared/util/timezone.pipe';

@Component({
    selector: 'jhi-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.css'],
    providers: [TimezonePipe]
})
export class FooterComponent {
    currentDate = new Date();
    constructor(private timezonePipe: TimezonePipe) {
        // this.currentDate = this.timezonePipe.transform(new Date());
    }
}
