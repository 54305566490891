import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ReferralsService } from '../referrals.service';
export const DATE_FORMATE='yyyy-MM-dd';
import * as FileSaver from 'file-saver';
import { ResourceService } from 'app/resource/resource.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { PickDateAdapter, PICK_FORMATS } from 'app/validators/validator';

@Component({
    selector: 'jhi-referral-details',
    templateUrl: './referral-details.component.html',
    styleUrls: ['./referral-details.component.scss'],
    providers: [{ provide: DateAdapter, useClass: PickDateAdapter }, { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }]
})
export class ReferralDetailsComponent implements OnInit {
    datePipe=new DatePipe('en-US');
    // public departmentSearchFilterCtrl: FormControl=new FormControl();
    // public departmentListFiltered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    referralApplicationForm: FormGroup;
    status: any;
    applicantId: any;
    resourceCode: string;
    candidateName: any;
    commentArea: any;
    auditDate: any;
    commentId: number;
    commentsPosted: boolean;
    commentsOfApplicant: any;
    showComment=[];
    recruiterComment: any;
    // departmentList: string[];
    auditDateGet: any;
    commentsID: any;
    eachComment: any;
    auditDatePresentTrue: any;
    timeOut = null;
    payLoadData1: { applicantId: number; auditDate: any; comment: any; commentId: number; comment_by: any; };
    contentDisposition: any;
    emailList: any;
    newResCode: any;
    constructor(private fb: FormBuilder, private referralService: ReferralsService, public dialog: MatDialog, private router: Router, private route: ActivatedRoute, private resourceService: ResourceService) {
        this.referralApplicationForm=this.fb.group({
            recruiter: new FormControl('', [Validators.required, Validators.maxLength(100)]),
            status: new FormControl('', [Validators.required]),
            creationDate: new FormControl('', [Validators.required]),
            applicantName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
            primaryContactNumber: new FormControl('', [Validators.required]),
            positionAppliedFor: new FormControl('', [Validators.required]),
            secondaryContactNumber: new FormControl('',),
            primaryEmail: new FormControl('', [Validators.required, Validators.email]),
            // secondaryEmail: new FormControl('', [Validators.email]),
            employeeName: new FormControl('', [Validators.required]),
            employeeID: new FormControl(''),
            employeeEmail: new FormControl('', [Validators.required, Validators.email]),
            employeeReferralSubDate: new FormControl('', [Validators.required]),
            applicantResourceCode: new FormControl(''),
        });
    }

    ngOnInit (): void {
        this.resourceCode=sessionStorage.getItem('resourceCode');
        this.route.queryParams.subscribe(params => {
            this.applicantId=params['applicantId'];
        });
        this.getApplicant();
        this.getStatusDetails();
        this.getComments();
        document.getElementById("update-btn").style.display ="none";
    }

    getApplicant () {
        this.referralService.getApplicant(this.applicantId).subscribe(res => {
            this.referralApplicationForm.patchValue({
                applicantName: res.name,
                recruiter: res.recruiterName,
                positionAppliedFor: res.jobTitle,
                primaryEmail: res.emailId,
                primaryContactNumber: res.contactNumber,
                status: res.applicantStatus? res.applicantStatus:'',
                secondaryContactNumber: res.alternateContactNumber,
                creationDate: res.actionCompletionDate? res.actionCompletionDate:this.datePipe.transform(new Date(), DATE_FORMATE),
                applicantResourceCode: res.resourceCode,
            });
            this.newResCode=this.referralApplicationForm.get('applicantResourceCode').value;
            this.candidateName=this.referralApplicationForm.get('applicantName').value;
            this.referralService.getReferredby(this.applicantId,this.newResCode).subscribe(res => {
                this.referralApplicationForm.patchValue({
                    employeeName: res.name,
                    employeeID: res.payrollId,
                    employeeEmail: res.emailId,
                    employeeReferralSubDate: res.referralSubmitionDate,
                });
            });
        });
    }
    autoPopulateEmail(searchParam) {
        if (searchParam) {
            if (this.timeOut) {
                clearTimeout(this.timeOut);
            }
            this.timeOut = setTimeout(() => {
                this.resourceService.autoPopulateEmail(searchParam).subscribe(emailList => {
                    this.emailList = emailList;
                });
            }, 1000);
        } else {
            this.emailList = [];
        }
    }
    downloadResume () {
        this.referralService.downloadResumeHeader(this.applicantId).subscribe(result => {
            this.contentDisposition=result.content_disposition;
            this.referralService.downloadResume(this.applicantId).subscribe(res => {
                let fileName='file';
            if (this.contentDisposition) {
                let fileNameRegex=/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches=fileNameRegex.exec(this.contentDisposition);
                if (matches!=null&&matches[1]) {
                    fileName=matches[1].replace(/['"]/g, '');
                }
            }
            FileSaver.saveAs(res.body, fileName);
            });
        });
    }
    updateApplicantDetails () {
        let payLoadData={
            applicantId: Number(this.applicantId),
            applicantStatus: this.referralApplicationForm.get('status').value,
            actionCompletionDate: this.datePipe.transform(this.referralApplicationForm.get('creationDate').value, DATE_FORMATE),
            recruiter: this.referralApplicationForm.get('recruiter').value,
        }
        this.referralService.updateApplicant(payLoadData).subscribe(res => {
            this.dialog.open(dataSavedSuccessfullyComponent);
            // alert('Data Saved Successfully!');
            // this.getApplicant();
        });
    }
    getStatusDetails () {
        this.referralService.getAllStatus().subscribe(res => {
            this.status=res.applicantStatusDTOList;
        });
    }
    getComments () {
        this.referralService.getComment(this.applicantId).subscribe(res => {
            this.commentsOfApplicant=res.commentDetailsList;
            if (this.commentsOfApplicant.length>0) {
                this.commentsPosted=true;
                this.commentsOfApplicant.forEach(res => {
                    this.eachComment=res;
                    this.commentsID=res.commentId;
                    this.showComment=res.comment;
                    this.recruiterComment=res.comment_by;
                    this.auditDateGet=res.auditDate;
                });
            }
        });
    }
    commentEdit (data) {
        this.commentArea=data.comment;
        document.getElementById("update-btn").style.display="inline-block";
        document.getElementById("post-btn").style.display="none";
        this.payLoadData1={
            applicantId: Number(data.applicantId),
            auditDate: data.auditDate,
            comment: this.commentArea,
            commentId: Number(data.commentId),
            comment_by: data.comment_by,
        }
    }
    editComment () {
        this.payLoadData1.comment=this.commentArea;
        this.referralService.updateComment(this.payLoadData1).subscribe(res => {
            this.commentArea='';
            this.commentsPosted=true;
            if (this.commentsPosted) {
                this.getComments();
            }
            document.getElementById("update-btn").style.display="none";
        document.getElementById("post-btn").style.display="inline-block";
        });
    }
    deleteComment (data) {
        this.referralService.deleteComment(data.commentId).subscribe(res => {
            this.commentsPosted=false;
            this.getComments();
        })
    }
    postComment () {
        let payLoadData={
            applicantId: Number(this.applicantId),
            auditDate: this.auditDate? this.auditDate:'',
            comment: this.commentArea,
            commentId: Number(this.commentId)? Number(this.commentId):0,
            comment_by: this.referralApplicationForm.get('recruiter').value,
        }
        this.referralService.addComment(payLoadData).subscribe(res => {
            this.commentArea='';
            this.commentsPosted=true;
            if (this.commentsPosted) {
                this.getComments();
            }
        });
    }
}

@Component({
    selector: 'data-save-success',
    templateUrl: 'dataSavedSuccessfully.component.html',
    styleUrls: ['./referral-details.component.scss']
})
export class dataSavedSuccessfullyComponent {
    constructor(public dialogRef: MatDialogRef<dataSavedSuccessfullyComponent>, private router: Router, private referralDetailsComponent: ReferralDetailsComponent) { }
    onNoClick () {

        this.dialogRef.close();
        this.dialogRef.afterClosed().subscribe(res => {
            this.referralDetailsComponent.ngOnInit();
        });
    }
}