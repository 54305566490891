<div class="addOffice">
    <h4 class="title">Add Office Location</h4>
    <mat-card class="example">
        <mat-card-content>
            <form [formGroup]="userForm">
				<div class="row ">
					<div class="col-3 float_columns">
						<mat-form-field class="form_field_width">
						<mat-select name="company" placeholder="Please select company" formControlName="selectOffice">
                    		<mat-option *ngFor="let data of companies" [value]="data">
                                    {{data.company.value}}
                        	</mat-option>
                    	</mat-select>
					</mat-form-field>
					</div>

				<div class="col-12 align_columns" *ngIf="userForm.get('selectOffice').value && !userForm.get('selectOffice').errors">
                    <div class="col-3 float_columns">
                        <p>
                            <mat-form-field class="form_field_width">
                                <input matInput placeholder="Country*" formControlName="countryCode"
                                    [matAutocomplete]="auto_country" [(ngModel)]="countryModel" maxlength="50">
                                <mat-autocomplete autoActiveFirstOption #auto_country="matAutocomplete"
                                    [displayWith]="displayFn" (optionSelected)="countrySelected($event,'country')">
                                    <mat-divider></mat-divider>
                                    <mat-option *ngFor="let country of allCountries" [value]="country">
                                        {{country.countryName}} ( {{country.countryCode}} )
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="userForm.get('countryCode').hasError('required')">
                                    This is required field
                                </mat-error>
                            </mat-form-field>
                        </p>
                    </div>

                    <div class="col-3 float_columns">
                        <p>
                            <mat-form-field class="form_field_width">
                                <input matInput placeholder="State*" formControlName="stateName"
                                    [(ngModel)]="selectedState" [matAutocomplete]="auto_state" maxlength="50">
                                <mat-autocomplete autoActiveFirstOption #auto_state="matAutocomplete"
                                    (optionSelected)="countrySelected($event,'state')">
                                    <mat-divider></mat-divider>

                                    <mat-option *ngFor="let state of states" [value]="state.stateName">

                                        {{state.stateName}}

                                    </mat-option>


                                </mat-autocomplete>
                                <mat-error *ngIf="userForm.get('stateName').hasError('required')">This is required
                                    field</mat-error>

                            </mat-form-field>
                        </p>
                    </div>

                    <div class="col-3 float_columns">
                        <p>
                            <mat-form-field class="form_field_width">
                                <input matInput placeholder="City*" formControlName="cityName"
                                    [(ngModel)]="selectedCity" [matAutocomplete]="auto_city" maxlength="50">
                                <mat-autocomplete autoActiveFirstOption #auto_city="matAutocomplete">
                                    <mat-divider></mat-divider>

                                    <mat-option *ngFor="let city of cities" [value]="city.cityName">
                                        {{city.cityName}}
                                    </mat-option>

                                </mat-autocomplete>
                                <mat-error *ngIf="userForm.get('cityName').hasError('required')">This is required
                                    field</mat-error>

                            </mat-form-field>
                        </p>
                    </div>

                	<div class="row add-office-button">
                    	<button mat-raised-button type="button" aria-label="Close" (click)="closeOfficeForm()" class="button-position">Cancel</button>
                    	<button mat-raised-button [disabled]="!userForm.valid || isAddOffice" class="add-button-css" (click)="addOfficeForm()">Add</button>
                    </div>
                </div>
			</div>

            </form>
        </mat-card-content>
    </mat-card>
</div>

<div class="ext_offices row">
    <h4 class="title col-6">Existing Offices</h4>
    <mat-form-field class="col-6">
        <span class="material-icons search-icon" matPrefix> &nbsp;search</span>
        <mat-label class="search-label">&nbsp; Search Office</mat-label>
        <input matInput (keyup)="Search($event)" class="input-search">
    </mat-form-field>
</div>

<div class="mat-elevation-z8">
    <mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="companyName">
            <mat-header-cell *matHeaderCellDef class="cellColor"> Company </mat-header-cell>
            <mat-cell *matCellDef="let location">
                {{location.companyName}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="countryCode">
            <mat-header-cell *matHeaderCellDef class="cellColor"> Country</mat-header-cell>
            <mat-cell *matCellDef="let location">
                {{location.countryName}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="stateName">
            <mat-header-cell *matHeaderCellDef class="cellColor"> State </mat-header-cell>
            <mat-cell *matCellDef="let location">
                {{location.stateName}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="cityName">
            <mat-header-cell *matHeaderCellDef class="cellColor"> City </mat-header-cell>
            <mat-cell *matCellDef="let location">
                {{location.cityName}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="options">
            <mat-header-cell *matHeaderCellDef class="cellColor"> </mat-header-cell>
            <mat-cell *matCellDef="let location">
                <mat-select [(value)]="selectedOption" [ngStyle]="{'width':'0%'}">
                    <mat-option (click)="openDialog(location)" value="1">Edit</mat-option>
                </mat-select>
            </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns" class="header-css"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="matRow-css"></mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[5, 10, 15, 20]"></mat-paginator>
</div>
