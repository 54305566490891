<div>
    <div class="row">
        <div class="col-lg-6">
            <div [ngSwitch]="isAuthenticated()">
                <div *ngSwitchCase="true">
                    <h1 *ngIf="account" id="main-heading">Welcome, {{account.firstName | titlecase}}! </h1>
                </div>
                <!-- <mat-card class="notification-card pl-3">
                    <div> 
                        <h5 class="thick">
                           <span class="text-class-align" style="vertical-align: middle;"> Important Notification </span><img class="notifications" src="../assets/notifications.png">
                        </h5>
                    </div>
                    <div>
                        <h6> GSPANN has upgraded mailbox security by implementing Multi Factor Authentication (MFA). After enforcing MFA, you will not be able to access O365 unless you setup Microsoft Authenticator App in your smartphone.
                        <br> 
                         Please<a  class="itrackcolor" (click)="getmfadocument()"> download</a> the detailed process document for enrolling.</h6>
                    </div>
                </mat-card> -->
            </div>
            <!-- <img src="../assets/refer&earn11.png" alt="Refer & Earn"> -->
        </div> 
        
        <div class="col-lg-6">
         <div class="project-detail viva-engage">
            <img src="../assets/Viva_Engage.png" alt="Viva_Engage" title="Viva_Engage" width="160px" /> MyGSPANN Adventures Await  <a href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI0NjM0NzU1ODkxMiJ9/all" target="_blank" class="viva-engage-link" >Click to Connect</a>
         </div>
        </div>
    </div>

    <div class="row pt-3" *ngIf="account && resourceCode !== apertureAdminResourceCode">
        <div class="col-lg-6">
            <!-- <mat-card class="mat-card my-timesheets"> -->
            <mat-accordion>
                <mat-expansion-panel class="my-timesheets">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <strong>My Timesheets - <span style="color: #007bff"> {{myTimesheetPendingCount}}</span></strong>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="pending">
                        <div class="counter">{{myTimesheetPendingCount}}</div>
                        <div class="description" style="padding-top: 10px;">
                            Timesheets Pending Last 30 Days
                        </div>
                    </div>
                    <div class="pending-details">
                        <div *ngFor="let pendingTimesheet of myTimesheetPendingWeekStatus">
                            <div class="detail-separator"></div>
                            <div>
                                <div class="detail row">
                                    <div class="col">
                                        <div class="detail-desc">
                                            <div>{{pendingTimesheet.week.weekName}}</div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="detail-action">
                                            <!-- <a [routerLink]="['/timesheet-view']">Submit</a> -->
                                            <a class="Submitlink" (click)="getTimeSheetDataWiseData(pendingTimesheet)">
                                                Submit
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <!-- </mat-card> -->
            <br><br><br>
            <mat-card>
                <mat-card-title class="title mb-0">
                    <strong>Refer & Earn</strong>
                </mat-card-title>
                <mat-card-content class="my-timesheets">
                    <div class="pending-details">
                        <div *ngFor="let jobs of allJobs;let i=index">
                            <div *ngIf="i<=2">
                                <div class="detail-separator"></div>
                                <div>
                                    <div class="detail row" style="padding-bottom: 0;">
                                        <div class="col">
                                            <div class="detail-desc">
                                               <span class="row-job-title" (click)="redirectTo(jobs)"> 
                                                <b>{{jobs.jobTitle}}</b> 
                                                </span>
                                                <span *ngIf="jobs.hotFlag">
                                                <button style="background-color: #EFA522;color: #ffffff;opacity: 100%;border-radius: 25px;font-size: 12px;font-weight: 700; border: none;">HOT
                                                </button></span>
                                                <br> 
                                                <b> Reward Points - {{jobs.reward}}</b>
                                                <p> 
                                                    Location - {{jobs.locations}} | Experience-
                                                    {{jobs.expMin}}
                                                    <span *ngIf="jobs.expMax === 0">+ Years</span>
                                                     <span *ngIf="jobs.expMax !== 0"> - {{jobs.expMax}} years</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col" style="line-height:60px">
                                            <div class="detail-action">
                                                <!-- <a [routerLink]="['/timesheet-view']">Submit</a> -->
                                                <a class="Submitlink" style="vertical-align: text-bottom;" (click)="makeReferral(jobs)">
                                                    <strong>Make a Referral</strong>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="allJobsLength >3" class="col-4" style="margin-top: 20px;">
                        <button mat-button class="btn btn-primary btn-block" style="color: white;"
                            skipLocationChange routerLink="/referrals/submit-referrals">
                             <strong>See All Positions</strong>
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>
            <!--div class="default-separator"></div>

            <mat-card class="mat-card quick-links">
                <mat-card-title class="title">
                    <strong>Quick Links</strong>
                </mat-card-title>
                <mat-card-content>
                    <div class="row">
                        <div class="col-sm col-md col-lg col-xl quick-link">
                            <a class="btn btn-primary btn-block text-uppercase" href="https://gspann.greythr.com/login.do" target="_blank">Greyt Hr</a>
                        </div>
                        <div class="col-sm col-md col-lg col-xl quick-link">
                            <a class="btn btn-primary btn-block text-uppercase" href="https://gspann.sharepoint.com/sites/Atom/SitePages/Home.aspx" target="_blank">Atom LMS</a>
                        </div>
                        <div class="col-sm col-md col-lg col-xl quick-link">
                            <a class="btn btn-primary btn-block text-uppercase" href="http://ppm.macys.net" target="_blank">Clarity</a>
                        </div>
                        <div class="col-sm col-md col-lg-4 col-xl quick-link">
                            <a class="btn btn-primary btn-block text-uppercase" href="http://gspannsrv01.cloudapp.net/helpdesk/open.php" target="_blank">TechOps</a>
                        </div>
                        <div class="col-sm col-md col-lg-4 col-xl quick-link">
                            <a class="btn btn-primary btn-block text-uppercase" href="https://pms.gspann.com/" target="_blank">PMS</a>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card-->
        </div>
        <div class="col-lg-3 disable-scrollbars">
            <mat-card class="mat-card my-allocations">
                <mat-card-title class="title mb-0">
                    <strong>My Allocations</strong>
                </mat-card-title>
                <mat-card-content>
                    <div class="allocated">
                        <div class="counter">{{projectAllocatedCount}}</div>
                        <div class="description">Projects Allocated ({{projectAllocatedOccupancy}}% Occupancy)</div>
                    </div>
                    <div class="allocated-details">
                        <div *ngFor="let allocations of myAllocations; let count = index;">
                            <div *ngIf="allocations.status.value.toLowerCase() === 'active'">
                                <div class="detail row">
                                    <div class="project-name"><strong>{{allocations.project.identifier.value}}</strong>
                                        ({{allocations.project.identifier.key}})</div>
                                    <div class="col-6 col-md-4 col-lg-6 pl-0">
                                        <div class="detail-desc">
                                            <div class="c100 p{{allocations.occupancy}} small">
                                                <span class="percent">{{allocations.occupancy}}% <span
                                                        class="occupancy">Occupancy</span></span>
                                                <div class="slice">
                                                    <div class="bar"></div>
                                                    <div class="fill"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-8 col-lg-6 pl-0">
                                        <div class="account">
                                            <div class="label">Account</div>
                                            <div class="value">{{allocations.account.name}}</div>
                                        </div>
                                        <div class="status">
                                            <div class="label">Status</div>
                                            <div class="value">{{allocations.project.status.value}}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="detail-separator" *ngIf="count < myAllocations.length - 1"></div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-lg-3">
            <mat-card class="mat-card my-profile">
                <mat-card-title class="title">
                    <strong>My Profile</strong>
                </mat-card-title>
                <mat-card-content>
                    <div class="profile-details">
                        <div class="detail row">
                            <div class="col-4">
                                <img *ngIf="!imageUrl" class="circle" src="../assets/User_Profile_Pic.png" />
                                <img *ngIf="imageUrl" class="circle" [src]="imageUrl" />
                            </div>
                            <div class="col-8 pl-0">
                                <div [ngSwitch]="isAuthenticated()">
                                    <div *ngIf="account" class="name">
                                        {{(account.firstName + ' ' + account.lastName) | titlecase}}</div>
                                    <div *ngIf="resourceDetails" class="designation">
                                        {{resourceDetails.designationName  + ' - ' + resourceDetails.departmentName}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4"></div>

                        <div class="detail row">
                            <div class="col-12">
                                <a class="btn btn-primary btn-block" (click)="goToMyProfile(resourceCode)">View Full Profile</a>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="mat-card our-other-products">
                <mat-card-title class="title">
                    <strong>Our Other Products</strong>
                </mat-card-title>
                <mat-card-content>
                    <div class="products-details">
                        <div class="detail row">
                            <div class="col-4">
                                <div class="project-logo">
                                    <img src="../assets/Beat.png" alt="BEAT" title="BEAT" width="80px" />
                                </div>
                            </div>
                            <div class="col-8">
                                <div class="project-detail">
                                    Revolutionary Automated QA Testing and Auditing Tool
                                    <div class="mt-4">
                                        <a href="https://www.gspann.com/products/beat" target="_blank">Learn More</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="detail-separator"></div>

                        <div class="detail row">
                            <div class="col-4">
                                <div class="project-logo">
                                    <img src="../assets/Carbon.png" alt="CARBON" title="CARBON" width="80px" />
                                </div>
                            </div>
                            <div class="col-8">
                                <div class="project-detail">
                                    Configurable Auto-Generating Big Data Ingestion Engine
                                    <div class="mt-4">
                                        <a href="https://www.gspann.com/products/carbon" target="_blank">Learn More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card *jhiHasAnyAuthority="'RESOURCE_BROWSER_REPORT'" class="mat-card my-profile">
                <mat-card-content>
                    <div class="profile-details">
                        <div class="detail row">
                            <div class="col-12">
                                <div class="labelText">
                                    Resource Browser Details
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4"></div>

                        <div class="detail row">
                            <div class="col-12">
                                <button class="exportBrowser" mat-raised-button color="primary"
                                    (click)='exportBrowserReoprt()'>
                                    <i class="fas fa-download" style="padding-right: 5px"></i> Export Browser Report
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>