<mat-card class='main-container logout'>
    <div class="row">
        <div class="col-md-12">
            <p><i class="fa fa-unlock-alt fa-2x" aria-hidden="true"></i></p>
            <h2>You are now logged out. Thank you and have a great day!</h2>
        </div>
        <div class="col-md-12">
            <button class="btn btn-info" (click)="login()">Log Back In</button>
        </div>
    </div>
</mat-card>
