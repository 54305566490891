import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';

import { NgbDateMomentAdapter } from './util/datepicker-adapter';
import { Itrack2SharedLibsModule, Itrack2SharedCommonModule, HasAnyAuthorityDirective } from './';
import { HasAnyAuthorityConditionalDirective } from './auth/has-any-authority-conditional.directive';
import { TimeSheetSubmitModule } from 'app/timesheet-submit/timesheet-submit.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DisableAfterClickDirective } from 'app/directives/disable-after-click.directive';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_COMPLETE_DATE_FORMATS } from 'app/app.constants';
@NgModule({
    imports: [Itrack2SharedLibsModule, Itrack2SharedCommonModule, TimeSheetSubmitModule, NgxMatSelectSearchModule],
    declarations: [HasAnyAuthorityDirective, HasAnyAuthorityConditionalDirective, DisableAfterClickDirective],
    providers: [{ provide: NgbDateAdapter, useClass: NgbDateMomentAdapter },
        // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        // { provide: MAT_DATE_FORMATS, useValue: MY_COMPLETE_DATE_FORMATS },
        // {provide: MAT_DATE_LOCALE, useValue: 'en-US'}
    ],
    exports: [
        Itrack2SharedCommonModule,
        HasAnyAuthorityDirective,
        DisableAfterClickDirective,
        HasAnyAuthorityConditionalDirective,
        TimeSheetSubmitModule,
        NgxMatSelectSearchModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class Itrack2SharedModule { }
