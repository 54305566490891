// import { AppRoutingModule } from './app-routing.module';

import './vendor.ts';
import { NgModule, Injector, Injectable, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { Ng2Webstorage } from 'ngx-webstorage';
import { JhiEventManager } from 'ng-jhipster';
import { MaterialModule } from 'app/material.module';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSortModule, MatSort } from '@angular/material/sort';
import { MatPaginatorModule, MatPaginator } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthExpiredInterceptor } from 'app/blocks/interceptor/auth-expired.interceptor';
import { ErrorHandlerInterceptor } from 'app/blocks/interceptor/errorhandler.interceptor';
import { NotificationInterceptor } from 'app/blocks/interceptor/notification.interceptor';
import { Itrack2SharedModule } from 'app/shared';
import { Itrack2CoreModule, UserRouteAccessService } from 'app/core';
import { Itrack2AppRoutingModule } from 'app/app-routing.module';
import { Itrack2HomeModule } from 'app/home/home.module';
import { Itrack2EntityModule } from 'app/entities/entity.module';
import { PaginationConfig } from 'app/blocks/config/uib-pagination.config';
import { StateStorageService } from 'app/core/auth/state-storage.service';
import { UnderConstructionComponent } from 'app/timesheet-submit/under-construction/under-construction.component';
// jhipster-needle-angular-add-module-import JHipster will add new module here
import { JhiMainComponent, NavbarComponent, FooterComponent, ProfileService, PageRibbonComponent, ErrorComponent, TimesheetNotificationComponent } from 'app/layouts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ListAccountsComponent } from 'app/accounts/list-accounts/list-accounts.component';
import { ListAccountsService } from 'app/accounts/list-accounts/account.service';
import { AddAccountComponent, AddAccountDialog } from 'app/accounts/add-account/add-account.component';
import { AddAccountService } from 'app/accounts/add-account/add-account.service';
import { ViewAccountService } from 'app/accounts/view-account/view-account.service';
import { ViewAccountComponent } from 'app/accounts/view-account/view-account.component';
import { RemoveAccountConfirmDialogComponent } from 'app/dialog/remove-account-confirm-dialog/remove-account-confirm-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoaderInterceptor } from 'app/interceptors/loader-interceptor.service';
// import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NotificationsComponent } from 'app/interceptors/notifications/notifications.component';
import { NotificationsService } from 'app/interceptors/notifications/notifications.service';
// import { MonthdatepickerComponent } from './shared/monthdatepicker/monthdatepicker.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { LogoutComponent } from './logout/logout.component';
import { InvalidUserComponent } from './invalid-user/invalid-user.component';
import { StatementOfWorkService } from 'app/statement-of-work/statement-of-work.service';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { UpperCaseDirective } from './directives/upper-case.directive';
import { AllocationsService } from 'app/allocations/allocations.service';
import { FaqComponent } from './faq/faq.component';
import { TimesheetService } from './timesheet-submit/timesheet.service';
import { ExportTimesheetComponent } from './export-timesheet/export-timesheet.component';
import { DuplicatesTimesheetComponent } from './duplicates-view/duplicates-view.component';
import { ProjectService } from './projects/projects.service';
import { MasterDatabaseService } from './master-database/master-database.service';
import { TimesheetApprovalsService } from './timesheet-approvals/timesheet-approvals.service';
import { TrainingsComponent } from './trainings/trainings.component';
// import { EmailValidatorDirective } from './resource/view-resource/email-validator.directive';
// import { MonthPickerComponent } from 'app/month-picker/month-picker.component';
// import { AuditComponent } from './audit/audit.component';
import { EditOfficeComponent } from 'app/master-database/add-office-locations/add-office-locations.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReferralsService } from './Referrals/referrals.service';
import { makeReferralPopup, successfulSubmissionComponent } from './Referrals/submit-referral/submit-referral.component';
import { dataSavedSuccessfullyComponent } from './Referrals/referral-details/referral-details.component';
import { AllocationPendingComponent, ReviewAllocationDialog } from './allocations/add-allocation/add-allocation.component';
import { PrivacyPolicyComponent } from './privacypolicy/privacypolicy.component';
import { DownloadAppDialogComponent } from './download-app-dialog/download-app-dialog.component';
import { SubBenchSelectionDialogComponent } from './sub-bench-selection-dialog/sub-bench-selection-dialog.component';
import { LeaveUpdateConfirmationDialogComponent } from './leave-update-confirmation-dialog/leave-update-confirmation-dailog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { enviornmentAperture } from 'environments/environment';
import { DesignationsUploadComponent } from './designations-upload/designations-upload.component';

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: enviornmentAperture.keycloakURL,
        realm: 'aperture',
        clientId: 'aperture-ui',
      },
      initOptions: {
        onLoad: 'login-required',  // allowed values 'login-required', 'check-sso';
        checkLoginIframe: false,
      },
    });
}
@NgModule({
  declarations: [
      JhiMainComponent,
      NavbarComponent,
      ErrorComponent,
      PageRibbonComponent,
      FooterComponent,
      ListAccountsComponent,
      AddAccountComponent,
      ViewAccountComponent,
      RemoveAccountConfirmDialogComponent,
      ReviewAllocationDialog,
      AllocationPendingComponent,
      EditOfficeComponent,
      UnderConstructionComponent,
      NotificationsComponent,
      LogoutComponent,
      InvalidUserComponent,
      ForbiddenComponent,
      NotFoundComponent,
      UpperCaseDirective,
      FaqComponent,
      ExportTimesheetComponent,
      DuplicatesTimesheetComponent,
      TrainingsComponent,
      // AddHolidayDialogComponent,
      AddAccountDialog,
      LeaveUpdateConfirmationDialogComponent,
      makeReferralPopup,
      successfulSubmissionComponent,
      dataSavedSuccessfullyComponent,
      TimesheetNotificationComponent,
      PrivacyPolicyComponent,
      // MonthPickerComponent
      DownloadAppDialogComponent,
      SubBenchSelectionDialogComponent,
      DesignationsUploadComponent,
  ],
  imports: [
    BrowserModule,
    // OwlDateTimeModule,
    // OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    MaterialModule,
    Itrack2AppRoutingModule,
    Ng2Webstorage.forRoot({ prefix: 'jhi', separator: '-' }),
    Itrack2SharedModule,
    Itrack2CoreModule,
    Itrack2HomeModule,
    Itrack2EntityModule,
    ImageCropperModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatGridListModule,
    FormsModule,
    NgbModule,
    // FlexLayoutModule,
    NgxMatSelectSearchModule,
    MatProgressSpinnerModule,
    KeycloakAngularModule,
    MatSortModule,
    // Ng4LoadingSpinnerModule.forRoot()
    // jhipster-needle-angular-add-module JHipster will add new module here
  ],
  exports: [
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
],
providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: LoaderInterceptor,
        multi: true
    },
    HttpClientModule,
    ProfileService,
    TimesheetService,
    ListAccountsService,
    AddAccountService,
    ViewAccountService,
    UserRouteAccessService,
    StatementOfWorkService,
    AllocationsService,
    PaginationConfig,
    ProjectService,
    MasterDatabaseService,
    ReferralsService,
    TimesheetApprovalsService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthExpiredInterceptor,
        multi: true,
        deps: [StateStorageService, Injector]
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorHandlerInterceptor,
        multi: true,
        deps: [JhiEventManager]
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: NotificationInterceptor,
        multi: true,
        deps: [Injector]
    },
    NotificationsService,
    MatSort
],
  entryComponents: [
    TimesheetNotificationComponent,
    RemoveAccountConfirmDialogComponent,
    ReviewAllocationDialog,
    AllocationPendingComponent,
    EditOfficeComponent,
    makeReferralPopup,
    successfulSubmissionComponent,
    // AddHolidayDialogComponent,
    AddAccountDialog,
    LeaveUpdateConfirmationDialogComponent,
    dataSavedSuccessfullyComponent,
    SubBenchSelectionDialogComponent
],
  bootstrap: [JhiMainComponent]
})
export class Itrack2AppModule { }
