<!-- <ng-template> -->
    <div class="modal-header">
        <h4>HR Allocations</h4>
        <button type="button" class="close_allocation_pending" aria-label="Close" (click)="closeModalPopup()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="row">
        <div class="col-6 search-in-popup">
            <!-- <mat-form-field class="form_field_class">
                <span class="material-icons search-icon" matPrefix>&nbsp;search&nbsp; </span><input matInput
                    (keyup)="searchResourceInHRA($event)" placeholder="Search Resource" #input1>
            </mat-form-field> -->
            <mat-form-field class="designation">
                <span matPrefix>
                    <mat-icon matSuffix>search</mat-icon>&nbsp;&nbsp;
                </span>
                <input matInput
                    placeholder="Search Resource (eg. type resource id, resource name, email-id, technology etc.)"
                    aria-label="State" [matAutocomplete]="auto" (keyup)="searchResource($event.target.value)"
                    jhiHasAnyAuthorityConditional="RESOURCES_QUICK_SEARCH">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option class="resourceList" *ngFor="let resource of resourcesList" [value]="resource.name">
                        <div class="row" matTooltip="{{resource.emailId}}" matTooltipPosition="right">
                            <div class="col-sm-1">
                                <img class="example-option-img" *ngIf="resource.imageData;else defaultImage1"
                                    aria-hidden src="{{resource.imageData}}" height="25">
                                <ng-template #defaultImage1>
                                    <img class="example-option-img" aria-hidden
                                        src="../../assets/User_Profile_Pic.png" height="25">
                                </ng-template>
                            </div>

                            <div class="col-sm-2">
                                <span>{{resource.resourceCode}}</span>
                            </div>
                            <div class="col-sm-5 res-name">
                                <span>{{resource.name}}</span>
                            </div>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="col-6 search-in-popup">
            <button mat-raised-button color="primary" (click)="exportHRAllocation()">Export Allocations</button>
        </div>
    </div>
    <mat-table  [dataSource]="HRAllocationsDataSource" class="mat-elevation-z0 table-class">
        <ng-container matColumnDef="resourceName">
            <mat-header-cell *matHeaderCellDef class="cellColor"> Resource Name</mat-header-cell>
            <mat-cell *matCellDef="let row"> <a> <span matTooltipClass="my-tooltip" matTooltip="Resource Name - {{row.resourceName}}&#13; Email ID - {{row.resourceEmailId}}&#13; Resource ID - {{row.resourceCode}}&#13; PayRoll ID - {{row.payrollId}}" matTooltipPosition="right"
                        (click)="pendingCountResourceClicked(row)"> {{row.resourceName}} </span></a>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="accontName">
            <mat-header-cell *matHeaderCellDef class="cellColor"> Account</mat-header-cell>
            <mat-cell *matCellDef="let row"> <span matTooltip="{{row.accontName}}" matTooltipPosition="right">
                    {{row.accontName}} </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="projectName">
            <mat-header-cell *matHeaderCellDef class="cellColor"> Project</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <span *ngIf="row.projectName !== null" matTooltip="{{row.projectName}}" matTooltipPosition="right">
                    {{row.projectName}} </span>
                <span *ngIf="row.projectName === null"> N/A </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="allocationType">
            <mat-header-cell *matHeaderCellDef class="cellColor"> Allocation Type</mat-header-cell>
            <mat-cell *matCellDef="let row"> <span *ngIf="row.allocationType !== null">
                    {{row.allocationType.value}}</span>
                <span *ngIf="row.allocationType === null"> N/A </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="startDate">
            <mat-header-cell *matHeaderCellDef class="cellColor"> Start Date</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.startDate}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="endDate">
            <mat-header-cell *matHeaderCellDef class="cellColor"> End Date</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.endDate}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="occupancy">
            <mat-header-cell *matHeaderCellDef class="cellColor"> Occupancy</mat-header-cell>
            <mat-cell *matCellDef="let row"> <span *ngIf="row.occupancy !== null">{{row.occupancy}}</span> <span
                    *ngIf="row.occupancy === null"> N/A </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="remark">
            <mat-header-cell *matHeaderCellDef class="cellColor"> Remarks</mat-header-cell>
            <mat-cell *matCellDef="let row"> <span *ngIf="row.remark === '' || row.remark === null">
                    N/A
                </span>
                <span *ngIf="row.remark !== '' || row.remark !== null">{{row.remark}}</span>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="HRAllocationsDisplayedColumns" class="header-css"></mat-header-row>
        <mat-row *matRowDef="let row; columns: HRAllocationsDisplayedColumns;" class="matRow-css">
        </mat-row>
    </mat-table>
    <mat-paginator [ngClass]="{'d-none': !HRAllocationsDataSource.filteredData.length}" showFirstLastButtons [pageSizeOptions]="[5, 10, 15, 20]"></mat-paginator>
<!-- </ng-template> -->
<div class="ng-noresource" *ngIf="HRAllocationsDataSource.filteredData?.length === 0 && isAllocationPending">
        <p>
            <ngb-alert class="alert-noresource" type="info" [dismissible]="false">
                Resource not found.
            </ngb-alert>
        </p>
</div>