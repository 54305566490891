import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'jhi-under-construction',
    templateUrl: './under-construction.component.html',
    styleUrls: []
})
export class UnderConstructionComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
