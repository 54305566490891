import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment/moment';
import { TIMEZONE_DEFAULT_DATE_FORMAT, TIMEZONE_DEFAULT_DATE_FORMAT_SHORT } from 'app/app.constants';

@Pipe({
    name: 'timezone'
})
export class TimezonePipe implements PipeTransform {
    transform(dateTime: any, format?: any): any {
        // Below statements are used to extract short abbreviation from date time string (Indian Standard Time) to IST
        let datetimeStr = new Date().toString();
        let start = datetimeStr.indexOf('(');
        let finish = datetimeStr.indexOf(')');
        let datetimeStrAcronym = datetimeStr.substr(start + 1, finish - start - 1);
        let timezoneAbbreviation = datetimeStrAcronym.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '');
        let timezoneOffset = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
        if (dateTime) {
            timezoneOffset = new Date(dateTime).toString().match(/([-\+][0-9]+)\s/)[1];
            if (format && format.toLowerCase() === 'default') {
                return moment().utcOffset(timezoneOffset).format(TIMEZONE_DEFAULT_DATE_FORMAT) + ' (' + timezoneAbbreviation + ')';
            } else {
                // converting +530 time offset into -530 and vice-versa as per the requirement of momemmt.utc() method
                if (timezoneOffset.includes('+')) {
                    timezoneOffset = timezoneOffset.slice(1, timezoneOffset.length);
                    timezoneOffset = '-' + timezoneOffset;
                } else {
                    timezoneOffset = timezoneOffset.slice(1, timezoneOffset.length);
                    timezoneOffset = '+' + timezoneOffset;
                }

                return moment.utc(dateTime + timezoneOffset).format(TIMEZONE_DEFAULT_DATE_FORMAT_SHORT) + ' (' + timezoneAbbreviation + ')';
            }
        }
    }
}
