<div class="sub-bench-modal">
    <span class="heading-leave-review-modal">Select Sub Bench</span>
    <span class="fa fa-remove close-icon p-0 pull-right" (click)="closeDialog()"></span>
    
    <mat-form-field  class="heading-leave-review-modal-selection" >
        <mat-select placeholder="Select Sub Bench" (selectionChange)="subBenchStatusSelected($event)">
            <mat-option *ngFor="let subBench of benchStatusList" [value]="subBench">{{subBench}}</mat-option>
        </mat-select>
    </mat-form-field>
    <button type="submit" (click)="sendSubBenchStatus()" mat-raised-button color="primary"  class="ml-4">Submit</button>
</div>
<div class="dark-overlay" *ngIf="load">
    <mat-spinner class="loader" strokeWidth="3" [diameter]="50"></mat-spinner>
</div>